/** @author Artem Bakulin <dekkyartem@gmail.com> */

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
    card: {
        boxShadow: '1mm 1mm 5mm rgba(0, 0, 0, 0.1)',
        backgroundColor: '#fff',
        textAlign: 'center',
        padding: '2.5mm',
        width: '100%',
    },
    indicator: {
        fontSize: '3mm',
        fontWeight: 600,
        opacity: 0.5,
    },
    value: {
        fontSize: '6mm',
        fontWeight: 'bold',
        margin: '4mm auto',
        color: 'rgb(14, 42, 60)',
    },
    iconUp: {
        color: '#2ad089',
    },
    iconDown: {
        color: '#f4508d',
    },
    increase: {
        display: 'flex',
        justifyContent: 'center',
        gap: '1mm',
        alignItems: 'center',
        fontSize: '3mm',
        marginBottom: '3.5mm',
    },
    prevValue: {
        opacity: 0.5,
        fontSize: '3mm',
    }
}));

/**
 * Первая страница раздела "Посещаемость" годового отчета.
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import { CategoryAxis, CircleBullet, LabelBullet, LineSeries, ValueAxis } from '@amcharts/amcharts4/charts';
import { Grid, Typography, WithStyles, withStyles } from '@material-ui/core';
import React from 'react';

import XYChart from '../../../components/Charts/XYChart';
import { PolyglotSingleton } from '../../../lib/services/translation';
import { IYearSummaryData } from '../../../types/analytics';
import { styles } from '../styles';
import { getChartData } from '../YearSummary';

interface IProps extends WithStyles<typeof styles> {
    data: IYearSummaryData[];
}

const polyglot = PolyglotSingleton.getInstance();

const VisitsPage1 = ({ data, classes }: IProps) => {
    const getCharts = (): React.ReactElement[] =>
        ['carsVisits', 'taxiVisits', 'busVisits'].map((type: string) => {
            const yAxe = new ValueAxis();
            const xAxe = new CategoryAxis();
            const serie = new LineSeries();
            const bullet = new CircleBullet();
            const label = new LabelBullet();

            label.label.text = '{value}';
            label.label.dy = -15;
            xAxe.dataFields.category = 'date';
            serie.dataFields.categoryX = 'date';
            serie.dataFields.valueY = 'value';
            serie.bullets.push(label);
            serie.bullets.push(bullet);

            return (
                <Grid item key={type} className={classes.chartWrapper}>
                    <Typography variant="h5" className={classes.title}>
                        {`${polyglot.t('visits.byType')} (${polyglot.t(
                            `visits.labels.${type.replace('Visits', '')}`,
                        )})`}
                    </Typography>
                    <XYChart
                        data={getChartData(data, 'visitsByType', type as keyof IYearSummaryData['visitsByType'])}
                        id={`visitsByType_${type}`}
                        yAxes={[yAxe]}
                        xAxes={[xAxe]}
                        series={[serie]}
                        className={classes.chart}
                    />
                </Grid>
            );
        });

    return (
        <Grid container spacing={3} alignItems="stretch" direction="column" wrap="nowrap" className={classes.chartRow}>
            {getCharts()}
        </Grid>
    );
};

export default withStyles(styles)(VisitsPage1);

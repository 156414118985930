import { Button, Grid, Typography, WithStyles, withStyles } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import CalendarToday from '@material-ui/icons/CalendarToday';
import React, {useCallback, useState} from 'react';
import { useSelector } from 'react-redux';

import AnalyticsCalendar from '../../containers/AnalyticsCalendar';
import { getDatesPeriodByCurrentPeriod, getLastDatesPeriodByType } from '../../lib/services/dates.periods.service';
import { PolyglotSingleton } from '../../lib/services/translation';
import { datePeriodString } from '../../lib/utils/strings';
import { calendarSelector } from '../../selectors/common.selectors';
import { IDatesPeriod, LastPeriodTypeEnum } from '../../types/date.periods';
import PageHeader from '../PageHeader';

import { styles } from './styles';

interface IProps extends WithStyles<typeof styles> {
    title: string;
    // tslint:disable-next-line:no-null-undefined-union
    action?: React.ReactNode;
}

const polyglot = PolyglotSingleton.getInstance();

const AnalyticsPageHeader = ({ title, action, classes }: IProps) => {
    const calendar = useSelector(calendarSelector);
    const currentPeriod = getDatesPeriodByCurrentPeriod(calendar.currentPeriod);
    const { lastPeriodType } = calendar;
    const lastPeriod =
        lastPeriodType !== LastPeriodTypeEnum.Custom
            ? getLastDatesPeriodByType(calendar.currentPeriod, lastPeriodType)
            : (calendar.customLastPeriod as IDatesPeriod);

    // Открыть-закрыть календарь
    const [isOpenCalendar, setOpenCalendar] = useState(false);
    const toggleCalendar = useCallback(() => {
        setOpenCalendar(!isOpenCalendar);
    }, [setOpenCalendar, isOpenCalendar]);

    const analyticsCalendarBlock = (
        <Grid item className={classes.periods}>
            <AnalyticsCalendar toggleCalendar={toggleCalendar} />
        </Grid>
    )

    return (
        <PageHeader>
            <Grid item container wrap="nowrap" alignItems="center" justify="space-between">
                <Typography variant="h1" className={classes.title}>
                    {title}&nbsp;{action !== undefined ? action : null}
                </Typography>
            </Grid>
            <Grid className={classes.calendarWrapper}>
                <div className={classes.calendarButtonWrapper}>
                    <Button variant="outlined" color="primary" onClick={toggleCalendar} className={classes.calendarButton}>
                        <CalendarToday />
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'start', marginLeft: 5}}>
                            <div className={classes.currentPeriodValue}>
                                &nbsp;{polyglot.t('dashboard.currentPeriod')}{' '}
                                &nbsp;{datePeriodString(currentPeriod.from, currentPeriod.to, polyglot.t('common.dates.date'))}
                            </div>
                            {calendar.isCompareEnabled ? (
                                <div className={classes.compareToPeriodValue}>
                                    &nbsp;{polyglot.t('dashboard.compareToPeriod')}{' '}
                                    &nbsp;{datePeriodString(lastPeriod.from, lastPeriod.to, polyglot.t('common.dates.date'))}
                                </div>
                            ): null}
                        </div>
                        {isOpenCalendar ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    </Button>
                </div>
                {isOpenCalendar ? analyticsCalendarBlock : null}
            </Grid>
        </PageHeader>
    );
};

export default withStyles(styles)(AnalyticsPageHeader);

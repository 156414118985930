/**
 * Страница раздела "Dashboard" месячного отчета.
 *
 * @author Artem Bakulin <dekkyartem@gamil.com>
 */

import { Grid, WithStyles, withStyles } from '@material-ui/core';
import React, { useMemo } from 'react';
import { ApiChartValuesType } from '../../../components/Charts/ApiChart/types';

import IndicatorCard from '../../../components/IndicatorCard';
import { PolyglotSingleton } from '../../../lib/services/translation';
import { durationString } from '../../../lib/utils/strings';
import { IChartSummaryData, ICustomSummaryData, IMonthSummaryData } from '../../../types/analytics';
import { NumberFormatFunction, NumberLocaleFormat, PeriodFormatFunction } from '../../../types/global';
import Chart from '../Chart';
import { styles } from '../styles';
import { CHART_DATE_FORMAT, formatPeriodDate } from '../Summary';

interface IProps extends WithStyles<typeof styles> {
    data: ICustomSummaryData | IMonthSummaryData;
    id: string;
    chartType?: 'column' | 'line';
    periodFormat?: string | PeriodFormatFunction;
    rotateLabels?: number;
}

type ResultIndicator = 'visits' | 'duration' | 'long_duration' | 'new_visitors' | 'rich_visitors' | 'regular_visitors';
type ResultIndicatorKey =
    | 'visits'
    | 'avgVisitDuration'
    | 'longVisitsPercent'
    | 'newVisitors'
    | 'regularVisitors'
    | 'richVisitors';

const polyglot = PolyglotSingleton.getInstance();

const indicatorToDataMap: {
    [indicator in ResultIndicator]: {
        title: string;
        key: ResultIndicatorKey;
        valueFormat?: NumberLocaleFormat;
        chartValueFormat?: string | NumberFormatFunction;
        minY?: number;
        valuesType?: ApiChartValuesType;
    };
} = {
    visits: {
        title: 'dashboard.visits',
        key: 'visits',
        minY: 0,
    },
    duration: {
        title: 'dashboard.durationShort',
        key: 'avgVisitDuration',
        valueFormat: durationString,
        chartValueFormat: durationString,
        minY: 3000000,
        valuesType: 'duration',
    },
    long_duration: {
        title: 'dashboard.longVisits',
        key: 'longVisitsPercent',
        valueFormat: { style: 'percent', maximumFractionDigits: 0 },
        chartValueFormat: '#.%',
        minY: 0,
        valuesType: 'percent',
    },
    new_visitors: {
        title: 'dashboard.newVisitors',
        key: 'newVisitors',
        minY: 0,
    },
    regular_visitors: {
        title: 'dashboard.regularVisitors',
        key: 'regularVisitors',
        minY: 0,
    },
    rich_visitors: {
        title: 'dashboard.richVisitors',
        key: 'richVisitors',
        minY: 0,
    },
};

const getData = (data: IProps['data'], key: ResultIndicatorKey, periodFormat: string | PeriodFormatFunction) => [
    ...('comporable' in data
        ? [
              {
                  period: formatPeriodDate(data.comporable[0].date, periodFormat),
                  value: data.comporable[0][key] ?? 0,
              },
          ]
        : []),
    ...data.months.map((row: IChartSummaryData) => ({
        period: formatPeriodDate(row.date, periodFormat),
        value: row[key] ?? 0,
    })),
];

const DashboardPage = ({ data, classes, id, chartType = 'line', periodFormat = CHART_DATE_FORMAT, rotateLabels }: IProps) => {
    const currentResult = useMemo(() => data.months[data.months.length - 1], [data]);
    const lastResult = useMemo(() => data.months[data.months.length - 2], [data]);
    const comporable = 'comporable' in data ? formatPeriodDate(data.comporable[0].date, periodFormat) : undefined;

    return (
        <>
            <Grid container spacing={2} alignItems="stretch" className={classes.indicatorsRow}>
                {Object.keys(indicatorToDataMap).map(key => {
                    const indicator = indicatorToDataMap[key as ResultIndicator];

                    return (
                        <Grid item xs={2} key={key}>
                            <IndicatorCard
                                className={classes.fullHeight}
                                title={polyglot.t(indicator.title)}
                                value={currentResult[indicator.key] ?? 0}
                                lastValue={lastResult[indicator.key] ?? 0}
                                valueFormat={indicator.valuesType}
                            />
                        </Grid>
                    );
                })}
            </Grid>
            <Grid container spacing={2} alignItems="stretch" className={classes.chartRow}>
                {Object.keys(indicatorToDataMap).map(key => {
                    const indicator = indicatorToDataMap[key as ResultIndicator];

                    return (
                        <Grid item xs={4} key={key}>
                            <Chart
                                id={`chartDiv${key}_${id}`}
                                data={getData(data, indicator.key, periodFormat)}
                                title={polyglot.t(indicator.title)}
                                numberFormat={indicator.chartValueFormat}
                                seriesType={chartType}
                                minY={indicator.minY}
                                comporableCategory={comporable}
                                rotateLabels={rotateLabels}
                            />
                        </Grid>
                    );
                })}
            </Grid>
        </>
    );
};

export default withStyles(styles)(DashboardPage);

/**
 * Reducers для глобального состояния приложения
 *
 * @author Artem Bakulin
 */

import { Action, handleActions } from 'redux-actions';

import { UserRoles } from '../acl';
import { globalActions } from '../actions/global';
import { usersActions } from '../actions/users';
import { INotification, Language } from '../types/global';
import { IUserOrganization } from '../types/organizations';
import { IUser } from '../types/users';

/* tslint:disable:no-null-keyword */

export interface IGlobalState {
    error?: Error;
    isInitialized: boolean;
    notifications: INotification[];
    redirectTo?: string;
    selectedOrganization?: IUserOrganization;
    user?: IUser;
    isSmartLook: boolean;
}

export const initialState: IGlobalState = {
    notifications: [],
    isInitialized: false,
    isSmartLook: false,
};

/**
 * Reducers для глобального состояния приложения
 */
// tslint:disable-next-line:no-any
export const globalReducer = handleActions<IGlobalState, any>(
    {
        // Инициализация приложенияий
        [globalActions.global.initialize.success.toString()]: (
            state: IGlobalState,
            action: Action<Partial<IGlobalState>>,
        ) => ({
            ...state,
            ...action.payload,
            user:
                action.payload.user !== undefined
                    ? {
                          ...action.payload.user,
                          roles: action.payload.user.roles.filter(role => UserRoles.includes(role)),
                      }
                    : action.payload.user,
            isInitialized: true,
        }),
        // Нотификации
        [globalActions.global.notifications.add.toString()]: (state: IGlobalState, action: Action<INotification>) => ({
            ...state,
            notifications: [...state.notifications, action.payload],
        }),
        [globalActions.global.notifications.delete.toString()]: (state: IGlobalState, action: Action<number>) => ({
            ...state,
            notifications: state.notifications.filter((_, index) => index !== action.payload),
        }),
        // Редирект
        [globalActions.global.redirectTo.toString()]: (state: IGlobalState, action: Action<string>) => ({
            ...state,
            redirectTo: action.payload,
        }),
        // Аутентификация пользователя
        [usersActions.user.authenticate.toString()]: (state: IGlobalState, action: Action<IUser>) => ({
            ...state,
            user: {
                ...action.payload,
                roles: action.payload.roles.filter(role => UserRoles.includes(role)),
            },
            selectedOrganization: action.payload.organizations.filter(org => org.type === 'retail')[0],
        }),
        // Деавторизация пользователя
        [usersActions.user.logout.success.toString()]: (state: IGlobalState) => ({
            ...state,
            user: undefined,
            selectedOrganization: undefined,
        }),
        [usersActions.user.changeLanguage.change.toString()]: (state: IGlobalState) => ({
            ...state,
            isInitialized: false,
        }),
        [usersActions.user.changeLanguage.success.toString()]: (state: IGlobalState, action: Action<Language>) => ({
            ...state,
            user: {...state.user as IUser, lang: action.payload},
            isInitialized: true,
        }),
        // Изменение выбранной организации
        [globalActions.global.changeOrganization.toString()]: (state: IGlobalState, action: Action<number>) => ({
            ...state,
            selectedOrganization: (state.user as IUser).organizations.find((org) => org.id === action.payload),
        }),
        // Изменение языка
        [globalActions.global.changeLanguage.change.toString()]: (state: IGlobalState) => ({
            ...state,
            isInitialized: false,
        }),
        [globalActions.global.changeLanguage.success.toString()]: (state: IGlobalState) => ({
            ...state,
            isInitialized: true,
        }),
    },
    initialState,
);

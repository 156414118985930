import { object, string } from 'yup';

export const schema = object().shape({
    name: string()
        .trim()
        .required('Обязательное поле'),
    contactPerson: string().nullable(),
    email: string()
        .email('Неверный формат email адреса')
        .nullable(),
    phone: string().nullable(),
    site: string()
        .url('Неверный формал url адреса организации')
        .nullable(),
    description: string().nullable(),
});

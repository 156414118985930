import { Button, Grid, MenuItem, WithStyles, withStyles } from '@material-ui/core';
import { AxiosError, AxiosResponse } from 'axios';
import { Field, Form, Formik } from 'formik';
import { CheckboxWithLabel } from 'formik-material-ui';
import React from 'react';

import api, { IImportBatchStoplistForm } from '../../api/organizations.api';
import TextField from '../../components/Formik/TextField';
import { PolyglotSingleton } from '../../lib/services/translation';
import { IUserOrganization } from '../../types/organizations';

import { schema } from './schema.batch';
import { styles } from './styles';

interface IProps extends WithStyles<typeof styles> {
    organization: IUserOrganization;
    onCancel?(): void;
    onSubmit?(): void;
    onError?(message: string): void;
}

interface IStoplistErrorPlates {
    message: Array<{
        property: string;
        children: Array<{ value: { plate: string } }>;
        value: Array<{ plate: string }>
    }>;
    statusCode: number;
}

const polyglot = PolyglotSingleton.getInstance();

// tslint:disable-next-line:no-any
const submit = (
    organization: IUserOrganization,
    onSubmit?: () => void,
    onError?: (message: string) => void,
) => async (values: IImportBatchStoplistForm) => {
    try {
        await api.importBatchStopList(organization, values);

        if (typeof onSubmit === 'function') {
            onSubmit();
        }
    } catch (e) {
        if (typeof onError === 'function') {
            if ((e as any).isAxiosError) {
                const response = (e as AxiosError).response as AxiosResponse<IStoplistErrorPlates>;
                if (response.data.statusCode === 400) {
                    const plates = response.data.message
                        .filter(item => item.property === 'items')[0]
                        .children.map(item => item.value.plate)
                        .join(', ');

                    onError(`${polyglot.t('stoplist.errors.plates')}: ${plates}`);

                    return;
                }
            }

            onError(polyglot.t('stoplist.errors.unknown'));
        }
    }
};

const ImportStoplistBatchForm = ({ classes, onSubmit, onCancel, organization, onError }: IProps) => (
    <Formik
        initialValues={{ type: 'all', items: '', rewrite: false }}
        onSubmit={submit(organization, onSubmit, onError)}
        validationSchema={schema}
    >
        <Form>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Field
                        name="type"
                        label={polyglot.t('stoplist.labels.listType')}
                        variant="outlined"
                        select
                        component={TextField}
                        className={classes.select}
                    >
                        <MenuItem value="all">{polyglot.t('stoplist.labels.fullList')}</MenuItem>
                        <MenuItem value="employee">{polyglot.t('stoplist.labels.employees')}</MenuItem>
                        <MenuItem value="commercial">{polyglot.t('stoplist.labels.commercial')}</MenuItem>
                    </Field>
                </Grid>
                <Grid item xs={12}>
                    <Field
                        name="rewrite"
                        Label={{ label: polyglot.t('stoplist.form.rewrite') }}
                        fullWidth
                        component={CheckboxWithLabel}
                        type="checkbox"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Field
                        name="items"
                        label={polyglot.t('stoplist.fromBuffer.list')}
                        fullWidth
                        variant="outlined"
                        multiline
                        rows={10}
                        component={TextField}
                    />
                </Grid>
                <Grid item xs={12} container justify="center" className={classes.actions}>
                    <Button variant="outlined" onClick={onCancel}>
                        {polyglot.t('common.buttons.cancel')}
                    </Button>
                    <Button variant="contained" color="primary" type="submit">
                        {polyglot.t('common.buttons.import')}
                    </Button>
                </Grid>
            </Grid>
        </Form>
    </Formik>
);

export default withStyles(styles)(ImportStoplistBatchForm);

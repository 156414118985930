import { LinearProgress, WithStyles, withStyles } from '@material-ui/core';
import React from 'react';

import { styles } from './styles';

interface IProps extends WithStyles<typeof styles> {}

const PageProgress = ({ classes }: IProps) => (
    <div className={classes.root}>
        <div className={classes.toolbar} />
        <LinearProgress />
    </div>
);

export default withStyles(styles)(PageProgress);

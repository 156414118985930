/**
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import { ChartData } from 'chart.js';
import moment from 'moment';
import React from 'react';

import { getPeriods } from '../../api/analytics.api';
import { DatasetExtended, DetailedApiChart } from '../../components/Charts/DetailedApiChart';
import { CHART_SERIES_COLORS, CHART_STACKED_SERIES_COLORS } from '../../components/Charts/DetailedApiChart/constants';
import { getDateCategories } from '../../components/Charts/DetailedApiChart/helpers';
import { PolyglotSingleton } from '../../lib/services/translation';
import { getPeriodFormat } from '../../lib/utils/dates';
import { datePeriodString, datesPeriodFromString } from '../../lib/utils/strings';
import { IAnalyticsCalendarState } from '../../reducers/analytics.calendar';
import { DetailedAnalyticsPeriod } from '../../types/analytics';
import { AnalyticsDataGranularity, IDatesPeriod } from '../../types/date.periods';

const polyglot = PolyglotSingleton.getInstance();
const carTypes = ['cars', 'taxi', 'bus', 'vans'];
const carLabels = {
    bus: 'visits.labels.bus',
    taxi: 'visits.labels.taxi',
    cars: 'visits.labels.cars',
    vans: 'visits.labels.vans',
}

const getDatesetByPeriods = (
    data: DetailedAnalyticsPeriod[],
    seriesNames: string[],
    periodIndex: number,
) => ({
    label: seriesNames[periodIndex],
    backgroundColor: CHART_SERIES_COLORS[periodIndex],
    data: carTypes.map(type => data[0][type]),
});

const getDatasetsByDates = (
    data: DetailedAnalyticsPeriod[][],
    seriesNames: string[],
    periods: IDatesPeriod[],
): DatasetExtended[] => {
    const datasets: DatasetExtended[] = [];

    data.forEach((period, periodIndex) => {
        carTypes.forEach((type, typeIndex) => {
            // tslint:disable-next-line:strict-boolean-expressions
            const bgColor = seriesNames[1]
                ? CHART_STACKED_SERIES_COLORS[periodIndex][typeIndex]
                : CHART_STACKED_SERIES_COLORS[1][typeIndex];

            datasets.push({
                label: `${polyglot.t(carLabels[type as keyof typeof carLabels])}${(new Array(periodIndex)).fill(' ').join()}`,
                title: seriesNames[periodIndex],
                stack: periodIndex.toString(),
                backgroundColor: bgColor,
                data: period.map(row => row[type]),
                period: periods[periodIndex],
                dates: period.map(row => moment(row.date).toDate()),
            });
        });
    });

    return datasets;
}

const dataToSeries = (
    data: DetailedAnalyticsPeriod[][],
    calendar: IAnalyticsCalendarState,
    granularity: AnalyticsDataGranularity | undefined,
): ChartData<'bar'> => {
    const periodFormat = getPeriodFormat(calendar.currentPeriodType, calendar.lastPeriodType);
    const periods = getPeriods(calendar).map(period => datesPeriodFromString(period, 'YYYY-MM-DD', ';')).reverse();
    const seriesNames = periods.map(period => datePeriodString(period.from, period.to, periodFormat, '–'));
    const series = {
        labels: granularity !== undefined
            ? getDateCategories(data, granularity, datesPeriodFromString(getPeriods(calendar)[0], 'YYYY-MM-DD', ';'))
            : carTypes.map(carType => polyglot.t(carLabels[carType as keyof typeof carLabels])),
        datasets: granularity !== undefined
            ? getDatasetsByDates(data.reverse(), seriesNames, periods)
            : data.reverse().map((period, index) => getDatesetByPeriods(period, seriesNames, index)),
    };

    return series;
}

export const ChartByType = () => (
    <DetailedApiChart
        title={polyglot.t('visits.byType')}
        chartApi="visits-by-type"
        dataToSeries={dataToSeries}
        height={300}
    />
);


import { createStyles, lighten, Theme } from '@material-ui/core';
import { CHART_FIRST_SERIE_COLOR, CHART_SECOND_SERIE_COLOR } from '../Charts/DetailedApiChart/constants';

export const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: theme.breakpoints.values.md,
        },
        selected: {
            fontWeight: 'bold',
            fontStyle: 'italic',
        },
        initialPeriod: {
            color: theme.palette.text.disabled,
            fontStyle: 'italic',
        },
        selectedPeriod: {
            fontWeight: 'bold',
            color: '#000',
        },
        period: {
            display: 'block',
            position: 'relative',
            paddingLeft: 20,
            '&:after': {
                content: `''`,
                position: 'absolute',
                left: 0,
                top: '50%',
                width: 14,
                height: 14,
                marginTop: -7,
                borderRadius: 100,
            }
        },
        currentPeriod: {
            '&:after': {
                background: CHART_SECOND_SERIE_COLOR,
            }
        },
        lastPeriod: {
            '&:after': {
                background: CHART_FIRST_SERIE_COLOR,
            }
        },
        datePicker: {
            ...theme.typography.body1,
            fontSize: '1rem',
            lineHeight: 1,
            width: '100%',
            marginTop: theme.spacing(3),

            '& *': {
                outline: 'none',
            },

            '& .DayPicker-Months': {
                justifyContent: 'space-between',
            },

            '& .DayPicker-Day': {
                width: 36,
                height: 36,
                borderRadius: 0,
            },

            '& .DayPicker-Day--lastOutside': {
                color: '#DCE0E0',
                cursor: 'default',
            },
            '&.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover': {
                backgroundColor: lighten(CHART_SECOND_SERIE_COLOR, 0.25),
                color: theme.palette.getContrastText(CHART_SECOND_SERIE_COLOR),
            },
            '& .DayPicker-Day--selected:not(.DayPicker-Day--outside)': {
                backgroundColor: CHART_SECOND_SERIE_COLOR,
                borderRadius: 0,
                color: theme.palette.getContrastText(CHART_SECOND_SERIE_COLOR),
            },

            // Day styles when custom periods overlaps
            '& .DayPicker-Day--lastRange.DayPicker-Day--selected:not(.DayPicker-Day--outside)': {
                backgroundColor: CHART_FIRST_SERIE_COLOR,
                color: theme.palette.getContrastText(CHART_FIRST_SERIE_COLOR),
            },
            '& .DayPicker-Day--lastEnteredTo:not(.DayPicker-Day--lastEnteredStart):not(.DayPicker-Day--lastEnteredEnd):not(.DayPicker-Day--outside)': {
                backgroundColor: CHART_FIRST_SERIE_COLOR,
                color: theme.palette.getContrastText(CHART_FIRST_SERIE_COLOR),
                borderRadius: 0,
            },
            '& .DayPicker-Day--lastRange:not(.DayPicker-Day--outside)': {
                backgroundColor: CHART_FIRST_SERIE_COLOR,
                color: theme.palette.getContrastText(CHART_FIRST_SERIE_COLOR),
                borderRadius: 0,
            },

            '& .DayPicker-Day--lastEnteredStart:not(.DayPicker-Day--outside)': {
                backgroundColor: CHART_FIRST_SERIE_COLOR,
                color: theme.palette.getContrastText(CHART_FIRST_SERIE_COLOR),
                borderRadius: 0,
            },

            '& .DayPicker-Day--lastEnteredStart:hover': {
                backgroundColor: `${lighten(CHART_FIRST_SERIE_COLOR, 0.25)} !important`,
            },
            '& .DayPicker-Day--lastStart:hover': {
                backgroundColor: `${lighten(CHART_FIRST_SERIE_COLOR, 0.25)} !important`,
            },
            '& .DayPicker-Day--lastEnd:hover': {
                backgroundColor: `${lighten(CHART_FIRST_SERIE_COLOR, 0.25)} !important`,
            },
            '& .DayPicker-Day--lastRange:hover': {
                backgroundColor: `${lighten(CHART_FIRST_SERIE_COLOR, 0.25)} !important`,
            },
        },
        // Day color on hover when selecting compare period
        selectingLastPeriod: {
            '&.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover': {
                backgroundColor: `${lighten(CHART_FIRST_SERIE_COLOR, 0.25)} !important`,
            },
            '& DayPicker-Day--lastEnteredStart.DayPicker-Day--lastEnteredTo': {
                backgroundColor: `${lighten(CHART_FIRST_SERIE_COLOR, 0.25)} !important`,
            }
        }
    });

/**
 * Страница "Частота".
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import { LinearProgress } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';

import AnalyticsPageHeader from '../../components/AnalyticsPageHeader';
import Layout from '../../containers/Layout';
import { PolyglotSingleton } from '../../lib/services/translation';
import { pageProgressSelector } from '../../selectors/analytics.selectors';

import ResultData from './ResultData';

const polyglot = PolyglotSingleton.getInstance();

const FrequencyPage = () => {
    const inProgress = useSelector(pageProgressSelector('frequency'));

    return (
        <Layout>
            <AnalyticsPageHeader title={polyglot.t('frequency.header')} />
            {inProgress ? <LinearProgress /> : null}
            <ResultData />
        </Layout>
    );
};

export default FrequencyPage;

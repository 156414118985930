/** @author Artem Bakulin <dekkyartem@gmail.com> */

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
    header: {
        width: '100%',
        fontSize: '2mm',
        marginBottom: '6mm',
    },
    hr: {
        marginTop: '1.5mm',
        width: '100%',
        height: 1,
        background: 'linear-gradient(275.65deg, #01D4D3 -28.05%, #000000 -0.13%, #21628C 42.04%, #E75791 72.12%, #EB2446 90.14%, #F2C215 106%)',
    },
}));

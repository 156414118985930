import { Button, Grid } from '@material-ui/core';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import * as React from 'react';

import usersApi from '../../api/users.api';
import TextField from '../../components/Formik/TextField';
import { localize, PolyglotSingleton } from '../../lib/services/translation';
import { IUser } from '../../types/users';

import { schema } from './schema';

interface IAuthorizeData {
    email: string;
    password: string;
}

interface IProps {
    authenticate(user: IUser): void;
}

const polyglot = PolyglotSingleton.getInstance();

class LoginForm extends React.PureComponent<IProps> {
    public render() {
        return (
            <Formik
                initialValues={{ email: '', password: '' }}
                onSubmit={this.onSubmit}
                validationSchema={schema}
                validateOnBlur={false}
                validateOnChange={false}
            >
                <Form>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Field
                                name="email"
                                label={polyglot.t('common.user.email')}
                                type="email"
                                variant="outlined"
                                fullWidth
                                component={TextField}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                name="password"
                                label={polyglot.t('common.user.password')}
                                type="password"
                                variant="outlined"
                                fullWidth
                                component={TextField}
                            />
                        </Grid>
                        <Grid item xs={12} container justify="center">
                            <Button type="submit" variant="outlined" color="secondary" size="large">
                                {polyglot.t('common.user.signIn')}
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            </Formik>
        );
    }

    private readonly onSubmit = async (
        values: IAuthorizeData,
        { setSubmitting, setFieldError }: FormikHelpers<IAuthorizeData>,
    ) => {
        setSubmitting(true);

        try {
            const user = await usersApi.authenticate(values.email, values.password);

            if (user.lang !== null) {
                try { localStorage.setItem('lng', user.lang); }
                // tslint:disable-next-line:no-empty
                catch (_) {};
            }

            await localize();

            setSubmitting(false);
            this.props.authenticate(user);
        } catch (error) {
            setFieldError('email', polyglot.t('common.user.error'));
            setSubmitting(false);
        }
    };
}

export default LoginForm;

/**
 * Вторая страница "Сводка" PDF отчета.
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import moment from 'moment';
import React from 'react';

import { ColumnChart } from '../../../components/Pdf/Charts/ColumnChart';
import { calcIncrease } from '../../../components/Pdf/Charts/helpers';
import { Typo } from '../../../components/Pdf/Typo';
import { PolyglotSingleton } from '../../../lib/services/translation';
import { IChartSummaryData, IMonthSummaryData } from '../../../types/analytics';

import { useStyles } from './styles';

interface IProps {
    data: IMonthSummaryData;
}

const polyglot = PolyglotSingleton.getInstance();

const getChartData = (key: Exclude<keyof IChartSummaryData, 'date'>, data: IMonthSummaryData): number[] => [
    data.comporable[0][key] as number,
    ...data.months.slice(1).map(row => row[key] as number),
];

export const SummaryPage2 = ({ data }: IProps) => {
    const classes = useStyles();

    const labels = [
        moment(data.comporable[0].date).format('MMM YY'),
        ...data.months.slice(1).map(row => moment(row.date).format('MMM YY')),
    ];
    const getData = (key: Exclude<keyof IChartSummaryData, 'date'>) => getChartData(key, data);
    const getYearIncrease = (key: Exclude<keyof IChartSummaryData, 'date'>) => {
        const keyData = getData(key);
        const yearIncrease = new Array(keyData.length).fill(null);

        yearIncrease[yearIncrease.length - 1] = calcIncrease(keyData[keyData.length - 1], keyData[0]);

        return yearIncrease;
    };
    const getMonthIncrease = (key: Exclude<keyof IChartSummaryData, 'date'>) => {
        const keyData = getData(key);
        const rawData = data.months.map(row => row[key] as number);

        return keyData.map((value, index) => (index > 0 ? calcIncrease(value, rawData[index - 1]) : null));
    };
    const weekData = [...data.weeks.visits.current.slice(1).map(row => row.value)];
    const weekMonthIncrease = [
        ...data.weeks.visits.current.slice(1)
            .map((row, index) => calcIncrease(row.value, data.weeks.visits.current[index].value)),
    ];
    const weeks = [...data.weeks.visits.current.slice(1).map(row => row.date)];

    const maxValue = Math.max(
        ...getData('avgVisitsWorkday'),
        ...getData('avgVisitsHoliday')
    );

    return (
        <>
            <div className={classes.fullwidthChart}>
                <ColumnChart
                    title={polyglot.t('pdf.charts.visitsByWeeks')}
                    labels={data.weeks.visits.current.slice(1).map(row => row.week)}
                    data={weekData}
                    monthIncrease={weekMonthIncrease}
                    size="big"
                    weeks={weeks}
                />
            </div>
            <div style={{ paddingTop: '12mm' }}>
                <Typo className={classes.title}>{polyglot.t('pdf.charts.visitsByDayType.title')}</Typo>
                <Typo className={classes.subtitle}>{polyglot.t('pdf.charts.visitsByDayType.hint')}</Typo>
            </div>
            <div className={classes.halfwidthCharts}>
                <ColumnChart
                    labels={labels}
                    data={getData('avgVisitsWorkday')}
                    pastYearDivider={1}
                    yearIncrease={getYearIncrease('avgVisitsWorkday')}
                    monthIncrease={getMonthIncrease('avgVisitsWorkday')}
                    legend={polyglot.t('pdf.charts.visitsByDayType.weekdays')}
                    showFooter
                    size="big"
                    max={maxValue}
                />
                <ColumnChart
                    labels={labels}
                    data={getData('avgVisitsHoliday')}
                    pastYearDivider={1}
                    yearIncrease={getYearIncrease('avgVisitsHoliday')}
                    monthIncrease={getMonthIncrease('avgVisitsHoliday')}
                    legend={polyglot.t('pdf.charts.visitsByDayType.weekends')}
                    size="big"
                    max={maxValue}
                />
            </div>
        </>
    );
};

/**
 * Таблица с аналитическими даннами для PDF отчета.
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import React from 'react';
import { PolyglotSingleton } from '../../../../lib/services/translation';

import { formatValue } from '../../../../lib/utils/strings';
import { ApiChartValuesType } from '../../../Charts/ApiChart/types';
import { Typo } from '../../Typo';
import { calcIncrease } from '../helpers';

import { useStyles } from './styles';

interface IProps {
    title?: string;
    columnsHeader?: Array<string | JSX.Element>;
    rowsHeader?: string[];
    rows: number[][];
    additionalRows?: number[][];
    size?: 'small' | 'standard';
    valueFormat?: ApiChartValuesType;
    valueFormatOptions?: Intl.NumberFormatOptions,
    additionalValueFormat?: ApiChartValuesType;
    additionalValueFormatOptions?: Intl.NumberFormatOptions;
    isFooter?: boolean;
    footerValueOptions?: Intl.NumberFormatOptions;
    footerTitle?: string;
}

const OPACITY_STEPS = 5;
const MIN_OPACITY = 0.1;
const COLORS = {
    range: [102, 144, 197],
    positive: [96, 217, 210],
    negative: [244, 80, 141],
}

const getOpacity = (value: number, min: number, max: number): number => (
    ((1 - MIN_OPACITY) / OPACITY_STEPS) * Math.ceil((value - min) / ((max - min) / OPACITY_STEPS)) + MIN_OPACITY
);

const getColor = (color: number[], min: number, max: number) => (value: number) =>
    `rgba(${color.join(', ')}, ${getOpacity(value, min, max)})`;

const polyglot = PolyglotSingleton.getInstance();

export const Table = ({
    title,
    rows,
    additionalRows,
    columnsHeader,
    rowsHeader,
    size = 'standard',
    valueFormat = 'absolute',
    valueFormatOptions,
    additionalValueFormat = 'percent',
    additionalValueFormatOptions,
    isFooter = true,
    footerValueOptions,
    footerTitle = 'pdf.charts.common.share'
}: IProps) => {
    const classes = useStyles();

    const footerRows = [
        [...rows[rows.length - 1].map((value, index) => calcIncrease(value ?? 0, rows[0][index] ?? 0))],
        [...rows[rows.length - 1].map((value, index) => calcIncrease(value ?? 0, rows[rows.length - 2][index] ?? 0))],
    ];
    const rowSpan = Array.isArray(additionalRows) ? { rowSpan: 2 } : {};
    const flatRows: number[] = ([] as number[]).concat(...rows).sort((a, b) => a - b);
    const max = flatRows.pop() ?? 0;
    const min = flatRows.shift() ?? 0;
    const getCellColor = getColor(COLORS.range, min, max);

    const flatFooterRows = ([] as Array<number | null>).concat(...footerRows);
    const positiveValues = flatFooterRows
        .filter(value => value !== null && value >= 0)
        .sort((a, b) => (a as number) - (b as number));
    const negativeValues = flatFooterRows
        .filter(value => value !== null && value < 0)
        .sort((a, b) => (a as number) - (b as number));
    const maxPositiveValue = positiveValues.pop() ?? 0;
    const minPositiveValue = positiveValues.shift() ?? 0;
    const minNegativeValue = negativeValues.pop() ?? 0;
    const maxNegativeValue = negativeValues.shift() ?? 0;
    const getFootColor = (value: number | null) => value !== null
        ? getColor(
            value >= 0 ? COLORS.positive : COLORS.negative,
            value >= 0 ? minPositiveValue : minNegativeValue,
            value >= 0 ? maxPositiveValue : maxNegativeValue,
        )(value)
        : 'transparent';

    const renderColumnsHeader = (columns: Array<string | React.ReactElement>) => (
        <thead>
            <tr>
                {rowsHeader !== undefined ? <th /> : null}
                {columns.map((header, index) => (
                    <th key={index} className={`${classes.column} ${classes[size]}`}>{header}</th>
                ))}
            </tr>
        </thead>
    );

    const renderAdditionalRow = (row: number[]) => (
        <tr>
            {row.map((cell, index) => (
                <td key={`add-cell-${index}`} className={classes.additional}>
                    {formatValue(additionalValueFormat, additionalValueFormatOptions)(cell)}
                </td>
            ))}
        </tr>
    );

    const renderRow = (row: number[]) => row.map((cell, index) => (
        <td
            key={`cell-${index}`}
            className={`${classes.td} ${classes[size]}`}
            style={{ backgroundColor: getCellColor(cell) }}
        >
            {formatValue(valueFormat, valueFormatOptions)(cell)}
        </td>
    ));

    const renderRows = () => rows.map((row, index) => (
        <React.Fragment key={index}>
            <tr>
                {rowsHeader !== undefined
                    ? <td className={`${classes.row} ${classes[size]}`} {...rowSpan}>{rowsHeader[index]}</td>
                    : null}
                {renderRow(row)}
            </tr>
            {additionalRows !== undefined ? renderAdditionalRow(additionalRows[index]) : null}
        </React.Fragment>
    ));

    const renderFooter = () => (
        <>
            <tr>
                {Array.isArray(rowsHeader) ? (
                    <td className={`${classes.footCell} ${classes[size]}`}>{polyglot.t(footerTitle)} {rowsHeader[rowsHeader.length - 1]}/{rowsHeader[0]}</td>
                ) : null}
                {footerRows[0].map((value, index) => (
                    <td
                        key={`foot-cell-${index}`}
                        className={`${classes.footCell} ${classes[size]}`}
                        style={{ backgroundColor: getFootColor(value) }}
                    >
                        {value !== null ? formatValue('percent', footerValueOptions)(value) : ''}
                    </td>
                ))}
            </tr>
            <tr>
                {Array.isArray(rowsHeader) ? (
                    <td className={`${classes.footCell} ${classes[size]}`}>{polyglot.t(footerTitle)} {rowsHeader[rowsHeader.length - 1]}/{rowsHeader[rowsHeader.length - 2]}</td>
                ) : null}
                {footerRows[1].map((value, index) => (
                    <td
                        key={`foot-cell-${index}`}
                        className={`${classes.footCell} ${classes[size]}`}
                        style={{ backgroundColor: getFootColor(value) }}
                    >
                        {formatValue('percent', footerValueOptions)(value)}
                    </td>
                ))}
            </tr>
        </>
    );

    return (
        <>
            {title !== undefined ? <Typo className={classes.title}>{title}</Typo> : null}
            <table className={classes.table}>
                { columnsHeader !== undefined ? renderColumnsHeader(columnsHeader) : null}
                <tbody>{ renderRows() }</tbody>
                {isFooter ? (
                    <tfoot>
                        <tr>
                            <td
                                className={classes.divider}
                                colSpan={rows[0].length + (Array.isArray(rowsHeader) ? 1 : 0)}
                            />
                        </tr>
                        {renderFooter()}
                    </tfoot>
                ) : null}
            </table>
        </>
    );
};

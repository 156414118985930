// tslint:disable-next-line:no-submodule-imports
import { all } from 'redux-saga/effects';

import cohortsSagas from './cohorts.page';
import dashboardSagas from './dashboard.page';
import frequencySagas from './frequency.page';
import globalSagas from './global';
import involvementSagas from './involvement.page';
import loyaltySagas from './loyalty.page';
import organizationsSagas from './organizations';
import usersSagas from './users';
import visitsSagas from './visits.page';

export default function* rootSaga() {
    yield all([
        globalSagas(),
        usersSagas(),
        organizationsSagas(),
        dashboardSagas(),
        visitsSagas(),
        involvementSagas(),
        frequencySagas(),
        loyaltySagas(),
        cohortsSagas(),
    ]);
}

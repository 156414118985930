/**
 * Модальное окно с настройками организации
 *
 * @author Artem Bakulin
 */

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Tab,
    Tabs,
    WithStyles,
    withStyles,
} from '@material-ui/core';
import { BubbleChart, Business, SupervisorAccount } from '@material-ui/icons';
import { Form, Formik, FormikErrors, FormikHelpers } from 'formik';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { globalActions } from '../../actions/global';
import api from '../../api/organizations.api';
import ContacsFields from '../../forms/OrganizationForm/Fields';
import { PolyglotSingleton } from '../../lib/services/translation';
import { IOrganization, IOrganizationSettings } from '../../types/organizations';

import AgesFields from './AgesFields';
import { schema } from './schema';
import SettingsTab from './SettingsTab';
import { styles } from './styles';
import VisitsFields from './VisitsFields';

interface IProps extends WithStyles<typeof styles> {
    handleClose(): void;
    isOpen: boolean;
    organization: IOrganization;
}

const polyglot = PolyglotSingleton.getInstance();
const getInitialValues = (organization: IOrganization): IOrganization => ({
    ...organization,
    settings: {
        ageGroups: [],
        priceGroups: [],
        ...organization.settings,
    },
});

const OrganizationSettingsModal = ({ organization, isOpen, handleClose, classes }: IProps) => {
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const handleTabChange = useCallback((_: React.ChangeEvent<{}>, newValue: number) => {
        setSelectedTab(newValue);
    }, []);
    const dispatch = useDispatch();
    const onSubmit = useCallback(
        async (values: IOrganization, { setSubmitting }: FormikHelpers<IOrganization>) => {
            try {
                setSubmitting(true);
                await api.updateSettings(organization, values);
                window.location.reload();
            } catch (error) {
                dispatch(
                    globalActions.global.notifications.add({
                        type: 'error',
                        message: polyglot.t('organizations.settings.error'),
                    }),
                );
            } finally {
                setSubmitting(false);
                handleClose();
            }
        },
        [dispatch, organization, handleClose],
    );
    const firstPageFields: Array<keyof IOrganization> = [
        'name',
        'contactPerson',
        'email',
        'phone',
        'description',
        'site',
    ];
    const secondPageFields: Array<keyof IOrganizationSettings> = [
        'countBus',
        'countTaxi',
        'minLongVisit',
        'minVisitTime',
        'workTime',
    ];
    const firstPageErrors = (errors: FormikErrors<IOrganization>) =>
        firstPageFields.some(field => errors[field] !== undefined);
    const secondPageErrors = (errors: FormikErrors<IOrganization>) =>
        secondPageFields.some(field => errors.settings !== undefined && errors.settings[field] !== undefined);
    const thirdPageErrors = (errors: FormikErrors<IOrganization>) =>
        errors.settings !== undefined && errors.settings.ageGroups !== undefined;
    const submitBtnClick = (submitFunc: () => void, isValid: boolean, errors: FormikErrors<IOrganization>) => () => {
        if (!isValid && Object.keys(errors).length > 0) {
            if (firstPageErrors(errors)) {
                setSelectedTab(0);
            } else if (secondPageErrors(errors)) {
                setSelectedTab(1);
            } else if (thirdPageErrors(errors)) {
                setSelectedTab(2);
            }

            return;
        }

        submitFunc();
    };

    return (
        <Dialog open={isOpen} onClose={handleClose} classes={{ paper: classes.root }} maxWidth="lg">
            <Formik initialValues={getInitialValues(organization)} validationSchema={schema} onSubmit={onSubmit}>
                {({ submitForm, isSubmitting, values, errors, isValid }) => (
                    <>
                        <DialogTitle id="form-dialog-title" className={classes.header}>
                            {organization.name}
                        </DialogTitle>
                        <DialogContent className={classes.content}>
                            <Tabs
                                orientation="vertical"
                                variant="scrollable"
                                value={selectedTab}
                                onChange={handleTabChange}
                                className={classes.tabs}
                                indicatorColor="primary"
                                textColor="primary"
                            >
                                <Tab label={polyglot.t('organizations.tabs.contacts')} icon={<Business />} />
                                <Tab label={polyglot.t('organizations.tabs.visits')} icon={<BubbleChart />} />
                                <Tab label={polyglot.t('organizations.tabs.ages')} icon={<SupervisorAccount />} />
                            </Tabs>

                            <Form className={classes.form}>
                                <SettingsTab hidden={selectedTab !== 0}>
                                    <Grid container spacing={3}>
                                        <ContacsFields />
                                    </Grid>
                                </SettingsTab>

                                <SettingsTab hidden={selectedTab !== 1}>
                                    <Grid container spacing={3}>
                                        <VisitsFields prefix="settings" />
                                    </Grid>
                                </SettingsTab>

                                <SettingsTab hidden={selectedTab !== 2}>
                                    <Grid container spacing={3}>
                                        <AgesFields values={values} />
                                    </Grid>
                                </SettingsTab>
                            </Form>
                        </DialogContent>
                        <DialogActions className={classes.footer}>
                            <Button onClick={handleClose} variant="outlined">
                                {polyglot.t('organizations.settings.cancelBtn')}
                            </Button>
                            <Button
                                onClick={submitBtnClick(submitForm, isValid, errors)}
                                color="primary"
                                variant="contained"
                                disabled={isSubmitting}
                            >
                                {polyglot.t('organizations.settings.saveBtn')}
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Formik>
        </Dialog>
    );
};

export default withStyles(styles)(OrganizationSettingsModal);

/**
 * Первая страница раздела "Посещаемость" пользовательского отчета.
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import { Grid, Typography, WithStyles, withStyles } from '@material-ui/core';
import React from 'react';

import ColumnChartStacked from '../../../components/Charts/ColumnChartStacked';
import PdfSection from '../../../components/PdfSection';
import Translate from '../../../components/Translate';
import { PolyglotSingleton } from '../../../lib/services/translation';
import { IChartSummaryData, ICustomSummaryData, IMonthSummaryData } from '../../../types/analytics';
import { PeriodFormatFunction } from '../../../types/global';
import Chart from '../Chart';
import { styles } from '../styles';
import { CHART_DATE_FORMAT, formatPeriodDate, getStackedChartData } from '../Summary';

interface IProps extends WithStyles<typeof styles> {
    data: ICustomSummaryData | IMonthSummaryData;
    page: number;
    period: string;
    chartType?: 'column' | 'line';
    periodFormat?: string | PeriodFormatFunction;
    rotateLabels?: number;
}

const polyglot = PolyglotSingleton.getInstance();

const getData = (
    data: IProps['data'],
    carType: 'carsVisits' | 'busVisits' | 'taxiVisits',
    periodFormat: string | PeriodFormatFunction,
) => [
    ...('comporable' in data
        ? [
              {
                  period: formatPeriodDate(data.comporable[0].date, periodFormat),
                  value: data.comporable[0].visitsByType[carType],
              },
          ]
        : []),
    ...data.months.map((row: IChartSummaryData) => ({
        period: formatPeriodDate(row.date, periodFormat),
        value: row.visitsByType[carType],
    })),
];

const VisitsPage = ({ data, classes, page, period, chartType = 'line', periodFormat = CHART_DATE_FORMAT, rotateLabels }: IProps) => {
    const comporable = 'comporable' in data ? formatPeriodDate(data.comporable[0].date, periodFormat) : undefined;
    const dataByPrice = [
        ...('comporable' in data
            ? getStackedChartData([data.comporable[0]], 'visitsByPrice', 'visits', periodFormat)
            : []),
        ...getStackedChartData(data.months, 'visitsByPrice', 'visits', periodFormat),
    ];
    const dataByAge = [
        ...('comporable' in data
            ? getStackedChartData([data.comporable[0]], 'visitsByAge', 'visits', periodFormat)
            : []),
        ...getStackedChartData(data.months, 'visitsByAge', 'visits', periodFormat),
    ];

    return (
        <>
            <PdfSection page={page} title={polyglot.t('visits.header')} period={period}>
                <Grid container spacing={2} alignItems="stretch" className={classes.chartRowTwoThirds}>
                    <Grid item xs={4}>
                        <Chart
                            id="chartDivTypeCars"
                            data={getData(data, 'carsVisits', periodFormat)}
                            title={`${polyglot.t('visits.byType')}<br>(${polyglot.t('visits.labels.cars')})`}
                            seriesType={chartType}
                            comporableCategory={comporable}
                            minY={0}
                            rotateLabels={rotateLabels}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Chart
                            id="chartDivTypeTaxi"
                            data={getData(data, 'taxiVisits', periodFormat)}
                            title={`${polyglot.t('visits.byType')}<br>(${polyglot.t('visits.labels.taxi')})`}
                            seriesType={chartType}
                            comporableCategory={comporable}
                            minY={0}
                            rotateLabels={rotateLabels}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Chart
                            id="chartDivTypeBus"
                            data={getData(data, 'busVisits', periodFormat)}
                            title={`${polyglot.t('visits.byType')}<br>(${polyglot.t('visits.labels.bus')})`}
                            seriesType={chartType}
                            comporableCategory={comporable}
                            minY={0}
                            rotateLabels={rotateLabels}
                        />
                    </Grid>
                </Grid>
            </PdfSection>
            <PdfSection page={page + 1} title={polyglot.t('visits.header')} period={period}>
                <Grid container spacing={2} alignItems="stretch" className={classes.chartRowTwoThirds}>
                    <Grid item xs={6}>
                        <div className={classes.chartWrapper}>
                            <Typography variant="h5" className={classes.title}>
                                <Translate phrase="visits.byPrice" />
                            </Typography>
                            <ColumnChartStacked
                                id="chartDivPrice"
                                data={dataByPrice}
                                noElements={true}
                                className={classes.fullHeight}
                                classes={{ chartWrapper: classes.chart }}
                                comporableCategory={comporable}
                                withTotals
                            />
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.chartWrapper}>
                            <Typography variant="h5" className={classes.title}>
                                <Translate phrase="visits.byAge" />
                            </Typography>
                            <ColumnChartStacked
                                id="chartDivAge"
                                data={dataByAge}
                                noElements={true}
                                className={classes.fullHeight}
                                classes={{ chartWrapper: classes.chart }}
                                comporableCategory={comporable}
                                withTotals
                            />
                        </div>
                    </Grid>
                </Grid>
            </PdfSection>
        </>
    );
};

export default withStyles(styles)(VisitsPage);

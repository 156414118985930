/**
 * Утилиты для получения урлов к различным статичным ресурсам
 *
 * @author Artem Bakulin
 */

/**
 * Возвращает url для картинки с изображением события с камеры
 *
 * @param image Путь до картинки с событием из БД
 */
export const generateCarImageUrl = (image: string) => `/api/static/car-images${image}`;

export const apiHostPrefix = () => document.location.hostname === 'node' ? 'http://node:4000' : '';

import { createStyles } from '@material-ui/core';

export const styles = () =>
    createStyles({
        root: {
            padding: '10px 30px',
            minHeight: 40,
            flex: 'none',
        },
        number: {
            color: '#fff',
            background: '#b5bceb',
            borderRadius: '2px',
            padding: '0 8px',
            textAlign: 'center',
            marginRight: '4px',
            fontSize: '12px',
            lineHeight: '22px',
            display: 'inline-block',
            verticalAlign: 'middle',
        },
        title: {
            color: '#6271df',
            display: 'inline-block',
            verticalAlign: 'middle',
        },
    });

/**
 * Форма создания/редактирования когорты рекламной кампании.
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import { Button, Grid } from '@material-ui/core';
import { format, intervalToDuration, isValid, parseISO, sub, subDays } from 'date-fns';
import { Field, Form, Formik, FormikHelpers, useField, useFormikContext } from 'formik';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { cohortsActions } from '../../actions/cohorts';
import { globalActions } from '../../actions/global';
import { CohortsApi } from '../../api/cohort.api';
import TextField from '../../components/Formik/TextField';
import { PolyglotSingleton } from '../../lib/services/translation';
import { selectedOrganizationSelector } from '../../selectors/common.selectors';
import { INotification } from '../../types/global';
import { ICampaignCohort, IUserOrganization } from '../../types/organizations';

import { schema } from './schema';

interface IProps {
    cohort?: ICampaignCohort;
    /* tslint:disable-next-line:no-null-undefined-union */
    actionsBtns?: React.ReactNode;
    api: CohortsApi;
    onError?(): void;
    onSuccess?(): void;
}

export interface ICohortValues {
    id?: number;
    name: string;
    dateFrom: Date | null;
    dateTo: Date | null;
    comparisonDate: Date | null;
    organizationId: number;
}

const polyglot = PolyglotSingleton.getInstance();

const ComparisonField = () => {
    const form = useFormikContext<ICohortValues>();
    const [field, meta] = useField('comparisonDate');
    const {
        values: { dateFrom, dateTo },
        touched,
        setFieldValue,
    } = form;
    const maxComparisonDate = useRef(sub(new Date(), {days: 1}));

    useEffect(() => {
        if (isValid(dateFrom) && isValid(dateTo)) {
            maxComparisonDate.current = subDays(
                sub(
                    dateFrom as Date,
                    intervalToDuration({ start: dateFrom as  Date, end: dateTo as Date })
                ),
                1
            );
        }
    }, [dateFrom, dateTo])

    useEffect(() =>{
        if (isValid(dateFrom) && isValid(dateTo) && (touched.dateFrom !== undefined || touched.dateTo !== undefined)) {
            maxComparisonDate.current = subDays(
                sub(
                    dateFrom as Date,
                    intervalToDuration({ start: dateFrom as  Date, end: dateTo as Date })
                ),
                1
            );

            if (!isValid(field.value) || (field.value as Date).valueOf() > maxComparisonDate.current.valueOf()) {
                setFieldValue('comparisonDate', maxComparisonDate.current);
            }
        }
    }, [dateFrom, dateTo, touched, maxComparisonDate, setFieldValue, field]);

    const handlePickerChange = (date: Date | null) => {
        setFieldValue('comparisonDate', date);
    }

    return (
        <KeyboardDatePicker
            label={polyglot.t('cohorts.form.label.comparedPeriod')}
            required
            format="dd.MM.yyyy"
            maxDate={maxComparisonDate.current}
            maxDateMessage={polyglot.t('cohorts.form.error.maxComparedDate', { date: format(maxComparisonDate.current, 'dd.MM.yyyy') })}
            field={field}
            meta={meta}
            form={form}
            onChange={handlePickerChange}
        />
    );
}

export const CampaignCohortForm = ({cohort, actionsBtns, onError, onSuccess, api}: IProps) => {
    const now = sub(new Date(), {days: 1});
    const dispatch = useDispatch();
    const organization = useSelector(selectedOrganizationSelector) as IUserOrganization;
    const initialValues = useMemo(() => ({
        name: '',
        dateFrom: null,
        dateTo: null,
        comparisonDate: null,
        organizationId: organization.id,
        ...cohort !== undefined ? {
            ...cohort,
            dateFrom: parseISO(cohort.dateFrom),
            dateTo: parseISO(cohort.dateTo),
            comparisonDate: parseISO(cohort.comparisonDate),
        } : {}
    }), [cohort, organization]);

    const notify = useCallback((notification: INotification) => {
        dispatch(globalActions.global.notifications.add(notification))
    }, [dispatch]);
    const handleSubmit = useCallback(async (
        values: ICohortValues,
        { setSubmitting }: FormikHelpers<ICohortValues>,
    ) => {
        try {
            setSubmitting(true);

            const maxComparisonDate = subDays(
                sub(
                    values.dateFrom as Date,
                    intervalToDuration({ start: values.dateFrom as  Date, end: values.dateTo as Date })
                ),
                1
            );

            if (
                (values.dateTo as Date).valueOf() < (values.dateFrom as Date).valueOf() ||
                (values.comparisonDate as Date).valueOf() > maxComparisonDate.valueOf()
            ) {
                setSubmitting(false);

                return;
            }

            const isNew = values.id === undefined;
            await api.createOrUpdate(values);
            dispatch(cohortsActions.cohort.list.send(organization.id))
            notify({
                    type: 'success',
                    message: isNew ? polyglot.t('cohorts.form.message.successNew') : polyglot.t('cohorts.form.message.successEdit'),
            });

            if (typeof onSuccess === 'function') {
                onSuccess();
            }
        } catch (e) {
            notify({
                type: 'error',
                message: polyglot.t('cohorts.form.error.save'),
            });

            if (typeof onError === 'function') {
                onError();
            }
        } finally {
            setSubmitting(false);
        }
    }, [onError, onSuccess, notify, api, dispatch, organization]);

    return (
        <Formik
            initialValues={initialValues as ICohortValues}
            validationSchema={schema}
            onSubmit={handleSubmit}
        >{
            ({values: { dateFrom, dateTo }}) => (
                <Form>
                    <Grid container spacing={3}>
                        <Grid item container spacing={2}>
                            <Grid item xs={12}>
                                <Field
                                    name="name"
                                    label={polyglot.t('cohorts.form.label.name')}
                                    required
                                    component={TextField}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container spacing={2}>
                            <Grid item xs={6}>
                                <Field
                                    name="dateFrom"
                                    required
                                    label={polyglot.t('cohorts.form.label.startDate')}
                                    maxDate={dateTo ?? now}
                                    maxDateMessage={polyglot.t('cohorts.form.error.maxStartDate')}
                                    component={KeyboardDatePicker}
                                    format="dd.MM.yyyy"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Field
                                    name="dateTo"
                                    required
                                    minDate={dateFrom ?? undefined}
                                    maxDate={now}
                                    minDateMessage={polyglot.t('cohorts.form.error.minEndDate')}
                                    maxDateMessage={polyglot.t('cohorts.form.error.maxEndDate')}
                                    label={polyglot.t('cohorts.form.label.endDate')}
                                    component={KeyboardDatePicker}
                                    format="dd.MM.yyyy"
                                />
                            </Grid>
                        </Grid>
                        <Grid item container spacing={2}>
                            <Grid item xs={6}>
                                <ComparisonField />
                            </Grid>
                        </Grid>
                        {actionsBtns !== undefined ? (
                            actionsBtns
                        ) : (
                            <Grid item xs={12} container justify="center">
                                <Button type="submit" variant="outlined" color="secondary" size="large">
                                    {polyglot.t('common.buttons.create')}
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Form>
            )
        }
        </Formik>
    );
}

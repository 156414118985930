/**
 * Страница "Приложение 1. Основные термины." месячного PDf отчета.
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import React from 'react';

import { Typo } from '../../../components/Pdf/Typo';
import { PolyglotSingleton } from '../../../lib/services/translation';
import { PRICE_GROUPS } from '../Summary';

import { useStyles } from './styles';

const polyglot = PolyglotSingleton.getInstance();

const terms = ['visits', 'uniqs', 'new', 'duration', 'premium'];

export const TermsPage = () => {
    const classes = useStyles();

    const renderTerm = (term: string) => (
        <Typo key={term} className={classes.term}>
            <b className={classes.bold}>{polyglot.t(`pdf.terms.${term}.term`)}</b> – {polyglot.t(`pdf.terms.${term}.description`)}
        </Typo>
    );

    return (
        <>
            <br/>
            {terms.map(renderTerm)}
            <br/>
            <Typo className={classes.term}><b className={classes.bold}>{polyglot.t('pdf.terms.price')}</b></Typo>
            <Typo className={`${classes.term} ${classes.prices}`} component="dl">
                {PRICE_GROUPS.map((group) => (
                    <React.Fragment key={group}>
                        <dt><b className={classes.bold}>{group}</b></dt>
                        <dd>{polyglot.t(`pdf.terms.prices.${group}`)}</dd>
                    </React.Fragment>
                ))}
            </Typo>
        </>
    );
}

/**
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import { FrequencyFilter } from '../../components/Charts/DetailedApiChart/ChartFrequency';
import { AnalyticsDataGranularity } from '../../types/date.periods';

export const getChartExportLink = (
	chartType: string,
	chartName: string,
	periods: string[]
): string =>
	`/api/areas/summary/${chartType}/1?chartName=${chartName}&period=${periods[0]}&periodComp=${periods[1]}`;

export const getChartExportXLSXLink = (
	organization: number,
	chartType: string,
	chartName: string,
	periods: string[],
	uniqs: boolean,
	percents: boolean,
	granularity?: AnalyticsDataGranularity,
	frequency?: FrequencyFilter,
): string => {
	const urlPart = `/api/areas/summary/${chartType}/${organization}`;
	const queryParts = [
		`chartName=${chartName}`,
		`uniqs=${uniqs}`,
		`percents=${percents}`,
		periods.map(period => `periods[]=${period.replace(';', '%3B')}`).join('&'),
		...(granularity !== undefined ? [`granularity=${granularity}`] : []),
		...(frequency !== undefined ? [`frequency=${frequency}`] : [])
	]

	return `${urlPart}?${queryParts.join('&')}`;
}

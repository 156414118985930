/**
 * Страница "Топ регионы по визитам" для месячного PDF отчета.
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import moment from 'moment';
import React from 'react';

import { Table } from '../../../components/Pdf/Charts/Table';
import { PolyglotSingleton } from '../../../lib/services/translation';
import { IQuarterSummaryData } from '../../../types/analytics';

interface IProps {
    data: IQuarterSummaryData;
}

const polyglot = PolyglotSingleton.getInstance();

export const TopRegionsPage = ({ data }: IProps) => {
    const quarters = [
        moment(data.comporable[0].date).format(polyglot.t('common.dates.quarter')),
        ...data.quarters.slice(1).map(quarter => moment(quarter.date).format(polyglot.t('common.dates.quarter'))),
    ];
    const regionsAll = ([] as string[]).concat(
        ...data.quarters.slice(1).reverse().map(quarter => quarter.topRegions.map(region => region.name)),
        data.comporable[0].topRegions.map(region => region.name),
    );
    const regions = Array.from(new Set(regionsAll));
    const rows = [
        regions.map((regionName) => data.comporable[0].topRegions.find(region => region.name === regionName)?.percent ?? 0),
        ...data.quarters.slice(1)
            .map((quarter) =>
                regions.map(
                    (regionName) => quarter.topRegions.find(region => region.name === regionName)?.percent ?? 0)),
    ];
    const additionalRows = [
        regions.map((regionName) => data.comporable[0].topRegions.find(region => region.name === regionName)?.value ?? 0),
        ...data.quarters.slice(1)
            .map((quarter) => regions.map((regionName) =>
                quarter.topRegions.find(region => region.name === regionName)?.value ?? 0)),
    ];

    return (
        <Table
            title={polyglot.t('pdf.charts.topRegions')}
            columnsHeader={regions}
            rowsHeader={quarters}
            rows={rows}
            additionalRows={additionalRows}
            valueFormat="percent"
            additionalValueFormat="absolute"
        />
    );
}

import { createStyles, Theme } from '@material-ui/core';

export const styles = (theme: Theme) =>
    createStyles({
        title: {
            marginBottom: theme.spacing(3),
        },
        chartsWrapper: {
            marginTop: 60,
        },
        chartGrid: {
            marginBottom: 60,
        },
        chartWrapper: {
            width: '100%',
            height: 500,
        },
    });

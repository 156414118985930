import { makeStyles } from '@material-ui/core';

import bg from './bg.jpg';

export const useStyles = makeStyles(() => ({
    page: {
        paddingTop: '2%',
        paddingBottom: '2%',
        background: `url(${bg}) no-repeat right center`,
        backgroundSize: 'contain',
    },
    logo: {
        height: '14mm',
        width: 'auto',
    },
    title: {
        maxWidth: '50%',
        fontSize: '12mm',
        lineHeight: 1.5
    },
    organization: {
        fontSize: '10mm',
        marginTop: '14mm',
        color: 'rgb(117, 112, 112)',
        fontWeight: 'bold',
    },
    date: {
        textTransform: 'uppercase',
        fontSize: '4mm',
        lineHeight: 1,
    }
}));

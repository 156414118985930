/**
 * Страница "Визиты по типу авто" PDF отчета.
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import moment from 'moment';
import React from 'react';

import { ColumnChart } from '../../../components/Pdf/Charts/ColumnChart';
import { calcIncrease } from '../../../components/Pdf/Charts/helpers';
import { PolyglotSingleton } from '../../../lib/services/translation';
import { IChartSummaryData, IMonthSummaryData } from '../../../types/analytics';

import { useStyles } from './styles';

interface IProps {
    data: IMonthSummaryData;
}

const polyglot = PolyglotSingleton.getInstance();

const getChartData = (key: keyof IChartSummaryData['visitsByType'], data: IMonthSummaryData): number[] => [
    data.comporable[0].visitsByType[key],
    ...data.months.slice(1).map(row => row.visitsByType[key]),
];

export const VisitsByTypePage = ({ data }: IProps) => {
    const classes = useStyles();

    const labels = [
        moment(data.comporable[0].date).format('MMM YY'),
        ...data.months.slice(1).map(row => moment(row.date).format('MMM YY')),
    ];
    const getData = (key: keyof IChartSummaryData['visitsByType']) => getChartData(key, data);
    const getYearIncrease = (key: keyof IChartSummaryData['visitsByType']) => {
        const keyData = getData(key);
        const yearIncrease = new Array(keyData.length).fill(null);

        yearIncrease[yearIncrease.length - 1] = calcIncrease(keyData[keyData.length - 1], keyData[0]);

        return yearIncrease;
    };
    const getMonthIncrease = (key: keyof IChartSummaryData['visitsByType']) => {
        const keyData = getData(key);
        const rawData = data.months.map(row => row.visitsByType[key]);

        return keyData.map((value, index) => (index > 0 ? calcIncrease(value, rawData[index - 1]) : null));
    };

    const maxValue = Math.max(
        ...getData('carsVisits'),
        ...getData('taxiVisits'),
        ...getData('busVisits'),
        ...getData('vansVisits')
    );

    return (
        <div className={classes.visitsByTypeCharts}>
            <ColumnChart
                title={polyglot.t('pdf.charts.visitsByType.cars')}
                labels={labels}
                data={getData('carsVisits')}
                pastYearDivider={1}
                yearIncrease={getYearIncrease('carsVisits')}
                monthIncrease={getMonthIncrease('carsVisits')}
                showFooter
                size="big"
                max={maxValue}
            />
            <ColumnChart
                labels={labels}
                data={getData('taxiVisits')}
                title={polyglot.t('pdf.charts.visitsByType.taxi')}
                pastYearDivider={1}
                yearIncrease={getYearIncrease('taxiVisits')}
                monthIncrease={getMonthIncrease('taxiVisits')}
                size="big"
                max={maxValue}
            />
            <ColumnChart
                labels={labels}
                data={getData('busVisits')}
                title={polyglot.t('pdf.charts.visitsByType.bus')}
                pastYearDivider={1}
                yearIncrease={getYearIncrease('busVisits')}
                monthIncrease={getMonthIncrease('busVisits')}
                size="big"
                max={maxValue}
            />
            <ColumnChart
                labels={labels}
                data={getData('vansVisits')}
                title={polyglot.t('pdf.charts.visitsByType.vans')}
                pastYearDivider={1}
                yearIncrease={getYearIncrease('vansVisits')}
                monthIncrease={getMonthIncrease('vansVisits')}
                size="big"
                max={maxValue}
            />
        </div>
    );
};

/**
 * Reducers для страницы "Вовлеченность" организации
 *
 * @author Artem Bakulin
 */

import { Action, handleActions } from 'redux-actions';

import { analyticsPagesActions } from '../actions/analytics.pages';
import { IDateAnalyticsPeriod } from '../types/analytics';

export interface IInvolvementPageResult {
    charts: {
        byPrice: {
            filter: { [index: string]: string };
            data: {
                [index: string]: Array<{
                    period: [string, string];
                    longVisits: Array<IDateAnalyticsPeriod<string>>;
                    average: Array<IDateAnalyticsPeriod<string>>;
                    percent: Array<IDateAnalyticsPeriod<string>>;
                    visits: Array<IDateAnalyticsPeriod<string>>;
                }>;
            };
        };
        byAges: {
            filter: { [index: string]: string };
            data: {
                [index: string]: Array<{
                    period: [string, string];
                    longVisits: Array<IDateAnalyticsPeriod<string>>;
                    average: Array<IDateAnalyticsPeriod<string>>;
                    percent: Array<IDateAnalyticsPeriod<string>>;
                    visits: Array<IDateAnalyticsPeriod<string>>;
                }>;
            };
        };
    };
}

export interface IInvolvementPageState {
    inProgress: boolean;
    results: {
        [hash: string]: IInvolvementPageResult | undefined;
    };
    error?: Error;
}

const initialState: IInvolvementPageState = {
    inProgress: false,
    results: {},
};

/**
 * Reducers для страницы "Вовлеченность" организации
 */
// tslint:disable-next-line:no-any
export const involvementPageReducer = handleActions<IInvolvementPageState, any>(
    {
        [analyticsPagesActions.involvement.send.toString()]: (state: IInvolvementPageState) => ({
            ...state,
            inProgress: true,
        }),
        [analyticsPagesActions.involvement.success.toString()]: (
            state: IInvolvementPageState,
            action: Action<{ hashString: string; data: IInvolvementPageResult }>,
        ) => ({
            ...state,
            results:
                state.results[action.payload.hashString] !== undefined
                    ? state.results
                    : {
                          ...state.results,
                          [action.payload.hashString]: action.payload.data,
                      },
            inProgress: false,
            error: undefined,
        }),
        [analyticsPagesActions.involvement.fail.toString()]: (state: IInvolvementPageState, action: Action<Error>) => ({
            ...state,
            inProgress: false,
            error: action.payload,
        }),
    },
    initialState,
);

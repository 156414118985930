import hash from 'object-hash';
import { Action } from 'redux-actions';
// tslint:disable-next-line:no-submodule-imports
import { all, call, put, select, takeEvery } from 'redux-saga/effects';

import { analyticsPagesActions } from '../actions/analytics.pages';
import { globalActions } from '../actions/global';
import api from '../api/analytics.api';
import { IAppState } from '../reducers';
import { IAnalyticsCalendarState } from '../reducers/analytics.calendar';
import { IInvolvementPageResult, IInvolvementPageState } from '../reducers/involvement.page';

function* sendDataRequest(action: Action<{ organizationId: number; data: IAnalyticsCalendarState }>) {
    try {
        const results = (yield select(
            (state: IAppState) => state.involvement.results,
        )) as IInvolvementPageState['results'];
        const hashString = `${hash(action.payload.data)}.${action.payload.organizationId}`;
        const resultData = results[hashString];

        const result: IInvolvementPageResult =
            resultData !== undefined
                ? resultData
                : ((yield call(
                      [api, 'involvementData'],
                      action.payload.organizationId,
                      action.payload.data,
                  )) as IInvolvementPageResult);

        yield put(analyticsPagesActions.involvement.success(hashString, result));
    } catch (error) {
        yield put(
            globalActions.global.notifications.add({
                message: 'Ошибка на сервере. Невозможно получить данные за период.',
                type: 'error',
            }),
        );
        yield put(analyticsPagesActions.involvement.fail(error as Error));
    }
}

function* getData() {
    yield takeEvery(analyticsPagesActions.involvement.send.toString(), sendDataRequest);
}

export default function* rootSaga() {
    yield all([getData()]);
}

/**
 * Утилиты, облегчающие настройку графиков.
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import { ChartOptions, ScriptableScaleContext } from 'chart.js';
import moment from 'moment';

import { PolyglotSingleton } from '../../../lib/services/translation';
import { formatValue } from '../../../lib/utils/strings';
import { ApiChartValuesType } from '../../Charts/ApiChart/types';
import { chartjsTheme } from '../styles.constants';

export interface IChartProps {
    title?: string;
    legend?: string;
    labels: string[];
    monthIncrease?: Array<number | null>;
    yearIncrease?:  Array<number | null>;
    increaseFormatOptions?: Intl.NumberFormatOptions;
    valueFormat?: ApiChartValuesType;
    valueFormatOptions?: Intl.NumberFormatOptions;
    showFooter?: boolean;
    pastYearDivider?: number;
    size?: 'standard' | 'big';
    weeks?: string[];
    months?: string[];
    max?: number;
    footerWrap?: 'wrap' | 'nowrap';
    type?: 'month' | 'quarter';
}

const polyglot = PolyglotSingleton.getInstance();

export const calcIncrease = (value: number, prevValue: number): number | null =>
    prevValue !== 0 ? (value / prevValue) - 1 : null;

export const isIncrease = (value: number, prevValue?: number): boolean =>
    prevValue !== undefined &&
    calcIncrease(value, prevValue) !== null &&
    calcIncrease(value, prevValue) as number > 0;

export const getChartOptions = (
    valueFormat: ApiChartValuesType,
    monthIncrease: Array<number | null> = [],
    yearIncrease: Array<number | null> = [],
    isLegend: boolean = false,
    dividerIndex?: number,
    weeks?: string[],
    months?: string[],
    max? :number,
    valueFormatOptions?: Intl.NumberFormatOptions,
    increaseFormatOptions?: Intl.NumberFormatOptions,
): ChartOptions<'bar'> => ({
    layout: {
        padding: {
            top: 54,
            left: 24,
            right: 24
        },
    },
    scales: {
        yAxis: {
            display: false,
            max,
        },
        xAxis: {
            grid: {
                color: (ctx: ScriptableScaleContext) => dividerIndex !== undefined && ctx.index === dividerIndex
                    ? chartjsTheme.colors.black
                    : chartjsTheme.colors.transparent,
                borderDash: [5, 5],
                tickLength: 16,
                drawTicks: weeks !== undefined || months !== undefined,
                ...weeks !== undefined ? { tickColor: (ctx: ScriptableScaleContext) =>
                    ctx.index === 0 ||
                    (
                        moment(weeks[ctx.index]).endOf('week').month() > 0 &&
                        moment(weeks[ctx.index]).endOf('week').month() !== moment(weeks[ctx.index - 1]).endOf('week').month()
                    ) ||
                    (
                        moment(weeks[ctx.index]).startOf('week').month() === 0 &&
                        moment(weeks[ctx.index]).startOf('week').month() !== moment(weeks[ctx.index - 1]).startOf('week').month()
                    )
                        ? chartjsTheme.colors.barBg
                        : chartjsTheme.colors.transparent,
                } : {},
                ...months !== undefined ? { tickColor: (ctx: ScriptableScaleContext) =>
                    ctx.index === 0 || moment(months[ctx.index]).endOf('month').quarter() !== moment(months[ctx.index - 1]).endOf('month').quarter()
                        ? chartjsTheme.colors.barBg
                        : chartjsTheme.colors.transparent,
                } : {},
            },
        },
        ...weeks !== undefined ? { xMonthAxis: {
            type: 'category',
            grid: {
                drawOnChartArea: false,
                drawBorder: false,
                drawTicks: false,
            },
            ticks: {
                padding: 0,
                align: 'start',
                callback: (_, index) => {
                    if (index === 0) {
                        return moment(weeks[index]).endOf('week').format('MMMM');
                    }

                    if (
                        moment(weeks[index]).endOf('week').month() > 0 &&
                        moment(weeks[index]).endOf('week').month() !== moment(weeks[index - 1]).endOf('week').month()
                    ) {
                        return moment(weeks[index]).endOf('week').format('MMMM');
                    }

                    if (
                        moment(weeks[index]).startOf('week').month() === 0 &&
                        moment(weeks[index]).startOf('week').month() !== moment(weeks[index - 1]).startOf('week').month()
                    ) {
                        return moment(weeks[index]).startOf('week').format('MMMM');
                    }

                    return null;
                }
            },
        }} : {},
        ...months !== undefined ? { xMonthAxis: {
            type: 'category',
            grid: {
                drawOnChartArea: false,
                drawBorder: false,
                drawTicks: false,
            },
            ticks: {
                padding: 0,
                align: 'start',
                callback: (_, index) => {
                    if (index === 0) {
                        return moment(months[index]).endOf('month').format(polyglot.t('common.dates.quarter'));
                    }

                    if (moment(months[index]).endOf('month').quarter() !== moment(months[index - 1]).endOf('month').quarter()) {
                        return moment(months[index]).endOf('month').format(polyglot.t('common.dates.quarter'));
                    }

                    return null;
                }
            },
        }} : {}
    },
    plugins: {
        tooltip: {
            enabled: false,
        },
        legend: {
            display: isLegend,
            labels: {
                boxHeight: 0,
                boxWidth: 0,
            },
        },
        datalabels: {
            display: true,
            anchor: 'end',
            align: 'top',
            color: chartjsTheme.colors.black,
            labels: {
                year: Array.isArray(yearIncrease) && yearIncrease.length > 0 ? {
                    color: (ctx) =>
                        yearIncrease[ctx.dataIndex] !== null && (yearIncrease[ctx.dataIndex] as number) >= 0
                            ? chartjsTheme.colors.positive
                            : chartjsTheme.colors.negative,
                    borderColor: (ctx) =>
                        yearIncrease[ctx.dataIndex] !== null && (yearIncrease[ctx.dataIndex] as number) >= 0
                            ? chartjsTheme.colors.positiveBg
                            : chartjsTheme.colors.negativeBg,
                    borderRadius: 50,
                    borderWidth: 1,
                    offset: 40,
                    padding: { left: 4, right: 4, top: 3, bottom: 2 },
                    font: {
                        size: 8,
                        weight: 300,
                    },
                    formatter: (_, ctx) => {
                        const index = ctx.dataIndex;
                        if (yearIncrease[index] === null || yearIncrease[index] === 0 || isNaN(yearIncrease[index] as number)) {
                            return null;
                        }

                        return formatValue('percent', increaseFormatOptions)(yearIncrease[index] as number);
                    },
                } : undefined,
                increase: {
                    color: (ctx) =>
                        monthIncrease[ctx.dataIndex] !== null && (monthIncrease[ctx.dataIndex] as number) >= 0
                            ? chartjsTheme.colors.positive
                            : chartjsTheme.colors.negative,
                    backgroundColor: (ctx) =>
                        monthIncrease[ctx.dataIndex] !== null && (monthIncrease[ctx.dataIndex] as number) >= 0
                            ? chartjsTheme.colors.positiveBg
                            : chartjsTheme.colors.negativeBg,
                    borderRadius: 50,
                    offset: 18,
                    padding: { left: 4, right: 4, top: 3, bottom: 2 },
                    font: {
                        size: 8,
                        weight: 300,
                    },
                    formatter: (_, ctx) => {
                        const index = ctx.dataIndex;
                        if (monthIncrease[index] === null || monthIncrease[index] === 0 || isNaN(monthIncrease[index] as number)) {
                            return null;
                        }

                        return formatValue('percent', increaseFormatOptions)(monthIncrease[index] as number);
                    },
                },
                value: {
                    font: {
                        size: 8,
                        weight: 400,
                    },
                    formatter: (value) => value !== 0
                        ? formatValue(valueFormat, valueFormatOptions)(value as number)
                        : '',
                },
            },
        },
    },
});

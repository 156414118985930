import { Grid, WithStyles, withStyles } from '@material-ui/core';
import React from 'react';

import { ChartByAges } from './ChartByAges';
import { ChartByPrice } from './ChartByPrice';
import { ChartByType } from './ChartByType';
import { styles } from './styles';

interface IProps extends WithStyles<typeof styles> {}

const ResultData = ({classes}: IProps) => (
    <>
        <Grid container spacing={4} className={classes.chartsWrapper} direction="column" alignItems="stretch">
            <Grid item>
                <ChartByType />
            </Grid>
            <Grid item>
                <ChartByPrice />
            </Grid>
            <Grid item>
                <ChartByAges />
            </Grid>
        </Grid>
    </>
);

export default withStyles(styles)(ResultData);

/**
 * Константы для графика ApiChart
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import { ApexOptions } from 'apexcharts';
import { CHART_SERIES_COLORS } from '../DetailedApiChart/constants';

export const TOOLTIP_DATE = 'dd, D MMM YYYY';
export const TOOLTIP_WEEK_DATE = 'D MMM YYYY';
export const TOOLTIP_MONTH_DATE = 'MMM YYYY';
export const DEFAULT_OPTIONS: ApexOptions = {
    colors: CHART_SERIES_COLORS.reverse(),
    tooltip: {
        shared: true,
    },
    stroke: {
        width: 2,
        curve: 'smooth',
    },
    xaxis: {
        tooltip: {
            enabled: false,
        },
    },
    chart: {
        animations: {
            enabled: false,
        },
        fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif;',
        toolbar: {
            show: false,
        },
    },
    markers: {
        size: 4,
    },
};

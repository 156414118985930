import { WithStyles, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';

import { styles } from './styles';

interface IProps extends WithStyles<typeof styles> {
    image: string;
    zoom?: boolean;
}

const onErrorHandler = (event: React.SyntheticEvent<HTMLImageElement, ErrorEvent>) => {
    if (event.nativeEvent.target !== null) {
        (event.nativeEvent.target as HTMLImageElement).style.display = 'none';
    }
};

const TableCellImage = ({ image, classes, zoom = true }: IProps) => (
    <img
        src={image}
        className={classNames({ [classes.thumb]: true, [classes.isZoom]: zoom })}
        onError={onErrorHandler}
        alt=""
    />
);

export default withStyles(styles)(TableCellImage);

/**
 * Страница "Топ регионы по визитам" для месячного PDF отчета.
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import moment from 'moment';
import React from 'react';

import { Table } from '../../../components/Pdf/Charts/Table';
import { PolyglotSingleton } from '../../../lib/services/translation';
import { IMonthSummaryData } from '../../../types/analytics';
import { CHART_DATE_FORMAT } from '../Summary';

interface IProps {
    data: IMonthSummaryData;
}

const polyglot = PolyglotSingleton.getInstance();

export const TopRegionsPage = ({ data }: IProps) => {
    const months = [
        moment(data.comporable[0].date).format(CHART_DATE_FORMAT),
        ...data.months.slice(1).map(month => moment(month.date).format(CHART_DATE_FORMAT)),
    ];
    const regionsAll = ([] as string[]).concat(
        ...data.months.slice(1).reverse().map(month => month.topRegions.map(region => region.name)),
        data.comporable[0].topRegions.map(region => region.name),
    );
    const regions = Array.from(new Set(regionsAll));
    const rows = [
        regions.map((regionName) => data.comporable[0].topRegions.find(region => region.name === regionName)?.percent ?? 0),
        ...data.months.slice(1)
            .map((month) =>
                regions.map(
                    (regionName) => month.topRegions.find(region => region.name === regionName)?.percent ?? 0)),
    ];
    const additionalRows = [
        regions.map((regionName) => data.comporable[0].topRegions.find(region => region.name === regionName)?.value ?? 0),
        ...data.months.slice(1)
            .map((month) => regions.map((regionName) =>
                month.topRegions.find(region => region.name === regionName)?.value ?? 0)),
    ];

    return (
        <Table
            title={polyglot.t('pdf.charts.topRegions')}
            columnsHeader={regions}
            rowsHeader={months}
            rows={rows}
            additionalRows={additionalRows}
            valueFormat="percent"
            additionalValueFormat="absolute"
        />
    );
}

/**
 * Поля ввода для формы создания новой организации / редактирования контактных данных
 *
 * @author Artem Bakulin
 */

import { Grid } from '@material-ui/core';
import { Field } from 'formik';
import React from 'react';

import TextField from '../../components/Formik/TextField';
import { PolyglotSingleton } from '../../lib/services/translation';

const polyglot = PolyglotSingleton.getInstance();

const Fields = () => (
    <>
        <Grid item xs={12} container spacing={2}>
            <Grid item>
                <Field
                    name="name"
                    label={polyglot.t('organizations.labels.name')}
                    required
                    component={TextField}
                />
            </Grid>
            <Grid item>
                <Field
                    name="site"
                    label={polyglot.t('organizations.labels.site')}
                    component={TextField}
                />
            </Grid>
        </Grid>
        <Grid item xs={12} container spacing={2}>
            <Grid item>
                <Field
                    name="contactPerson"
                    label={polyglot.t('organizations.columns.person')}
                    component={TextField}
                />
            </Grid>
            <Grid item>
                <Field
                    name="phone"
                    label={polyglot.t('organizations.columns.phone')}
                    component={TextField}
                />
            </Grid>
            <Grid item>
                <Field
                    name="email"
                    label={polyglot.t('organizations.columns.email')}
                    type="email"
                    component={TextField}
                />
            </Grid>
        </Grid>
        <Grid item xs={12}>
            <Field
                name="description"
                label={polyglot.t('organizations.labels.description')}
                multiline
                rows={5}
                variant="outlined"
                fullWidth
                component={TextField}
            />
        </Grid>
    </>
)

export default Fields;

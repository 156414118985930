import { createStyles } from '@material-ui/core';
// tslint:disable-next-line:no-submodule-imports
import grey from '@material-ui/core/colors/grey';

export const styles = createStyles({
    root: {
        backgroundColor: grey[100],
        borderRadius: 999,
        transition: 'none',
        display: 'flex',
    },
    selected: {
        boxShadow: 'none',
    },
});

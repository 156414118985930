/**
 * API для работы с аналитическими данными
 *
 * @author Artem Bakulin
 */

import {
    getDatesPeriodByPresetType,
    getLastDatesPeriodByType,
} from '../lib/services/dates.periods.service';
import { datePeriodString } from '../lib/utils/strings';
import { IAnalyticsCalendarState } from '../reducers/analytics.calendar';
import { IDashboardResultPeriod } from '../reducers/dashboard.page';
import { IFrequencyPageResult } from '../reducers/frequency.page';
import { IInvolvementPageResult } from '../reducers/involvement.page';
import { ILoyaltyPageResult, ILoyaltyPageState } from '../reducers/loyalty.page';
import { IVisitsResultPeriod } from '../reducers/visits.page';
import { IMonthSummaryData, IQuarterSummaryData, IYearSummaryData } from '../types/analytics';
import {
    CurrentPeriodTypeEnum,
    ICurrentPeriodCustom,
    IDatesPeriod,
    LastPeriodTypeEnum,
} from '../types/date.periods';
import { IAnalyticsPageResult } from '../types/global';

import { BaseApi } from './abstract.api';

/**
 * Возвращает массив строк с периодами дат для запросов в api
 *
 * @param data Актульаные данные календаря
 */
export const getPeriods = (data: IAnalyticsCalendarState): string[] => {
    const periods: IDatesPeriod[] = [];

    switch (data.currentPeriodType) {
        case CurrentPeriodTypeEnum.Custom:
            periods.push((data.currentPeriod as ICurrentPeriodCustom).period);
            break;
        default:
            periods.push(getDatesPeriodByPresetType(data.currentPeriodType));
    }

    periods.push(data.lastPeriodType === LastPeriodTypeEnum.Custom
        ? data.customLastPeriod as IDatesPeriod
        : getLastDatesPeriodByType(data.currentPeriod, data.lastPeriodType));

    return periods
        .filter((_, index) => !data.isCompareEnabled ? (index === 0) : true )
        .map(period => datePeriodString(period.from, period.to, 'YYYY-MM-DD', ';', false));
};

/**
 * API для работы с организациями системы
 */
class AnalyticsApi extends BaseApi {
    public constructor() {
        super('/areas');
    }

    /**
     * Возвращает данные для страницы Dashboard / Сводка
     *
     * @param organizationId Уникальное id организации
     * @param data Актульаные данные календаря
     */
    public async dashboardData(
        organizationId: number,
        data: IAnalyticsCalendarState,
    ): Promise<IAnalyticsPageResult<IDashboardResultPeriod>> {
        const result = await this.axios.get<IAnalyticsPageResult<IDashboardResultPeriod>>(
            `/dashboard/${organizationId}`,
            {
                params: {
                    periods: getPeriods(data),
                },
            },
        );

        return result.data;
    }

    /**
     * Возвращает данные для страницы "Посещения"
     *
     * @param organizationId Уникальное id организации
     * @param data Актуальные данные календаря
     */
    public async visitsData(
        organizationId: number,
        data: IAnalyticsCalendarState,
    ): Promise<IAnalyticsPageResult<IVisitsResultPeriod>> {
        const result = await this.axios.get<IAnalyticsPageResult<IVisitsResultPeriod>>(`/visits/${organizationId}`, {
            params: {
                periods: getPeriods(data),
                granularity: 'day',
            },
        });

        return result.data;
    }

    /**
     * Возвращает данные для страницы "Вовлеченность"
     *
     * @param organizationId Уникальное id организации
     * @param data Актуальные данные календаря
     */
    public async involvementData(
        organizationId: number,
        data: IAnalyticsCalendarState,
    ): Promise<IInvolvementPageResult> {
        const result = await this.axios.get<IInvolvementPageResult>(`/involvement/${organizationId}`, {
            params: {
                periods: getPeriods(data),
                granularity: 'day',
            },
        });

        return result.data;
    }

    /**
     * Возвращает данные для страницы "Частота"
     *
     * @param organizationId Уникальное id организации
     * @param data Актуальные данные календаря
     */
    public async frequencyData(organizationId: number, data: IAnalyticsCalendarState): Promise<IFrequencyPageResult> {
        const result = await this.axios.get<IFrequencyPageResult>(`/frequency/${organizationId}`, {
            params: {
                periods: getPeriods(data),
            },
        });

        return result.data;
    }

    /**
     * Возвращает данные для страницы "Лояльность"
     *
     * @param organizationId Уникальное id организации
     * @param months Кол-во месяцев для данных
     */
    public async loyaltyData(
        organizationId: number,
        months: ILoyaltyPageState['selectedMonths'],
    ): Promise<ILoyaltyPageResult> {
        const result = await this.axios.get<ILoyaltyPageResult>(`/loyalty/${organizationId}`, { params: { months } });

        return result.data;
    }

    /**
     * Возвращает данные для годового отчета.
     *
     * @param organizationId  Уникальное id организации
     * @param query URL параметры
     */
    public async summaryData(
        organizationId: number,
        query: {
            periodType: 'month' | 'year' | 'quarter' | 'custom';
            periods?: string[];
            month?: string;
            quarter?: string;
            year?: number;
            current?: CurrentPeriodTypeEnum;
            last?: LastPeriodTypeEnum;
        },
    ): Promise<IYearSummaryData[] | IMonthSummaryData | IQuarterSummaryData> {
        const result = await this.axios.get<IYearSummaryData[] | IMonthSummaryData | IQuarterSummaryData>(
            `/analytics-summary/${organizationId}`,
            {
                params: query,
            },
        );

        return result.data;
    }
}

export default new AnalyticsApi();

/**
 * API для работы с производственным календарем
 *
 * @author Artem Bakulin
 */

import { IHolidayCalendarEntity } from '../types/holiday.calendar';

import { BaseApi } from './abstract.api';

/**
 * API для работы с производственным календарем
 */
class HolidayCalendarApi extends BaseApi {
    public constructor() {
        super('/holiday-calendar');
    }

    /**
     * Получение полного пользовательского календаря
     */
    public async list(): Promise<IHolidayCalendarEntity[]> {
        return (await this.axios.get<IHolidayCalendarEntity[]>('/')).data;
    }

    /**
     * Обновление всего производственного календаря
     */
    public async update(): Promise<IHolidayCalendarEntity[]> {
        return (await this.axios.put<IHolidayCalendarEntity[]>('/')).data;
    }
}

export default new HolidayCalendarApi();

import { connect } from 'react-redux';

import { usersActions } from '../actions/users';
import LoginForm from '../forms/LoginForm';

const mapDispatchToProps = {
    authenticate: usersActions.user.authenticate,
};

export default connect(
    undefined,
    mapDispatchToProps,
)(LoginForm);

import { createStyles, Theme } from '@material-ui/core';

import { APP_MENU_WIDTH } from '../AppMenu/styles';

export const styles = (theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
            position: 'relative',
            minWidth: theme.breakpoints.values.lg - APP_MENU_WIDTH,
            minHeight: '100vh',
        },
        toolbar: theme.mixins.toolbar,
    });

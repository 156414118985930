import { Grid, IconButton, Paper, Typography, WithStyles, withStyles } from '@material-ui/core';
import { IconFlagRU, IconFlagUS, IconFlagES, IconFlagFR } from 'material-ui-flags';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import { globalActions } from '../../actions/global';
import LoginForm from '../../containers/LoginForm';
import { PolyglotSingleton } from '../../lib/services/translation';
import { Language } from '../../types/global';

import { styles } from './styles';

interface IProps extends WithStyles<typeof styles> {}

const polyglot = PolyglotSingleton.getInstance();

const LoginPage = ({classes}: IProps) => {
    const dispatch = useDispatch();

    const changeLanguageClick = React.useCallback((lng: Language) => () => {
        dispatch(globalActions.global.changeLanguage.change(lng));
    }, [dispatch]);

    return (
        <Grid container spacing={3} alignItems="flex-start" className={classes.loginFormWrapper} justify="center">
            <Paper className={classes.loginPaper}>
                <Grid container spacing={0} justify="flex-end">
                    <IconButton onClick={changeLanguageClick(Language.RU)}>
                        <IconFlagRU />
                    </IconButton>
                    <IconButton onClick={changeLanguageClick(Language.EN)}>
                        <IconFlagUS />
                    </IconButton>
                    <IconButton onClick={changeLanguageClick(Language.ES)}>
                        <IconFlagES />
                    </IconButton>
                    <IconButton onClick={changeLanguageClick(Language.FR)}>
                        <IconFlagFR />
                    </IconButton>
                </Grid>
                <Typography variant="h4" align="center" paragraph>
                    {polyglot.t('common.user.header')}
                </Typography>
                <LoginForm />
            </Paper>
        </Grid>
    );
}

export default withStyles(styles)(LoginPage);

/**
 * Redux Actions для работы с пользователями
 *
 * @author Artem Bakulin
 */

import { Action, createActions } from 'redux-actions';

import { Language } from '../types/global';
import { IUser } from '../types/users';

type ErrorActionFunction = (error: Error) => Action<Error>;

interface IUsersActions {
    user: {
        logout: {
            failed: ErrorActionFunction;
            send(): Action<undefined>;
            success(): Action<undefined>;
        };
        profile: {
            failed: ErrorActionFunction;
            fetch(): Action<undefined>;
            success(user: IUser): Action<IUser>;
        };
        changeLanguage: {
            failed: ErrorActionFunction;
            change(lng: Language): Action<Language>;
            success(lng: Language): Action<Language>;
        };
        authenticate(user: IUser): Action<IUser>;
    };
}

export const usersActions: IUsersActions = createActions({
    user: {
        authenticate: (user: IUser) => user,
        profile: {
            fetch: undefined,
            success: (user: IUser) => user,
            failed: (error: Error) => error,
        },
        changeLanguage: {
            failed: (error: Error) => error,
            change: (lng: Language) => lng,
            success: (lng: Language) => lng,
        },
        logout: {
            send: undefined,
            success: undefined,
            failed: undefined,
        },
    },
    // tslint:disable-next-line:no-any
}) as any;

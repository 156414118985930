/**
 * Запросы к API для получениях аналитических данных для индивидуальных графиков.
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import { ApiChartDataResult } from '../components/Charts/ApiChart/types';
import { FrequencyFilter } from '../components/Charts/DetailedApiChart/ChartFrequency';
import { IAnalyticsCalendarState } from '../reducers/analytics.calendar';
import { AnalyticsDataGranularity } from '../types/date.periods';

import { BaseApi } from './abstract.api';
import { getPeriods } from './analytics.api';

const getApiOptionsParams = (
    data: IAnalyticsCalendarState,
    granularity?: AnalyticsDataGranularity,
    percents?: boolean,
    uniqs?: boolean,
    frequency?: FrequencyFilter,
) => ({
    params: {
        periods: getPeriods(data),
        granularity,
        percents,
        uniqs,
        frequency,
    },
});

/**
 * Запросы к API для получениях аналитических данных для индивидуальных графиков.
 */
class ChartsApi extends BaseApi {
    public constructor() {
        super('/charts');
    }

    /**
     * Возвращает данные для графика визитов.
     *
     * Страницы: Сводка
     *
     * @param organizationId Уникальное id организации
     * @param data Актуальные данные календаря
     */
    public async chartData<K extends ApiChartDataResult>(
        apiRoute: string,
        organizationId: number,
        data: IAnalyticsCalendarState,
        granularity?: AnalyticsDataGranularity,
        percents?: boolean,
        uniqs?: boolean,
        frequency?: FrequencyFilter,
    ): Promise<K> {
        return (
            await this.axios.get<K>(
                `/${apiRoute}/${organizationId}`,
                getApiOptionsParams(data, granularity, percents, uniqs, frequency),
            )
        ).data;
    }
}

export default new ChartsApi();

/**
 * Страница "Визиты по стоимости авто" для месячного отчета.
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import moment from 'moment';
import React from 'react';

import { Table } from '../../../components/Pdf/Charts/Table';
import { PolyglotSingleton } from '../../../lib/services/translation';
import { IMonthSummaryData } from '../../../types/analytics';
import { PRICE_GROUPS } from '../Summary';

interface IProps {
    data: IMonthSummaryData;
}

const polyglot = PolyglotSingleton.getInstance();

export const VisitsByPricePage = ({ data }: IProps) => {
    const rows = [
        [...PRICE_GROUPS.map((group) => data.comporable[0].visitsByPrice[`visits_percent_${group}`])],
        ...data.months.slice(1).map((monthData) => [
            ...PRICE_GROUPS.map((group) => monthData.visitsByPrice[`visits_percent_${group}`])
        ])
    ];
    const additionalRows = [
        [...PRICE_GROUPS.map((group) => data.comporable[0].visitsByPrice[`visits_${group}`])],
        ...data.months.slice(1).map((monthData) => [
            ...PRICE_GROUPS.map((group) => monthData.visitsByPrice[`visits_${group}`])
        ])
    ];
    const months = [
        moment(data.comporable[0].date).format('MMM YY'),
        ...data.months.slice(1).map((month) => moment(month.date).format('MMM YY')),
    ];
    const columns = PRICE_GROUPS.map((group) => (<>
        <span>{group}</span>
        <br />
        <span style={{ opacity: 0.5, fontSize: '3.5mm'}}>
            {polyglot.t(`pdf.priceRages.${group}`)}
        </span>
    </>))

    return (
        <Table
            title={polyglot.t('pdf.charts.visitsByPrice')}
            columnsHeader={columns}
            rowsHeader={months}
            rows={rows}
            additionalRows={additionalRows}
            valueFormat="percent"
            additionalValueFormat="absolute"
        />
    );
};

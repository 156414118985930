import { createMuiTheme, Theme } from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';
import { darken } from '@material-ui/core/styles/colorManipulator';
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';

export const colors = {
    textOnDark: '#d3d2d8',
    accentColor: '#b593d1',
};

// tslint:disable-next-line:no-object-literal-type-assertion
export const theme: Theme = createMuiTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
    palette: {
        primary: {
            main: '#00aedb',
            contrastText: '#fff',
        },
        secondary: {
            main: '#4a4868',
            contrastText: '#fff',
        },
        background: {
            default: '#fff',
        },
        text: {
            primary: '#4a4a4a',
            secondary: 'rgba(74, 74, 74, 0.5)',
        },
    },
    typography: {
        fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
        fontSize: 12,
        fontWeightMedium: 600,
        h1: {
            fontSize: '2.25rem',
            fontWeight: 300,
        },
        h2: {
            fontSize: '1.625rem',
            fontWeight: 300,
        },
        caption: {
            fontSize: '0.875rem',
            fontWeight: 500,
        },
    },
    overrides: {
        MuiButton: {
            root: {
                borderRadius: 999,
                fontWeight: 400,
                fontSize: '0.75rem',
                textTransform: 'none',
            },
            contained: {
                boxShadow: 'none',
                padding: '7px 16px 5px',
            },
            outlined: {
                padding: '7px 16px 5px',
            },
            sizeLarge: {
                padding: '8px 36px',
                fontSize: '1.125rem',
            },
            sizeSmall: {
                fontSize: '0.625rem',
            },
        },
        MuiPaper: {
            rounded: {
                borderRadius: 20,
            },
        },
        MuiAppBar: {
            root: {
                boxShadow: 'none',
                borderBottom: '1px solid rgba(212, 211, 219, 0.5)',
            },
            colorDefault: {
                backgroundColor: '#fff',
            },
        },
        MuiInputLabel: {
            outlined: {
                transform: 'translate(14px, 12px) scale(1)',
            },
        },
        MuiOutlinedInput: {
            input: {
                padding: '10px 14px',
            },
        },
        MuiFilledInput: {
            input: {
                padding: '10px 14px',
            },
        },
        MuiDialogContent: {
            root: {
                padding: '8px 24px 24px',
            },
        },
        MuiCard: {
            root: {
                boxShadow: '2px 2px 10px rgba(0, 0, 0, .1)',
            },
        },
        MuiToggleButton: {
            root: {
                backgroundColor: grey[100],
                color: '#4a4a4a',
                textTransform: 'none',
                height: 40,
                border: 'none',
                padding: '8px 16px',
                whiteSpace: 'nowrap',

                '&:not(:first-child)': {
                    borderColor: grey[200],
                },

                '&:last-child': {
                    borderTopRightRadius: 999,
                    borderBottomRightRadius: 999,
                    borderRight: 'none',
                },

                '&:first-child': {
                    borderTopLeftRadius: 999,
                    borderBottomLeftRadius: 999,
                },

                '&$selected': {
                    color: '#fff',
                    backgroundColor: '#00aedb',
                },

                '&:not($selected):hover': {
                    backgroundColor: darken(grey[100], 0.08),
                },

                '&$selected:hover': {
                    backgroundColor: `${darken('#00aedb', 0.08)} !important`,
                },
            },
        },
        MuiDialogContentText: {
            root: {
                marginBottom: 24,
            },
        },
    },
} as ThemeOptions);

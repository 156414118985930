import { connect } from 'react-redux';

import { organizationsActions } from '../actions/organizations';
import OrganizationsPage from '../pages/Organizations';
import { IAppState } from '../reducers';
import { IUser } from '../types/users';

const mapStateToProps = (state: IAppState) => ({
    ...state.organizations,
    user: state.global.user as IUser,
});

const mapDispatchToProps = {
    list: organizationsActions.organization.list.send,
    delete: organizationsActions.organization.delete.send,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(OrganizationsPage);

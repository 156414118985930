import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import React, { useCallback } from 'react';

import { PolyglotSingleton } from '../../../lib/services/translation';
import { AnalyticsDataGranularity } from '../../../types/date.periods';

import { useStyles } from './styles';

interface IProps {
    granularity?: AnalyticsDataGranularity;
    required?: boolean;
    onGranularityChange(granularity?: AnalyticsDataGranularity): void;
}

const polyglot = PolyglotSingleton.getInstance();

export const ChartGranularity = ({granularity, onGranularityChange, required = false}: IProps) => {
    const classes = useStyles();
    const changeAnalyticsGranularity = useCallback((_, value: AnalyticsDataGranularity | null) => {
        onGranularityChange(value !== null ? value : (required ? granularity : undefined));
    }, [onGranularityChange, required, granularity]);

    return (
        <ToggleButtonGroup
            className={classes.granularityBtns}
            value={granularity}
            exclusive
            onChange={changeAnalyticsGranularity}
        >
            <ToggleButton value="day">{polyglot.t('analyticsCalendar.granularity.day')}</ToggleButton>
            <ToggleButton value="week">{polyglot.t('analyticsCalendar.granularity.week')}</ToggleButton>
            <ToggleButton value="month">{polyglot.t('analyticsCalendar.granularity.month')}</ToggleButton>
        </ToggleButtonGroup>
    );
}

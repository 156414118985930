import { connect } from 'react-redux';

import { globalActions } from '../actions/global';
import { organizationsActions } from '../actions/organizations';
import OrganizationForm from '../forms/OrganizationForm';

const mapDispatchToProps = {
    create: organizationsActions.organization.create,
    update: organizationsActions.organization.update,
    notify: globalActions.global.notifications.add,
};

export default connect(
    undefined,
    mapDispatchToProps,
)(OrganizationForm);

/** @author Artem Bakulin <dekkyartem@gmail.com> */

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
    typo: {
        fontFamily: '"HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif',
        fontWeight: 300,
        color: '#121212',
    },
    gradient: {
        background: 'linear-gradient(275.65deg, #01D4D3 -28.05%, #000000 -0.13%, #21628C 42.04%, #E75791 72.12%, #EB2446 90.14%, #F2C215 106%)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        fontWeight: 700,
        width: 'fit-content',
    },
}));

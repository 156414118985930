/**
 * Footer страницы PDF отчета.
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import { Grid, WithStyles, withStyles } from '@material-ui/core';
import React from 'react';

import Logo from '../Logo';

import { styles } from './styles';

interface IProps extends WithStyles<typeof styles> {
    period?: string;
}

const PdfFooter = ({ classes, period }: IProps) => (
    <Grid container justify="space-between" alignItems="center" className={classes.root}>
        <Grid item>
            <Logo />
        </Grid>
        <Grid item className={classes.period}>
            {period}
        </Grid>
    </Grid>
);

export default withStyles(styles)(PdfFooter);

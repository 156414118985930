/**
 * Стили для страницы отчетов.
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import { createStyles, Theme } from '@material-ui/core';

export const styles = (theme: Theme) =>
    createStyles({
        title: {
            marginBottom: theme.spacing(3),
        },
        yearSelect: {
            minWidth: 125,
        },
        monthSelect: {
            minWidth: 125,
        },
        fileSelect: {
            minWidth: 125,
        },
        progress: {
            marginTop: theme.spacing(3),
        },
        lastPeriodTitle: {
            opacity: 0.5,
        },
    });

/**
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */
import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
    thead: {
        backgroundColor: theme.palette.primary.main,
    },
    theadCell: {
        border: '1px solid #fff',
        color: '#fff',
    }
}));

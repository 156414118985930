/**
 * Генерация месячного отчета.
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import { FormControl, Grid, InputLabel, MenuItem, Select, WithStyles, withStyles } from '@material-ui/core';
import moment from 'moment';
import React, { useCallback, useState } from 'react';

import { MIN_DATE_VNUKOVO } from '../../components/AnalyticsCalendar';
import Translate from '../../components/Translate';
import { IUserOrganization } from '../../types/organizations';

import { ReportFileType } from '.';
import ReportButton from './ReportButton';
import { styles } from './styles';

interface IProps extends WithStyles<typeof styles> {
    id: string;
    organization: IUserOrganization;
    setProgress?(state: boolean): void;
}

const monthsElements = () => {
    const firstMonth: moment.Moment = moment(MIN_DATE_VNUKOVO).startOf('month').add(3, 'months');
    const monthsCount: number = moment().diff(firstMonth, 'months');
    const months: React.ReactElement[] = [];

    for (let i = 0; i < monthsCount; i += 1) {
        const curMonth: moment.Moment = moment(firstMonth).add(i, 'months');

        months.push(
            <MenuItem value={curMonth.format('YYYY-MM-01')} key={curMonth.format('YYYY-MM-01')}>
                {curMonth.format('MMM YY')}
            </MenuItem>,
        );
    }

    return months.reverse();
};

const ReportMonth = ({ id, classes, organization }: IProps) => {
    const [month, setMonth] = useState(
        moment()
            .subtract(1, 'month')
            .format('YYYY-MM-01'),
    );
    const [fileType, setFileType] = useState<ReportFileType>('pdf');

    const changeMonth = useCallback((event: React.ChangeEvent<{ value: unknown }>) => {
        setMonth(event.target.value as string);
    }, []);

    const changeFile = useCallback((event: React.ChangeEvent<{ value: unknown }>) => {
        setFileType(event.target.value as ReportFileType);
    }, []);

    const generateReport = useCallback(() => {
        window.open(`/api/areas/summary/${organization.id}?type=${fileType}&periodType=month&month=${month}`);
    }, [month, fileType, organization]);

    return (
        <Grid container spacing={3}>
            <Grid item>
                <FormControl variant="outlined" className={classes.monthSelect}>
                    <InputLabel id={`${id}MonthLabel`}>
                        <Translate phrase="reports.labels.selectMonth" />
                    </InputLabel>
                    <Select value={month} id={`${id}Month`} onChange={changeMonth}>
                        {monthsElements()}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item>
                <FormControl variant="outlined" className={classes.fileSelect}>
                    <InputLabel id={`${id}FileLabel`}>
                        <Translate phrase="reports.labels.selectFileType" />
                    </InputLabel>
                    <Select value={fileType} id={`${id}File`} onChange={changeFile}>
                        <MenuItem value="pdf">PDF</MenuItem>
                        <MenuItem value="xlsx">Excel</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item>
                <ReportButton onClick={generateReport} />
            </Grid>
        </Grid>
    );
};

export default withStyles(styles)(ReportMonth);

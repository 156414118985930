/**
 * Redux Actions для работы с организациями
 *
 * @author Artem Bakulin
 */

import { Action, createActions } from 'redux-actions';

import { IOrganization } from '../types/organizations';

interface IOrganizationsActions {
    organization: {
        list: {
            fail(): Action<undefined>;
            send(): Action<undefined>;
            success(organizations: IOrganization[]): Action<IOrganization[]>;
        };
        create(organization: IOrganization): Action<IOrganization>;
        update(organization: IOrganization): Action<IOrganization>;
        delete: {
            send(organizationId: number): Action<number>;
            success(organizationId: number): Action<number>;
        };
    };
}

export const organizationsActions: IOrganizationsActions = createActions({
    organization: {
        list: {
            send: undefined,
            success: (organizations: IOrganization[]) => organizations,
            fail: undefined,
        },
        create: (organization: IOrganization) => organization,
        update: (organization: IOrganization) => organization,
        delete: {
            send: (organizationId: number) => organizationId,
            success: (organizationId: number) => organizationId,
        },
    },
    // tslint:disable-next-line:no-any
}) as any;

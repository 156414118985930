/**
 * Reducers для управления состоянием организаций
 *
 * @author Artem Bakulin
 */
import { Action, handleActions } from 'redux-actions';

import { organizationsActions } from '../actions/organizations';
import { IOrganization } from '../types/organizations';

export interface IOrganizationsState {
    inProgress: boolean;
    items?: IOrganization[];
}

const initialState: IOrganizationsState = {
    inProgress: false,
};

/**
 * Reducers для управления состоянием организаций
 */
// tslint:disable-next-line:no-any
export const organizationsReducer = handleActions<IOrganizationsState, any>(
    {
        // Получение списка организаций
        [organizationsActions.organization.list.send.toString()]: (state: IOrganizationsState) => ({
            ...state,
            inProgress: true,
        }),
        [organizationsActions.organization.list.success.toString()]: (
            state: IOrganizationsState,
            action: Action<IOrganization[]>,
        ) => ({
            ...state,
            inProgress: false,
            items: action.payload,
        }),
        [organizationsActions.organization.list.fail.toString()]: (state: IOrganizationsState) => ({
            ...state,
            inProgress: false,
        }),

        // Создание / обновление организаций
        [organizationsActions.organization.create.toString()]: (
            state: IOrganizationsState,
            action: Action<IOrganization>,
        ) => ({
            ...state,
            items: state.items !== undefined ? [...state.items, action.payload] : [action.payload],
        }),
        [organizationsActions.organization.update.toString()]: (
            state: IOrganizationsState,
            action: Action<IOrganization>,
        ) => {
            const newState = { ...state, items: [...(state.items as IOrganization[])] };
            const updatedItemIndex = (state.items as IOrganization[]).findIndex(item => item.id === action.payload.id);

            if (updatedItemIndex >= 0) {
                newState.items[updatedItemIndex] = action.payload;
            }

            return newState;
        },

        // Удаление организаций
        [organizationsActions.organization.delete.success.toString()]: (
            state: IOrganizationsState,
            action: Action<number>,
        ) => {
            const newState = { ...state, inProgress: false, items: [...(state.items as IOrganization[])] };
            const deletedItemIndex = (state.items as IOrganization[]).findIndex(item => item.id === action.payload);

            if (deletedItemIndex >= 0) {
                newState.items.splice(deletedItemIndex, 1);
            }

            return newState;
        },
    },
    initialState,
);

/**
 * Хэдер страницы PDF отчета
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import moment from 'moment';
import React from 'react';

import { IUserOrganization } from '../../../types/organizations';
import { Typo } from '../Typo';

import { useStyles } from './styles';

interface IProps {
    month: string,
    organization?: IUserOrganization,
    format?: string;
}

export const Header = ({ month, organization, format = 'MMMM YYYY' }: IProps) => {
    const classes = useStyles();

    return (
        <div className={classes.header}>
            <Typo noWrap>{moment(month).format(format).toUpperCase()} {organization?.name ?? ''}</Typo>
            <div className={classes.hr} />
        </div>
    );
}

/**
 * Месячный отчет в PDF формате
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import api from '../../api/analytics.api';
import { Section } from '../../components/Pdf/Section';
import { PolyglotSingleton } from '../../lib/services/translation';
import { selectOrganizationById } from '../../selectors/common.selectors';
import { IMonthSummaryData } from '../../types/analytics';

import { DurationPage } from './MonthSummary/DurationPage';
import { FrequencyPage } from './MonthSummary/FrequencyPage';
import { NewCarsPage } from './MonthSummary/NewCarsPage';
import { SummaryPage } from './MonthSummary/SummaryPage';
import { SummaryPage2 } from './MonthSummary/SummaryPage2';
import { TermsPage } from './MonthSummary/TermsPage';
import { TopRegionsPage } from './MonthSummary/TopRegionsPage';
import { VisitsByAgePage } from './MonthSummary/VisitsByAgePage';
import { VisitsByPricePage } from './MonthSummary/VisitsByPricePage';
import { VisitsByTypePage } from './MonthSummary/VisitsByTypePage';
import { TitlePage } from './TitlePage';

interface IProps {
    organizationId: number;
    month: string;
}

const polyglot = PolyglotSingleton.getInstance();

const MonthSummary = ({ organizationId, month }: IProps) => {
    const organization = useSelector(selectOrganizationById(organizationId));
    const [inProgress, setProgress] = useState(false);
    const [data, setData] = useState<IMonthSummaryData>();

    useEffect(() => {
        // tslint:disable-next-line: no-floating-promises
        (async () => {
            setProgress(true);
            try {
                const summaryData = (await api.summaryData(organizationId, {
                    periodType: 'month',
                    month,
                })) as IMonthSummaryData;
                setData(summaryData);
            } catch (error) {
                // tslint:disable-next-line: no-console
                console.log(error);
            }
            setProgress(false);
        })();
    }, [organizationId, month]);

    if (inProgress || data === undefined) {
        return null;
    }

    return (
        <>
            <TitlePage month={month} organization={organization} />
            <Section month={month} organization={organization} page={2} name={polyglot.t('pdf.sections.summary')}>
                <SummaryPage data={data} />
            </Section>
            <Section month={month} organization={organization} page={3} name={polyglot.t('pdf.sections.summary')}>
                <SummaryPage2 data={data} />
            </Section>
            <Section month={month} organization={organization} page={4} name={polyglot.t('pdf.sections.newCars')}>
                <NewCarsPage data={data} />
            </Section>
            <Section month={month} organization={organization} page={5} name={polyglot.t('pdf.sections.visitsByType')}>
                <VisitsByTypePage data={data} />
            </Section>
            <Section month={month} organization={organization} page={6} name={polyglot.t('pdf.sections.visitsByPrice')}>
                <VisitsByPricePage data={data} />
            </Section>
            <Section month={month} organization={organization} page={7} name={polyglot.t('pdf.sections.visitsByAge')}>
                <VisitsByAgePage data={data} organization={organization} />
            </Section>
            <Section month={month} organization={organization} page={8} name={polyglot.t('pdf.sections.duration')}>
                <DurationPage data={data} organization={organization} />
            </Section>
            <Section month={month} organization={organization} page={9} name={polyglot.t('pdf.sections.frequency')}>
                <FrequencyPage data={data} organization={organization} />
            </Section>
            <Section month={month} organization={organization} page={10} name={polyglot.t('pdf.sections.topRegions')}>
                <TopRegionsPage data={data} />
            </Section>
            <Section month={month} organization={organization} page={11} name={polyglot.t('pdf.sections.appendix1')}>
                <TermsPage />
            </Section>
        </>
    );
}

export default MonthSummary;

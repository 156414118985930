import { createStyles, Theme } from '@material-ui/core';

export const styles = (theme: Theme) =>
    createStyles({
        wrapper: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: theme.zIndex.tooltip + 100,
            background: theme.palette.common.white,
        },
        progress: {
            width: '120px !important',
            height: '120px !important',
        },
    });

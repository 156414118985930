/**
 * Генерация "custom" отчета.
 *
 * @author Artem Bakulin<dekkyartem@gmail.com>
 */

import { Grid, Typography, WithStyles, withStyles } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { getPeriods } from '../../api/analytics.api';
import AnalyticsCalendar from '../../containers/AnalyticsCalendar';
import { getDatesPeriodByCurrentPeriod, getLastDatesPeriodByType } from '../../lib/services/dates.periods.service';
import { PolyglotSingleton } from '../../lib/services/translation';
import { datePeriodString } from '../../lib/utils/strings';
import { calendarSelector } from '../../selectors/common.selectors';
import { IDatesPeriod, LastPeriodTypeEnum } from '../../types/date.periods';
import { IUserOrganization } from '../../types/organizations';

import ReportButton from './ReportButton';
import { styles } from './styles';

interface IProps extends WithStyles<typeof styles> {
    id: string;
    organization: IUserOrganization;
    setProgress?(state: boolean): void;
}

const polyglot = PolyglotSingleton.getInstance();

const ReportCustom = ({ organization, classes }: IProps) => {
    const calendar = useSelector(calendarSelector);
    const currentPeriod = getDatesPeriodByCurrentPeriod(calendar.currentPeriod);
    const { lastPeriodType } = calendar;
    const lastPeriod =
        lastPeriodType !== LastPeriodTypeEnum.Custom
            ? getLastDatesPeriodByType(calendar.currentPeriod, lastPeriodType)
            : (calendar.customLastPeriod as IDatesPeriod);

    const generateReport = useCallback(() => {
        const periods = getPeriods(calendar)
            .map(period => `periods[]=${encodeURI(period)}`)
            .join('&');
        window.open(
            `/api/areas/summary/${organization.id}?type=pdf&periodType=custom&${periods}&current=${calendar.currentPeriodType}&last=${calendar.lastPeriodType}`,
        );
    }, [organization, calendar]);

    return (
        <>
            <Grid container spacing={3} direction="column">
                <Grid item>
                    <AnalyticsCalendar toggleCalendar={() => {}} />
                </Grid>
                <Grid item container spacing={3} alignItems="center">
                    <Grid item>
                        <Typography variant="caption" noWrap component="p">
                            {datePeriodString(currentPeriod.from, currentPeriod.to, polyglot.t('common.dates.date'))}
                        </Typography>
                        <Typography variant="caption" className={classes.lastPeriodTitle} noWrap component="p">
                            {datePeriodString(lastPeriod.from, lastPeriod.to, polyglot.t('common.dates.date'))}{' '}
                            {polyglot.t('dashboard.precedingAcronym')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <ReportButton onClick={generateReport} />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default withStyles(styles)(ReportCustom);

/**
 * Карточка со страницы Сводка для PDF отчета.
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import cx from 'classnames';
import React from 'react';

import { PolyglotSingleton } from '../../../lib/services/translation';
import { formatValue } from '../../Charts/ApiChart/helpers';
import { ApiChartValuesType } from '../../Charts/ApiChart/types';
import { calcIncrease } from '../Charts/helpers';
import { Typo } from '../Typo';

import { ReactComponent as Decrease } from './decrease.svg';
import { ReactComponent as Increase } from './increase.svg';
import { useStyles } from './styles';

interface IProps {
    indicator: string;
    value: number;
    prevValue: number;
    className?: string;
    isNegativeIncrease?: boolean;
    valueFormat?: ApiChartValuesType;
}

const polyglot = PolyglotSingleton.getInstance();

export const AnalyticCard = ({
    indicator,
    value,
    prevValue,
    valueFormat = 'absolute',
    className,
    isNegativeIncrease = false,
}: IProps) => {
    const classes = useStyles();

    const increaseValue = calcIncrease(value, prevValue);
    const increaseIcon = increaseValue !== null
        ? (
            (!isNegativeIncrease && increaseValue >= 0) || (isNegativeIncrease && increaseValue <= 0) ? (
                <Increase className={classes.iconUp} />
            ) : (
                <Decrease className={classes.iconDown} />
            )
        )
        : null;

    return (
        <div className={cx(classes.card, className !== undefined ? className : undefined)}>
            <Typo className={classes.indicator}>{indicator}</Typo>
            <Typo className={classes.value}>{formatValue(valueFormat)(value)}</Typo>
            {increaseValue !== null
                ? <Typo className={classes.increase}>{increaseIcon}&nbsp;{formatValue('percent')(increaseValue)}</Typo>
                : null}
            <Typo className={classes.prevValue}>{formatValue(valueFormat)(prevValue)}&nbsp;{polyglot.t('dashboard.precedingAcronym')}</Typo>
        </div>
    );
}

/**
 * Генерация годового отчета.
 *
 * @author Artem Bakulin<dekkyartem@gmail.com>
 */

import { FormControl, Grid, InputLabel, MenuItem, Select, WithStyles, withStyles } from '@material-ui/core';
import moment from 'moment';
import React, { useCallback, useState } from 'react';

import { MIN_DATE_VNUKOVO } from '../../components/AnalyticsCalendar';
import Translate from '../../components/Translate';
import { IUserOrganization } from '../../types/organizations';

import ReportButton from './ReportButton';
import { styles } from './styles';

interface IProps extends WithStyles<typeof styles> {
    id: string;
    organization: IUserOrganization;
    setProgress?(state: boolean): void;
}

const yearsElements = () => {
    const firstYear: moment.Moment = moment(MIN_DATE_VNUKOVO).startOf('year');
    const yearsCount: number = moment()
        .startOf('year')
        .diff(firstYear, 'years');
    const years: React.ReactElement[] = [];

    for (let i = 0; i < yearsCount; i += 1) {
        const curYear: moment.Moment = moment(firstYear).add(i, 'years');

        years.push(
            <MenuItem value={curYear.year()} key={curYear.year()}>
                {curYear.year()}
            </MenuItem>,
        );
    }

    return years.reverse();
};

const ReportYear = ({ id, classes, organization }: IProps) => {
    const [year, setYear] = useState(
        moment()
            .subtract(1, 'year')
            .year(),
    );

    const changeYear = useCallback((event: React.ChangeEvent<{ value: unknown }>) => {
        setYear(parseInt(event.target.value as string, 10));
    }, []);

    const generateReport = useCallback(() => {
        window.open(`/api/areas/summary/${organization.id}?type=pdf&periodType=year&year=${year}`);
    }, [year, organization]);

    return (
        <Grid container spacing={3}>
            <Grid item>
                <FormControl variant="outlined" className={classes.yearSelect}>
                    <InputLabel id={`${id}YearLabel`}>
                        <Translate phrase="reports.labels.selectYear" />
                    </InputLabel>
                    <Select value={year} id={`${id}Year`} onChange={changeYear}>
                        {yearsElements()}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item>
                <ReportButton onClick={generateReport} />
            </Grid>
        </Grid>
    );
};

export default withStyles(styles)(ReportYear);

/**
 * PDF отчет за пользовательский период времени.
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import React, { useEffect, useState } from 'react';

import api from '../../api/analytics.api';
import PdfSection from '../../components/PdfSection';
import { PolyglotSingleton } from '../../lib/services/translation';
import { getPeriodFormat } from '../../lib/utils/dates';
import { datePeriodString, datesPeriodFromString } from '../../lib/utils/strings';
import { ICustomSummaryData } from '../../types/analytics';
import { CurrentPeriodTypeEnum, LastPeriodTypeEnum } from '../../types/date.periods';

import DashboardPage from './MonthCustomSummary/DashboardPage';
import DurationPage from './MonthCustomSummary/DurationPage';
import FrequencyPage from './MonthCustomSummary/FrequencyPage';
import VisitsPage12 from './MonthCustomSummary/VisitsPage12';
import VisitsPagesMore from './MonthCustomSummary/VisitsPagesMore';

const PRICES_PAGE_SIZE = 3;

interface IProps {
    organizationId: number;
    periods: string[];
    current: CurrentPeriodTypeEnum;
    last: LastPeriodTypeEnum;
}

const polyglot = PolyglotSingleton.getInstance();

const PdfCustomSummary = ({ organizationId, periods, current, last }: IProps) => {
    const [inProgress, setProgress] = useState(false);
    const [data, setData] = useState<ICustomSummaryData>();

    useEffect(() => {
        // tslint:disable-next-line: no-floating-promises
        (async () => {
            setProgress(true);
            try {
                const summaryData = (await api.summaryData(organizationId, {
                    periodType: 'custom',
                    periods,
                    current,
                    last,
                })) as ICustomSummaryData;
                setData(summaryData);
            } catch (error) {
                // tslint:disable-next-line: no-console
                console.log(error);
            }
            setProgress(false);
        })();
    }, [organizationId, periods, current, last]);

    if (inProgress || data === undefined) {
        return null;
    }

    const periodFormat = getPeriodFormat(current, last);
    const period = datesPeriodFromString(periods[0], 'YYYY-MM-DD', ';');
    const periodString = datePeriodString(period.from, period.to, getPeriodFormat(current, last), '–');
    const chartType =
        current === CurrentPeriodTypeEnum.Custom || last !== LastPeriodTypeEnum.Prior || data.months.length === 1
            ? 'column'
            : 'line';
    const pricesGroups = Object.keys(data.months[0].visitsByPriceAndAge);
    const visitsPricesPages = new Array(Math.ceil(pricesGroups.length / PRICES_PAGE_SIZE)).fill(0);
    const rotateLabels =
        ![CurrentPeriodTypeEnum.LastMonth, CurrentPeriodTypeEnum.LastSeason].includes(current) ||
        last === LastPeriodTypeEnum.Custom
            ? -20
            : undefined;

    return (
        <>
            <PdfSection page={1} title={polyglot.t('dashboard.header')} period={periodString}>
                <DashboardPage
                    data={data}
                    id="months"
                    chartType={chartType}
                    periodFormat={periodFormat}
                    rotateLabels={rotateLabels}
                />
            </PdfSection>
            <VisitsPage12
                data={data}
                chartType={chartType}
                periodFormat={periodFormat}
                page={2}
                period={periodString}
                rotateLabels={rotateLabels}
            />
            {visitsPricesPages.map((_, index) => (
                <VisitsPagesMore
                    key={index}
                    data={data}
                    id={`months_${index}`}
                    periodFormat={periodFormat}
                    period={periodString}
                    page={index + 3}
                    pricesGroups={pricesGroups.slice(index * PRICES_PAGE_SIZE, (index + 1) * PRICES_PAGE_SIZE)}
                />
            ))}
            <PdfSection
                page={visitsPricesPages.length + 4}
                title={polyglot.t('involvement.header')}
                period={periodString}
            >
                <DurationPage data={data} chartType={chartType} periodFormat={periodFormat} />
            </PdfSection>
            <PdfSection
                page={visitsPricesPages.length + 5}
                title={polyglot.t('frequency.header')}
                period={periodString}
            >
                <FrequencyPage data={data} periodFormat={periodFormat} />
            </PdfSection>
        </>
    );
};

export default PdfCustomSummary;

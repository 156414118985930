/**
 * Компонент графика, который умеет сам делать запросы на сервер, для страницы "Визиты".
 * Построен на библиотеке ChartJS.
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { ChartData, ChartDataset, ChartOptions } from 'chart.js';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import cx from 'classnames';
import { merge } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';

import { getPeriods } from '../../../api/analytics.api';
import api from '../../../api/charts.api';
import { PolyglotSingleton } from '../../../lib/services/translation';
import { getChartExportXLSXLink } from '../../../lib/utils/charts';
import { difference } from '../../../lib/utils/math';
import { formatValue } from '../../../lib/utils/strings';
import { IAnalyticsCalendarState } from '../../../reducers/analytics.calendar';
import { calendarSelector, selectedOrganizationSelector } from '../../../selectors/common.selectors';
import { DetailedAnalyticsPeriod } from '../../../types/analytics';
import { AnalyticsDataGranularity, IDatesPeriod } from '../../../types/date.periods';
import { ImportXLXSChartButton } from '../ImportXLXSChartButton';

import { ChartFrequency, FrequencyFilter } from './ChartFrequency';
import { ChartGranularity } from './ChartGranularity';
import { ChartPercentsSelector } from './ChartPercentSelector';
import { ChartUniqsSelector } from './ChartUniqsSelector';
import { getWeekCategory, isEmptyChartData } from './helpers';
import { useStyles } from './styles';

const polyglot = PolyglotSingleton.getInstance();

export type ApiChartFiltersList = Set<'granularity' | 'uniqs' | 'percents' | 'frequency'>;
export type DatasetExtended = ChartDataset<'bar', number[]> & {
    period: IDatesPeriod;
    title?: string;
    dates?: Date[];
};

interface IProps {
    className?: string;
    title?: string;
    chartApi: string;
    filters?: ApiChartFiltersList;
    dataToSeries(
        data: DetailedAnalyticsPeriod[][],
        calendar: IAnalyticsCalendarState,
        granularity: AnalyticsDataGranularity | undefined,
        isPercent: boolean,
        isUniqs: boolean,
        frequency: FrequencyFilter | undefined,
    ): ChartData;
    height?: number;
    options?(
        data: DetailedAnalyticsPeriod[][],
        calendar: IAnalyticsCalendarState,
        granularity: AnalyticsDataGranularity | undefined,
        isPercent: boolean,
        isUniqs: boolean,
        frequency: FrequencyFilter | undefined,
    ): ChartOptions;
}

const getDateTitle = (
    date: Date,
    granularity: AnalyticsDataGranularity,
    curPeriod: IDatesPeriod,
    isFirst: boolean,
    isLast: boolean,
): string => {
    const dateFormat = granularity === 'month' ? 'MMM YY' : 'D.MM.YYYY';

    if (granularity === 'week') {
        return getWeekCategory(moment(date), curPeriod, isFirst, isLast);
    }

    return moment(date).format(dateFormat);
};

const getDefaultOptions = (granularity: AnalyticsDataGranularity | undefined, isPercent: boolean): ChartOptions => ({
    layout: {
        padding: {
            top: 24,
        },
    },
    plugins: {
        datalabels: {
            display: granularity === undefined,
            anchor: 'end',
            align: 'top',
            formatter: value => formatValue(isPercent ? 'percent' : 'absolute')(value as number),
        },
        tooltip: {
            mode: granularity !== undefined ? 'x' : 'index',
            intersect: false,
            callbacks: {
                title: ctx => {
                    const dataIndex = ctx[0].dataIndex;
                    const isFirst = dataIndex === 0;
                    const isLast = dataIndex + 1 === ctx[0].dataset.data.length;
                    const dataset = ctx[0].dataset as DatasetExtended;
                    const label = ctx[0].label?.trim();

                    return granularity === undefined
                        ? label
                        : (
                            Array.isArray(dataset.dates)
                                ? getDateTitle(dataset.dates[dataIndex], granularity, dataset.period, isFirst, isLast)
                                : ((ctx[0].dataset as unknown) as DatasetExtended).title ?? label ?? ''
                        );
                },
                label: ctx => {
                    let label = `${ctx.dataset.label?.trim() ?? ''} ${formatValue(isPercent ? 'percent' : 'absolute')(
                        ctx.parsed.y,
                    )}`;

                    if (granularity === undefined && !isPercent) {
                        const total = ctx.dataset.data.reduce((a, b) => (a as number) + (b as number)) as number;
                        const change = total !== 0 ? (ctx.raw as number) / total : 0;
                        label += ` (${formatValue('percent')(change)})`;
                    }

                    return label;
                },
                footer: ctx => {
                    // tslint:disable-next-line:strict-type-predicates
                    if (granularity === undefined && ctx[1] !== undefined && ctx[0] !== undefined) {
                        return `${polyglot.t('common.dictionary.change')}: ${difference(ctx[1].raw as number, ctx[0].raw as number)}`;
                    }

                    return '';
                },
            },
        },
    },
    scales: {
        y: {
            stacked: granularity !== undefined,
            ticks: {
                callback: value =>
                    formatValue(isPercent ? 'percent' : 'absolute', { maximumFractionDigits: 0 })(value as number),
            },
        },
        x: {
            stacked: granularity !== undefined,
        },
    },
});

export const DetailedApiChart = ({
    className,
    title,
    chartApi,
    dataToSeries,
    height,
    filters = new Set(['percents', 'uniqs', 'granularity']),
    options,
}: IProps) => {
    const classes = useStyles();
    const calendar = useSelector(calendarSelector);
    const periods = getPeriods(calendar);
    const organization = useSelector(selectedOrganizationSelector);
    const [inProgress, setProgress] = useState<boolean>(false);
    const [granularity, setGranularity] = useState<AnalyticsDataGranularity | undefined>(undefined);
    const [isPercent, setIsPercent] = useState(false);
    const [isUniqs, setIsUniqs] = useState(false);
    const [frequency, setFrequency] = useState<FrequencyFilter | undefined>(filters.has('frequency') ? '3' : undefined);
    const [data, setData] = useState<DetailedAnalyticsPeriod[][] | undefined>(undefined);

    useEffect(() => {
        if (organization !== undefined) {
            setProgress(true);
            setData(undefined);

            api.chartData<DetailedAnalyticsPeriod[][]>(
                chartApi,
                organization.id,
                calendar,
                granularity,
                isPercent,
                isUniqs,
                frequency,
            )
                .finally(() => {
                    setProgress(false);
                })
                .then(setData)
                .catch(() => {
                    setData(undefined);
                });
        }
    }, [chartApi, granularity, calendar, organization, isPercent, isUniqs, frequency]);

    const getExportLink = useCallback(() => {
        if (organization === undefined) {
            return '';
        }

        return getChartExportXLSXLink(
            organization.id,
            'visit',
            chartApi,
            periods,
            isUniqs,
            isPercent,
            granularity,
            frequency,
        );
    }, [chartApi, organization, periods, isUniqs, isPercent, granularity, frequency]);

    return (
        <Grid container direction="column" wrap="nowrap" alignItems="stretch" className={cx(classes.root, className)}>
            <Grid item container wrap="nowrap" justify="space-between">
                <Grid item>
                    {title !== undefined ? (
                        <Typography variant="h2" style={{ marginBottom: '32px' }}>
                            {title}
                        </Typography>
                    ) : null}
                </Grid>
                <Grid item style={{ display: 'flex' }}>
                    {filters.has('frequency') ? (
                        <ChartFrequency frequency={frequency as FrequencyFilter} onFrequencyChange={setFrequency} />
                    ) : null}
                    {filters.has('granularity') ? (
                        <ChartGranularity granularity={granularity} onGranularityChange={setGranularity} />
                    ) : null}
                    {filters.has('percents') ? (
                        <ChartPercentsSelector isPercent={isPercent} onSetPercentsChange={setIsPercent} />
                    ) : null}
                    {filters.has('uniqs') ? (
                        <ChartUniqsSelector isUniqs={isUniqs} onSetUniqsChange={setIsUniqs} />
                    ) : null}
                    <ImportXLXSChartButton chartExportXLSXLink={getExportLink()} />
                </Grid>
            </Grid>
            <Grid
                item
                container
                direction="column"
                alignItems={data !== undefined ? 'flex-start' : 'center'}
                style={{ minHeight: height ?? 'none' }}
            >
                {isEmptyChartData(data) && !inProgress ? (
                    <Typography variant="h5" component="p" color="error">
                        {polyglot.t('dashboard.noDataForPeriod')}
                    </Typography>
                ) : null}
                {inProgress ? <CircularProgress /> : null}
                {data !== undefined && !isEmptyChartData(data) ? (
                    <div className={classes.chartWrapper}>
                        <Bar
                            data={dataToSeries([...data], calendar, granularity, isPercent, isUniqs, frequency)}
                            height={height}
                            options={merge(
                                {},
                                getDefaultOptions(granularity, isPercent),
                                options !== undefined
                                    ? options(data, calendar, granularity, isPercent, isUniqs, frequency)
                                    : {},
                            )}
                            plugins={[DataLabelsPlugin]}
                        />
                    </div>
                ) : null}
            </Grid>
        </Grid>
    );
};

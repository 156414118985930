import { createStyles, Theme } from '@material-ui/core';

export const styles = (theme: Theme) => createStyles({
    root: {
        minWidth: theme.breakpoints.values.md + 180,
    },
    customDate: {
        borderLeft: `1px dashed rgba(178, 177, 191, 0.6)`,
    },
    periodSelect: {
        width: '100%',
    },
    datePicker: {
        height: 360,
        borderRight: `1px dashed rgba(178, 177, 191, 0.6)`,
    },
    periodControls: {
        height: 316,
        paddingLeft: 20,
    },
    periodReset: {
        marginRight: 16,
    }
});

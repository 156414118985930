/**
 * Reducers для страницы "Лояльность" организации
 *
 * @author Artem Bakulin
 */

import { Action, handleActions } from 'redux-actions';

import { analyticsPagesActions } from '../actions/analytics.pages';

export interface ILoyaltyPageResult {
    charts: {
        byFrequency: {
            data: Array<{
                label: string;
                value: number;
            }>;
        };
        byFrequencyPercents: {
            data: Array<{
                label: string;
                value: number;
            }>;
        };
        byUniqs: {
            data: Array<{
                label: string;
                value: number;
            }>;
        };
    };
}

export interface ILoyaltyPageState {
    inProgress: boolean;
    selectedMonths: 1 | 6 | 12 | 24;
    results: {
        [index: string]: ILoyaltyPageResult | undefined;
    };
    error?: Error;
}

const initialState: ILoyaltyPageState = {
    inProgress: false,
    selectedMonths: 12,
    results: {},
};

/**
 * Reducers для страницы "Лояльность" организации
 */
// tslint:disable-next-line:no-any
export const loyaltyPageReducer = handleActions<ILoyaltyPageState, any>(
    {
        [analyticsPagesActions.loyalty.selectMonths.toString()]: (
            state: ILoyaltyPageState,
            action: Action<{ months: ILoyaltyPageState['selectedMonths'] }>,
        ) => ({
            ...state,
            selectedMonths: action.payload.months,
        }),
        [analyticsPagesActions.loyalty.send.toString()]: (state: ILoyaltyPageState) => ({
            ...state,
            inProgress: true,
        }),
        [analyticsPagesActions.loyalty.success.toString()]: (
            state: ILoyaltyPageState,
            action: Action<{ hashString: string; data: ILoyaltyPageResult }>,
        ) => ({
            ...state,
            inProgress: false,
            results: {
                ...state.results,
                [action.payload.hashString]: action.payload.data,
            },
        }),
        [analyticsPagesActions.loyalty.fail.toString()]: (state: ILoyaltyPageState, action: Action<Error>) => ({
            ...state,
            inProgress: false,
            error: action.payload,
        }),
    },
    initialState,
);

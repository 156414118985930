/**
 * Одна страница с аналитическими данными. Включает хэдер и футер.
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import React from 'react';

import { IUserOrganization } from '../../../types/organizations';
import { Footer } from '../Footer';
import { Header } from '../Header';
import { Page } from '../Page';
import { Typo } from '../Typo';

import { ReactComponent as Logo } from './logo-small.svg';
import { useStyles } from './styles';

interface IProps {
    month: string;
    organization?: IUserOrganization;
    page?: number;
    name?: string;
    format?: string;
}

export const Section: React.FC<IProps> = ({ name, page, month, organization, children, format }) => {
    const classes = useStyles();

    return (
        <Page className={classes.page}>
            <div className={classes.analyticSection}>
                <Header month={month} organization={organization} format={format} />
                <Logo className={classes.logo} />
                {name !== undefined ? <Typo variant='h2' className={classes.title}>{name}</Typo> : null}
                <main className={classes.content}>
                    {children}
                </main>
                <Footer page={page} />
            </div>
        </Page>
    );
}

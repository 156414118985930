import { createStyles, Theme } from '@material-ui/core';

import { colors } from '../../theme';

export const APP_MENU_WIDTH = 252;

export const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: APP_MENU_WIDTH,
            flexShrink: 0,
        },
        drawerPaper: {
            border: 'none',
            width: APP_MENU_WIDTH,
            backgroundColor: theme.palette.secondary.main,
            paddingRight: theme.spacing(3),
        },
        section: {
            borderBottom: '1px solid #3b3955',
            paddingBottom: theme.spacing(4),

            '&:last-child': {
                borderBottom: 'none',
            },
        },
        title: {
            ...theme.mixins.gutters(),
            fontSize: '0.625rem',
            letterSpacing: '0.1em',
            textTransform: 'uppercase',
            color: colors.textOnDark,
            paddingTop: theme.spacing(5),
            paddingBottom: theme.spacing(1),
        },
        listItem: {
            borderTopRightRadius: 100,
            borderBottomRightRadius: 100,

            '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, .1)',
            },
        },
        listItemSelected: {
            color: colors.accentColor,
            backgroundColor: 'rgba(0, 0, 0, .14)',

            '&:after': {
                content: "''",
                position: 'absolute',
                left: 0,
                top: 0,
                width: 5,
                height: '100%',
                backgroundColor: colors.accentColor,
                borderTopRightRadius: 5,
                borderBottomRightRadius: 5,
            },
        },
        listText: {
            ...theme.typography.body1,
            color: colors.textOnDark,
            fontSize: '1rem',
            padding: 0,
        },
        listTextSelected: {
            color: theme.palette.common.white,
        },
        icon: {
            color: colors.textOnDark,
            fontSize: '1rem',
        },
        iconSelected: {
            color: colors.accentColor,
        },
        toolbar: theme.mixins.toolbar,
        organizationName: {
            color: theme.palette.common.white,
            margin: theme.spacing(5, 0, 2, 2),
        },
        organizationSelection: {
            padding: theme.spacing(5, 0, 2, 2),
        }
    });

import { Grid, WithStyles, withStyles } from '@material-ui/core';
import moment from 'moment';
import React from 'react';

import { ApiChartValuesType } from '../../components/Charts/ApiChart/types';
import IndicatorCard from '../../components/IndicatorCard';
import { PolyglotSingleton } from '../../lib/services/translation';
import { datesPeriodFromString } from '../../lib/utils/strings';
import { IDashboardResultPeriod } from '../../reducers/dashboard.page';
import { IDateAnalyticsPeriod } from '../../types/analytics';
import { CurrentPeriodTypeEnum, LastPeriodTypeEnum } from '../../types/date.periods';
import { IAnalyticsPageResult } from '../../types/global';

import { DashboardChart } from './DashboardChart';
import { styles } from './styles';

type DashboardResultIndicator =
    | 'visitors'
    | 'duration'
    | 'new_visitors'
    | 'new_visitors_percent'
    | 'rich_visitors'
    | 'unique_visitors';

const polyglot = PolyglotSingleton.getInstance();

interface IIndicatorToDataMap {
    title: string;
    popover?: string;
    key: keyof IDashboardResultPeriod;
    valuesType?: ApiChartValuesType;
    route?: string;
    percentsEnabled?: boolean;
    uniqsEnabled?: boolean;
}

const indicatorToDataMap: {
    [indicator in DashboardResultIndicator]: IIndicatorToDataMap;
} = {
    // Визиты
    visitors: {
        title: 'dashboard.visits',
        popover: 'dashboard.popoverText.visits',
        key: 'visitors',
        route: 'visits',
    },
    // Уникальные посетители
    unique_visitors: {
        title: 'dashboard.uniqs',
        key: 'uniqVisitors',
        route: 'uniqs',
    },
    // Новые посетители
    new_visitors: {
        title: 'dashboard.newVisitors',
        popover: 'dashboard.popoverText.newVisitors',
        key: 'newVisitors',
        route: 'new-visits',
    },
    // Доля новых посетителей
    new_visitors_percent: {
        title: 'dashboard.newVisitorsPercent',
        key: 'newVisitorsPercent',
        route: 'new-visits-percent',
        valuesType: 'percent',
    },
    // Проведенное время
    duration: {
        title: 'dashboard.duration',
        popover: 'dashboard.popoverText.duration',
        key: 'avgVisitDuration',
        valuesType: 'duration',
        route: 'duration',
    },
    // Состоятельные визиты
    rich_visitors: {
        title: 'dashboard.richVisitors',
        popover: 'dashboard.popoverText.richVisitors',
        key: 'richVisitorsUniqs',
        route: 'rich-visits-uniqs',
    },

    // long_duration: {
    //     title: 'dashboard.longVisits',
    //     popover: 'dashboard.popoverText.longVisits',
    //     key: 'longVisitsPercent',
    //     valuesType: 'percent',
    //     route: 'long-visits-percent',
    // },
    // regular_visitors: {
    //     title: 'dashboard.regularVisitors',
    //     popover: 'dashboard.popoverText.regularVisitors',
    //     key: 'regularVisitors',
    //     route: 'regular-visits',
    //     percentsEnabled: false,
    // },
};

interface IProps extends WithStyles<typeof styles> {
    result: NonNullable<IAnalyticsPageResult<IDashboardResultPeriod>>;
    currentPeriodType: CurrentPeriodTypeEnum;
    lastPeriodType: LastPeriodTypeEnum;
    minLongVisitTime: number | undefined;
}

interface IState {
    selectedIndicator: DashboardResultIndicator;
    chartData?: IDateAnalyticsPeriod[][];
    selectedType: {
        new_visitors: 'absolute' | 'percents';
        regular_visitors: 'absolute' | 'percents';
        rich_visitors: 'absolute' | 'percents';
    };
}

const resultToChartData = (
    result: NonNullable<IAnalyticsPageResult<IDashboardResultPeriod>>,
    indicator: DashboardResultIndicator,
): IDateAnalyticsPeriod[][] | undefined => {
    const chartData: IDateAnalyticsPeriod[][] = [];

    Object.keys(result)
        .sort(
            (b, a) =>
                datesPeriodFromString(b, 'YYYY-MM-DD', ';').from.valueOf() -
                datesPeriodFromString(a, 'YYYY-MM-DD', ';').from.valueOf(),
        )
        .forEach(periodKey => {
            const periodData = result[periodKey][indicatorToDataMap[indicator].key];

            if (periodData !== undefined) {
                chartData.push(
                    periodData.map(item => ({
                        date: moment(item.date, 'YYYY-MM-DD').toDate(),
                        value: item.value,
                    })),
                );
            }
        });

    return chartData.length > 0 ? chartData : undefined;
};

class DashboardResultData extends React.PureComponent<IProps, IState> {
    public constructor(props: IProps) {
        super(props);

        this.handleSelect = this.handleSelect.bind(this);
        this.state = {
            selectedIndicator: 'visitors',
            chartData: resultToChartData(props.result, 'visitors'),
            selectedType: {
                new_visitors: 'absolute',
                regular_visitors: 'absolute',
                rich_visitors: 'absolute',
            },
        };
    }

    public componentDidUpdate(prevProps: IProps) {
        // tslint:disable-next-line:strict-comparisons
        if (prevProps.result !== this.props.result) {
            this.setState({
                chartData: resultToChartData(this.props.result, this.state.selectedIndicator),
            });
        }
    }

    public render() {
        const periods = Object.keys(this.props.result).sort(
            (a, b) =>
                datesPeriodFromString(b, 'YYYY-MM-DD', ';').from.valueOf() -
                datesPeriodFromString(a, 'YYYY-MM-DD', ';').from.valueOf(),
        );
        const { classes, result ,minLongVisitTime } = this.props;
        const currentResult = result[periods[0]];
        const lastResult: IDashboardResultPeriod | undefined = periods.length < 1 ? undefined : result[periods[1]];
        const { selectedIndicator } = this.state;
        const indicators: DashboardResultIndicator[] = Object.keys(indicatorToDataMap) as DashboardResultIndicator[];
        const longVisitsPopover = polyglot.t('dashboard.popoverText.longVisits') + ' ' + minLongVisitTime + ' ' + polyglot.t('dashboard.popoverText.minutes');

        return (
            <>
                <Grid container spacing={2} alignItems="stretch">
                    {indicators.map(key => (
                        <Grid item md={2} key={key}>
                            <IndicatorCard
                                className={classes.card}
                                title={polyglot.t(indicatorToDataMap[key].title)}
                                helpText={
                                    indicatorToDataMap[key].popover !== undefined
                                        ? (indicatorToDataMap[key].key === 'longVisitsPercent'
                                            ? longVisitsPopover
                                            : polyglot.t(indicatorToDataMap[key].popover as string))
                                        : undefined
                                }
                                // tslint:disable-next-line:no-non-null-assertion
                                value={currentResult[indicatorToDataMap[key].key]![0].value}
                                lastValue={
                                    lastResult !== undefined
                                        ? // tslint:disable-next-line:no-non-null-assertion
                                          lastResult[indicatorToDataMap[key].key]![0].value
                                        : undefined
                                }
                                valueFormat={indicatorToDataMap[key].valuesType}
                                selected={selectedIndicator === key}
                                onClick={this.changeSelectedIndicator(key)}
                            />
                        </Grid>
                    ))}
                </Grid>
                {indicatorToDataMap[selectedIndicator].route !== undefined ? (
                    <DashboardChart
                        key={selectedIndicator}
                        className={classes.chartWrapper}
                        chartApi={indicatorToDataMap[selectedIndicator].route as string}
                        title={polyglot.t(indicatorToDataMap[selectedIndicator].title)}
                        tooltipNames={polyglot.t(indicatorToDataMap[selectedIndicator].title)}
                        valuesType={indicatorToDataMap[selectedIndicator].valuesType}
                        percentsEnabled={indicatorToDataMap[selectedIndicator].percentsEnabled}
                        uniqsEnabled={indicatorToDataMap[selectedIndicator].uniqsEnabled}
                    />
                ) : null}
            </>
        );
    }

    private handleSelect(_: unknown, value: 'absolute' | 'percents') {
        this.setState({
            selectedType: { ...this.state.selectedType, [this.state.selectedIndicator]: value },
            chartData: resultToChartData(
                this.props.result,
                (value === 'percents'
                    ? `${this.state.selectedIndicator}_percents`
                    : this.state.selectedIndicator) as DashboardResultIndicator,
            ),
        });
    }

    private readonly changeSelectedIndicator = (selectedIndicator: DashboardResultIndicator) => {
        const percentSwitchKeys = Object.keys(this.state.selectedType);

        return () => {
            this.setState({
                selectedIndicator,
                chartData: resultToChartData(
                    this.props.result,
                    percentSwitchKeys.includes(selectedIndicator) &&
                        this.state.selectedType[selectedIndicator as keyof IState['selectedType']] === 'percents'
                        ? (`${selectedIndicator}_percents` as DashboardResultIndicator)
                        : selectedIndicator,
                ),
            });
        };
    };
}

export default withStyles(styles)(DashboardResultData);

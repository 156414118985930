/**
 * Возвращает необходимую страницу PDF отчета в зависимости от урла.
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import moment from 'moment';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { IStackedSeries } from '../../components/Charts/ColumnChartStacked';
import { useQuery } from '../../hooks/query';
import { datePeriodString } from '../../lib/utils/strings';
import { IChartSummaryData } from '../../types/analytics';
import { CurrentPeriodTypeEnum, LastPeriodTypeEnum } from '../../types/date.periods';
import { PeriodFormatFunction } from '../../types/global';

import PdfCustomSummary from './CustomSummary';
import PdfMonthSummary from './MonthSummary';
import PdfQuarterSummary from './QuarterSummary';
import PdfYearSummary from './YearSummary';

interface IProps extends RouteComponentProps<{ id: string }> { }

export interface IPdfPageProps {
    page: number;
    title?: string;
    period: string;
}

export const CHART_DATE_FORMAT = 'MMM YY';
export const PRICE_GROUPS = ['Low', 'Middle', 'Middle+', 'Premium', 'Upper premium'];

export const formatPeriodDate = (date: string, periodFormat: string | PeriodFormatFunction): string => {
    const periodDatesStrings = date.split(';');
    const periodDates = periodDatesStrings.map(dateString => moment(dateString).toDate());

    return periodDatesStrings.length > 1
        ? datePeriodString(periodDates[0], periodDates[1], periodFormat, '–')
        : datePeriodString(periodDates[0], periodDates[0], periodFormat, '–');
};

export const getMultyChartData = (
    data: IChartSummaryData[],
    key: keyof IChartSummaryData,
    periodFormat: string | PeriodFormatFunction = CHART_DATE_FORMAT,
): Array<{ period: string;[index: string]: number | string }> =>
    data.map((row: IChartSummaryData): { period: string;[index: string]: number | string } => ({
        period: formatPeriodDate(row.date, periodFormat),
        ...(row[key] as { [index: string]: number | string }),
    }));

export const getStackedChartData = <
    T extends Exclude<
        keyof IChartSummaryData,
        | 'date'
        | 'visitsByType'
        | 'visitsByFreq'
        | 'visitsByRegion'
        | 'visits'
        | 'uniqs'
        | 'avgVisitDuration'
        | 'longVisitsPercent'
        | 'newVisitors'
        | 'newVisitorsPercent'
        | 'regularVisitors'
        | 'richVisitors'
        | 'richUniqs'
        | 'avgVisitsHoliday'
        | 'avgVisitsWorkday'
        | 'avgNewVisitors'
        | 'topRegions'
    >
>(
    data: IChartSummaryData[],
    key: T,
    type: 'visits' | 'uniqs',
    periodFormat: string | PeriodFormatFunction = CHART_DATE_FORMAT,
): IStackedSeries[] =>
    data.map(
        row =>
            Object.assign(
                {
                    period: formatPeriodDate(row.date, periodFormat),
                },
                ...Object.keys(row[key])
                    .filter(dataKey => dataKey.includes(type))
                    .map(dataKey => ({
                        [dataKey.replace(`${type}_`, '')]: row[key][dataKey],
                    })),
            ) as { period: string;[index: string]: string | number },
    );

const PdfSummary = ({ match }: IProps) => {
    const query: URLSearchParams = useQuery();
    const organizationId = parseInt(match.params.id, 10);

    switch (query.get('periodType')) {
        case 'month':
            const month = query.get('month');

            return month !== null ? <PdfMonthSummary organizationId={organizationId} month={month} /> : null;
        case 'quarter':
            const quarter = query.get('quarter');

            return quarter !== null ? <PdfQuarterSummary organizationId={organizationId} quarter={quarter} /> : null;
        case 'year':
            const year = query.get('year');

            return year !== null ? <PdfYearSummary organizationId={organizationId} year={parseInt(year, 10)} /> : null;
        case 'custom':
            const current = query.get('current');
            const last = query.get('last');

            return current !== null && last !== null ? (
                <PdfCustomSummary
                    organizationId={organizationId}
                    periods={query.getAll('periods[]')}
                    current={current as CurrentPeriodTypeEnum}
                    last={last as LastPeriodTypeEnum}
                />
            ) : null;
        default:
            return null;
    }
};

export default PdfSummary;

import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import { acl, SiteResourcesEnum } from '../acl';
import PrivateRoute from '../components/PrivateRoute';
import PdfSummary from '../pages/Pdf/Summary';
import { userSelector } from '../selectors/common.selectors';

const redirect = () => <Redirect to="/" />;

const PdfSection = () => {
    const user = useSelector(userSelector);

    // Пользователь не авторизован
    if (user === undefined) {
        return null;
    }

    return (
        <Switch>
            <PrivateRoute
                permission={acl.can(user.roles).readOwn(SiteResourcesEnum.OrganizationStats)}
                exact
                path="/pdf/summary/:id(\d+)"
                component={PdfSummary}
            />
            <Route render={redirect} />
        </Switch>
    );
};

export default PdfSection;

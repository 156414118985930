/**
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import { Action } from 'redux-actions';
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { cohortsActions } from '../actions/cohorts';
import { globalActions } from '../actions/global';
import { CohortsApi } from '../api/cohort.api';
import { ICampaignCohort } from '../types/organizations';


function* listCohortsRequest({ payload: organizationId }: Action<number>) {
    try {
        const api = new CohortsApi();
        const cohorts = (yield call({ context: api, fn: api.list }, organizationId)) as ICampaignCohort[];
        yield put(cohortsActions.cohort.list.success(cohorts));

        for (const cohort of cohorts.filter((item) => !item.isActualCache)) {
            yield put(cohortsActions.cohort.get.send(cohort.id));
        }
    } catch {
        yield put(
            globalActions.global.notifications.add({
                message: 'Невозможно получить список рекламных кампаний.',
                type: 'error',
            }),
        );
        yield put(cohortsActions.cohort.get.fail());
    }
}

function* listCohorts() {
    yield takeLatest(cohortsActions.cohort.list.send.toString(), listCohortsRequest);
}

function* getCohortRequest({ payload: cohortId }: Action<number>) {
    try {
        const api = new CohortsApi();
        const cohort = (yield call({ context: api, fn: api.get }, cohortId)) as ICampaignCohort;
        yield put(cohortsActions.cohort.get.success(cohort));
    } catch {
        yield put(
            globalActions.global.notifications.add({
                message: 'Невозможно получить аналитику по рекламной кампании.',
                type: 'error',
            }),
        );
        yield put(cohortsActions.cohort.list.fail());
    }
}

function* getCohort() {
    yield takeEvery(cohortsActions.cohort.get.send.toString(), getCohortRequest);
}

function* deleteCohortRequest({ payload }: Action<{ id: number; organizationId: number }>) {
    try {
        const api = new CohortsApi(payload.organizationId);
        yield call([api, 'delete'], payload.id);
        yield put(cohortsActions.cohort.delete.success(payload.id));
    } catch {
        yield put(
            globalActions.global.notifications.add({
                message: 'Невозможно удалить рекламную кампанию.',
                type: 'error',
            }),
        );
    }
}

function* deleteCohorts() {
    yield takeLatest(cohortsActions.cohort.delete.send.toString(), deleteCohortRequest);
}

export default function* rootSaga() {
    yield all([listCohorts(), deleteCohorts(), getCohort()]);
}


/**
 * Redux Actions для страниц аналитики
 *
 * @author Artem Bakulin
 */

import { Action, createActions } from 'redux-actions';

import { IAnalyticsCalendarState } from '../reducers/analytics.calendar';
import { IDashboardResultPeriod } from '../reducers/dashboard.page';
import { IFrequencyPageResult } from '../reducers/frequency.page';
import { IInvolvementPageResult } from '../reducers/involvement.page';
import { ILoyaltyPageResult, ILoyaltyPageState } from '../reducers/loyalty.page';
import { IVisitsResultPeriod } from '../reducers/visits.page';
import { IAnalyticsPageResult } from '../types/global';

interface IAnalyticsPageActions<T> {
    send(
        organizationId: number,
        data: IAnalyticsCalendarState,
    ): Action<{
        organizationId: number;
        data: IAnalyticsCalendarState;
    }>;
    success(hashString: string, data: T): Action<{ request: IAnalyticsCalendarState; data: T }>;
    fail(error: Error): Action<Error>;
}

interface IAnalyticsPagesActions {
    dashboard: IAnalyticsPageActions<IAnalyticsPageResult<IDashboardResultPeriod>>;
    visits: IAnalyticsPageActions<IAnalyticsPageResult<IVisitsResultPeriod>>;
    involvement: IAnalyticsPageActions<IInvolvementPageResult>;
    frequency: IAnalyticsPageActions<IFrequencyPageResult>;
    loyalty: {
        send(
            organizationId: number,
            months: ILoyaltyPageState['selectedMonths'],
        ): Action<{
            organizationId: number;
            months: ILoyaltyPageState['selectedMonths'];
        }>;
        success(
            hashString: string,
            data: ILoyaltyPageResult,
        ): Action<{ months: ILoyaltyPageState['selectedMonths']; data: ILoyaltyPageResult }>;
        fail(error: Error): Action<Error>;
        selectMonths(
            months: ILoyaltyPageState['selectedMonths'],
        ): Action<{ months: ILoyaltyPageState['selectedMonths'] }>;
    };
}

export const analyticsPagesActions: IAnalyticsPagesActions = createActions({
    dashboard: {
        send: (organizationId: number, data: IAnalyticsCalendarState) => ({
            organizationId,
            data,
        }),
        success: (hashString: string, data: IAnalyticsPageResult<IDashboardResultPeriod>) => ({ hashString, data }),
        fail: (error: Error) => error,
    },
    visits: {
        send: (organizationId: number, data: IAnalyticsCalendarState) => ({
            organizationId,
            data,
        }),
        success: (hashString: string, data: IAnalyticsPageResult<IVisitsResultPeriod>) => ({ hashString, data }),
        fail: (error: Error) => error,
    },
    involvement: {
        send: (organizationId: number, data: IAnalyticsCalendarState) => ({
            organizationId,
            data,
        }),
        success: (hashString: string, data: IInvolvementPageResult) => ({ hashString, data }),
        fail: (error: Error) => error,
    },
    frequency: {
        send: (organizationId: number, data: IAnalyticsCalendarState) => ({
            organizationId,
            data,
        }),
        success: (hashString: string, data: IFrequencyPageResult) => ({ hashString, data }),
        fail: (error: Error) => error,
    },
    loyalty: {
        send: (organizationId: number, months: ILoyaltyPageState['selectedMonths']) => ({
            organizationId,
            months,
        }),
        success: (hashString: string, data: ILoyaltyPageResult) => ({ hashString, data }),
        fail: (error: Error) => error,
        selectMonths: (months: ILoyaltyPageState['selectedMonths']) => ({ months }),
    },
    // tslint:disable-next-line:no-any
}) as any;

import { Grid, Typography, WithStyles, withStyles } from '@material-ui/core';
import moment from 'moment';
import React from 'react';

import PieChart from '../../components/Charts/PieChart';
import { PolyglotSingleton } from '../../lib/services/translation';
import { ILoyaltyPageResult } from '../../reducers/loyalty.page';

import Chart from './Chart';
import { styles } from './styles';

interface IProps extends WithStyles<typeof styles> {
    result: NonNullable<ILoyaltyPageResult>;
}

const polyglot = PolyglotSingleton.getInstance();

//
const ResultData = ({ result, classes }: IProps) => {
    const uniqsData = result.charts.byUniqs.data.map(val => ({
        ...val,
        label: moment(val.label, 'YYYY-M-DD').format('MMM YY'),
    }));

    return (
        <Grid container spacing={2} className={classes.chartsWrapper}>
            <Grid item xs={6} className={classes.chartGrid}>
                <Typography variant="h2" style={{ marginBottom: '32px' }}>
                    {polyglot.t('loyalty.charts.byFrequency')}
                </Typography>
                <Chart id="loyaltyFrequency" data={result.charts.byFrequency.data} className={classes.chartWrapper} />
            </Grid>
            <Grid item xs={6} className={classes.chartGrid}>
                <Typography variant="h2" style={{ marginBottom: '32px' }}>
                    {polyglot.t('loyalty.charts.byFrequencyPercents')}
                </Typography>
                <div className={classes.chartWrapper}>
                    <PieChart
                        id="loyaltyFrequencyPercents"
                        data={result.charts.byFrequencyPercents.data}
                        numberFormat="#.%"
                        legend="top"
                    />
                </div>
            </Grid>
            <Grid item xs={6} className={classes.chartGrid}>
                <Typography variant="h2" style={{ marginBottom: '32px' }}>
                    {polyglot.t('loyalty.charts.byUniqs')}
                </Typography>
                <Chart id="loyaltyUniqs" data={uniqsData} className={classes.chartWrapper} />
            </Grid>
        </Grid>
    );
};

export default withStyles(styles)(ResultData);

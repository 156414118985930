/**
 * Страница "Визиты по стоимости авто" для месячного отчета.
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import moment from 'moment';
import React from 'react';

import { Table } from '../../../components/Pdf/Charts/Table';
import { PolyglotSingleton } from '../../../lib/services/translation';
import { IQuarterSummaryData } from '../../../types/analytics';
import { PRICE_GROUPS } from '../Summary';

interface IProps {
    data: IQuarterSummaryData;
}

const polyglot = PolyglotSingleton.getInstance();

export const VisitsByPricePage = ({ data }: IProps) => {
    const rows = [
        [...PRICE_GROUPS.map((group) => data.comporable[0].visitsByPrice[`visits_percent_${group}`])],
        ...data.quarters.slice(1).map((monthData) => [
            ...PRICE_GROUPS.map((group) => monthData.visitsByPrice[`visits_percent_${group}`])
        ])
    ];
    const additionalRows = [
        [...PRICE_GROUPS.map((group) => data.comporable[0].visitsByPrice[`visits_${group}`])],
        ...data.quarters.slice(1).map((monthData) => [
            ...PRICE_GROUPS.map((group) => monthData.visitsByPrice[`visits_${group}`])
        ])
    ];
    const quarters = [
        moment(data.comporable[0].date).format(polyglot.t('common.dates.quarter')),
        ...data.quarters.slice(1).map((month) => moment(month.date).format(polyglot.t('common.dates.quarter'))),
    ];
    const columns = PRICE_GROUPS.map((group) => (<>
        <span>{group}</span>
        <br />
        <span style={{ opacity: 0.5, fontSize: '3.5mm' }}>
            {polyglot.t(`pdf.priceRages.${group}`)}
        </span>
    </>))

    return (
        <Table
            title={polyglot.t('pdf.charts.visitsByPrice')}
            columnsHeader={columns}
            rowsHeader={quarters}
            rows={rows}
            additionalRows={additionalRows}
            valueFormat="percent"
            additionalValueFormat="absolute"
        />
    );
};

/**
 * Страница "Продолжительность" в месячном PDF отчете.
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import moment from 'moment';
import React from 'react';

import { Table } from '../../../components/Pdf/Charts/Table';
import { PolyglotSingleton } from '../../../lib/services/translation';
import { IMonthSummaryData } from '../../../types/analytics';
import { IUserOrganization } from '../../../types/organizations';
import { DEFAULT_OWNER_AGE_GROUPS } from '../../../types/vehicles';
import { CHART_DATE_FORMAT, PRICE_GROUPS } from '../Summary';

interface IProps {
    data: IMonthSummaryData;
    organization?: IUserOrganization;
}

const polyglot = PolyglotSingleton.getInstance();

export const DurationPage = ({ data, organization }: IProps) => {
    const months = [
        moment(data.comporable[0].date).format(CHART_DATE_FORMAT),
        ...data.months.slice(1).map(month => moment(month.date).format(CHART_DATE_FORMAT)),
    ];

    const orgAgeGroups = organization?.settings.ageGroups;
    const ageGroups = Array.isArray(orgAgeGroups) && orgAgeGroups.length > 0
        ? orgAgeGroups.map(group => group.name)
        : Object.keys(DEFAULT_OWNER_AGE_GROUPS);
    const ageRows = [
        [...ageGroups.map(group => data.comporable[0].durationByAge[group])],
        ...data.months
            .slice(1)
            .map(monthData => [...ageGroups.map(group => monthData.durationByAge[group])]),
    ];

    const priceRows = [
        [...PRICE_GROUPS.map((group) => data.comporable[0].durationByPrice[group])],
        ...data.months.slice(1).map((monthData) => [
            ...PRICE_GROUPS.map((group) => monthData.durationByPrice[group])
        ])
    ];
    const priceColumns = PRICE_GROUPS.map((group) => (<>
        <span>{group}</span>
        <br />
        <span style={{ opacity: 0.5, fontSize: '2mm'}}>
            {polyglot.t(`pdf.priceRages.${group}`)}
        </span>
    </>))

    return (
        <>
            <div style={{ marginBottom: '20mm' }}>
                <Table
                    title={polyglot.t('pdf.charts.durationByPrice')}
                    columnsHeader={priceColumns}
                    rowsHeader={months}
                    rows={priceRows}
                    valueFormat="duration"
                    size="small"
                    footerTitle="pdf.charts.common.shareShort"
                />
            </div>
            <div>
                <Table
                    title={polyglot.t('pdf.charts.durationByAge')}
                    columnsHeader={ageGroups}
                    rowsHeader={months}
                    rows={ageRows}
                    valueFormat="duration"
                    size="small"
                    footerTitle="pdf.charts.common.shareShort"
                />
            </div>
        </>
    );
}

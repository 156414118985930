/**
 * Сервис для работы с периодами дат для графиков и аналитики
 *
 * @author Artem Bakulin
 */

import moment from 'moment';

import {
    CurrentPeriod,
    CurrentPeriodPresetsTypes,
    CurrentPeriodTypeEnum,
    CurrentPresetPeriod,
    ICurrentPeriodRounded,
    IDatesPeriod,
    LastPeriodTypeEnum,
    SeasonsEnum,
} from '../../types/date.periods';
import { daysBetweenDates, getSeasonByDate, getSeasonDatePeriodForYear } from '../utils/dates';

export const CURRENT_PERIOD_TYPE_MAPPING: { [type in CurrentPeriodPresetsTypes]: CurrentPresetPeriod } = {
    [CurrentPeriodTypeEnum.Today]: {
        type: 'rounded',
        value: 0,
        unit: 'days',
    },
    [CurrentPeriodTypeEnum.Yesterday]: {
        type: 'accurate',
        value: 1,
        unit: 'days',
    },
    [CurrentPeriodTypeEnum.Last7Days]: {
        type: 'accurate',
        value: 7,
        unit: 'days',
    },
    [CurrentPeriodTypeEnum.Last30Days]: {
        type: 'accurate',
        value: 30,
        unit: 'days',
    },
    [CurrentPeriodTypeEnum.CurrentMonth]: {
        type: 'rounded',
        value: 0,
        unit: 'months',
    },
    [CurrentPeriodTypeEnum.LastWeek]: {
        type: 'rounded',
        value: 1,
        unit: 'weeks',
    },
    [CurrentPeriodTypeEnum.LastMonth]: {
        type: 'rounded',
        value: 1,
        unit: 'months',
    },
    [CurrentPeriodTypeEnum.LastQuarter]: {
        type: 'rounded',
        value: 1,
        unit: 'quarters',
    },
    [CurrentPeriodTypeEnum.LastSeason]: {
        type: 'rounded',
        value: 1,
        unit: 'seasons',
    },
    [CurrentPeriodTypeEnum.CurrentYear]: {
        type: 'rounded',
        value: 0,
        unit: 'years',
    },
};

/**
 * Возвращает предустановленные настройки периода времени по типу периода
 *
 * @param type Тип периода времени для которого запрашиваются настройки
 */
export const getCurrentPeriodPresetByType = (type: CurrentPeriodPresetsTypes): CurrentPresetPeriod =>
    CURRENT_PERIOD_TYPE_MAPPING[type];

/**
 * Возвращает период дат согласно настройкам этого периода
 *
 * @param currentPeriod Настройки периода
 */
export const getDatesPeriodByCurrentPeriod = (currentPeriod: CurrentPeriod): IDatesPeriod => {
    if (currentPeriod.type === 'custom') {
        return currentPeriod.period;
    }

    if (currentPeriod.type === 'rounded') {
        if (currentPeriod.unit !== 'seasons') {
            const dateInPeriod = moment().subtract(currentPeriod.value, currentPeriod.unit);

            return {
                from: moment(dateInPeriod)
                    .startOf(currentPeriod.unit)
                    .toDate(),
                to: moment(dateInPeriod)
                    .endOf(currentPeriod.unit)
                    .toDate(),
            };
        }

        const currentSeason: SeasonsEnum = getSeasonByDate(moment().toDate());
        const lastSeason: SeasonsEnum = currentSeason === SeasonsEnum.Winter ? SeasonsEnum.Autumn : currentSeason - 1;
        const year = lastSeason > currentSeason ? moment().year() - 1 : moment().year();

        return getSeasonDatePeriodForYear(lastSeason, year);
    }

    const to = moment()
        .subtract(1, 'day')
        .toDate();

    return {
        from: moment()
            .subtract(currentPeriod.value, currentPeriod.unit)
            .toDate(),
        to,
    };
};

/**
 * Возвращает период дат для предустановленного пресета периода
 *
 * @param type Тип периода времени дляк оторого запрашиваются настройки
 */
export const getDatesPeriodByPresetType = (type: CurrentPeriodPresetsTypes): IDatesPeriod =>
    getDatesPeriodByCurrentPeriod(getCurrentPeriodPresetByType(type));

/**
 * Возвращает предыдущий период дат отсносительно указанного периода и типа сравниваемого периода
 *
 * @param period Даты, относительно которых необходимо найти сравниваемый период
 * @param type Тип сравниваемого периода
 * @param accurate До какого периода округялем полученный результат (неделя, месяц, квартал)
 */
export const getDatesPeriodByType = (
    period: IDatesPeriod,
    type: Exclude<LastPeriodTypeEnum, LastPeriodTypeEnum.Custom>,
    accurate?: ICurrentPeriodRounded['unit'],
): IDatesPeriod => {
    if (type === LastPeriodTypeEnum.Comparable) {
        return {
            from: moment(period.from)
                .year(period.from.getFullYear() - 1)
                .toDate(),
            to: moment(period.to)
                .year(period.to.getFullYear() - 1)
                .toDate(),
        };
    }

    const to = moment(period.from)
        .subtract(1, 'day')
        .toDate();

    if (accurate === 'seasons') {
        return {
            from: moment(to)
                .subtract(2, 'months')
                .startOf('month')
                .toDate(),
            to,
        };
    }

    return {
        from:
            accurate !== undefined
                ? moment(to)
                      .startOf(accurate)
                      .toDate()
                : moment(period.from)
                      .subtract(daysBetweenDates(period.from, period.to) + 1, 'days')
                      .toDate(),
        to,
    };
};

/**
 * Возвращает предыдущий период дат отсносительно текущего периода и типа сравниваемого периода
 *
 * @param currentPeriod Настройки текущего период
 * @param type Тип сравниваемого периода
 */
export const getLastDatesPeriodByType = (
    currentPeriod: CurrentPeriod,
    type: Exclude<LastPeriodTypeEnum, LastPeriodTypeEnum.Custom>,
): IDatesPeriod =>
    getDatesPeriodByType(
        getDatesPeriodByCurrentPeriod(currentPeriod),
        type,
        currentPeriod.type === 'rounded' ? currentPeriod.unit : undefined,
    );

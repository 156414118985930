// tslint:disable-next-line:no-submodule-imports
import { Action } from 'redux-actions';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { usersActions } from '../actions/users';
import api from '../api/users.api';
import { localize } from '../lib/services/translation';
import { ymSetUser, ymSetUserParams } from '../lib/utils/ym';
import { Language } from '../types/global';
import { IUser } from '../types/users';

const authUserSaga = (action: Action<IUser>) => {
    ymSetUser(action.payload);
    ymSetUserParams(action.payload, action.payload.organizations.filter(org => org.type === 'retail')[0]);
};

function* authUser() {
    yield takeLatest(usersActions.user.authenticate.toString(), authUserSaga);
}

function* logoutUserRequest() {
    try {
        yield call([api, 'logout']);
        yield put(usersActions.user.logout.success());
    } catch (error) {
        yield put(usersActions.user.logout.failed(error as Error));
    }
}

function* logoutUser() {
    yield takeLatest(usersActions.user.logout.send.toString(), logoutUserRequest);
}

function* changeLanguageRequest(action: Action<Language>) {
    try {
        yield call([api, 'changeLanguage'], action.payload);
        localStorage.setItem('lng', action.payload);
        yield localize();
        yield put(usersActions.user.changeLanguage.success(action.payload));
    } catch (error) {
        yield put(usersActions.user.changeLanguage.failed(error as Error));
    }
}

function* changeLanguage() {
    yield takeLatest(usersActions.user.changeLanguage.change.toString(), changeLanguageRequest);
}

export default function* rootSaga() {
    yield all([logoutUser(), changeLanguage(), authUser()]);
}

import { createStyles, Theme } from '@material-ui/core';

export const styles = (theme: Theme) =>
    createStyles({
        root: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
        },
        toolbar: theme.mixins.toolbar,
    });

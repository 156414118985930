import { object, string } from 'yup';

export const schema = object().shape({
    email: string()
        .email('Неверный формат email адреса')
        .required('Обязательное поле'),
    password: string()
        .trim()
        .required('Необходимо ввести пароль'),
});

import { WithStyles, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';

import { styles } from './styles';

interface IProps extends WithStyles<typeof styles> {
    /* tslint:disable-next-line:no-null-undefined-union */
    children?: React.ReactNode | React.ReactNodeArray;
    className?: string;
}

const PageHeader = ({ children, className, classes }: IProps) => (
    <header className={classNames(classes.root, className)}>{children}</header>
);

export default withStyles(styles)(PageHeader);

/**
 * Страница со списком организаций
 *
 * @author Artem Bakulin
 */

import { Button, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';
import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';

import { acl, SiteResourcesEnum } from '../../acl';
import PageFab from '../../components/PageFab';
import PageHeader from '../../components/PageHeader';
import PageProgress from '../../components/PageProgress';
import Layout from '../../containers/Layout';
import { PolyglotSingleton } from '../../lib/services/translation';
import OrganizationModal from '../../modals/OrganizationModal';
import OrganizationSettingsModal from '../../modals/OrganizationSettingsModal';
import { IOrganizationsState } from '../../reducers/organizations';
import { IOrganization } from '../../types/organizations';
import { IUser } from '../../types/users';

interface IProps extends IOrganizationsState {
    list(): void;
    delete(organizationId: number): void;
    user: IUser;
}

const polyglot = PolyglotSingleton.getInstance();

const OrganizationsPage = ({ list, user, inProgress, items, ...props }: IProps) => {
    const [isOrganizationOpen, setOrganizationOpen] = useState(false);
    const [isSettingsOpen, setSettingsOpen] = useState(false);
    const [selectedOrganization, setSelectedOrganization] = useState<IOrganization | undefined>(undefined);
    const openOrganizationModal = (org?: IOrganization) => () => {
        setSelectedOrganization(org);
        setOrganizationOpen(true);
    }
    const closeOrganizationModal = useCallback(() => {
        setOrganizationOpen(false);
        setSelectedOrganization(undefined);
    }, [])
    const openSettingsModal = (org: IOrganization) => () => {
        setSelectedOrganization(org);
        setSettingsOpen(true);
    }
    const closeSettingsModal = useCallback(() => {
        setSettingsOpen(false);
        setSelectedOrganization(undefined);
    }, [])

    if (items === undefined) {
        list();
    }

    if (inProgress) {
        return <PageProgress />;
    }

    return (
        <Layout>
            <PageHeader>
                <Typography variant="h1">{polyglot.t('organizations.header')}</Typography>
            </PageHeader>

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{polyglot.t('organizations.columns.number')}</TableCell>
                        <TableCell>{polyglot.t('organizations.columns.name')}</TableCell>
                        <TableCell>{polyglot.t('organizations.columns.person')}</TableCell>
                        <TableCell>{polyglot.t('organizations.columns.phone')}</TableCell>
                        <TableCell>{polyglot.t('organizations.columns.email')}</TableCell>
                        <TableCell>{polyglot.t('organizations.columns.site')}</TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items !== undefined && items.length > 0 ? (
                        items.map((item: IOrganization, index: number) => (
                            <TableRow key={item.id}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{item.name}</TableCell>
                                <TableCell>{item.contactPerson}</TableCell>
                                <TableCell>{item.phone}</TableCell>
                                <TableCell>{item.email}</TableCell>
                                <TableCell>{item.site}</TableCell>
                                <TableCell>
                                    <Button variant="text" color="primary" onClick={openSettingsModal(item)}>
                                        {polyglot.t('common.buttons.settings')}
                                    </Button>
                                    {acl.can(user.roles).readOwn(SiteResourcesEnum.OrganizationsStopLits).granted ? (
                                        <Button
                                            variant="text"
                                            color="primary"
                                            component={Link}
                                            to={`/organizations/stoplist/${item.id}`}
                                        >
                                            {polyglot.t('common.menu.stopList')}
                                        </Button>
                                    ) : null}
                                    {acl.can(user.roles).deleteAny(SiteResourcesEnum.Organizations).granted ? (
                                        <IconButton
                                            onClick={() => { props.delete(item.id) }} // tslint:disable-line:jsx-no-lambda
                                            aria-label={polyglot.t('common.buttons.delete')}
                                        >
                                            <Delete />
                                        </IconButton>
                                    ) : null}
                                </TableCell>
                            </TableRow>
                        ))
                    ) : (
                            <TableRow>
                                <TableCell colSpan={7}>
                                    <Typography color="primary" align="center">
                                        {polyglot.t('organizations.noData')}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                </TableBody>
            </Table>

            {
                acl.can(user.roles).createAny(SiteResourcesEnum.Organizations).granted ? (
                    <PageFab
                        color="primary"
                        aria-label={polyglot.t('organizations.add')}
                        onClick={openOrganizationModal()}
                    >
                        <Add />
                    </PageFab>
                ) : null
            }

            {
                selectedOrganization !== undefined
                    ? <OrganizationSettingsModal isOpen={isSettingsOpen} handleClose={closeSettingsModal} organization={selectedOrganization} />
                    : null
            }
            <OrganizationModal isOpen={isOrganizationOpen} handleClose={closeOrganizationModal} organization={selectedOrganization} />
        </Layout >
    );
}

export default OrganizationsPage;

/**
 * Компонент для типографии в PDF отчетах
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import { Typography, TypographyProps } from '@material-ui/core';
import cx from 'classnames';
import React from 'react';

import { useStyles } from './styles';

interface IProps extends TypographyProps {
    isGradient?: boolean,
    component?: React.ElementType,
}

export const Typo: React.FC<IProps> = ({ className, children, isGradient = false, ...props }) => {
    const classes = useStyles();

    return (
        <Typography className={cx(classes.typo, className, isGradient ? classes.gradient : undefined)} {...props}>
            {children}
        </Typography>
    );
};

/**
 * API для работы с рекламными когортами.
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import { formatISO } from 'date-fns';

import { ICohortValues } from '../forms/CampaignCohortForm';
import { apiHostPrefix } from '../lib/utils/urls';
import { ICampaignCohort } from '../types/organizations';

import { BaseApi, parseNullToEmpty, stringifyWithoutEmpty } from './abstract.api';

/**
 * API для работы с рекламными когортами.
 */
export class CohortsApi extends BaseApi {
    public constructor(organizationId?: number) {
        super(organizationId !== undefined ? `/organizations/${organizationId}/cohorts` : '/');
    }

    public async createOrUpdate(cohortValues: ICohortValues) {
        const data = {
            ...cohortValues,
            ...{
                dateFrom: formatISO(cohortValues.dateFrom as Date),
                dateTo: formatISO(cohortValues.dateTo as Date),
                comparisonDate: formatISO(cohortValues.comparisonDate as Date),
            }
        }
        const method = data.id !== undefined ? 'PUT' : 'POST';
        const response = await this.axios.request<ICampaignCohort>({
            url: '',
            method,
            transformRequest: [stringifyWithoutEmpty()],
            transformResponse: [parseNullToEmpty()],
            headers: { 'Content-Type': 'application/json' },
            data,
        });

        return response.data;
    }

    public async list(organizationId: number) {
        const response = await this.axios.request<ICampaignCohort[]>({
            url: `organization/${organizationId}`,
            baseURL: `${apiHostPrefix()}/api/cohorts/`,
            transformResponse: [parseNullToEmpty()]
        });

        return response.data;
    }

    public async get(cohortId: number) {
        const response = await this.axios.request<ICampaignCohort>({
            url: cohortId.toString(),
            baseURL: `${apiHostPrefix()}/api/cohorts/`,
            transformResponse: [parseNullToEmpty()]
        });

        return response.data;
    }

    public async delete(cohortId: number) {
        await this.axios.delete<undefined>(`/${cohortId}`);
    }
}

/**
 * Селектор выбора типа значений (абсолютные/относительные).
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import React, { useCallback } from 'react';

import { useStyles } from './styles';

interface IProps {
    isPercent: boolean,
    onSetPercentsChange(isPercent: boolean): void,
}

export const ChartPercentsSelector = ({isPercent, onSetPercentsChange}: IProps) => {
    const classes = useStyles();
    const changeChartDataType = useCallback((_, value: boolean | null) => {
        onSetPercentsChange(value === null ? isPercent : value);
    }, [onSetPercentsChange, isPercent]);

    return (
        <ToggleButtonGroup
            className={classes.percentBtns}
            value={isPercent}
            exclusive
            onChange={changeChartDataType}
        >
            <ToggleButton value={false}>123</ToggleButton>
            <ToggleButton value={true}>%</ToggleButton>
        </ToggleButtonGroup>
    );
}

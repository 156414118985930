/**
 * Компонент универсального графика, который умеет сам делать запросы к серверу за данными.
 * Построен на библиотеке apexcharts.
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { ApexOptions } from 'apexcharts';
import cx from 'classnames';
import { merge } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useSelector } from 'react-redux';

import { getPeriods } from '../../../api/analytics.api';
import api from '../../../api/charts.api';
import { PolyglotSingleton } from '../../../lib/services/translation';
import { getChartExportXLSXLink } from '../../../lib/utils/charts';
import { calendarSelector, selectedOrganizationSelector } from '../../../selectors/common.selectors';
import { AnalyticsDataGranularity } from '../../../types/date.periods';
import { ChartGranularity } from '../CommonChart/ChartGranularity';
import { isEmptyChartData } from '../DetailedApiChart/helpers';
import { ImportXLXSChartButton } from '../ImportXLXSChartButton';

import { ChartPercentsSelector } from './ChartPercentSelector';
import { ChartUniqsSelector } from './ChartUniqsSelector';
import { DEFAULT_OPTIONS } from './constants';
import { formatValue } from './helpers';
import { useStyles } from './styles';
import { ApiChartDataResult, ApiChartFiltersList, ApiChartType, ApiChartValuesType, IApiChartSerie } from './types';

const polyglot = PolyglotSingleton.getInstance();

interface IProps<K extends ApiChartDataResult> {
    chartApi: string;
    dataToSeries(
        data: K,
        granularity: AnalyticsDataGranularity | undefined,
        isPercent: boolean,
        isUniqs: boolean,
    ): IApiChartSerie[];
    categories(
        data: K,
        granularity: AnalyticsDataGranularity | undefined,
        isPercent: boolean,
        isUniqs: boolean,
    ): string[];
    valuesType: ApiChartValuesType;
    filters?: ApiChartFiltersList;
    title?: string;
    className?: string;
    height?: number;
    options?: ApexOptions;
    granularityRequired?: boolean;
    type?: ApiChartType;
    onFiltersChange?(granularity: AnalyticsDataGranularity | undefined, isPercent: boolean, isUniqs: boolean): void;
}

export const ApiChart = <K extends ApiChartDataResult>({
    chartApi,
    dataToSeries,
    categories,
    filters = new Set(),
    title,
    className,
    height,
    options,
    granularityRequired = false,
    type = 'bar',
    onFiltersChange,
    valuesType = 'absolute',
}: IProps<K>) => {
    const classes = useStyles();
    const calendar = useSelector(calendarSelector);
    const periods = getPeriods(calendar);
    const organization = useSelector(selectedOrganizationSelector);
    const [inProgress, setProgress] = useState<boolean>(false);
    const [granularity, setGranularity] = useState<AnalyticsDataGranularity | undefined>(
        granularityRequired ? 'day' : undefined,
    );
    const [isPercent, setIsPercent] = useState(false);
    const [isUniqs, setIsUniqs] = useState(false);
    const [data, setData] = useState<K | undefined>(undefined);

    useEffect(() => {
        if (onFiltersChange !== undefined) {
            onFiltersChange(granularity, isPercent, isUniqs);
        }
    }, [onFiltersChange, granularity, isPercent, isUniqs]);

    useEffect(() => {
        if (organization !== undefined) {
            setProgress(true);
            setData(undefined);

            api.chartData<K>(chartApi, organization.id, calendar, granularity, isPercent, isUniqs)
                .finally(() => { setProgress(false); })
                .then(setData)
                .catch(() => { setData(undefined); });
        }
    }, [chartApi, granularity, calendar, organization, isPercent, isUniqs]);


    const getExportLink = useCallback(() => {
        if (organization === undefined) {
            return '';
        }

        return getChartExportXLSXLink(
            organization.id,
            'visit',
            chartApi,
            periods,
            isUniqs,
            isPercent,
            granularity,
        );
    }, [chartApi, organization, periods, isUniqs, isPercent, granularity]);

    const chartOptions = useMemo(
        () =>
            merge(
                {},
                DEFAULT_OPTIONS,
                data !== undefined
                    ? {
                          yaxis: {
                              labels: {
                                  formatter: formatValue(isPercent ? 'percent' : valuesType, {
                                      maximumFractionDigits: isPercent || valuesType === 'percent' ? 0 : 1,
                                  }),
                              },
                          },
                          xaxis: {
                              categories: categories(data, granularity, isPercent, isUniqs),
                          },
                      }
                    : {},
                options,
            ),
        [options, data, isPercent, granularity, isUniqs, categories, valuesType],
    );

    return (
        <Grid container direction="column" wrap="nowrap" alignItems="stretch" className={cx(classes.root, className)}>
            <Grid item container wrap="nowrap" justify="space-between">
                <Grid item>
                    {title !== undefined ? (
                        <Typography variant="h2" style={{ marginBottom: '32px' }}>
                            {title}
                        </Typography>
                    ) : null}
                </Grid>
                <Grid item style={{ display: 'flex' }}>
                    {filters.has('granularity') ? (
                        <ChartGranularity
                            granularity={granularity}
                            onGranularityChange={setGranularity}
                            required={granularityRequired}
                        />
                    ) : null}
                    {filters.has('percents') ? (
                        <ChartPercentsSelector isPercent={isPercent} onSetPercentsChange={setIsPercent} />
                    ) : null}
                    {filters.has('uniqs') ? (
                        <ChartUniqsSelector isUniqs={isUniqs} onSetUniqsChange={setIsUniqs} />
                    ) : null}
                    <ImportXLXSChartButton chartExportXLSXLink={getExportLink()} />
                </Grid>
            </Grid>
            <Grid item container direction="column" alignItems={data !== undefined ? 'flex-start' : 'center'}>
                {isEmptyChartData(data) && !inProgress ? (
                    <Typography variant="h5" component="p" color="error">
                        {polyglot.t('dashboard.noDataForPeriod')}
                    </Typography>
                ) : null}
                {inProgress ? <CircularProgress /> : null}
                {data !== undefined && !isEmptyChartData(data) ? (
                    <div className={classes.chartWrapper}>
                        <ReactApexChart
                            options={chartOptions}
                            series={dataToSeries(data, granularity, isPercent, isUniqs)}
                            type={type}
                            height={height}
                        />
                    </div>
                ) : null}
            </Grid>
        </Grid>
    );
};

/**
 * Страница "Сводка" PDF отчета.
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import moment from 'moment';
import React from 'react';

import { AnalyticCard } from '../../../components/Pdf/AnalyticCard';
import { ColumnChart } from '../../../components/Pdf/Charts/ColumnChart';
import { calcIncrease } from '../../../components/Pdf/Charts/helpers';
import { PolyglotSingleton } from '../../../lib/services/translation';
import { IChartSummaryData, IMonthSummaryData } from '../../../types/analytics';

import { useStyles } from './styles';

interface IProps {
    data: IMonthSummaryData
}

const polyglot = PolyglotSingleton.getInstance();

const getChartData = (key: Exclude<keyof IChartSummaryData, 'date'>, data: IMonthSummaryData): number[] =>
    [ data.comporable[0][key] as number, ...data.months.slice(1).map((row) => row[key] as number) ];

export const SummaryPage = ({ data }: IProps) => {
    const classes = useStyles();

    const prevPeriod = data.months[data.months.length - 2];
    const curPeriod = data.months[data.months.length - 1];
    const labels = [
        moment(data.comporable[0].date).format('MMM YY'),
        ...data.months.slice(1).map((row) => moment(row.date).format('MMM YY')),
    ];
    const getData = (key: Exclude<keyof IChartSummaryData, 'date'>) => getChartData(key, data);
    const getYearIncrease = (key: Exclude<keyof IChartSummaryData, 'date'>) => {
        const keyData = getData(key);
        const yearIncrease = (new Array(keyData.length)).fill(null);

        yearIncrease[yearIncrease.length - 1] = calcIncrease(keyData[keyData.length - 1] ?? 0, keyData[0] ?? 0);

        return yearIncrease;
    }
    const getMonthIncrease = (key: Exclude<keyof IChartSummaryData, 'date'>) => {
        const keyData = getData(key);
        const rawData = data.months.map((row) => row[key] as number);

        return keyData.map((value, index) => index > 0 ? calcIncrease(value ?? 0, rawData[index - 1] ?? 0): null);
    }

    const maxValue = Math.max(
        ...getData('visits'),
        ...getData('uniqs'),
        ...getData('newVisitors'),
        ...getData('richUniqs'),
    );

    return (
        <>
            <div className={classes.cardsWrapper}>
                <AnalyticCard indicator={polyglot.t('dashboard.visits')} value={curPeriod.visits} prevValue={prevPeriod.visits} />
                <AnalyticCard indicator={polyglot.t('dashboard.uniqs')} value={curPeriod.uniqs} prevValue={prevPeriod.uniqs} />
                <AnalyticCard indicator={polyglot.t('dashboard.newVisitors')} value={curPeriod.newVisitors} prevValue={prevPeriod.newVisitors} />
                <AnalyticCard indicator={polyglot.t('dashboard.newVisitorsPercent')} value={curPeriod.newVisitorsPercent} prevValue={prevPeriod.newVisitorsPercent} valueFormat="percent" />
                <AnalyticCard indicator={polyglot.t('dashboard.duration')} value={curPeriod.avgVisitDuration ?? 0} prevValue={prevPeriod.avgVisitDuration ?? 0} valueFormat="duration" />
                <AnalyticCard indicator={polyglot.t('dashboard.richVisitors')} value={curPeriod.richUniqs} prevValue={prevPeriod.richUniqs} />
            </div>
            <div className={classes.summaryCharts}>
                <ColumnChart
                    title={polyglot.t('dashboard.visits')}
                    labels={labels}
                    data={getData('visits')}
                    pastYearDivider={1}
                    yearIncrease={getYearIncrease('visits')}
                    monthIncrease={getMonthIncrease('visits')}
                    showFooter
                    max={maxValue}
                />
                <ColumnChart
                    labels={labels}
                    data={getData('uniqs')}
                    title={polyglot.t('dashboard.uniqs')}
                    pastYearDivider={1}
                    yearIncrease={getYearIncrease('uniqs')}
                    monthIncrease={getMonthIncrease('uniqs')}
                    max={maxValue}
                />
                <ColumnChart
                    labels={labels}
                    data={getData('newVisitors')}
                    title={polyglot.t('dashboard.newVisitors')}
                    pastYearDivider={1}
                    yearIncrease={getYearIncrease('newVisitors')}
                    monthIncrease={getMonthIncrease('newVisitors')}
                    max={maxValue}
                />
                <ColumnChart
                    labels={labels}
                    data={getData('newVisitorsPercent')}
                    title={polyglot.t('dashboard.newVisitorsPercent')}
                    valueFormat="percent"
                    pastYearDivider={1}
                    yearIncrease={getYearIncrease('newVisitorsPercent')}
                    monthIncrease={getMonthIncrease('newVisitorsPercent')}
                />
                <ColumnChart
                    labels={labels}
                    data={getData('avgVisitDuration')}
                    title={polyglot.t('dashboard.duration')}
                    valueFormat="duration"
                    pastYearDivider={1}
                    yearIncrease={getYearIncrease('avgVisitDuration')}
                    monthIncrease={getMonthIncrease('avgVisitDuration')}
                />
                <ColumnChart
                    labels={labels}
                    data={getData('richUniqs')}
                    title={polyglot.t('dashboard.richVisitors')}
                    pastYearDivider={1}
                    yearIncrease={getYearIncrease('richUniqs')}
                    monthIncrease={getMonthIncrease('richUniqs')}
                    max={maxValue}
                />
            </div>
        </>
    );
}

import { Action } from 'redux-actions';
// tslint:disable-next-line:no-submodule-imports
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { UserAdminRoles } from '../acl';
import { globalActions } from '../actions/global';
import appApi from '../api/app.api';
import userApi from '../api/users.api';
import { localize } from '../lib/services/translation';
import { ymSetUser, ymSetUserParams } from '../lib/utils/ym';
import { IGlobalState } from '../reducers/global';
import { IAppConfig, Language } from '../types/global';
import { IUser } from '../types/users';

function* initAppRequests() {
    try {
        const [user] = (yield all([
            call([userApi, 'profile']),
            call([appApi, 'config']),
        ])) as [IUser | undefined, IAppConfig];

        const globalState: Partial<IGlobalState> = {};

        // Инициализируем авторизованного пользователя
        if (user !== undefined) {
            user.organizations = user.organizations.filter(org => org.type === 'retail');

            if (user.roles.some(role => UserAdminRoles.includes(role)) || user.organizations.length > 0) {
                globalState.user = user;
                globalState.selectedOrganization = user.organizations.length > 0 ? user.organizations[0] : undefined;
            }

            if (user.lang !== null) {
                localStorage.setItem('lng', user.lang);
            }

            ymSetUser(user);
            ymSetUserParams(user, globalState.selectedOrganization);
        }

        yield localize();

        yield put(globalActions.global.initialize.success(globalState));
    } catch (error) {
        yield localize();
        yield put(globalActions.global.initialize.success({ user: undefined }));
    }
}

function* changeLanguageRequest(action: Action<Language>) {
    try {
        localStorage.setItem('lng', action.payload);
    } finally {
        yield localize();
        yield put(globalActions.global.changeLanguage.success());
    }
}

function* initApp() {
    yield takeLatest(globalActions.global.initialize.begin.toString(), initAppRequests);
}

function* changeLanguage() {
    yield takeLatest(globalActions.global.changeLanguage.change.toString(), changeLanguageRequest);
}

export default function* rootSaga() {
    yield all([initApp(), changeLanguage()]);
}

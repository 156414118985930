/**
 * Схема валидации для формы Рекламной когорты.
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import { date, object, string } from 'yup';

export const schema = object().shape({
    name: string()
        .trim()
        .required('Обязательное поле'),
    dateFrom: date()
        .typeError('Неверный формат даты')
        .required('Обязательное поле'),
    dateTo: date()
        .typeError('Неверный формат даты')
        .required('Обязательное поле'),
    comparisonDate: date()
        .typeError('Неверный формат даты')
        .required('Обязательное поле'),
});

import { Button } from '@material-ui/core';
import { Description } from '@material-ui/icons';
import React from 'react';

import { styles } from './styles';

interface IProps {
    chartExportXLSXLink: string|undefined
}

export const ImportXLXSChartButton = ({
    chartExportXLSXLink
}: IProps) => {
    const classes = styles();    
    
    return (
        <Button 
            className={classes.wrapper} 
            onClick={() => window.open(chartExportXLSXLink)} 
        >
             <Description className={classes.root} />
        </Button>
    );
};
import { object, string } from 'yup';

export const schema = object().shape({
    type: string()
        .required('Обязательное поле')
        .oneOf(['all', 'employee', 'commercial']),
    items: string()
        .required('Обязательное поле')
        .test(
            'items',
            'Вы должны указать хотя бы один гос. номер',
            (value?: string) => value !== undefined && value.split(/\r|\n/).length > 0,
        ),
});

/**
 * Компонент для интеграции Material UI Text Field File U=*--*1`     pload с Formik Field
 *
 * @author Artem Bakulin
 */

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { FieldProps, getIn } from 'formik';
import React from 'react';

export interface ISimpleFileUploadProps extends FieldProps {
    label: string;
    disabled?: boolean;
}

const SimpleFileUpload = ({
    label,
    field,
    form: { touched, errors, isSubmitting, setFieldValue },
    disabled = false,
}: ISimpleFileUploadProps) => {
    const error = getIn(touched, field.name) && getIn(errors, field.name);

    return (
        <div>
            <FormControl>
                <InputLabel shrink error={!!error}>
                    {label}
                </InputLabel>
                <Input
                    error={!!error}
                    inputProps={{
                        type: 'file',
                        disabled: disabled || isSubmitting,
                        name: field.name,
                        // tslint:disable-next-line:no-any
                        onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                            if (event.currentTarget.files === null) {
                                setFieldValue(field.name, null);

                                return;
                            }

                            const file = event.currentTarget.files[0];
                            setFieldValue(field.name, file);
                        },
                    }}
                />
                {Boolean(error) ? <FormHelperText error>{error as string}</FormHelperText> : null}
            </FormControl>
        </div>
    );
};

export default SimpleFileUpload;

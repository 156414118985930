/**
 * Линейный график для PDF отчетов.
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import { ChartData } from 'chart.js';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import React from 'react';
import { Line } from 'react-chartjs-2';

import { Typo } from '../../Typo';
import { FooterHint } from '../FooterHint';
import { getChartOptions, IChartProps } from '../helpers';
import { useStyles } from '../styles';

interface IProps extends IChartProps {
    data: Array<Array<number | null>>;
}

const lineColors = [
    'rgb(99, 216, 213, 1)',
    'rgb(89, 152, 220, 1)',
    'rgb(51, 233, 155, 1)',
];

const dataToSeries = (
    datasets: Array<Array<number | null>>,
    labels: string[],
): ChartData => ({
    labels,
    datasets: datasets.map((data, index) => (
        {
            label: `${index}`,
            borderColor: lineColors[index],
            tension: 0.5,
            pointBackgroundColor: '#ffffff',
            data
        }
    )),
});

export const LineChart = ({
    title,
    labels,
    data,
    monthIncrease,
    yearIncrease,
    increaseFormatOptions,
    valueFormat = 'absolute',
    valueFormatOptions,
    pastYearDivider,
    showFooter = false,
    size = 'standard',
    weeks,
    months,
    footerWrap,
    type,
}: IProps) => {
    const classes = useStyles();
    const chartData = dataToSeries(data, labels);

    return (
        <div className={classes.colummChart}>
            {title !== undefined ? <Typo className={classes.title}>{title}</Typo> : null}
            <div className={`${classes.chart} ${classes[size]}`}>
                <Line
                    data={chartData}
                    options={getChartOptions(
                        valueFormat,
                        monthIncrease,
                        yearIncrease,
                        false,
                        pastYearDivider,
                        weeks,
                        months,
                        undefined,
                        valueFormatOptions,
                        increaseFormatOptions
                    )}
                    plugins={[DataLabelsPlugin]}
                />
            </div>
            {showFooter ? <FooterHint wrap={footerWrap} type={type} /> : null}
        </div>
    );
}


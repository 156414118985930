import { createStyles, Theme } from '@material-ui/core';

export const styles = (theme: Theme) =>
    createStyles({
        '@global': {
            body: {
                background: theme.palette.secondary.main,
            },
        },
        loginFormWrapper: {
            paddingTop: '10vh',
            width: '100vw',
            height: '100vh',
        },
        loginPaper: {
            padding: theme.spacing(3),
            width: 500,
        },
    });

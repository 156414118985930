/**
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import { ChartData, ChartOptions } from 'chart.js';
import moment from 'moment';
import React from 'react';

import { getPeriods } from '../../api/analytics.api';
import { DatasetExtended, DetailedApiChart } from '../../components/Charts/DetailedApiChart';
import { CHART_SERIES_COLORS, CHART_STACKED_SERIES_COLORS } from '../../components/Charts/DetailedApiChart/constants';
import { getDateCategories } from '../../components/Charts/DetailedApiChart/helpers';
import { getPeriodFormat } from '../../lib/utils/dates';
import { datePeriodString, datesPeriodFromString, durationString } from '../../lib/utils/strings';
import { IAnalyticsCalendarState } from '../../reducers/analytics.calendar';
import { DetailedAnalyticsPeriod } from '../../types/analytics';
import { AnalyticsDataGranularity, IDatesPeriod } from '../../types/date.periods';

interface IProps {
    filter: 'price' | 'age';
    type: 'long-visits' | 'duration';
    title: string;
}

const getDatesetByPeriods = (data: DetailedAnalyticsPeriod[], seriesNames: string[], periodIndex: number) => ({
    label: seriesNames[periodIndex],
    backgroundColor: CHART_SERIES_COLORS[periodIndex],
    data: Object.keys(data[0])
        .filter(key => key !== 'date')
        .map(key => data[0][key]),
});

const getDatasetsByDates = (
    data: DetailedAnalyticsPeriod[][],
    seriesNames: string[],
    periods: IDatesPeriod[],
): DatasetExtended[] => {
    const datasets: DatasetExtended[] = [];
    const priceRanges = Object.keys(data[0][0]).filter(key => key !== 'date');

    data.forEach((period, periodIndex) => {
        priceRanges.forEach((range, rangeIndex) => {
            const bgColor = seriesNames[1]
                ?  CHART_STACKED_SERIES_COLORS[periodIndex][rangeIndex]
                : CHART_STACKED_SERIES_COLORS[1][rangeIndex];

            datasets.push({
                label: `${range}${(new Array(periodIndex)).fill(' ').join()}`,
                title: seriesNames[periodIndex],
                stack: periodIndex.toString(),
                backgroundColor: bgColor,
                data: period.map(row => row[range]),
                period: periods[periodIndex],
                dates: period.map(row => moment(row.date).toDate()),
            });
        });
    });

    return datasets;
};

const getDurationOptions = (
    _: DetailedAnalyticsPeriod[][],
    __: IAnalyticsCalendarState,
    granularity: AnalyticsDataGranularity | undefined,
): ChartOptions => ({
    plugins: {
        datalabels: {
            formatter: (value) => durationString(value as number),
        },
        tooltip: {
            callbacks: {
                label: (ctx) => `${ctx.dataset.label ?? ''} ${durationString(ctx.parsed.y)}`,
            },
        },
    },
    scales: {
        y: {
            display: granularity === undefined,
            ticks: {
                callback: (value) => durationString(value as number),
            },
        },
    },
});

const dataToSeries = (
    data: DetailedAnalyticsPeriod[][],
    calendar: IAnalyticsCalendarState,
    granularity: AnalyticsDataGranularity | undefined,
): ChartData<'bar'> => {
    const periodFormat = getPeriodFormat(calendar.currentPeriodType, calendar.lastPeriodType);
    const periods = getPeriods(calendar).map(period => datesPeriodFromString(period, 'YYYY-MM-DD', ';')).reverse();
    const seriesNames = periods.map(period => datePeriodString(period.from, period.to, periodFormat, '–'));
    const priceRanges = Object.keys(data[0][0]).filter(key => key !== 'date');
    const series = {
        labels: granularity !== undefined
            ? getDateCategories(data, granularity, datesPeriodFromString(getPeriods(calendar)[0], 'YYYY-MM-DD', ';'))
            : priceRanges,
        datasets:
            granularity !== undefined
                ? getDatasetsByDates(data.reverse(), seriesNames, periods)
                : data.reverse().map((period, index) => getDatesetByPeriods(period, seriesNames, index)),
    };

    return series;
};

export const Chart = ({ type, title, filter }: IProps) => (
    <DetailedApiChart
        title={title}
        chartApi={`${type}-by-${filter}`}
        dataToSeries={dataToSeries}
        filters={type === 'duration' ? new Set(['granularity']) : undefined}
        height={300}
        options={type === 'duration' ? getDurationOptions : undefined}
    />
);

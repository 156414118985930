import { WithStyles, withStyles } from '@material-ui/core';
import React from 'react';

import { IGlobalState } from '../../reducers/global';
import { Language } from '../../types/global';
import { IUserOrganization } from '../../types/organizations';
import { IUser } from '../../types/users';
import AppMenu from '../AppMenu';
import Header from '../Header';

import { styles } from './styles';

interface IProps extends WithStyles<typeof styles> {
    // tslint:disable-next-line:no-null-undefined-union */
    children: React.ReactNode | React.ReactNodeArray;
    user: IGlobalState['user'];
    organization?: IUserOrganization;
    logout(): void;
    changeOrganization(orgId: number): void;
    changeLanguage(lng: Language): void;
}

const Layout = ({ user, logout, children, classes, organization, changeOrganization, changeLanguage }: IProps) => (
    <div className={classes.root}>
        <Header user={user} logout={logout} changeLanguage={changeLanguage} />
        <AppMenu user={user as IUser} organization={organization} changeOrganization={changeOrganization} />
        <main className={classes.content}>
            <div className={classes.toolbar}/>
            {children}
        </main>
    </div>
);

export default withStyles(styles)(Layout);

import { Omit, WithStyles, withStyles } from '@material-ui/core';
import { ToggleButtonGroup as MuiToggleButtonGroup } from '@material-ui/lab';
// tslint:disable-next-line:no-submodule-imports
import { ToggleButtonGroupProps } from '@material-ui/lab/ToggleButtonGroup';
import React from 'react';

import { styles } from './styles';

interface IProps extends WithStyles<typeof styles>, Omit<ToggleButtonGroupProps, 'classes'> {}

const ToggleButtonGroup = ({ classes, ...rest }: IProps) => (
    <MuiToggleButtonGroup {...rest} classes={{ root: classes.root, grouped: classes.selected }} />
);

export default withStyles(styles)(ToggleButtonGroup);

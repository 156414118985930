/**
 * График "Уники с разбивкой по частоте".
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import { ChartData } from 'chart.js';
import React from 'react';

import { getPeriods } from '../../api/analytics.api';
import { DetailedApiChart } from '../../components/Charts/DetailedApiChart';
import { CHART_SERIES_COLORS } from '../../components/Charts/DetailedApiChart/constants';
import { PolyglotSingleton } from '../../lib/services/translation';
import { getPeriodFormat } from '../../lib/utils/dates';
import { datePeriodString, datesPeriodFromString } from '../../lib/utils/strings';
import { IAnalyticsCalendarState } from '../../reducers/analytics.calendar';
import { DetailedAnalyticsPeriod } from '../../types/analytics';

const polyglot = PolyglotSingleton.getInstance();

const getDatesetByPeriods = (data: DetailedAnalyticsPeriod[], seriesNames: string[], periodIndex: number) => ({
    label: seriesNames[periodIndex],
    backgroundColor: CHART_SERIES_COLORS[periodIndex],
    data: Object.keys(data[0])
        .filter(key => key !== 'date')
        .map(key => data[0][key]),
});

const dataToSeries = (
    data: DetailedAnalyticsPeriod[][],
    calendar: IAnalyticsCalendarState,
): ChartData<'bar'> => {
    const periodFormat = getPeriodFormat(calendar.currentPeriodType, calendar.lastPeriodType);
    const seriesNames = getPeriods(calendar)
        .map(period => datesPeriodFromString(period, 'YYYY-MM-DD', ';'))
        .map(period => datePeriodString(period.from, period.to, periodFormat, '–'))
        .reverse();
    const labels = Object.keys(data[0][0]).filter(key => key !== 'date');
    const series = {
        labels,
        datasets: data.reverse().map((period, index) => getDatesetByPeriods(period, seriesNames, index)),
    };

    return series;
};

export const ChartUniqsByFrequency = () => (
    <DetailedApiChart
        title={polyglot.t('frequency.charts.byFrequency')}
        filters={new Set(['percents', 'frequency'])}
        chartApi="uniqs-by-frequency"
        dataToSeries={dataToSeries}
        height={300}
    />
);

/**
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import React, { PureComponent } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import GlobalProgress from './components/GlobalProgress';
import LoginPage from './pages/Login';
import { IAppState } from './reducers';
import PdfSection from './sections/Pdf';
import SiteSection from './sections/Site';

interface IProps {
    app: IAppState['global'];
    initialize(): void;
}

class App extends PureComponent<IProps> {
    public componentDidMount() {
        if (this.props.app.user === undefined) {
            this.props.initialize();
        }
    }

    // tslint:disable-next-line:prefer-function-over-method
    public render() {
        const { app } = this.props;

        // Первоначальная инициализация приложения
        if (!app.isInitialized) {
            return <GlobalProgress />;
        }

        // Пользователь не авторизован
        if (app.user === undefined) {
            return <LoginPage />;
        }

        return (
            <Router>
                <Switch>
                    <Route path="/pdf" component={PdfSection} />
                    <Route path="/" component={SiteSection} />
                </Switch>
            </Router>
        );
    }
}

export default App;

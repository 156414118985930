import { createStyles, Theme } from '@material-ui/core';
// tslint:disable-next-line:no-submodule-imports
import { green, red } from '@material-ui/core/colors';

export const styles = (theme: Theme) =>
    createStyles({
        root: {
            position: 'relative',
        },
        selected: {
            backgroundColor: theme.palette.primary.main,
        },
        header: {
            width: '100%',

            position: 'absolute',
            top: 0,
            left: 0,
        },
        title: {
            opacity: 0.5,
        },
        titleSelected: {
            opacity: 1,
            color: theme.palette.common.white,
        },
        action: {
            height: '100%',
        },
        content: {
            paddingTop: 70,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100%',
        },
        value: {
            color: theme.palette.primary.main,
            fontSize: '2.25rem',
            paddingBottom: theme.spacing(2),
        },
        valueSelected: {
            color: theme.palette.common.white,
        },
        lastValue: {
            opacity: 0.6,
        },
        lastValueSelected: {
            color: theme.palette.common.white,
        },
        increase: {
            ...theme.typography.body1,
            fontSize: '1.5rem',
            display: 'flex',
        },
        increaseSelected: {
            color: theme.palette.common.white,

            '& svg': {
                color: 'inherit',
            },
        },
        increaseIconUp: {
            fontSize: 34,
            color: green[300],
        },
        increaseIconDown: {
            fontSize: 34,
            color: red[300],
        },
        popover: {
            pointerEvents: 'none',
        },
        popoverPaper: {
            maxWidth: '450px',
            minWidth: '300px',
            width: 'auto',
        },
        popoverText: {
            padding: theme.spacing(2),
            display: 'inline-block',
        },
        popoverIcon: {
            opacity: 0.5,

            '&:hover': {
                opacity: 1,
            }
        },
        popoverIconSelected: {
            fill: theme.palette.common.white,
            opacity: 0.5,

            '&:hover': {
                opacity: 1,
            }
        }
    });

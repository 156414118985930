/**
 * Кастомная тема для графиков
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

 import { BaseObject, color, ColorSet } from '@amcharts/amcharts4/core';

const vnukovoTheme = (object: BaseObject): void => {
    if (object instanceof ColorSet) {
        object.list = [
            color({ r: 131, g: 164, b: 121, a: 0.5 }),
            color({ r: 74, g: 144, b: 226, a: 0.4 }),
            color('#ff8400'),
            color('#5234ab'),
            color('#48c54a'),
            color('#cd00cd'),
            color('#de9e53'),
            color('#77877a'),
        ];
    }
}

export default vnukovoTheme;

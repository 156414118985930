/**
 * Раздел "Частота" годового отчета.
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import { Grid, Typography, WithStyles, withStyles } from '@material-ui/core';
import moment from 'moment';
import React from 'react';

import ColumnChartStacked, { IStackedSeries } from '../../../components/Charts/ColumnChartStacked';
import Translate from '../../../components/Translate';
import { IYearSummaryData } from '../../../types/analytics';
import { styles } from '../styles';

interface IProps extends WithStyles<typeof styles> {
    data: IYearSummaryData[];
}

const getChartData = (data: IYearSummaryData[]): Array<{ period: string; [index: string]: string | number }> =>
    data.map(row => ({
        period: moment(row.date).format('MMM YY'),
        1: row.visitsByFreq.visitsFreq1,
        2: row.visitsByFreq.visitsFreq2,
        3: row.visitsByFreq.visitsFreq3,
        '>3': row.visitsByFreq.visitsFreqMore,
    }));

const FrequencyPage = ({ data, classes }: IProps) => (
    <Grid
        container
        spacing={3}
        direction="column"
        justify="space-between"
        wrap="nowrap"
        alignItems="stretch"
        className={classes.chartRowTwoThirds}
    >
        <Grid item>
            <Typography variant="h5" className={classes.title}>
                <Translate phrase="frequency.charts.byFrequency" />
            </Typography>
        </Grid>
        <Grid item className={classes.fullHeight}>
            <ColumnChartStacked
                id="visitsByFrequencyChart"
                data={getChartData(data) as IStackedSeries[]}
                noElements={true}
                className={classes.fullHeight}
                classes={{
                    chartWrapper: classes.chart,
                }}
                withTotals
            />
        </Grid>
    </Grid>
);

export default withStyles(styles)(FrequencyPage);

/**
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import { useTheme } from '@amcharts/amcharts4/core';
import { CssBaseline } from '@material-ui/core';
// tslint:disable-next-line:no-implicit-dependencies
import { ThemeProvider } from '@material-ui/styles';
import React from 'react';
import { defaults } from 'react-chartjs-2';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';
import { YMInitializer } from 'react-yandex-metrika';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';

import App from './containers/App';
import chartTheme from './lib/charts/themes/vnukovo.theme';
import { rootReducer } from './reducers';
import appSaga from './sagas';
import * as serviceWorker from './serviceWorker';
import { theme } from './theme';

defaults.animation = false;
defaults.maintainAspectRatio = false;
defaults.plugins.legend.position = 'bottom';
defaults.font.family = '"Montserrat", "Helvetica", "Arial", sans-serif';
defaults.datasets.bar.maxBarThickness = 100;

useTheme(chartTheme);

const sagaMiddleware = createSagaMiddleware();
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(appSaga);

ReactDOM.render(
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Helmet defaultTitle="Synaps retail" titleTemplate="%s :: Synaps retail" />
            <YMInitializer
                accounts={[86750765]}
                options={{
                    clickmap: true,
                    trackLinks: true,
                    accurateTrackBounce: true,
                    webvisor: true
                }}
                version="2"
            />
            <App />
        </ThemeProvider>
    </Provider>,
    document.getElementById('root'),
);

/*
If you want your app to work offline and load faster, you can change
unregister() to register() below. Note this comes with some pitfalls.
Learn more about service workers: https://bit.ly/CRA-PWA
*/
serviceWorker.unregister();

import { InterpolationOptions } from 'node-polyglot';
import React from 'react';

import { PolyglotSingleton } from '../../lib/services/translation';

interface IProps {
    phrase: string;
    // tslint:disable-next-line:no-any
    values?: number | InterpolationOptions | undefined;
}

const polyglot = PolyglotSingleton.getInstance();

export default class Translate extends React.PureComponent<IProps> {
    public render() {
        return <>{polyglot.t(this.props.phrase, this.props.values)}</>;
    }
}

import { createStyles } from '@material-ui/core';

export const styles = createStyles({
    chartGrid: {
        marginBottom: 60,
    },
    chartsWrapper: {
        marginTop: 60,
    },
    chartWrapper: {
        width: '100%',
        height: 500,
    },
    chartExport: {
        marginLeft: 16,
    },
    filter: {
        minWidth: 150,
        marginRight: 16,
    },
});

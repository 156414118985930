/**
 * Компонент графика для страницы "Сводка".
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import { ApexOptions } from 'apexcharts';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';

import { getPeriods } from '../../api/analytics.api';
import { ApiChart } from '../../components/Charts/ApiChart';
import { TOOLTIP_DATE, TOOLTIP_MONTH_DATE, TOOLTIP_WEEK_DATE } from '../../components/Charts/ApiChart/constants';
import { formatValue, getDateCategories } from '../../components/Charts/ApiChart/helpers';
import { ApiChartValuesType } from '../../components/Charts/ApiChart/types';
import { PolyglotSingleton } from '../../lib/services/translation';
import { getPeriodFormat } from '../../lib/utils/dates';
import { datePeriodString, datesPeriodFromString } from '../../lib/utils/strings';
import { calendarSelector } from '../../selectors/common.selectors';
import { IDateAnalyticsPeriod } from '../../types/analytics';
import { AnalyticsDataGranularity } from '../../types/date.periods';

const polyglot = PolyglotSingleton.getInstance();

interface IProps {
    className?: string;
    title: string;
    chartApi: string;
    percentsEnabled?: boolean;
    uniqsEnabled?: boolean;
    tooltipNames?: string | string[];
    valuesType?: ApiChartValuesType;
}

interface ITooltipOptions {
    dataPointIndex: number;
    series: number[][];
    w: {
        config: {
            colors: string[];
            series: Array<{
                name: string;
                tooltipName?: string;
                granularity: AnalyticsDataGranularity;
                isPercent: boolean;
                dates: string[];
            }>;
        };
    };
}

const getDateLabel = (date: string, granularity?: AnalyticsDataGranularity): string => {
    if (granularity === 'week') {
        return `${polyglot.t('common.dates.period.from')}&nbsp;<b>${moment(date).format(
            TOOLTIP_WEEK_DATE,
        )}</b>&nbsp;${polyglot.t('common.dates.period.to')}&nbsp;<b>${moment(date)
            .add(6, 'days')
            .format(TOOLTIP_WEEK_DATE)}</b>`;
    }

    return `<b>${moment(date).format(granularity === 'month' ? TOOLTIP_MONTH_DATE : TOOLTIP_DATE)}</b>`;
};

const mapDataToSeries = (names: string[], tooltipNames?: string | string[]) => (
    data: Array<Array<IDateAnalyticsPeriod<string>>>,
    granularity: AnalyticsDataGranularity,
    isPercent: boolean,
) =>
    data.map((period, index) => ({
        name: names[index],
        granularity,
        isPercent,
        ...(tooltipNames !== undefined
            ? {
                  tooltipName: Array.isArray(tooltipNames) ? tooltipNames[index] : tooltipNames,
              }
            : {}),
        dates: period.map(item => item.date),
        data: period.map(item => item.value),
    })).reverse();

/**
 * Функция генерации всплывающей подсказки для ApiChart.
 *
 * @param data Набор данных для графика
 * @param granularity Гранулярность данных
 * @param valueFormatter Функция форматирования значений
 *
 * @returns Возвращает подсказку
 */
export const getTootip = (valuesType: ApiChartValuesType) => ({
    dataPointIndex,
    series,
    w: { config },
}: ITooltipOptions) => {
    let html = '';
    let count = 0;

    series.forEach((serie: Array<number | undefined>, index: number) => {
        const value = serie[dataPointIndex];
        const valueType = config.series[0].isPercent ? 'percent' : valuesType;

        if (value === undefined) {
            return;
        }

        count += 1;
        html += `
                <div style="margin-top: 4px; margin-bottom: -4px;">
                    <div class="apexcharts-tooltip-series-group" style="display: flex;">
                        ${getDateLabel(config.series[index].dates[dataPointIndex], config.series[0].granularity)}
                    </div>
                    <div class="apexcharts-tooltip-series-group" style="display: flex;">
                        <span class="apexcharts-tooltip-marker" style="background-color: ${
                            config.colors[index]
                        };"></span>
                        <div class="apexcharts-tooltip-text" style="font-size: 12px;">
                            <div class="apexcharts-tooltip-y-group">
                                <span class="apexcharts-tooltip-text-y-label">
                                    ${config.series[index].tooltipName ?? config.series[index].name}: </span>
                                <span class="apexcharts-tooltip-text-y-value">
                                    ${formatValue(valueType)(value)}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            `;
    });

    if (count === 2) {
        const change = (series[1][dataPointIndex] / series[0][dataPointIndex] - 1) * 100;

        html += `
                <div class="apexcharts-tooltip-series-group" style="display: flex;">
                    <div class="apexcharts-tooltip-text" style="font-size: 14px;">
                        <div class="apexcharts-tooltip-y-group">
                            <span class="apexcharts-tooltip-text-y-label">
                                ${polyglot.t('common.dictionary.change')}: </span>
                            <span class="apexcharts-tooltip-text-y-value">
                                ${change > 0 ? '+' : ''}${change.toLocaleString('ru', { maximumFractionDigits: 1 })}%
                            </span>
                        </div>
                    </div>
                </div>
            `;
    }

    return html;
};

export const DashboardChart = ({
    title,
    chartApi,
    tooltipNames,
    percentsEnabled = false,
    uniqsEnabled = false,
    valuesType = 'absolute',
    className,
}: IProps) => {
    const filters = new Set<'granularity' | 'percents' | 'uniqs'>(['granularity']);
    if (percentsEnabled) {
        filters.add('percents');
    }
    if (uniqsEnabled) {
        filters.add('uniqs');
    }

    const calendar = useSelector(calendarSelector);
    const periodFormat = getPeriodFormat(calendar.currentPeriodType, calendar.lastPeriodType);
    const seriesNames = getPeriods(calendar)
        .map(period => datesPeriodFromString(period, 'YYYY-MM-DD', ';'))
        .map(period => datePeriodString(period.from, period.to, periodFormat, '–'));
    const options: ApexOptions = {
        tooltip: {
            custom: getTootip(valuesType),
        },
    };

    return (
        <ApiChart<Array<Array<IDateAnalyticsPeriod<string>>>>
            className={className}
            chartApi={chartApi}
            title={title}
            filters={filters}
            dataToSeries={mapDataToSeries(seriesNames, tooltipNames)}
            categories={getDateCategories}
            height={300}
            granularityRequired={true}
            type="line"
            options={options}
            valuesType={valuesType}
        />
    );
};

/**
 * Страницы с 4 по 5 раздела "Посещаемость" годового отчета.
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import { Grid, Typography, WithStyles, withStyles } from '@material-ui/core';
import React from 'react';

import ColumnChartStacked, { IStackedSeries } from '../../../components/Charts/ColumnChartStacked';
import PdfSection from '../../../components/PdfSection';
import { PolyglotSingleton } from '../../../lib/services/translation';
import { ICustomSummaryData, IMonthSummaryData } from '../../../types/analytics';
import { PeriodFormatFunction } from '../../../types/global';
import { styles } from '../styles';
import { CHART_DATE_FORMAT, formatPeriodDate, IPdfPageProps } from '../Summary';

interface IProps extends WithStyles<typeof styles>, IPdfPageProps {
    data: IMonthSummaryData | ICustomSummaryData;
    id: string;
    periodFormat?: string | PeriodFormatFunction;
    pricesGroups: string[];
}

const getStackedChartData = (
    data: IMonthSummaryData | ICustomSummaryData,
    priceKey: string,
    periodFormat: string | PeriodFormatFunction,
): IStackedSeries[] => [
    ...('comporable' in data
        ? [
              {
                  period: formatPeriodDate(data.comporable[0].date, periodFormat),
                  ...data.comporable[0].visitsByPriceAndAge[priceKey],
              },
          ]
        : []),
    ...data.months.map(row => ({
        period: formatPeriodDate(row.date, periodFormat),
        ...row.visitsByPriceAndAge[priceKey],
    })),
];

const polyglot = PolyglotSingleton.getInstance();

const VisitsPagesMore = ({
    data,
    classes,
    id,
    pricesGroups,
    periodFormat = CHART_DATE_FORMAT,
    page,
    period,
}: IProps) => {
    const legendId = `visitsPagesLegend_${id}`;

    return (
        <PdfSection page={page} period={period} title={polyglot.t('visits.header')}>
            <div className={classes.chartRowTwoThirds}>
                <Typography variant="h5" className={classes.title}>
                    {`${polyglot.t('visits.byAgeAndPrice')}`}
                </Typography>
                <div id={`visitsPagesLegend_${id}`} className={classes.legend} />
                <Grid container spacing={2} alignItems="stretch" className={classes.fullHeight}>
                    {pricesGroups.map((group: string, index: number) => {
                        const chartData = getStackedChartData(data, group, periodFormat);
                        const comporable = 'comporable' in data ? chartData[0].period : undefined;

                        return (
                            // tslint:disable-next-line:no-any no-unsafe-any
                            <Grid
                                item
                                // tslint:disable-next-line:no-any no-unsafe-any
                                xs={(12 / pricesGroups.length) as any}
                                key={group}
                                className={classes.fullHeight}
                            >
                                <Typography variant="h5" className={classes.title}>
                                    {group}
                                </Typography>
                                <ColumnChartStacked
                                    id={`chartDivPriceAndAge${group}_${id}`}
                                    data={chartData}
                                    noElements={true}
                                    className={classes.chartWrapper}
                                    classes={{ chartWrapper: classes.chart }}
                                    legendContainer={legendId}
                                    disableLegend={index > 0}
                                    comporableCategory={comporable}
                                    withTotals
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            </div>
        </PdfSection>
    );
};

export default withStyles(styles)(VisitsPagesMore);

import { createStyles, Theme } from '@material-ui/core';

export const styles = (theme: Theme) =>
    createStyles({
        imports: {
            '& > *': {
                marginLeft: theme.spacing(2),
            },
        },
    });

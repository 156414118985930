/**
 * Основной компонент для линейных графиков.
 *
 * @TODO: Необходим полный рефакторинг компонента.
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

/* tslint:disable:no-submodule-imports */
import {
    Axis,
    AxisRendererX,
    AxisRendererY,
    Legend,
    ValueAxis,
    XYChart as Chart,
    XYCursor,
    XYSeries,
} from '@amcharts/amcharts4/charts';
import { create, DataSource, NumberFormatter, Scrollbar } from '@amcharts/amcharts4/core';
import am4lang_ru_RU from '@amcharts/amcharts4/lang/ru_RU';
import { WithStyles, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';

import { CustomNumberFormatter } from '../../../lib/charts/formatters/custom.number.formatter';
import { NumberFormatFunction } from '../../../types/global';

import { styles } from './styles';

export interface IXYChartProps<T> {
    id: string;
    data?: T[] | T[][];
    dataSource?: DataSource;
    xAxes?: Array<Axis<AxisRendererX>>;
    yAxes?: Array<Axis<AxisRendererY>>;
    series: XYSeries[];
    numberFormat: string | NumberFormatFunction;
    legend?: Legend;
    cursor?: XYCursor;
    scrollbarX?: Scrollbar;
    scrollbarY?: Scrollbar;
    className?: string;
    disableAxis?: boolean;
    initAxes?(chart: Chart): void;
}

interface IProps<T> extends WithStyles<typeof styles>, IXYChartProps<T> {}

class XYChart<T> extends React.PureComponent<IProps<T>> {
    private chart?: Chart;

    public static defaultProps = {
        id: 'chartDiv',
        numberFormat: '#,###.##',
        disableAxis: false,
    };

    public componentDidMount() {
        const { data, dataSource, yAxes, xAxes, initAxes } = this.props;

        if (data === undefined && dataSource === undefined) {
            throw new TypeError('Вы должны указать источник данных');
        }

        if (initAxes === undefined && (yAxes === undefined || xAxes === undefined)) {
            throw new TypeError('Вы должны указать оси Y и X или передать функцию их инициализирующую.');
        }

        const chart = create(this.props.id, Chart);
        chart.language.locale = am4lang_ru_RU;
        chart.language.locale._thousandSeparator = ' ';
        chart.maskBullets = false;

        if (this.props.series.length > 1) {
            this.props.series.forEach((serie, index) => {
                serie.data = this.props.data !== undefined ?
                    this.props.data[index] as T[] : [];
            });
        } else {
            if (dataSource !== undefined) {
                chart.dataSource = dataSource;
            } else {
                chart.data = data as T[];
            }
        }

        if (initAxes !== undefined) {
            initAxes(chart);
        } else {
            chart.xAxes.pushAll(xAxes as Axis[]);
            chart.yAxes.pushAll(yAxes as Axis[]);

            if (this.props.disableAxis !== undefined && this.props.disableAxis) {
                (xAxes as Axis[]).forEach((xAxe: Axis) => {
                    xAxe.renderer.grid.template.disabled = true;
                });
                (yAxes as Axis[]).forEach((yAxe: Axis) => {
                    yAxe.renderer.labels.template.disabled = true;
                    yAxe.renderer.grid.template.disabled = true;
                });
            }
        }

        chart.series.pushAll(this.props.series);

        if (this.props.legend !== undefined) {
            chart.legend = this.props.legend;
        }

        if (this.props.cursor !== undefined) {
            chart.cursor = this.props.cursor;
        }

        if (this.props.scrollbarX !== undefined) {
            chart.scrollbarX = this.props.scrollbarX;
        }

        if (this.props.scrollbarY !== undefined) {
            chart.scrollbarY = this.props.scrollbarY;
        }

        if (typeof this.props.numberFormat === 'function') {
            chart.numberFormatter = new CustomNumberFormatter(this.props.numberFormat);
        } else {
            chart.numberFormatter.numberFormat = this.props.numberFormat;
        }

        if (chart.xAxes.length > 0) {
            chart.xAxes.values[0].renderer.minGridDistance = 10;
        }

        if (chart.yAxes.length > 0) {
            (chart.yAxes.values[0] as ValueAxis).extraMax = 0.1;
        }

        this.chart = chart;
    }

    public componentDidUpdate(prevProps: IProps<T>) {
        if (this.chart !== undefined) {
            if (this.props.series !== prevProps.series) {
                const len = this.chart.series.length;
                for (let i = 0; i < len; i += 1) {
                    this.chart.series.removeIndex(0).dispose();
                }
                this.chart.colors.reset();
                this.chart.series.pushAll(this.props.series);
            }

            if (this.props.data !== prevProps.data && this.props.data !== undefined) {
                this.chart.data = this.props.data;
            }

            if (typeof this.props.numberFormat === 'function') {
                this.chart.numberFormatter = new CustomNumberFormatter(this.props.numberFormat);
            } else {
                this.chart.numberFormatter = new NumberFormatter();
                this.chart.numberFormatter.numberFormat = this.props.numberFormat;
            }
        }
    }

    public componentWillUnmount() {
        if (this.chart !== undefined) {
            this.chart.dispose();
        }
    }

    public render() {
        const { id, classes, className } = this.props;
        const allClasses = classNames(classes.root, className);

        return <div id={id} className={allClasses} />;
    }
}

export default withStyles(styles)(XYChart);

/**
 * Хук для получения объека с параметрами query из урла.
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import { useLocation } from 'react-router-dom';

/**
 * Хук для получения объека с параметрами query из урла.
 */
export const useQuery = () => new URLSearchParams(useLocation().search);

import {
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    WithStyles,
    withStyles,
} from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import { IUserOrganization } from '../../types/organizations';
import { IUser } from '../../types/users';
import AppOrganizationSelect from '../AppOrganizationSelect';

import { generateMenu } from './menu.generator';
import { styles } from './styles';

interface IProps extends WithStyles<typeof styles>, RouteComponentProps {
    user: IUser;
    organization?: IUserOrganization;
    changeOrganization(orgId: number): void;
}

// tslint:disable-next-line:no-any no-unsafe-any
const listLink = (path: string) => React.forwardRef((props: any, ref) => <Link to={path} {...props} innerRef={ref} />);

const AppMenu = ({ classes, user, location, organization, changeOrganization }: IProps) => (
    <Drawer variant="permanent" className={classes.root} classes={{ paper: classes.drawerPaper }}>
        <div className={classes.toolbar} />

        {organization !== undefined && user.organizations.length === 1 ? (
            <Typography variant="h5" className={classes.organizationName}>
                {organization.name}
            </Typography>
        ) : null}
        {organization !== undefined && user.organizations.length > 1 ? (
            <div className={classes.organizationSelection}>
                <AppOrganizationSelect
                    organization={organization}
                    organizations={user.organizations}
                    onChange={changeOrganization}
                />
            </div>
        ) : null}

        {generateMenu(user).map(section => (
            <section key={section.id} className={classes.section}>
                <Typography variant="h6" className={classes.title}>
                    {section.title}
                </Typography>
                <List />
                {section.items.map(item => {
                    const isSelected =
                        item.isExact !== undefined && item.isExact
                            ? location.pathname === item.path
                            : location.pathname.indexOf(item.path) === 0;

                    return (
                        <ListItem
                            key={item.path}
                            selected={isSelected}
                            className={classes.listItem}
                            classes={{ selected: classes.listItemSelected }}
                            component={listLink(item.path)}
                        >
                            {item.icon !== undefined ? (
                                <ListItemIcon
                                    className={classNames({ [classes.icon]: true, [classes.iconSelected]: isSelected })}
                                >
                                    <item.icon />
                                </ListItemIcon>
                            ) : null}
                            <ListItemText
                                className={classNames({
                                    [classes.listText]: true,
                                    [classes.listTextSelected]: isSelected,
                                })}
                                disableTypography
                            >
                                {item.title}
                            </ListItemText>
                        </ListItem>
                    );
                })}
            </section>
        ))}
    </Drawer>
);

export default withStyles(styles)(withRouter(AppMenu));

import { createStyles, Theme } from '@material-ui/core';

export const styles = (theme: Theme) =>
    createStyles({
        root: {
            zIndex: theme.zIndex.drawer + 1,
        },
        toolbar: {
            justifyContent: 'space-between',
        },
    });

/**
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import { Grid, WithStyles, withStyles } from '@material-ui/core';
import React from 'react';

import { ChartUniqsByFrequency } from './ChartUniqsByFrequency';
import { ChartVisitsByRegions } from './ChartVisitsByRegions';
import { styles } from './styles';

interface IProps extends WithStyles<typeof styles> {}

const ResultData = ({classes}: IProps) => (
    <Grid container spacing={4} className={classes.chartsWrapper} direction="column" alignItems="stretch">
        <Grid item>
            <ChartUniqsByFrequency />
        </Grid>
        <Grid item>
            <ChartVisitsByRegions />
        </Grid>
    </Grid>
);

export default withStyles(styles)(ResultData);

import { Action } from 'redux-actions';
// tslint:disable-next-line:no-submodule-imports
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { globalActions } from '../actions/global';
import { organizationsActions } from '../actions/organizations';
import api from '../api/organizations.api';
import { ymSetUserParams } from '../lib/utils/ym';
import { IAppState } from '../reducers';
import { IOrganization } from '../types/organizations';
import { IUser } from '../types/users';

function* changeOrganizationSaga(action: Action<number>) {
    const state = (yield select()) as IAppState;

    ymSetUserParams(
        state.global.user as IUser,
        (state.global.user as IUser).organizations.find((org) => org.id === action.payload),
    );
}

function* changeOrganization() {
    yield takeLatest(globalActions.global.changeOrganization.toString(), changeOrganizationSaga);
}

function* listOrganizationsRequest() {
    try {
        const organizations: IOrganization[] = (yield call({ context: api, fn: api.list })) as IOrganization[];
        yield put(organizationsActions.organization.list.success(organizations));
    } catch {
        yield put(
            globalActions.global.notifications.add({
                message: 'Невозможно получить список организаций.',
                type: 'error',
            }),
        );
        yield put(organizationsActions.organization.list.fail());
    }
}

function* listOrganizations() {
    yield takeLatest(organizationsActions.organization.list.send.toString(), listOrganizationsRequest);
}

function* deleteOrganizationRequest(action: Action<number>) {
    try {
        yield call([api, 'delete'], action.payload);
        yield put(organizationsActions.organization.delete.success(action.payload));
    } catch {
        yield put(
            globalActions.global.notifications.add({
                message: 'Невозможно удалить организацию.',
                type: 'error',
            }),
        );
    }
}

function* deleteOrganization() {
    yield takeLatest(organizationsActions.organization.delete.send.toString(), deleteOrganizationRequest);
}

export default function* rootSaga() {
    yield all([listOrganizations(), deleteOrganization(), changeOrganization()]);
}

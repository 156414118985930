/** @author Artem Bakulin <dekkyartem@gmail.com> */

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
    page: {
        padding: '2% 4% 2% 3%',
        position: 'relative',
    },
    analyticSection: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
    },
    logo: {
        position: 'absolute',
        top: '9mm',
        right: '2mm',
        width: '8mm',
        height: '8mm',
    },
    title: {
        textTransform: 'uppercase',
        fontSize: '4mm',
        fontWeight: 300,
        flex: 0,
        marginBottom: '6mm',
    },
    content: {
        height: '100%',
    }
}));

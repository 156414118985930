/**
 * Общие селекторы, которые используются на большом кол-ве страниц.
 *
 * @author Artem Bakulin
 */
import { IAppState } from '../reducers';

export const calendarSelector = (state: IAppState) => state.analyticsCalendar;
export const selectedOrganizationSelector = (state: IAppState) => state.global.selectedOrganization;
export const isSmartLookSelector = (state: IAppState) => state.global.isSmartLook;
export const userSelector = (state: IAppState) => state.global.user;
export const selectOrganizationById = (id: number) =>
    (state: IAppState) =>
        state.global.user?.organizations.find((organization) => organization.id === id);

import { Permission } from 'accesscontrol';
import React from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router-dom';

import { getHomeUrl, UserRolesEnum } from '../../acl';

interface IProps extends RouteProps {
    /* tslint:disable-next-line:no-any */
    component?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
    /* tslint:disable-next-line:no-null-undefined-union no-any */
    render?(props: RouteComponentProps<any>): React.ReactNode;
    permission: Permission;
    onDeny?(): void;
}

const renderComponent = (
    permission: Permission,
    /* tslint:disable-next-line:no-any */
    Component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>,
    /* tslint:disable-next-line:no-any */
) => (props: RouteComponentProps<any>) =>
    permission.granted ? (
        <Component {...props} />
    ) : (
        <Redirect to={{ pathname: getHomeUrl(permission.roles as UserRolesEnum[]), state: { from: props.location } }} />
    );

/* tslint:disable-next-line:no-null-undefined-union no-any */
const renderFunc = (permission: Permission, render: (props: RouteComponentProps<any>) => React.ReactNode) => (
    /* tslint:disable-next-line:no-any */
    props: RouteComponentProps<any>,
) =>
    permission.granted ? (
        render(props)
    ) : (
        <Redirect to={{ pathname: getHomeUrl(permission.roles as UserRolesEnum[]), state: { from: props.location } }} />
    );

const PrivateRoute = ({ permission, onDeny, component, render, ...rest }: IProps) => {
    if (typeof onDeny === 'function') {
        onDeny();
    }

    if (component !== undefined) {
        return <Route {...rest} render={renderComponent(permission, component)} />;
    }

    if (render !== undefined) {
        return <Route {...rest} render={renderFunc(permission, render)} />;
    }

    return null;
};

export default PrivateRoute;

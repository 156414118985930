/**
 * Reducers Actions для аналитического календаря (выбор периодов дат для аналитических страниц)
 *
 * @author Artem Bakulin
 */

import { Action, createActions } from 'redux-actions';

import {
    CurrentPeriodPresetsTypes,
    IDatesPeriod,
    LastPeriodTypeEnum
} from '../types/date.periods';

interface IAnalyticsCalendarActions {
    analyticsCalendar: {
        changeCurrentPeriodType(type: CurrentPeriodPresetsTypes): Action<CurrentPeriodPresetsTypes>;
        changeLastPeriodType(type: LastPeriodTypeEnum): Action<LastPeriodTypeEnum>;
        setCustomCurrentPeriod(period: IDatesPeriod): Action<IDatesPeriod>;
        setCustomLastPeriod(period: IDatesPeriod): Action<IDatesPeriod>;
        toggleComparePeriod(compareToggle: boolean): Action<boolean>;
    };
}

export const analyticsCalendarActions: IAnalyticsCalendarActions = createActions({
    analyticsCalendar: {
        changeCurrentPeriodType: (type: CurrentPeriodPresetsTypes) => type,
        changeLastPeriodType: (type: LastPeriodTypeEnum) => type,
        setCustomCurrentPeriod: (period: IDatesPeriod) => period,
        setCustomLastPeriod: (period: IDatesPeriod) => period,
        toggleComparePeriod: (compareToggle: boolean) => compareToggle
    },
    // tslint:disable-next-line:no-any
}) as any;

/**
 * Reducers для страницы "Частота" организации
 *
 * @author Artem Bakulin
 */

import { Action, handleActions } from 'redux-actions';

import { analyticsPagesActions } from '../actions/analytics.pages';

export interface IFrequencyPageResult {
    charts: {
        byFrequency: {
            data: Array<{
                period: [string, string];
                '1': number;
                '2': number;
                '3': number;
                '> 3': number;
            }>;
        };
        byRegions: {
            data: Array<{
                period: [string, string];
                msk: number;
                others: number;
            }>;
        };
        byFrequencyVisits: {
            data: Array<{
                period: [string, string];
                '1': number;
                '2': number;
                '3': number;
                '> 3': number;
            }>;
        };
        byRegionsVisits: {
            data: Array<{
                period: [string, string];
                msk: number;
                others: number;
            }>;
        };
    };
}

export interface IFrequencyPageState {
    inProgress: boolean;
    results: {
        [hash: string]: IFrequencyPageResult | undefined;
    };
    error?: Error;
}

const initialState: IFrequencyPageState = {
    inProgress: false,
    results: {},
};

/**
 * Reducers для страницы "Частота" организации
 */
// tslint:disable-next-line:no-any
export const frequencyPageReducer = handleActions<IFrequencyPageState, any>(
    {
        [analyticsPagesActions.frequency.send.toString()]: (state: IFrequencyPageState) => ({
            ...state,
            inProgress: true,
        }),
        [analyticsPagesActions.frequency.success.toString()]: (
            state: IFrequencyPageState,
            action: Action<{ hashString: string; data: IFrequencyPageResult }>,
        ) => ({
            ...state,
            results:
                state.results[action.payload.hashString] !== undefined
                    ? state.results
                    : {
                          ...state.results,
                          [action.payload.hashString]: action.payload.data,
                      },
            inProgress: false,
            error: undefined,
        }),
        [analyticsPagesActions.frequency.fail.toString()]: (state: IFrequencyPageState, action: Action<Error>) => ({
            ...state,
            inProgress: false,
            error: action.payload,
        }),
    },
    initialState,
);

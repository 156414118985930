/** @author Artem Bakulin <dekkyartem@gmail.com> */

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
    footer: {
        position: 'absolute',
        width: '3%',
        bottom: '2%',
        right: 0,
    },
    pageNumber: {
        textAlign: 'center',
        fontSize: '3mm',
        fontWeight: 700,
    },
    copy: {
        fontSize: '2mm',
        transform: 'rotateZ(-90deg)',
        opacity: 0.5,
        left: '-6mm',
        top: '-14mm',
        whiteSpace: 'nowrap',
        position: 'absolute',
    }
}));

/**
 * API для работы с организациями системы.
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import { Query } from 'material-table';

import { IRemoteDataTableResult } from '../components/DataTable';
import { IOrganization, IStopListRecord, IUserOrganization } from '../types/organizations';

import { BaseApi, parseNullToEmpty, stringifyWithoutEmpty } from './abstract.api';

export interface IImportCsvStoplistForm {
    type: 'all' | 'employee' | 'commercial';
    file: File | string;
    rewrite: boolean;
}

export interface IImportBatchStoplistForm {
    type: 'all' | 'employee' | 'commercial';
    items: string;
    rewrite: boolean;
}

/**
 * API для работы с организациями системы
 */
export class OrganizationsApi extends BaseApi {
    public constructor() {
        super('/organizations');
    }

    /**
     * Возвращает список организаций
     */
    public async list(): Promise<IOrganization[]> {
        return (await this.axios.get<IOrganization[]>('', { transformResponse: [parseNullToEmpty()] })).data;
    }

    /**
     * Создает новую или обновляет уже сущетсвующую организацию
     *
     * @param organization Данные о новой или измененной организации
     */
    public async createOrUpdate(organization: Partial<IOrganization>): Promise<IOrganization> {
        const method = organization.id !== undefined ? 'PUT' : 'POST';
        const response = await this.axios.request<IOrganization>({
            url: '',
            method,
            transformRequest: [stringifyWithoutEmpty()],
            transformResponse: [parseNullToEmpty()],
            headers: { 'Content-Type': 'application/json' },
            data: organization,
        });

        return response.data;
    }

    /**
     * Обновляет настройки организации
     *
     * @param organization Организация для которой необходимо обновить настройки
     * @param settings Новые настройки организации
     */
    public async updateSettings(organization: IOrganization, settings: Partial<IOrganization>): Promise<IOrganization> {
        const response = await this.axios.request<IOrganization>({
            url: `/${organization.id}/settings`,
            transformRequest: [stringifyWithoutEmpty()],
            transformResponse: [parseNullToEmpty()],
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: settings,
        });

        return response.data;
    }

    /**
     * Удаляет организацию из системы.
     *
     * @param organizationId Уникальный id организации в системе
     */
    public async delete(organizationId: number): Promise<void> {
        await this.axios.delete(`/${organizationId}`);
    }

    /**
     * Возвращает стоплист организации
     *
     * @param organization Организация, для которой необходимо вернуть стоплист
     * @param query Запрос с фильтрами и пагинацией
     */
    public async stopList(
        organization: IUserOrganization,
        query: Partial<Query<IStopListRecord>> & { page: number; pageSize: number },
    ): Promise<IRemoteDataTableResult<IStopListRecord>> {
        const url = `/${organization.id}/stop-list`;
        const params = {
            offset: query.page * query.pageSize,
            limit: query.pageSize,
            orderBy:
                query.orderBy !== undefined
                    ? [this.getGroupBy(query.orderBy.field as string, query.orderDirection)]
                    : undefined,
            search: query.search,
        };

        const response = await this.axios.request<IStopListRecord[]>({
            url,
            params,
        });
        const totalCountHeader = (response.headers as { [header: string]: string })['x-total-count'];

        return {
            data: response.data,
            page: query.page,
            totalCount: parseInt(totalCountHeader, 10),
        };
    }

    /**
     * Удаляет записи из стоп-листа организации
     */
    public async deleteFromStopList(organization: IUserOrganization, plates: string[]): Promise<void> {
        const url = `/${organization.id}/stop-list`;

        await this.axios.delete(url, { data: { plates } });
    }

    /**
     * Загружает на сервер csv файл со стоп-листом для импорта нового списка
     */
    public async importCsvStopList(organization: IUserOrganization, values: IImportCsvStoplistForm): Promise<number> {
        const url = `/${organization.id}/stop-list/import`;
        const formData = new FormData();

        if (values.type !== 'all') {
            formData.set('type', values.type);
        }

        formData.set('rewrite', `${values.rewrite}`);
        formData.append('file', values.file);

        const insertedCountResponse = await this.axios.post<number>(url, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });

        return insertedCountResponse.data;
    }

    /**
     * Импортирует стоп-лист для организации
     */
    public async importBatchStopList(
        organization: IUserOrganization,
        values: IImportBatchStoplistForm,
    ): Promise<number> {
        const url = `/${organization.id}/stop-list/batch`;
        const data = {
            type: values.type !== 'all' ? values.type : undefined,
            rewrite: values.rewrite,
            items: values.items.split(/\r|\n/).map((itemString: string) => ({
                plate: itemString.split(/\s|\t/)[0],
                fio:
                    itemString.split(/\s|\t/).length > 1
                        ? itemString
                            .split(/\s|\t/)
                            .slice(1)
                            .join(' ')
                        : undefined,
            })),
        };

        const insertedCountResponse = await this.axios.post<number>(url, data);

        return insertedCountResponse.data;
    }
}

export default new OrganizationsApi();

/**
 * Redux Actions для работы с когортами
 *
 * @author Artem Bakulin
 */

import { Action, createActions } from 'redux-actions';

import { ICampaignCohort } from '../types/organizations';

interface ICohortsActions {
    cohort: {
        list: {
            fail(): Action<undefined>;
            send(organizationId: number): Action<number>;
            success(cohorts: ICampaignCohort[]): Action<ICampaignCohort[]>;
        };
        get: {
            fail(): Action<undefined>;
            send(cohortId: number): Action<number>;
            success(cohort: ICampaignCohort): Action<ICampaignCohort>;
        };
        delete: {
            send(organizationId: number, cohortId: number): Action<{ id: number, organizationId: number }>;
            success(cohortId: number): Action<number>;
        };
    };
}

export const cohortsActions: ICohortsActions = createActions({
    cohort: {
        list: {
            send: (organizationId: number) => organizationId,
            success: (cohorts: ICampaignCohort[]) => cohorts,
            fail: undefined,
        },
        get: {
            send: (cohortId: number) => cohortId,
            success: (cohort: ICampaignCohort) => cohort,
            fail: undefined,
        },
        delete: {
            send: (organizationId, cohortId: number) => ({ id: cohortId, organizationId }),
            success: (cohortId: number) => cohortId,
        },
    },
}) as unknown as ICohortsActions;

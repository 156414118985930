import { createStyles } from '@material-ui/core';

export const styles = createStyles({
    card: {
        height: '100%',
    },
    chartGrid: {
        marginBottom: 60,
    },
    chartTitle: {
        marginBottom: 32,
        display: 'flex',
        alignItems: 'center',
    },
    chartExport: {
        marginLeft: 16,
    },
    chartsWrapper: {
        marginTop: 60,
    },
    chartWrapper: {
        width: '100%',
        height: 500,
    },
    filter: {
        minWidth: 120,
    },
    filterAgePrice: {
        minWidth: 200,
        marginRight: 16,
    },
    filterPrice: {
        display: 'flex', 
        justifyContent: 'flex-end', 
        width: '100%', 
        marginBottom: 5
    },
    filterPriceVisitsType: {
        minWidth: 200,
        marginRight: 16,
    },
    filterPriceDetailingType: {
        minWidth: 300,
    }
});

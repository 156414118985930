import { Button, Grid, Typography, WithStyles } from '@material-ui/core';
import { Delete, FileCopy, List, SaveAlt } from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';
import { Query } from 'material-table';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import api from '../../api/organizations.api';
import Alert from '../../components/Alert';
import DataTable from '../../components/DataTable';
import PageHeader from '../../components/PageHeader';
import TableCellImage from '../../components/TableCellImage';
import Layout from '../../containers/Layout';
import ImportStoplistBatchForm from '../../forms/ImportStoplistForm/ImportStoplistBatchForm';
import ImportStoplistCsvForm from '../../forms/ImportStoplistForm/ImportStoplistCsvForm';
import { PolyglotSingleton } from '../../lib/services/translation';
import { generateCarImageUrl } from '../../lib/utils/urls';
import ImportStoplistModal from '../../modals/ImportStoplistCsvModal';
import { IStopListRecord, IUserOrganization, StopListTypeLabel } from '../../types/organizations';
import { IUser } from '../../types/users';

import { styles } from './styles';

interface IProps extends RouteComponentProps<{ id: string | undefined }>, WithStyles<typeof styles> {
    user: IUser;
}

const polyglot = PolyglotSingleton.getInstance();

// tslint:disable-next-line: no-any
const dataFetch = (organization: IUserOrganization) => (query: Query<IStopListRecord>) =>
    api.stopList(organization, query);
const imgRender = (rowData: IStopListRecord) =>
    rowData.image !== undefined ? <TableCellImage image={generateCarImageUrl(rowData.image)} /> : null;
const dataRender = (column: 'lastEventTime' | 'createdAt' | 'updatedAt') => (rowData: IStopListRecord) =>
    rowData[column] !== undefined ? moment(rowData[column]).format('DD MMM YYYY HH:mm') : null;
const vehicleRender = (rowData: IStopListRecord) =>
    rowData.vehicle !== null && rowData.vehicle.generation !== undefined ? rowData.vehicle.generation.name : null;
const typeRender = (rowData: IStopListRecord) => StopListTypeLabel[rowData.type];
const initialDelState = { open: false, items: [] };
const initialImportModalState: { open: boolean; form: 'csv' | 'list' } = { open: false, form: 'csv' };

const OrganizationsStoplists = ({ user, match, classes }: IProps) => {
    const tableRef = useRef<typeof DataTable>();
    const [delState, setDelState] = useState<{ open: boolean; items: IStopListRecord[] }>(initialDelState);
    const [importModalState, setImportModalState] = useState(initialImportModalState);
    const [error, setError] = useState<string | undefined>(undefined);

    if (match.params.id === undefined) {
        return <Redirect to={`/stoplist/${user.organizations[0].id}`} />;
    }

    const organizationId = parseInt(match.params.id, 10);
    const selectedOrganization = user.organizations.find(org => org.id === organizationId);

    if (selectedOrganization === undefined) {
        return <Redirect to={`/stoplist/${user.organizations[0].id}`} />;
    }

    const delAction = (items: IStopListRecord[]) => async () => {
        await api.deleteFromStopList(selectedOrganization, items.map((item: IStopListRecord) => item.plate));
        setDelState(initialDelState);
        if (tableRef.current !== undefined) {
            // tslint:disable-next-line:no-any no-unsafe-any
            (tableRef.current as any).onQueryChange();
        }
    };
    const cancelDelAction = () => {
        setDelState(initialDelState);
    };

    const openImportModal = (form: typeof initialImportModalState.form) => () => {
        setError(undefined);
        setImportModalState({ open: true, form });
    };
    const closeImportModal = () => {
        setImportModalState({ ...importModalState, open: false });
    };
    const submitImportAction = () => {
        if (tableRef.current !== undefined) {
            // tslint:disable-next-line:no-any no-unsafe-any
            (tableRef.current as any).onQueryChange();
        }
        closeImportModal();
    };
    const submitError = (submitError: string) => {
        setError(submitError);
    }

    return (
        <Layout>
            <PageHeader>
                <Grid container spacing={3} justify="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h1">
                            {polyglot.t('stoplist.header')} ({selectedOrganization.name})
                        </Typography>
                    </Grid>
                    <Grid item className={classes.imports}>
                        <Button variant="outlined" color="primary" onClick={openImportModal('csv')}>
                            <FileCopy />
                            &nbsp;{polyglot.t('stoplist.fromCsv.button')}
                        </Button>
                        <Button variant="outlined" color="primary" onClick={openImportModal('list')}>
                            <List />
                            &nbsp;{polyglot.t('stoplist.fromBuffer.button')}
                        </Button>
                        <Button
                            variant="outlined"
                            color="secondary"
                            href={`/api/organizations/${selectedOrganization.id}/stop-list/export`}
                            target="_blank"
                        >
                            <SaveAlt />
                            &nbsp;{polyglot.t('stoplist.toCsv.button')}
                        </Button>
                    </Grid>
                </Grid>
            </PageHeader>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <DataTable
                        title=""
                        tableRef={tableRef}
                        columns={[
                            {
                                title: polyglot.t('stoplist.columns.photo'),
                                field: 'image' as 'image',
                                render: imgRender,
                                sorting: false,
                            },
                            { title: polyglot.t('stoplist.columns.plate'), field: 'plate' as 'plate' },
                            {
                                title: polyglot.t('stoplist.columns.vehicle'),
                                // tslint:disable-next-line: no-any
                                field: 'vehicle.generation.name' as any,
                                render: vehicleRender,
                                sorting: false,
                            },
                            { title: polyglot.t('stoplist.columns.type'), field: 'type', render: typeRender },
                            { title: polyglot.t('stoplist.columns.person'), field: 'fio', sorting: false },
                            {
                                title: polyglot.t('stoplist.columns.lastEvent'),
                                field: 'lastEventTime',
                                render: dataRender('lastEventTime'),
                                sorting: false,
                            },
                            {
                                title: polyglot.t('stoplist.columns.createdAt'),
                                field: 'createdAt',
                                render: dataRender('createdAt'),
                            },
                        ]}
                        data={dataFetch(selectedOrganization)}
                        options={{
                            pageSize: 20,
                            pageSizeOptions: [20, 50, 100],
                            grouping: false,
                            selection: true,
                            debounceInterval: 500,
                        }}
                        actions={[
                            {
                                tooltip: polyglot.t('common.hintDelete'),
                                // tslint:disable-next-line:no-any
                                icon: (() => <Delete />) as any,
                                onClick: (_, data: IStopListRecord | IStopListRecord[]) => {
                                    setDelState({ open: true, items: Array.isArray(data) ? data : [data] });
                                },
                            },
                        ]}
                    />
                </Grid>
            </Grid>

            <ImportStoplistModal
                open={importModalState.open}
                organization={selectedOrganization}
                onClose={closeImportModal}
                form={
                    importModalState.form === 'csv' ? (
                        <ImportStoplistCsvForm
                            organization={selectedOrganization}
                            onCancel={closeImportModal}
                            onSubmit={submitImportAction}
                            onError={submitError}
                        />
                    ) : (
                        <ImportStoplistBatchForm
                            organization={selectedOrganization}
                            onCancel={closeImportModal}
                            onSubmit={submitImportAction}
                            onError={submitError}
                        />
                    )
                }
                helpText={
                    importModalState.form === 'csv'
                        ? polyglot.t('stoplist.fromCsv.hint')
                        : polyglot.t('stoplist.fromBuffer.hint')
                }
                error={error}
            />

            <Alert
                open={delState.open}
                title={polyglot.t('common.buttons.delete', delState.items.length)}
                onAgree={delAction(delState.items)}
                onCancel={cancelDelAction}
                agree={polyglot.t('common.buttons.delete')}
            />
        </Layout>
    );
};

export default withStyles(styles)(OrganizationsStoplists);

import { Omit, WithStyles, withStyles } from '@material-ui/core';
import { ToggleButton as MuiToggleButton } from '@material-ui/lab';
// tslint:disable-next-line:no-submodule-imports
import { ToggleButtonProps } from '@material-ui/lab/ToggleButton';
import React from 'react';

import { styles } from './styles';

interface IProps extends WithStyles<typeof styles>, Omit<ToggleButtonProps, 'classes'> {}

const ToggleButton = ({ classes, ...rest }: IProps) => <MuiToggleButton {...rest} classes={{ root: classes.root }} />;

export default withStyles(styles)(ToggleButton);

/**
 * Интерфейсы для описания организаций
 *
 * @author Artem Bakulin
 */

import { IVehicle } from './vehicles';

/* tslint:disable:member-ordering */

export interface IOrganizationSettingsAgeGroup {
    /** Название возрастной группы */
    name: string;
    /** Массив возрастов. которые входят в группу */
    groups: number[];
}

export interface IOrganizationSettingsPriceGroup {
    /** Название ценовой группы */
    name: string;
    /** Мин. / макс. цена автомобилей в группе */
    range: [number, number];
}

export interface IOrganizationSettings {
    /** Рабочее время организации */
    workTime: {
        start: string | null;
        end: string | null;
    };
    /** Учитывать среди клиентов такси или ент */
    countTaxi: boolean;
    /** Учитывать среди клиентов автобусы или нет */
    countBus: boolean;
    /** Минимальное время визита, чтобы считатье его клиентом */
    minVisitTime: number;
    /** Минимальное время визита, чтобы считать его длительным */
    minLongVisit: number;
    /** Настройки возрастных групп организации */
    ageGroups?: IOrganizationSettingsAgeGroup[];
    /** Настройки ценовых групп организации */
    priceGroups?: IOrganizationSettingsPriceGroup[];
}

export interface IUserOrganization {
    /** Уникальные id пользователя. Primary key in DB. */
    id: number;
    /** Название организации */
    name: string;
    /** Тип организации */
    type: 'retail';
    /** Настройки организации */
    settings: IOrganizationSettings;
}

export interface IOrganization extends IUserOrganization {
    /** Описание организации */
    description: string;
    /** Email организации */
    email: string;
    /** Сайт организации */
    site: string;
    /** Телефон организации */
    phone: string;
    /** Контактное лицо */
    contactPerson: string;
}

/**
 * Названия типов записей из стоп-листа
 */
export const StopListTypeLabel = {
    employee: 'Сотрудник',
    commercial: 'Коммерческий транспорт',
};

/**
 * Запись из стоп-листа организации
 */
export interface IStopListRecord {
    /** Гос. номер автомобиля */
    plate: string;
    /** Изображение последнего события с этим гос. номером */
    image?: string;
    /** Дата последнего зарегистрированного события с этим номером */
    lastEventTime?: string;
    /** Описание автомобиля с этим гос. номером */
    vehicle: IVehicle | null;
    /** ФИО сотрудника */
    fio: string | null;
    /** Тип записи в стоп-листе */
    type: 'employee' | 'commercial';
    /** Дата создания записи в БД */
    createdAt: string;
    /** Дата последнего редактирования в БД */
    updatedAt: string;
}

export interface ICampaignCohortAnalytics {
    /** Кол-во визитов в когорте */
    visitors: number;
    /** Кол-во визитов в сравниваемом периоде */
    comparisonVisitors: number;
    /** Кол-во авто, которые уже посещали авто */
    previouslyArrived: number;
    /** Кол-во авто, которые уже посещали авто в сравниваемом периоде */
    comparisonPreviouslyArrived: number;
    /** Прирость авто, которые уже посещали ТЦ */
    previouslyArrivedGrowth: number;
    /** Прирост траффика */
    trafficGrowth: number;
    /** Кол-во новых посетителей в когорте */
    newVisitors: number;
    /** Кол-во новых посетителей в сравниваемом периоде */
    comparisonNewVisitors: number;
    /** Прирост новых посетителей */
    newVisitorsGrowth: number;

    /** Процент новых посетителей, которые вернулись в последующие 30 дней */
    return30: number;
    /** Процент новых посетителей, которые вернулись в последующие 90 дней */
    return90: number;
    /** Процент новых посетителей, которые вернулись в последующие 180 дней */
    return180: number;
    /** Процент новых посетителей, которые вернулись в последующие 360 дней */
    return360: number;
    /** Частота посещений новых посетителей в период проведения рекламной кампании */
    frequency: number;
    /** Частота посещений новых посетителей в последующие 30 дней */
    frequency30: number;
    /** Частота посещений новых посетителей в последующие 90 дней */
    frequency90: number;
    /** Частота посещений новых посетителей в последующие 180 дней */
    frequency180: number;
    /** Частота посещений новых посетителей в последующие 360 дней */
    frequency360: number;
}

/**
 * Когорта рекламной кампании организации
 */
export interface ICampaignCohort {
    /** Уникальный id когорты */
    id: number;
    /** Название рекламной кампании */
    name: string;
    /** Дата начала когорты */
    dateFrom: string;
    /** Дата окончания когорты */
    dateTo: string;
    /** Дата начала аналогичного сравниваемого периода */
    comparisonDate: string;
    /** Id организации, которой принадлежит когорта */
    organizationId: number;
    /** Аналитика по когорте */
    lastAnalytics?: ICampaignCohortAnalytics;
    /** Актуальный кэш или нет */
    isActualCache: boolean;
}

/**
 * Раздел "Кампании" с когортным анализом.
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import {
    CircularProgress,
    Grid,
    IconButton,
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from '@material-ui/core';
import { Add, Delete, Edit } from '@material-ui/icons';
import { add, differenceInDays, format, intervalToDuration, parseISO } from 'date-fns';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { cohortsActions } from '../../actions/cohorts';
import { CohortsApi } from '../../api/cohort.api';
import PageFab from '../../components/PageFab';
import PageHeader from '../../components/PageHeader';
import Layout from '../../containers/Layout';
import { PolyglotSingleton } from '../../lib/services/translation';
import { formatValue } from '../../lib/utils/strings';
import { CampaignCohortModal } from '../../modals/CampaignCohortModal';
import { IAppState } from '../../reducers';
import { selectedOrganizationSelector } from '../../selectors/common.selectors';
import { ICampaignCohort, ICampaignCohortAnalytics, IUserOrganization } from '../../types/organizations';

import { useStyles } from './styles';

const polyglot = PolyglotSingleton.getInstance();

const cohortPeriod = ({ dateFrom, dateTo }: ICampaignCohort): string =>
    `${format(parseISO(dateFrom), 'dd.MM.yyyy')} - ${format(parseISO(dateTo), 'dd.MM.yyyy')}`;

const cohortComparisonPeriod = ({dateFrom, dateTo, comparisonDate}: ICampaignCohort): string => {
    const beginDate = parseISO(comparisonDate);
    const endDate = add(beginDate, intervalToDuration({ start: parseISO(dateFrom), end: parseISO(dateTo) }));

    return `${format(beginDate, 'dd.MM.yyyy')} - ${format(endDate, 'dd.MM.yyyy')}`;
}

const daysBetweenNow = (date: string): number => differenceInDays(new Date(), parseISO(date));

const showResult = (
    cohort: ICampaignCohort,
    key: 'return' | 'frequency',
    period: 30 | 90 | 180 | 360
): string => {
    if (daysBetweenNow(cohort.dateTo) < period) {
        return polyglot.t('cohorts.dataCollection');
    }

    const analyticsKey = `${key}${period}`;
    const data = cohort.lastAnalytics !== undefined ? cohort.lastAnalytics[analyticsKey as keyof ICampaignCohortAnalytics] : undefined;

    return data !== undefined ? formatValue(key === 'return' ? 'percent' : 'float')(data) : '';
}

export const Campaigns = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isOpenModal, setOpenModal] = useState(false);
    const [selectedCohort, setSelectedCohort] = useState<ICampaignCohort | undefined>(undefined);
    const organization = useSelector(selectedOrganizationSelector);
    const inProgress = useSelector((state: IAppState) => state.cohorts.inProgress);
    const cohorts = useSelector((state: IAppState) => state.cohorts.items);
    const organizationId = (organization as IUserOrganization).id;

    const api = useMemo(() => new CohortsApi(organizationId), [organizationId]);
    const openModal = useCallback(() => { setOpenModal(true); }, []);
    const closeModal = useCallback(() => {
        setSelectedCohort(undefined);
        setOpenModal(false);
    }, []);
    const editCohort = useCallback((cohort: ICampaignCohort) => () => {
        setSelectedCohort(cohort);
        setOpenModal(true);
    }, []);
    const deleteCohort = useCallback((cohort: ICampaignCohort) => () => {
        dispatch(cohortsActions.cohort.delete.send(organizationId, cohort.id))
    }, [dispatch, organizationId]);

    useEffect(() => {
        dispatch(cohortsActions.cohort.list.send(organizationId));
    }, [dispatch, organizationId]);

    return (
        <Layout>
            <PageHeader>
                <Typography variant="h1">{polyglot.t('cohorts.title')}</Typography>
            </PageHeader>
            {inProgress ? <LinearProgress /> : null}
            {Array.isArray(cohorts) ? (
                <Table>
                    <TableHead className={classes.thead}>
                        <TableRow>
                            <TableCell colSpan={9} className={classes.theadCell} />
                            <TableCell colSpan={4} className={classes.theadCell}>{polyglot.t("cohorts.columns.returnCars")}</TableCell>
                            <TableCell colSpan={5} className={classes.theadCell}>{polyglot.t("cohorts.columns.visitsFrequency")}</TableCell>
                            <TableCell className={classes.theadCell} />
                        </TableRow>
                        <TableRow>
                            <TableCell rowSpan={2} className={classes.theadCell}>№</TableCell>
                            <TableCell rowSpan={2} className={classes.theadCell}>{polyglot.t("cohorts.columns.name")}</TableCell>
                            <TableCell rowSpan={2} className={classes.theadCell}>{polyglot.t("cohorts.columns.analyzedPeriod")}</TableCell>
                            <TableCell rowSpan={2} className={classes.theadCell}>{polyglot.t("cohorts.columns.comparedPeriod")}</TableCell>
                            <TableCell rowSpan={2} className={classes.theadCell}>{polyglot.t("cohorts.columns.previouslyCars")}</TableCell>
                            <TableCell rowSpan={2} className={classes.theadCell}>{polyglot.t("cohorts.columns.previouslyCarsIncrease")}</TableCell>
                            <TableCell rowSpan={2} className={classes.theadCell}>{polyglot.t("cohorts.columns.newCars")}</TableCell>
                            <TableCell rowSpan={2} className={classes.theadCell}>{polyglot.t("cohorts.columns.newCarsIncrease")}</TableCell>
                            <TableCell rowSpan={2} className={classes.theadCell}>{polyglot.t("cohorts.columns.shareCarsIncrease")}</TableCell>
                            <TableCell className={classes.theadCell}>{polyglot.t("cohorts.columns.in30days")}</TableCell>
                            <TableCell className={classes.theadCell}>{polyglot.t("cohorts.columns.in90days")}</TableCell>
                            <TableCell className={classes.theadCell}>{polyglot.t("cohorts.columns.in180days")}</TableCell>
                            <TableCell className={classes.theadCell}>{polyglot.t("cohorts.columns.in360days")}</TableCell>
                            <TableCell className={classes.theadCell}>{polyglot.t("cohorts.columns.duringCampaign")}</TableCell>
                            <TableCell className={classes.theadCell}>{polyglot.t("cohorts.columns.plus30days")}</TableCell>
                            <TableCell className={classes.theadCell}>{polyglot.t("cohorts.columns.plus90days")}</TableCell>
                            <TableCell className={classes.theadCell}>{polyglot.t("cohorts.columns.plus180days")}</TableCell>
                            <TableCell className={classes.theadCell}>{polyglot.t("cohorts.columns.plus360days")}</TableCell>
                            <TableCell className={classes.theadCell} rowSpan={2}/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {cohorts.map((cohort, index) => (
                            <TableRow key={cohort.id}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{cohort.name}&nbsp;{!cohort.isActualCache ? <CircularProgress size={16} /> : null}</TableCell>
                                <TableCell>{cohortPeriod(cohort)}</TableCell>
                                <TableCell>{cohortComparisonPeriod(cohort)}</TableCell>
                                <TableCell>{formatValue('absolute')(cohort.lastAnalytics?.previouslyArrived)}</TableCell>
                                <TableCell>{formatValue('percent')(cohort.lastAnalytics?.previouslyArrivedGrowth)}</TableCell>
                                <TableCell>{formatValue('absolute')(cohort.lastAnalytics?.newVisitors)}</TableCell>
                                <TableCell>{formatValue('percent')(cohort.lastAnalytics?.trafficGrowth)}</TableCell>
                                <TableCell>{formatValue('percent')(cohort.lastAnalytics?.newVisitorsGrowth)}</TableCell>
                                <TableCell>{showResult(cohort, 'return', 30)}</TableCell>
                                <TableCell>{showResult(cohort, 'return', 90)}</TableCell>
                                <TableCell>{showResult(cohort, 'return', 180)}</TableCell>
                                <TableCell>{showResult(cohort, 'return', 360)}</TableCell>
                                <TableCell>{formatValue('float')(cohort.lastAnalytics?.frequency)}</TableCell>
                                <TableCell>{showResult(cohort, 'frequency', 30)}</TableCell>
                                <TableCell>{showResult(cohort, 'frequency', 90)}</TableCell>
                                <TableCell>{showResult(cohort, 'frequency', 180)}</TableCell>
                                <TableCell>{showResult(cohort, 'frequency', 360)}</TableCell>
                                <TableCell>
                                    <Grid container wrap="nowrap" justify="center" alignItems="center">
                                        <IconButton color="primary" onClick={editCohort(cohort)}>
                                            <Edit />
                                        </IconButton>
                                        <IconButton color="secondary" onClick={deleteCohort(cohort)}>
                                            <Delete />
                                        </IconButton>
                                    </Grid>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            ) : null}
            <PageFab
                color="primary"
                aria-label={polyglot.t('cohorts.addNewCampaign')}
                onClick={openModal}
            >
                <Add />
            </PageFab>
            <CampaignCohortModal isOpen={isOpenModal} handleClose={closeModal} api={api} cohort={selectedCohort} />
        </Layout>
    );
};

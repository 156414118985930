/**
 * Фильтр по частоте.
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import React, { useCallback } from 'react';

import { useStyles } from './styles';

interface IProps {
    frequency: FrequencyFilter;
    onFrequencyChange(freq: FrequencyFilter): void;
}

export type FrequencyFilter = '3' | '10' | '15' | '20';

export const ChartFrequency = ({frequency, onFrequencyChange}: IProps) => {
    const classes = useStyles();
    const changeAnalyticsFrequency = useCallback((_, value: FrequencyFilter | null) => {
        onFrequencyChange(value !== null ? value : frequency);
    }, [onFrequencyChange, frequency]);

    return (
        <ToggleButtonGroup
            className={classes.granularityBtns}
            value={frequency}
            exclusive
            onChange={changeAnalyticsFrequency}
        >
            <ToggleButton value="3">{'> 3'}</ToggleButton>
            <ToggleButton value="10">{'> 10'}</ToggleButton>
            <ToggleButton value="15">{'> 15'}</ToggleButton>
            <ToggleButton value="20">{'> 20'}</ToggleButton>
        </ToggleButtonGroup>
    );
}

import { Button, Grid } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';

import api from '../../api/organizations.api';
import { PolyglotSingleton } from '../../lib/services/translation';
import { INotification } from '../../types/global';
import { IOrganization } from '../../types/organizations';

import Fields from './Fields';
import { schema } from './schema';

interface IProps {
    organization?: IOrganization;
    /* tslint:disable-next-line:no-null-undefined-union */
    actionsBtns?: React.ReactNode;
    create(organiztion: IOrganization): void;
    update(organiztion: IOrganization): void;
    notify(notification: INotification): void;
    onError?(): void;
    onSuccess?(): void;
}

const newOrganization: Partial<IOrganization> = {
    name: '',
    contactPerson: '',
    phone: '',
    site: '',
    email: '',
    description: '',
    type: 'retail',
};

const polyglot = PolyglotSingleton.getInstance();

class OrganizationForm extends React.PureComponent<IProps> {
    private readonly onSubmit = async (
        values: Partial<IOrganization>,
        { setSubmitting }: FormikHelpers<Partial<IOrganization>>,
    ) => {
        setSubmitting(true);
        const isNew = values.id === undefined;

        try {
            const organization = await api.createOrUpdate(values);

            values.id !== undefined ? this.props.update(organization) : this.props.create(organization);
            this.props.notify({
                type: 'success',
                message: isNew ? polyglot.t('organizations.successAdd') : polyglot.t('organizations.successEdit'),
            });

            if (typeof this.props.onSuccess === 'function') {
                this.props.onSuccess();
            }
        } catch {
            this.props.notify({
                type: 'error',
                message: isNew ? polyglot.t('organizations.errorAdd') : polyglot.t('organizations.errorEdit'),
            });

            if (typeof this.props.onError === 'function') {
                this.props.onError();
            }
        }

        setSubmitting(false);
    };

    public render() {
        const { organization, actionsBtns } = this.props;
        const initialValues = organization !== undefined ? { ...organization } : { ...newOrganization };

        return (
            <Formik initialValues={initialValues} onSubmit={this.onSubmit} validationSchema={schema}>
                <Form>
                    <Grid container spacing={3}>
                        <Fields />

                        {actionsBtns !== undefined ? (
                            actionsBtns
                        ) : (
                            <Grid item xs={12} container justify="center">
                                <Button type="submit" variant="outlined" color="secondary" size="large">
                                    {polyglot.t('common.buttons.create')}
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Form>
            </Formik>
        );
    }
}

export default OrganizationForm;

/**
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */
import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
    },
    granularityBtns: {
        marginBottom: theme.spacing(2),
    },
    percentBtns: {
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
    chartWrapper: {
        width: '100%',
    },
}));

/**
 * Квартальный отчет в PDF формате
 * 
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import api from '../../api/analytics.api';
import { Section } from '../../components/Pdf/Section';
import { PolyglotSingleton } from '../../lib/services/translation';
import { selectOrganizationById } from '../../selectors/common.selectors';
import { IQuarterSummaryData } from '../../types/analytics';
import { TermsPage } from './MonthSummary/TermsPage';
import { DurationPage } from './QuarterSummary/DurationPage';
import { FrequencyPage } from './QuarterSummary/FrequencyPage';
import { NewCarsPage } from './QuarterSummary/NewCarsPage';
import { SummaryPage } from './QuarterSummary/SummaryPage';
import { SummaryPage2 } from './QuarterSummary/SummaryPage2';
import { TopRegionsPage } from './QuarterSummary/TopRegionsPage';
import { VisitsByAgePage } from './QuarterSummary/VisitsByAgePage';
import { VisitsByPricePage } from './QuarterSummary/VisitsByPricePage';
import { VisitsByTypePage } from './QuarterSummary/VisitsByTypePage';

import { TitlePage } from './TitlePage';

interface IProps {
    organizationId: number;
    quarter: string;
}

const polyglot = PolyglotSingleton.getInstance();

const QuarterSummary = ({ organizationId, quarter }: IProps) => {
    const organization = useSelector(selectOrganizationById(organizationId));
    const [inProgress, setProgress] = useState(false);
    const [data, setData] = useState<IQuarterSummaryData>();

    useEffect(() => {
        // tslint:disable-next-line: no-floating-promises
        (async () => {
            setProgress(true);
            try {
                const summaryData = (await api.summaryData(organizationId, {
                    periodType: 'quarter',
                    quarter,
                })) as IQuarterSummaryData;
                setData(summaryData);
            } catch (error) {
                // tslint:disable-next-line: no-console
                console.log(error);
            }
            setProgress(false);
        })();
    }, [organizationId, quarter]);

    if (inProgress || data === undefined) {
        return null;
    }

    return (
        <>
            <TitlePage month={quarter} organization={organization} format={polyglot.t('common.dates.quarter')} />
            <Section month={quarter} organization={organization} page={2} name={polyglot.t('pdf.sections.summary')} format={polyglot.t('common.dates.quarter')}>
                <SummaryPage data={data} />
            </Section>
            <Section month={quarter} organization={organization} page={3} name={polyglot.t('pdf.sections.summary')} format={polyglot.t('common.dates.quarter')}>
                <SummaryPage2 data={data} />
            </Section>
            <Section month={quarter} organization={organization} page={4} name={polyglot.t('pdf.sections.newCars')} format={polyglot.t('common.dates.quarter')}>
                <NewCarsPage data={data} />
            </Section>
            <Section month={quarter} organization={organization} page={5} name={polyglot.t('pdf.sections.visitsByType')} format={polyglot.t('common.dates.quarter')}>
                <VisitsByTypePage data={data} />
            </Section>
            <Section month={quarter} organization={organization} page={6} name={polyglot.t('pdf.sections.visitsByPrice')} format={polyglot.t('common.dates.quarter')}>
                <VisitsByPricePage data={data} />
            </Section>
            <Section month={quarter} organization={organization} page={7} name={polyglot.t('pdf.sections.visitsByAge')} format={polyglot.t('common.dates.quarter')}>
                <VisitsByAgePage data={data} organization={organization} />
            </Section>
            <Section month={quarter} organization={organization} page={8} name={polyglot.t('pdf.sections.duration')} format={polyglot.t('common.dates.quarter')}>
                <DurationPage data={data} organization={organization} />
            </Section>
            <Section month={quarter} organization={organization} page={9} name={polyglot.t('pdf.sections.frequency')} format={polyglot.t('common.dates.quarter')}>
                <FrequencyPage data={data} organization={organization} />
            </Section>
            <Section month={quarter} organization={organization} page={10} name={polyglot.t('pdf.sections.topRegions')} format={polyglot.t('common.dates.quarter')}>
                <TopRegionsPage data={data} />
            </Section>
            <Section month={quarter} organization={organization} page={11} name={polyglot.t('pdf.sections.appendix1')} format={polyglot.t('common.dates.quarter')}>
                <TermsPage />
            </Section>
        </>
    );
}

export default QuarterSummary;
/**
 * Общие константы для компонентов PDF отчета.
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

export const colorsTheme = {
    positive: '#2ad089',
    negative: '#f4508d',
    positiveBg: 'rgba(42, 208, 137, 0.2)',
    negativeBg: 'rgba(244, 80, 141, 0.2)',
    text: '#121212',
};

export const chartjsTheme = {
    colors: {
        positive: colorsTheme.positive,
        negative: colorsTheme.negative,
        positiveBg: 'rgb(42, 208, 137, 0.2)',
        negativeBg: 'rgb(244, 80, 141, 0.2)',
        barBg: '#c6d8ee',
        transparent: 'rgb(0, 0, 0, 0)',
        black: '#121212',
    },
};

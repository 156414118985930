import { connect } from 'react-redux';

import { analyticsPagesActions } from '../actions/analytics.pages';
import Dashboard from '../pages/Dashboard';
import { IAppState } from '../reducers';
import { IDashboardResultPeriod } from '../reducers/dashboard.page';
import { analyticsResultSelector, pageErrorSelector, pageProgressSelector } from '../selectors/analytics.selectors';
import { calendarSelector } from '../selectors/common.selectors';
import { IAnalyticsPageResult } from '../types/global';

const mapStateToProps = (state: IAppState) => ({
    request: calendarSelector(state),
    result: analyticsResultSelector('dashboard')(state) as IAnalyticsPageResult<IDashboardResultPeriod>,
    organization: state.global.selectedOrganization,
    inProgress: pageProgressSelector('dashboard')(state),
    error: pageErrorSelector('dashboard')(state),
});

const mapDispatchToProps = {
    getData: analyticsPagesActions.dashboard.send,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Dashboard);

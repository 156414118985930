import { createStyles, Theme } from '@material-ui/core';
import { CHART_FIRST_SERIE_COLOR, CHART_SECOND_SERIE_COLOR } from '../Charts/DetailedApiChart/constants';

export const styles = (theme: Theme) =>
    createStyles({
        title: {
            marginBottom: theme.spacing(3),
        },
        periods: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            overflowX: 'auto',
            background: '#FFFFFF'
        },
        lastPeriodTitle: {
            opacity: 0.5,
        },
        currentPeriodValue: {
            display: 'flex',
            alignItems: 'center',
            color: CHART_SECOND_SERIE_COLOR,
            fontWeight: 'bold'
        },
        compareToPeriodValue: {
            marginTop: -4,
            fontWeight: 'bold',
            color: CHART_FIRST_SERIE_COLOR
        },
        calendarWrapper: {
            position: 'fixed',
            right: '0px',
            paddingLeft: '252px',
            overflow: 'hidden',
            top: '90px',
            zIndex: 1,
            width: '100%'
        },
        calendarButton: {
            background: '#FFFFFF',
            '&:hover': {
                backgroundColor: '#f7fcfe',
            },
        },
        calendarButtonWrapper: {
           display: 'flex',
            justifyContent: 'flex-end'
        }
    });

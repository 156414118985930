import {WithStyles, withStyles} from '@material-ui/core';
import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/fr';
import 'moment/locale/es';
import React, { useState, useEffect } from 'react';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import MomentLocaleUtils from 'react-day-picker/moment';

import { PolyglotSingleton } from '../../lib/services/translation';
import { isValidPeriod } from '../../lib/utils/dates';
import { IDatesPeriod } from '../../types/date.periods';
import { MIN_DATE_VNUKOVO } from '../AnalyticsCalendar';

import { styles } from './styles';
import Navbar from '../DatepickerNavbar';

interface IProps extends WithStyles<typeof styles> {
    currentPeriodDefault?: IDatesPeriod;
    lastPeriodDefault?: IDatesPeriod;
    selectablePeriod: 'current' | 'last';
    isCompareEnabled: boolean;
    onChange: (event: IAnalyticsCalenderDatepickerPeriods) => void;
}

export interface IAnalyticsCalenderDatepickerPeriods {
    currentPeriod?: IDatesPeriod,
    lastPeriod?: IDatesPeriod
}

const polyglot = PolyglotSingleton.getInstance();

const AnalyticsCalendarDatepicker = ({classes, currentPeriodDefault, lastPeriodDefault, selectablePeriod, isCompareEnabled, onChange}: IProps) => {
    const maxDate = moment()
        .subtract(1, 'day')
        .toDate();

    const disabledDays = {
        before: MIN_DATE_VNUKOVO,
        after: maxDate,
    };



    const [currentPeriod, setCurrentPeriod] = useState<Partial<IDatesPeriod>>();
    const [lastPeriod, setLastPeriod] = useState<Partial<IDatesPeriod>>();
    const [enteredTo, setEnteredTo] = useState<Date | undefined>();
    const [lastEnteredTo, setLastEnteredTo] = useState<Partial<IDatesPeriod>>();
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [selectingLastPeriod, setSelectingLastPeriod] = useState<boolean>(false);

    const clickDay = (day: Date) => {
        if (DayPicker.ModifiersUtils.dayMatchesModifier(day, disabledDays)) {
            return;
        }

        // Работаем с выбором анализируемого периода
        if (selectablePeriod === 'current') {
            if (!isEditing) {
                setIsEditing(true);
                setCurrentPeriod({ from: day });
            } else if (moment(day).isAfter(currentPeriod?.from) || moment(day).isSame(currentPeriod?.from)) {
                setCurrentPeriod({ ...currentPeriod, to: day });
                onChange({currentPeriod: {...currentPeriod, to: day}, lastPeriod} as IAnalyticsCalenderDatepickerPeriods);
                setIsEditing(false);
            }
            return;
        }

        // Работаем с выбором сравниваемого периода
        if (!isEditing) {
            setIsEditing(true);
            setLastPeriod({ from: day });
        } else if (moment(day).isAfter(lastPeriod?.from) || moment(day).isSame(lastPeriod?.from)) {
            setLastPeriod({ ...lastPeriod, to: day });
            onChange({currentPeriod, lastPeriod: {...lastPeriod, to: day}} as IAnalyticsCalenderDatepickerPeriods);
            setIsEditing(false);
        }
    };

    const enterDay = (day: Date) => {
        if (!isEditing || DayPicker.ModifiersUtils.dayMatchesModifier(day, disabledDays)) {
            return;
        }

        // Работаем с выбором анализируемого периода
        if (selectablePeriod === 'current' && moment(day).isAfter(currentPeriod?.from)) {
            setEnteredTo(day);
            return;
        }

        // Работаем с выбором сравниваемого периода
        if (moment(day).isBefore(lastPeriod?.from)) {
            return;
        }

        setLastEnteredTo({
            ...lastPeriod,
            to: day
        });
    };

    useEffect(() => {
        if (isEditing) {
            return;
        }

        if (currentPeriodDefault) {
            setCurrentPeriod(currentPeriodDefault);
            setEnteredTo(currentPeriodDefault.to);
        }

        if (lastPeriodDefault) {
            setLastPeriod(lastPeriodDefault);
        }

        setIsEditing(false);
    }, [currentPeriodDefault, lastPeriodDefault, isEditing]);

    useEffect(() => {
        if (selectablePeriod === 'last' && isCompareEnabled) {
            setSelectingLastPeriod(true);
        } else {
            setSelectingLastPeriod(false);
        }
    }, [selectablePeriod, isCompareEnabled]);

    const selectedDays = [];
    const modifiers: { [index: string]: any } = {};

    if (currentPeriod && currentPeriod.from) {
        selectedDays.push(currentPeriod.from);
        modifiers.start = currentPeriod.from;

        if (enteredTo !== undefined) {
            modifiers.end = enteredTo;

            selectedDays.push({ from: currentPeriod.from, to: enteredTo });
        }
    }

    if (isCompareEnabled) {
        if (isValidPeriod(lastPeriod)) {
            modifiers.lastStart = (lastPeriod as IDatesPeriod).from;
            modifiers.lastEnd = (lastPeriod as IDatesPeriod).to;
            modifiers.lastRange = lastPeriod as IDatesPeriod;
        } else {
            modifiers.lastEnteredStart = lastEnteredTo?.from;
            modifiers.lastEnteredEnd = lastEnteredTo?.to;
            modifiers.lastEnteredTo = lastEnteredTo;
        }
    }

    return (
        <DayPicker
            navbarElement={<Navbar />}
            className={`${classes.datePicker} ${selectingLastPeriod && classes.selectingLastPeriod}`}
            localeUtils={MomentLocaleUtils}
            locale={polyglot.locale()}
            numberOfMonths={3}
            fromMonth={MIN_DATE_VNUKOVO}
            toMonth={maxDate}
            onDayClick={clickDay}
            onDayMouseEnter={enterDay}
            disabledDays={disabledDays}
            selectedDays={selectedDays}
            modifiers={modifiers}
        />
    );
};

export default withStyles(styles)(AnalyticsCalendarDatepicker);

/**
 * Селекторы для dashboard
 *
 * @author Artem Bakulin
 */

import hash from 'object-hash';
import { createSelector } from 'reselect';

import { IAppState } from '../reducers';
import { IAnalyticsCalendarState } from '../reducers/analytics.calendar';
import { IUserOrganization } from '../types/organizations';

import { calendarSelector, selectedOrganizationSelector } from './common.selectors';

type AnalyticsPages = 'dashboard' | 'visits' | 'involvement' | 'frequency';

export const analyticsResultsSelector = (page: AnalyticsPages) => (state: IAppState) => state[page].results;
export const pageProgressSelector = (page: AnalyticsPages) => (state: IAppState) => state[page].inProgress;
export const pageErrorSelector = (page: AnalyticsPages) => (state: IAppState) => state[page].error;
export const hashSelector = createSelector(
    calendarSelector,
    selectedOrganizationSelector,
    (calendar: IAnalyticsCalendarState, organization: IUserOrganization | undefined) =>
        `${hash(calendar)}.${organization !== undefined ? organization.id : ''}`,
);

export const analyticsResultSelector = (page: AnalyticsPages) =>
    createSelector(
        hashSelector,
        analyticsResultsSelector(page),
        (hashString: string, results) => results[hashString],
    );

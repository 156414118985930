import { Button, Dialog, DialogActions, DialogContent, DialogTitle, WithStyles, withStyles } from '@material-ui/core';
import React from 'react';

import { styles } from './styles';

interface IProps extends WithStyles<typeof styles> {
    open: boolean;
    title: string;
    description?: string;
    agree?: string;
    cancel?: string;
    // tslint:disable:no-any
    onAgree(): any;
    onCancel(): any;
    // tslint:enable:no-any
}

const Alert = ({
    open,
    classes,
    title,
    description,
    agree = 'Продолжить',
    cancel = 'Отменить',
    onAgree,
    onCancel,
}: IProps) => (
    <Dialog open={open} onClose={onCancel} maxWidth="md">
        <DialogTitle className={classes.title} disableTypography>
            {title}
        </DialogTitle>
        {description !== undefined ? <DialogContent>{description}</DialogContent> : null}
        <DialogActions className={classes.actions}>
            <Button onClick={onCancel}>{cancel}</Button>
            <Button onClick={onAgree} variant="outlined" color="primary">
                {agree}
            </Button>
        </DialogActions>
    </Dialog>
);

export default withStyles(styles)(Alert);

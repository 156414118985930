/**
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import enLocale from 'date-fns/locale/en-US';
import ruLocale from 'date-fns/locale/ru';
import esLocale from 'date-fns/locale/es';
import frLocale from 'date-fns/locale/fr';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import { acl, getHomeUrl, SiteResourcesEnum } from '../acl';
import PrivateRoute from '../components/PrivateRoute';
import DashboardPage from '../containers/Dashboard';
import OrganizationsPage from '../containers/Organizations';
import { PolyglotSingleton } from '../lib/services/translation';
import { Campaigns as CampaignsPage } from '../pages/Campaigns';
import FrequencyPage from '../pages/Frequency';
import HolidayCalendarPage from '../pages/HolidayCalendar';
import InvolvementPage from '../pages/Involvement';
import LoyaltyPage from '../pages/Loyalty';
import OrganizationsStoplists from '../pages/OrganizationsStoplists';
import ReportsPage from '../pages/Reports';
import VisitsPage from '../pages/Visits';
import { userSelector } from '../selectors/common.selectors';
import { IUser } from '../types/users';

const redirectOnNotFound = (user: IUser) => () => <Redirect to={getHomeUrl(user)} />;
const localeMap = {
    ru: ruLocale,
    en: enLocale,
    es: esLocale,
    fr: frLocale,
}
const polyglot = PolyglotSingleton.getInstance();

const SiteSection = () => {
    const user = useSelector(userSelector);

    // Пользователь не авторизован
    if (user === undefined) {
        return null;
    }

    return (
        // tslint:disable-next-line:no-unsafe-any
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[polyglot.locale() as keyof typeof localeMap]}>
            <Switch>
                <PrivateRoute
                    permission={acl.can(user.roles).readOwn(SiteResourcesEnum.OrganizationStats)}
                    exact
                    path="/"
                    component={DashboardPage}
                />
                <PrivateRoute
                    permission={acl.can(user.roles).readOwn(SiteResourcesEnum.OrganizationStats)}
                    exact
                    path="/visits"
                    component={VisitsPage}
                />
                <PrivateRoute
                    permission={acl.can(user.roles).readOwn(SiteResourcesEnum.OrganizationStats)}
                    exact
                    path="/involvement"
                    component={InvolvementPage}
                />
                <PrivateRoute
                    permission={acl.can(user.roles).readOwn(SiteResourcesEnum.OrganizationStats)}
                    exact
                    path="/frequency"
                    component={FrequencyPage}
                />
                <PrivateRoute
                    permission={acl.can(user.roles).readOwn(SiteResourcesEnum.OrganizationStats)}
                    exact
                    path="/loyalty"
                    component={LoyaltyPage}
                />
                <PrivateRoute
                    permission={acl.can(user.roles).readOwn(SiteResourcesEnum.OrganizationStats)}
                    exact
                    path="/cohorts"
                    component={CampaignsPage}
                />
                <PrivateRoute
                    permission={acl.can(user.roles).readOwn(SiteResourcesEnum.OrganizationStats)}
                    exact
                    path="/reports"
                    component={ReportsPage}
                />
                <PrivateRoute
                    permission={acl.can(user.roles).readOwn(SiteResourcesEnum.Organizations)}
                    path="/organizations"
                    exact
                    component={OrganizationsPage}
                />
                <PrivateRoute
                    permission={acl.can(user.roles).readOwn(SiteResourcesEnum.OrganizationsStopLits)}
                    path="/organizations/stoplist/:id(\d+)?"
                    // tslint:disable-next-line:jsx-no-lambda
                    render={props => <OrganizationsStoplists user={user} {...props} />}
                />
                <PrivateRoute
                    permission={acl.can(user.roles).readAny(SiteResourcesEnum.HolidayCalendar)}
                    exact
                    path="/holiday-calendar"
                    component={HolidayCalendarPage}
                />
                <Route render={redirectOnNotFound(user)} />
            </Switch>
        </MuiPickersUtilsProvider>
    );
};

export default SiteSection;

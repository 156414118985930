import { createStyles } from '@material-ui/core';

export const styles = () =>
    createStyles({
        unbreakable: {
            pageBreakInside: 'avoid',
            pageBreakAfter: 'always',
            height: '100vh',
            width: '100%',
            overflow: 'hidden',
        },
        content: {
            flexGrow: 1,
            padding: `10px 30px`,
            height: '100%',
            width: '100%',
        },
    });

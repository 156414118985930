/**
 * Root reducer для redux
 *
 * @author Artem Bakulin
 */

import { combineReducers } from 'redux';

import { IAnalyticsPageState } from '../types/global';

import { analyticsCalendarReducer as analyticsCalendar, IAnalyticsCalendarState } from './analytics.calendar';
import { cohortsReducer as cohorts, ICohortsState } from './cohorts';
import { dashboardPageReducer as dashboard, IDashboardResultPeriod } from './dashboard.page';
import { frequencyPageReducer as frequency, IFrequencyPageState } from './frequency.page';
import { globalReducer as global, IGlobalState } from './global';
import { IInvolvementPageState, involvementPageReducer as involvement } from './involvement.page';
import { ILoyaltyPageState, loyaltyPageReducer as loyalty } from './loyalty.page';
import { IOrganizationsState, organizationsReducer as organizations } from './organizations';
import { IVisitsResultPeriod, visitsPageReducer as visits } from './visits.page';

export interface IAppState {
    analyticsCalendar: IAnalyticsCalendarState;
    global: IGlobalState;
    organizations: IOrganizationsState;
    dashboard: IAnalyticsPageState<IDashboardResultPeriod>;
    visits: IAnalyticsPageState<IVisitsResultPeriod>;
    involvement: IInvolvementPageState;
    frequency: IFrequencyPageState;
    loyalty: ILoyaltyPageState;
    cohorts: ICohortsState,
}

export const rootReducer = combineReducers({
    global,
    organizations,
    dashboard,
    analyticsCalendar,
    visits,
    involvement,
    frequency,
    loyalty,
    cohorts,
});

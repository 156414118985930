import ym from 'react-yandex-metrika';

import { IUserOrganization } from '../../types/organizations';
import { IUser } from '../../types/users';

export const ymSetUser = (user: IUser) => { ym('setUserID', user.email);  }

export const ymSetUserParams = (user: IUser, organization?: IUserOrganization) => {
    ym('userParams', {
        organization: organization !== undefined ? organization.name : undefined,
        userID: user.email
    });
}

/**
 * Титульный лист PDF отчета
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import { Grid } from '@material-ui/core';
import moment from 'moment';
import React from 'react';

import { Page } from '../../../components/Pdf/Page';
import { Typo } from '../../../components/Pdf/Typo';
import { PolyglotSingleton } from '../../../lib/services/translation';
import { IUserOrganization } from '../../../types/organizations';

import { ReactComponent as Arrow } from './arrow.svg';
import { ReactComponent as Logo } from './logo.svg';
import { useStyles } from './styles';

interface IProps {
    month: string,
    organization?: IUserOrganization,
    format?: string;
}

const polyglot = PolyglotSingleton.getInstance();

export const TitlePage = ({ month, organization, format = 'MMMM YY' }: IProps) => {
    const classes = useStyles();

    return (
        <Page className={classes.page}>
            <Grid container direction="column" justify="space-between" wrap="nowrap">
                <Grid item>
                    <Logo className={classes.logo} />
                </Grid>
                <Grid item>
                    <Typo className={classes.title}>{polyglot.t('pdf.title')}</Typo>
                    {organization !== undefined
                        ? <Typo className={classes.organization}>{organization.name}</Typo>
                        : null}
                </Grid>
                <Grid item>
                    <Typo>{moment(month).format(format).toUpperCase()}&nbsp;<Arrow /></Typo>
                </Grid>
            </Grid>
        </Page>
    );
};

/**
 * Страница "Частота визитов" для месячного отчета.
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import moment from 'moment';
import React from 'react';

import { calcIncrease } from '../../../components/Pdf/Charts/helpers';
import { LineChart } from '../../../components/Pdf/Charts/LineChart';
import { Table } from '../../../components/Pdf/Charts/Table';
import { PolyglotSingleton } from '../../../lib/services/translation';
import { IMonthSummaryData } from '../../../types/analytics';
import { IUserOrganization } from '../../../types/organizations';
import { DEFAULT_OWNER_AGE_GROUPS } from '../../../types/vehicles';
import { CHART_DATE_FORMAT, PRICE_GROUPS } from '../Summary';

import { useStyles } from './styles';

interface IProps {
    data: IMonthSummaryData;
    organization?: IUserOrganization;
}

const polyglot = PolyglotSingleton.getInstance();

export const FrequencyPage = ({ data, organization }: IProps) => {
    const classes = useStyles();

    const months = [
        moment(data.comporable[0].date).format(CHART_DATE_FORMAT),
        ...data.months.slice(1).map(month => moment(month.date).format(CHART_DATE_FORMAT)),
    ];

    const orgAgeGroups = organization?.settings.ageGroups;
    const ageGroups =
        Array.isArray(orgAgeGroups) && orgAgeGroups.length > 0
            ? orgAgeGroups.map(group => group.name)
            : Object.keys(DEFAULT_OWNER_AGE_GROUPS);
    const ageRows = [
        [...ageGroups.map(group => data.comporable[0].visitsByAge[`freq_${group}`])],
        ...data.months.slice(1).map(monthData => [...ageGroups.map(group => monthData.visitsByAge[`freq_${group}`])]),
    ];

    const priceRows = [
        [...PRICE_GROUPS.map(group => data.comporable[0].visitsByPrice[`freq_${group}`])],
        ...data.months.slice(1).map(monthData => [...PRICE_GROUPS.map(group => monthData.visitsByPrice[`freq_${group}`])]),
    ];
    const priceColumns = PRICE_GROUPS.map(group => (
        <>
            <span>{group}</span>
            <br />
            <span style={{ opacity: 0.5, fontSize: '2mm' }}>
                {polyglot.t(`pdf.priceRages.${group}`)}
            </span>
        </>
    ));

    const freqMonths = [
        moment(data.comporable[0].date).format(CHART_DATE_FORMAT),
        '',
        ...data.months.slice(1).map(month => moment(month.date).format(CHART_DATE_FORMAT)),
    ];
    const freqData: Array<Array<number | null>> = [
        [data.comporable[0].visits / data.comporable[0].uniqs],
        [null, null, ...data.months.slice(1).map(month => (month.visits / month.uniqs))],
    ];
    const freqLength = freqData[1].length;
    const yearIncrease = (new Array(freqLength)).fill(null);
    const monthIncrease = (new Array(freqLength)).fill(null);
    yearIncrease[freqLength - 1] = calcIncrease(freqData[1][freqLength - 1] ?? 0, freqData[0][0] ?? 0);
    for (let i = 0; i < 3; i += 1) {
        monthIncrease[i + 2] = calcIncrease(
            freqData[1][i + 2] ?? 0,
            (data.months[i].visits / data.months[i].uniqs) ?? 0,
        );
    }

    return (
        <>
            <div className={classes.frequency}>
                <div>
                    <LineChart
                        title={polyglot.t('pdf.charts.frequency')}
                        labels={freqMonths}
                        data={freqData}
                        pastYearDivider={1}
                        yearIncrease={yearIncrease}
                        monthIncrease={monthIncrease}
                        showFooter
                        valueFormat="float"
                        valueFormatOptions={{ maximumFractionDigits: 2 }}
                        increaseFormatOptions={{ maximumFractionDigits: 2 }}
                        footerWrap="wrap"
                    />
                </div>
                <div>
                    <Table
                        title={polyglot.t('pdf.charts.frequencyByPrice')}
                        columnsHeader={priceColumns}
                        rowsHeader={months}
                        rows={priceRows}
                        valueFormat="float"
                        valueFormatOptions={{ maximumFractionDigits: 2 }}
                        size="small"
                        footerValueOptions={{ maximumFractionDigits: 2 }}
                        footerTitle="pdf.charts.common.shareShort"
                    />
                </div>
            </div>
            <div>
                <Table
                    title={polyglot.t('pdf.charts.frequencyByAge')}
                    columnsHeader={ageGroups}
                    rowsHeader={months}
                    rows={ageRows}
                    valueFormat="float"
                    valueFormatOptions={{ maximumFractionDigits: 2 }}
                    size="small"
                    footerValueOptions={{ maximumFractionDigits: 2 }}
                    footerTitle="pdf.charts.common.shareShort"
                />
            </div>
        </>
    );
};

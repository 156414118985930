import { connect } from 'react-redux';

import { analyticsCalendarActions } from '../actions/analytics.calendar';
import AnalyticsCalendar from '../components/AnalyticsCalendar';
import { IAppState } from '../reducers';

const mapStateToProps = (state: IAppState) => ({...state.analyticsCalendar});

const mapDispatchToProps = {
    changeCurrentPeriodType: analyticsCalendarActions.analyticsCalendar.changeCurrentPeriodType,
    changeLastPeriodType: analyticsCalendarActions.analyticsCalendar.changeLastPeriodType,
    setCustomCurrentPeriod: analyticsCalendarActions.analyticsCalendar.setCustomCurrentPeriod,
    setCustomLastPeriod: analyticsCalendarActions.analyticsCalendar.setCustomLastPeriod,
    toggleComparePeriod: analyticsCalendarActions.analyticsCalendar.toggleComparePeriod,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AnalyticsCalendar);

import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        height: '100%',
        fontFamily: theme.typography.fontFamily,
        fontSize: '0.75rem',
        minHeight: 300,
    },
    granularityBtns: {
        marginBottom: theme.spacing(2),
    },
    filterDataType: {
        minWidth: 150,
        marginRight: theme.spacing(2),
    },
    filterDetailed: {
        minWidth: 200,
    }
}));

import { FormControl, MenuItem, Select, WithStyles, withStyles } from '@material-ui/core';
import React, { ChangeEvent } from 'react';

import { IUserOrganization } from '../../types/organizations';

import { styles } from './styles';

interface IProps extends WithStyles<typeof styles> {
    organizations: IUserOrganization[];
    organization: IUserOrganization;
    onChange(orgId: number): void;
}

const handleChange = (cb: (orgId: number) => void) =>
    (event: ChangeEvent<{ name?: string; value: unknown }>) => {
        cb(event.target.value as number);
    }

const AppOrganizationSelect = ({ organization, organizations, classes, onChange }: IProps) => (
    <FormControl variant="outlined">
        <Select
            value={organization.id}
            className={classes.select}
            onChange={handleChange(onChange)}
        >
            {organizations.map((org: IUserOrganization) => (
                <MenuItem value={org.id} key={org.id}>
                    {org.name}
                </MenuItem>
            ))}
        </Select>
    </FormControl>
);

export default withStyles(styles)(AppOrganizationSelect);

/**
 * Общие для всего сайта Redux Actions
 *
 * @author Artem Bakulin
 */

import { Action, createActions } from 'redux-actions';

import { IGlobalState } from '../reducers/global';
import { INotification, Language } from '../types/global';

interface IGlobalActions {
    global: {
        initialize: {
            begin(): Action<undefined>;
            success(init: Partial<IGlobalState>): Action<Partial<IGlobalState>>;
        };
        notifications: {
            add(notification: INotification): Action<INotification>;
            delete(index: number): Action<number>;
        };
        redirectTo(url: string): Action<string>;
        changeOrganization(organizationId: number): Action<number>;
        changeLanguage: {
            change(lng: Language): Action<Language>;
            success(): Action<undefined>;
        };
    };
}

export const globalActions: IGlobalActions = createActions({
    global: {
        initialize: {
            begin: undefined,
            success: (init: Partial<IGlobalState>) => init,
        },
        notifications: {
            add: (notification: INotification) => notification,
            delete: (index: number) => index,
        },
        redirectTo: (url: string) => url,
        changeOrganization: (organizationId: number) => organizationId,
        changeLanguage: {
            change: (lng: Language) => lng,
            success: undefined,
        },
    },
    // tslint:disable-next-line:no-any
}) as any;

/** @author Artem Bakulin <dekkyartem@gmail.com> */

import { makeStyles } from '@material-ui/core';

import { colorsTheme } from '../../styles.constants';

export const useStyles = makeStyles(() => ({
    footer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        position: 'absolute',
        flexWrap: 'nowrap',
        top: '100%',
        left: 0,
    },
    hint: {
        '&:first-child': {
            marginRight: '8mm',
        },

        display: 'flex',
        alignItems: 'center',
        flexWrap: 'nowrap',
        fontSize: '3mm',
        color: 'rgba(18, 18, 18, 1)',
        whiteSpace: 'nowrap',
    },
    circles: {
        marginRight: '2mm',
        display: 'flex',
        whiteSpace: 'nowrap',
    },
    circle: {
        marginRight: '1mm',
        borderRadius: '50%',
        width: '3mm',
        height: '3mm',
        display: 'inline-block',
    },
    positive: {
        border: `1px solid ${colorsTheme.positive}`,
    },
    negative: {
        border: `1px solid ${colorsTheme.negative}`,
    },
    positiveBg: {
        backgroundColor: colorsTheme.positiveBg,
    },
    negativeBg: {
        backgroundColor: colorsTheme.negativeBg,
    },
    wrap: {
        flexWrap: 'wrap',
    },
}));

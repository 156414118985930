/**
 * Модальное окно для создания/редактирования когорты рекламной кампании.
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import { Button, Dialog, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import React from 'react';

import { CohortsApi } from '../../api/cohort.api';
import { CampaignCohortForm } from '../../forms/CampaignCohortForm';
import { PolyglotSingleton } from '../../lib/services/translation';
import { ICampaignCohort } from '../../types/organizations';

interface IProps {
    cohort?: ICampaignCohort;
    isOpen: boolean;
    handleClose(): void;
    api: CohortsApi;
}

const polyglot = PolyglotSingleton.getInstance();

export const CampaignCohortModal = ({isOpen, handleClose, cohort, api}: IProps) => (
    <Dialog open={isOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
            {cohort !== undefined ? cohort.name : polyglot.t('cohorts.modal.newCampaign')}
        </DialogTitle>
        <DialogContent>
            <CampaignCohortForm
                api={api}
                cohort={cohort}
                onSuccess={handleClose}
                actionsBtns={
                    <Grid item container spacing={3} justify="flex-end">
                        <Grid item>
                            <Button onClick={handleClose} variant="outlined">
                                {polyglot.t('common.buttons.cancel')}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button type="submit" color="primary" variant="contained">
                                {cohort !== undefined
                                    ? polyglot.t('common.buttons.save')
                                    : polyglot.t('common.buttons.create')}
                            </Button>
                        </Grid>
                    </Grid>
                }
            />
        </DialogContent>
    </Dialog>
);

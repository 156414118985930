/**
 * Страница для создания и выгрузки различных отчетов
 *
 * @author Artem Bakulin<dekkyartem@gmail.com>
 */

import { Grid, LinearProgress, Typography, WithStyles, withStyles } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import PageHeader from '../../components/PageHeader';
import ToggleButton from '../../components/ToggleButton';
import ToggleButtonGroup from '../../components/ToggleButtonGroup';
import Translate from '../../components/Translate';
import Layout from '../../containers/Layout';
import { IAppState } from '../../reducers';
import { IUserOrganization } from '../../types/organizations';

import ReportCustom from './ReportCustom';
import ReportMonth from './ReportMonth';
import ReportQuarter from './ReportQuarter';
import ReportYear from './ReportYear';
import { styles } from './styles';

export type ReportType = 'month' | 'year' | 'quarter' | 'custom';
export type ReportFileType = 'pdf' | 'xlsx';

// TEMP: const reportsTypes: ReportType[] = ['month', 'year', 'custom'];
const reportsTypes: ReportType[] = ['month', 'quarter'];

interface IProps extends WithStyles<typeof styles> { }

const reportBody = (reportType: ReportType, organization: IUserOrganization, setProgress: (state: boolean) => void) => {
    switch (reportType) {
        case 'month':
            return <ReportMonth id="reportMonth" organization={organization} setProgress={setProgress} />;
        case 'quarter':
            return <ReportQuarter id="reportQuarter" organization={organization} setProgress={setProgress} />;
        case 'year':
            return <ReportYear id="reportYear" organization={organization} setProgress={setProgress} />;
        case 'custom':
            return <ReportCustom id="reportCustom" organization={organization} setProgress={setProgress} />;
        default:
            return null;
    }
};

const ReportsPage = ({ classes }: IProps) => {
    const organization = useSelector((state: IAppState) => state.global.selectedOrganization);
    const [inProgress, setProgress] = useState(false);
    const [reportType, setReportType] = useState<ReportType>('month');

    const changeReportType = useCallback((_, value: ReportType) => {
        setReportType(value);
    }, []);

    if (organization === undefined) {
        return null;
    }

    return (
        <Layout>
            <PageHeader>
                <Grid container spacing={3} justify="space-between" wrap="nowrap" alignItems="stretch">
                    <Grid item>
                        <Typography variant="h1" className={classes.title}>
                            <Translate phrase="reports.title" />
                        </Typography>
                    </Grid>
                </Grid>
            </PageHeader>
            <Grid container spacing={3} direction="column" alignItems="flex-start">
                <Grid item>
                    <ToggleButtonGroup value={reportType} onChange={changeReportType} exclusive>
                        {reportsTypes.map((type: ReportType) => (
                            <ToggleButton value={type} key={type}>
                                <Translate phrase={`reports.type.${type}`} />
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup>
                </Grid>
                <Grid item>{reportBody(reportType, organization, setProgress)}</Grid>
            </Grid>

            {inProgress ? <LinearProgress className={classes.progress} /> : null}
        </Layout>
    );
};

export default withStyles(styles)(ReportsPage);

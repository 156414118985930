/**
 * Первая страница раздела "Посещаемость" годового отчета.
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import { CategoryAxis, CircleBullet, Legend, LineSeries, ValueAxis } from '@amcharts/amcharts4/charts';
import { Grid, Typography, WithStyles, withStyles } from '@material-ui/core';
import React, { useMemo } from 'react';

import XYChart from '../../../components/Charts/XYChart';
import { NumberFormatFunction } from '../../../types/global';
import { styles } from '../styles';

interface IProps extends WithStyles<typeof styles> {
    data: Array<{ period: string; [index: string]: string | number }>;
    id: string;
    numberFormat: NumberFormatFunction | string;
    title: string;
}

const DurationPage = ({ data, classes, id, title, numberFormat }: IProps) => {
    const groups = Object.keys(data[0]).filter(key => key !== 'period');
    const yAxes = useMemo(() => [new ValueAxis()], []);
    const xAxes = useMemo(() => [new CategoryAxis()], []);
    const series = useMemo(() => {
        const allSeries: LineSeries[] = [];

        groups.forEach((group: string) => {
            const allSerie = new LineSeries();
            const allBullet = new CircleBullet();
            allBullet.tooltipText = `{${group}}`;
            allSerie.name = group;
            allSerie.dataFields.categoryX = 'period';
            allSerie.dataFields.valueY = group;
            allSerie.bullets.push(allBullet);

            allSeries.push(allSerie);
        });

        return allSeries;
    }, [groups]);
    const legend = useMemo(() => {
        const newLegend = new Legend();
        newLegend.position = 'top';

        return newLegend;
    }, []);

    xAxes[0].dataFields.category = 'period';

    return (
        <Grid
            container
            spacing={3}
            direction="column"
            justify="space-between"
            wrap="nowrap"
            alignItems="stretch"
            className={classes.chartRowTwoThirds}
        >
            <Grid item>
                <Typography variant="h5" className={classes.title}>
                    {title}
                </Typography>
            </Grid>
            <Grid item className={classes.fullHeight}>
                <XYChart
                    data={data}
                    id={id}
                    yAxes={yAxes}
                    xAxes={xAxes}
                    series={series}
                    legend={legend}
                    className={classes.chart}
                    numberFormat={numberFormat}
                />
            </Grid>
        </Grid>
    );
};

export default withStyles(styles)(DurationPage);

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    LinearProgress,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Typography,
    WithStyles,
    withStyles,
} from '@material-ui/core';
import { HelpOutline, MoreVert } from '@material-ui/icons';
import React, { useCallback, useEffect, useState } from 'react';

import AnalyticsPageHeader from '../../components/AnalyticsPageHeader';
import Translate from '../../components/Translate';
import Layout from '../../containers/Layout';
import { PolyglotSingleton } from '../../lib/services/translation';
import { IAnalyticsCalendarState } from '../../reducers/analytics.calendar';
import { IDashboardResultPeriod } from '../../reducers/dashboard.page';
import { IAnalyticsPageResult } from '../../types/global';
import { IUserOrganization } from '../../types/organizations';

import DashboardResultData from './ResultData';
import { styles } from './styles';

interface IProps extends WithStyles<typeof styles> {
    request: IAnalyticsCalendarState;
    getData(organizationId: number, data: IAnalyticsCalendarState): void;
    inProgress: boolean;
    organization?: IUserOrganization;
    result?: IAnalyticsPageResult<IDashboardResultPeriod>;
    error?: Error;
}

const polyglot = PolyglotSingleton.getInstance();

const help = (
    <>
        <Typography variant="caption">
            <Translate phrase="dashboard.analyzedPeriod" />:
        </Typography>
        <List>
            <ListItem>
                <ListItemText>
                    <b>
                        <Translate phrase="common.periods.today" />
                    </b>{' '}
                    - <Translate phrase="dashboard.help.description1" />
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>
                    <b>
                        <Translate phrase="common.periods.yesterday" />
                    </b>{' '}
                    - <Translate phrase="dashboard.help.description2" />
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>
                    <b>
                        <Translate phrase="common.periods.lastWeek" />
                    </b>{' '}
                    - <Translate phrase="dashboard.help.description3" />
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>
                    <b>
                        <Translate phrase="common.periods.lastPrefix" />{' '}
                        <Translate phrase="common.periods.days" values={7} />
                    </b>{' '}
                    - <Translate phrase="dashboard.help.description4" />
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>
                    <b>
                        <Translate phrase="common.periods.currentMonth" />
                    </b>{' '}
                    - <Translate phrase="dashboard.help.description5" />
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>
                    <b>
                        <Translate phrase="common.periods.lastMonth" />
                    </b>{' '}
                    - <Translate phrase="dashboard.help.description6" />
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>
                    <b>
                        <Translate phrase="common.periods.lastPrefix" />{' '}
                        <Translate phrase="common.periods.days" values={30} />
                    </b>{' '}
                    - <Translate phrase="dashboard.help.description7" />
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>
                    <b>
                        <Translate phrase="common.periods.currentYear" />
                    </b>{' '}
                    - <Translate phrase="dashboard.help.description8" />
                </ListItemText>
            </ListItem>
        </List>
        <Typography variant="caption">
            <Translate phrase="dashboard.comparisonPeriod" />:
        </Typography>
        <List>
            <ListItem>
                <ListItemText>
                    <b>
                        <Translate phrase="dashboard.precedingPeriod" />
                    </b>{' '}
                    - <Translate phrase="dashboard.precedingPeriodTip" />
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>
                    <b>
                        <Translate phrase="dashboard.comparablePeriod" />
                    </b>{' '}
                    - <Translate phrase="dashboard.comparablePeriodTip" />
                </ListItemText>
            </ListItem>
        </List>
    </>
);

const DashboardPage = ({ request, result, inProgress, getData, organization, error, classes }: IProps) => {
    const { lastPeriodType, currentPeriodType } = request;
    const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLButtonElement) | null>(null);
    const [isOpenHelp, setOpenHelp] = useState(false);

    const openMenu = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);
    const closeMenu = useCallback(() => {
        setAnchorEl(null);
    }, []);
    const openHelpModal = useCallback(() => {
        setOpenHelp(true);
        setAnchorEl(null);
    }, []);
    const closeHelpModal = useCallback(() => {
        setOpenHelp(false);
    }, []);

    useEffect(() => {
        if (organization !== undefined && result === undefined && !inProgress && error === undefined) {
            getData(organization.id, request);
        }
    });

    return (
        <Layout>
            <AnalyticsPageHeader
                title={polyglot.t('dashboard.header')}
                action={
                    <IconButton aria-controls="dashboardMenu" aria-haspopup="true" onClick={openMenu}>
                        <MoreVert />
                    </IconButton>
                }
            />
            {inProgress ? <LinearProgress /> : null}
            {result !== undefined ? (
                <DashboardResultData
                    currentPeriodType={currentPeriodType}
                    lastPeriodType={lastPeriodType}
                    result={result}
                    minLongVisitTime={organization?.settings.minLongVisit}
                />
            ) : null}

            <Dialog open={isOpenHelp} onClose={closeHelpModal}>
                <DialogTitle disableTypography className={classes.helpTitle}>
                    <Typography variant="h4" align="center">
                        {polyglot.t('dashboard.help.header')}
                    </Typography>
                </DialogTitle>
                <DialogContent className={classes.helpContent}>{help}</DialogContent>
                <DialogActions className={classes.helpActions}>
                    <Button variant="outlined" color="primary" onClick={closeHelpModal}>
                        {polyglot.t('common.buttons.close')}
                    </Button>
                </DialogActions>
            </Dialog>

            <Menu id="dashboardMenu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={closeMenu}>
                <MenuItem onClick={openHelpModal}>
                    <ListItemIcon>
                        <HelpOutline />
                    </ListItemIcon>
                    <Typography variant="inherit">{polyglot.t('dashboard.menu.help')}</Typography>
                </MenuItem>
            </Menu>
        </Layout>
    );
};

export default withStyles(styles)(DashboardPage);

import { createStyles, Theme } from '@material-ui/core';

export const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            height: '100%',
            fontFamily: theme.typography.fontFamily,
            fontSize: '0.75rem',
        },
    });

/**
 * Утилиты/функции помощники для работы с ApiChart.
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import moment from 'moment';

import { durationString } from '../../../lib/utils/strings';
import { AnalyticsDataGranularity } from '../../../types/date.periods';

import { ApiChartValuesType } from './types';

/**
 * Возвращает функцию форматирования числа, согласно переданному типу.
 *
 * @param type Тип числовых данных графика, в который надо форматировать значение
 */
export const formatValue = (
    type: ApiChartValuesType = 'absolute',
    options: Intl.NumberFormatOptions = { maximumFractionDigits: 1 },
) => (value?: number) => {
    if (value === undefined) {
        return '';
    }

    switch (type) {
        case 'duration':
            return durationString(value);
        case 'percent':
            return value.toLocaleString('ru-RU', { ...options, style: 'percent' });
        case 'float':
            return value.toLocaleString('ru-RU', { ...options, style: 'decimal' });
        case 'absolute':
        default:
            return value.toLocaleString('ru-RU', options);
    }
};

export const getDateCategories = (data: Array<Array<{ date?: string}>>, granularity: AnalyticsDataGranularity) => {
    const maxLength = data.reduce((a, b) => (b.length > a ? b.length : a), 0);
    const categories = [];
    const dateFormat = granularity === 'month' ? 'MMM YY' : 'D.MM.YYYY';

    for (let i = 0; i < maxLength; i += 1) {
        categories.push(
            // tslint:disable-next-line:strict-type-predicates
            data[0][i] !== undefined
                ? moment(data[0][i].date).format(dateFormat)
                : moment(data[0][data[0].length - 1].date)
                      .add(i - data[0].length + 1, 'days')
                      .format(dateFormat),
        );
    }

    return categories;
};

import {
    Card,
    CardActionArea,
    CardContent,
    CardHeader,
    Popover,
    Typography,
    WithStyles,
    withStyles,
} from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp, HelpOutline } from '@material-ui/icons';
import classNames from 'classnames';
import React, { SyntheticEvent, useState } from 'react';

import { PolyglotSingleton } from '../../lib/services/translation';
import { formatValue } from '../Charts/ApiChart/helpers';
import { ApiChartValuesType } from '../Charts/ApiChart/types';

import { styles } from './styles';

type OnClick = (event: React.MouseEvent) => void;

interface IProps extends WithStyles<typeof styles> {
    title: string;
    value?: number;
    selected?: boolean;
    lastValue?: number;
    className?: string;
    valueFormat?: ApiChartValuesType;
    isNegativeIncrease?: boolean;
    onClick?: OnClick;
    helpText?: string;
}

interface IInnerProps extends IProps {
    anchorEl: (EventTarget & Element) | null;
    handleOpen(event: SyntheticEvent): void;
    handleClose(): void;
}

const polyglot = PolyglotSingleton.getInstance();

const increaseElement = (
    value: number,
    lastValue: number,
    classes: IProps['classes'],
    selected: boolean = false,
    isNegativeIncrease: boolean = false,
) => {
    if (lastValue === 0) {
        return null;
    }

    const increaseValue = (lastValue !== 0 ? value / lastValue : 2) - 1;
    const increaseIcon =
        (!isNegativeIncrease && increaseValue >= 0) || (isNegativeIncrease && increaseValue <= 0) ? (
            <ArrowDropUp className={classes.increaseIconUp} />
        ) : (
            <ArrowDropDown className={classes.increaseIconDown} />
        );

    return (
        <Typography className={classNames({ [classes.increase]: true, [classes.increaseSelected]: selected })} noWrap>
            {increaseIcon} {formatValue('percent')(increaseValue)}
        </Typography>
    );
};

const inner = ({
    value,
    title,
    classes,
    selected,
    lastValue,
    anchorEl,
    handleClose,
    handleOpen,
    helpText,
    valueFormat = 'absolute',
}: IInnerProps) => (
    <>
        <CardHeader
            title={title}
            titleTypographyProps={{ variant: 'caption', align: 'center' }}
            className={classes.header}
            classes={{ title: classNames({ [classes.title]: true, [classes.titleSelected]: selected }) }}
            action={
                helpText !== undefined ? (
                    <>
                        <HelpOutline
                            className={Boolean(selected) ? classes.popoverIconSelected : classes.popoverIcon}
                            aria-owns={Boolean(anchorEl) ? 'mouse-over-popover' : undefined}
                            aria-haspopup="true"
                            onMouseEnter={handleOpen}
                            onMouseLeave={handleClose}
                        />
                        <Popover
                            className={classes.popover}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            classes={{
                                paper: classes.popoverPaper,
                            }}
                            disableRestoreFocus
                        >
                            <Typography className={classes.popoverText}>{helpText}</Typography>
                        </Popover>
                    </>
                ) : (
                    undefined
                )
            }
        />
        <CardContent className={classes.content}>
            <Typography noWrap className={classNames({ [classes.value]: true, [classes.valueSelected]: selected })}>
                {value !== undefined ? formatValue(valueFormat)(value) : 'N/A'}
            </Typography>
            {value !== undefined && lastValue !== undefined ? (
                <>
                    {increaseElement(value, lastValue, classes, selected)}
                    <Typography
                        noWrap
                        variant="caption"
                        className={classNames({ [classes.lastValue]: true, [classes.lastValueSelected]: selected })}
                    >
                        {formatValue(valueFormat)(lastValue)} {polyglot.t('dashboard.precedingAcronym')}
                    </Typography>
                </>
            ) : null}
        </CardContent>
    </>
);

const IndicatorCard = (props: IProps) => {
    const [anchorEl, setAnchorEl] = useState<(EventTarget & Element) | null>(null);

    const handleOpen = (event: SyntheticEvent) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const innerProps: IInnerProps = {
        ...props,
        anchorEl,
        handleOpen,
        handleClose,
    };

    return (
        <Card
            className={classNames({
                [props.classes.root]: true,
                [props.classes.selected]: props.selected,
                ...(props.className !== undefined ? { [props.className]: true } : {}),
            })}
        >
            {props.onClick !== undefined ? (
                <CardActionArea onClick={props.onClick} className={props.classes.action}>
                    {inner(innerProps)}
                </CardActionArea>
            ) : (
                inner(innerProps)
            )}
        </Card>
    );
};

export default withStyles(styles)(IndicatorCard);

/**
 * Страницы с 4 по n раздела "Посещаемость" годового отчета.
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import { Typography, WithStyles, withStyles } from '@material-ui/core';
import moment from 'moment';
import React from 'react';

import ColumnChartStacked, { IStackedSeries } from '../../../components/Charts/ColumnChartStacked';
import PdfSection from '../../../components/PdfSection';
import { PolyglotSingleton } from '../../../lib/services/translation';
import { IYearSummaryData } from '../../../types/analytics';
import { styles } from '../styles';

interface IProps extends WithStyles<typeof styles> {
    data: IYearSummaryData[];
    startPage: number;
    year: number;
}

const polyglot = PolyglotSingleton.getInstance();

const getStackedChartData = (
    data: IYearSummaryData[],
    priceKey: string,
): Array<{ period: string; [index: string]: number | string }> =>
    data.map(row => ({
        period: moment(row.date).format('MMM YY'),
        ...row.visitsByPriceAndAge[priceKey],
    }));

const VisitsPagesMore = ({ data, classes, startPage, year }: IProps) => {
    const pricesGroups = Object.keys(data[0].visitsByPriceAndAge);
    const pages = pricesGroups.map((priceGroup, index) => (
        <PdfSection
            page={startPage + index}
            key={priceGroup}
            title={polyglot.t('visits.header')}
            period={year.toString()}
        >
            <div className={classes.chartRowTwoThirds}>
                <Typography variant="h5" className={classes.title}>
                    {`${polyglot.t('visits.byAgeAndPrice')} (${priceGroup})`}
                </Typography>
                <ColumnChartStacked
                    id={`visitsByPriceAndAge_${priceGroup}`}
                    data={(getStackedChartData(data, priceGroup) as unknown) as IStackedSeries[]}
                    noElements={true}
                    className={classes.fullHeight}
                    classes={{
                        chartWrapper: classes.fullHeight,
                    }}
                    withTotals
                />
            </div>
        </PdfSection>
    ));

    return <>{pages}</>;
};

export default withStyles(styles)(VisitsPagesMore);

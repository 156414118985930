/**
 * Футер для PDF отчета
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import { format } from 'date-fns';
import React from 'react';

import { Typo } from '../Typo';

import { useStyles } from './styles';

interface IProps {
    page?: number;
}

export const Footer = ({ page }: IProps) => {
    const classes = useStyles();

    return (
        <div className={classes.footer}>
            {page !== undefined ? <Typo className={classes.pageNumber}>{page}</Typo> : null}
            <Typo className={classes.copy}>{format(new Date(), 'yyyy')} © SYNAPS LABS</Typo>
        </div>
    );
};

/**
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

export const CHART_FIRST_SERIE_COLOR = '#aec7e8';
export const CHART_SECOND_SERIE_COLOR = '#1f77b4';
export const CHART_SERIES_COLORS = [CHART_SECOND_SERIE_COLOR, CHART_FIRST_SERIE_COLOR];
export const CHART_STACKED_COLORS = ['#4E79A7', '#A0CBE8', '#F28E2B', '#FFBE7D', '#59A14F', '#8CD17D', '#B6992D', '#F1CE63', '#499894', '#86BCB6', '#E15759', '#FF9D9A', '#79706E', '#BAB0AC', '#D37295', '#FABFD2', '#B07AA1', '#D4A6C8', '#9D7660', '#D7B5A6'];
export const CHART_STACKED_SERIES_COLORS = [
    ['#aec7e8', '#ffbb78', '#98df8a', '#ff9896', '#c5b0d5', '#c49c94', '#f7b6d2', '#c7c7c7', '#dbdb8d', '#9edae5'],
    ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b', '#e377c2', '#7f7f7f', '#bcbd22', '#17becf'],

];

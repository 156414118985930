import React from 'react';
import {Button, Grid, WithStyles, withStyles} from '@material-ui/core';
import { styles } from './styles';

export interface INavbarProps extends WithStyles<typeof styles> {
    onPreviousClick?: () => void;
    onNextClick?: () => void;
}

function DatepickerNavbar({classes, onPreviousClick = () => {}, onNextClick = () => {}}: INavbarProps) {
    return (
        <Grid container justify="flex-end">
            <Grid item>
                <Button className={classes.monthButton} size="small" variant="outlined" onClick={() => onPreviousClick()}>
                    ← 
                </Button>
            </Grid>
            <Grid item>
                <Button className={classes.monthButton} size="small" variant="outlined" onClick={() => onNextClick()}>
                    →
                </Button>
            </Grid>
        </Grid>
    );
}

export default withStyles(styles)(DatepickerNavbar);
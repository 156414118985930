/**
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

// tslint:disable-next-line:no-submodule-imports
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import {
    BarChart,
    Business,
    CalendarToday,
    Category,
    Forward5,
    SupervisorAccount,
    TableChart,
    Timelapse,
    TrendingUp,
} from '@material-ui/icons';
import { Permission } from 'accesscontrol';
import { ComponentType } from 'react';

import { acl, SiteResourcesEnum } from '../../acl';
import { PolyglotSingleton } from '../../lib/services/translation';
import { IUser } from '../../types/users';

export interface IMenuItem {
    icon?: ComponentType<SvgIconProps>;
    isExact?: boolean;
    path: string;
    permission: Permission;
    title: string;
}

export interface IMenuSection {
    id: string;
    items: IMenuItem[];
    title: string;
}

export type Menu = IMenuSection[];

const polyglot = PolyglotSingleton.getInstance();

/**
 * Генерирует структуру меню с учетом прав доступа пользователя
 *
 * @param user Авторизованный пользователь
 */
export const generateMenu = (user: IUser): Menu =>
    [
        {
            id: 'analytics',
            title: polyglot.t('common.menu.analytics'),
            items: [
                {
                    title: polyglot.t('common.menu.dashboard'),
                    icon: Category,
                    permission: acl.can(user.roles).readOwn(SiteResourcesEnum.OrganizationStats),
                    path: '/',
                    isExact: true,
                },
                {
                    title: polyglot.t('common.menu.visits'),
                    icon: BarChart,
                    permission: acl.can(user.roles).readOwn(SiteResourcesEnum.OrganizationStats),
                    path: '/visits',
                    isExact: true,
                },
                {
                    title: polyglot.t('common.menu.involvement'),
                    icon: Timelapse,
                    permission: acl.can(user.roles).readOwn(SiteResourcesEnum.OrganizationStats),
                    path: '/involvement',
                    isExact: true,
                },
                {
                    title: polyglot.t('common.menu.frequency'),
                    icon: Forward5,
                    permission: acl.can(user.roles).readOwn(SiteResourcesEnum.OrganizationStats),
                    path: '/frequency',
                    isExact: true,
                },
                // {
                //     title: polyglot.t('common.menu.loyalty'),
                //     icon: Loyalty,
                //     permission: acl.can(user.roles).readOwn(SiteResourcesEnum.OrganizationStats),
                //     path: '/loyalty',
                //     isExact: true,
                // },
                {
                    title: polyglot.t('common.menu.reports'),
                    icon: TableChart,
                    permission: acl.can(user.roles).readOwn(SiteResourcesEnum.OrganizationStats),
                    path: '/reports',
                    isExact: true,
                },
                {
                    title: polyglot.t('common.menu.cohorts'),
                    icon: TrendingUp,
                    permission: acl.can(user.roles).readOwn(SiteResourcesEnum.OrganizationStats),
                    path: '/cohorts',
                    isExact: true,
                },
            ],
        },

        {
            id: 'admin',
            title: polyglot.t('common.menu.administration'),
            items: [
                {
                    title: polyglot.t('common.menu.organizations'),
                    icon: Business,
                    permission: acl.can(user.roles).readOwn(SiteResourcesEnum.Organizations),
                    path: '/organizations',
                },
                {
                    title: polyglot.t('common.menu.users'),
                    icon: SupervisorAccount,
                    permission: acl.can(user.roles).readOwn(SiteResourcesEnum.Organizations),
                    path: '/users',
                },
                {
                    title: polyglot.t('common.menu.calendar'),
                    icon: CalendarToday,
                    permission: acl.can(user.roles).readAny(SiteResourcesEnum.HolidayCalendar),
                    path: '/holiday-calendar',
                },
            ],
        },
    ]
        .map(section => ({ ...section, items: section.items.filter(item => item.permission.granted) }))
        .filter(section => section.items.length > 0);

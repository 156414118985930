/**
 * Header страницы PDF отчета.
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import { Grid, WithStyles, withStyles } from '@material-ui/core';
import React from 'react';

import { styles } from './styles';

interface IProps extends WithStyles<typeof styles> {
    children?: React.ReactNode;
    page?: number;
    title?: string;
}

const PdfHeader = ({ classes, children, page, title }: IProps) => (
    <Grid container justify="space-between" className={classes.root} wrap="nowrap">
        <Grid item container alignItems="center">
            {page !== undefined ? <div className={classes.number}>{page}</div> : null}
            {title !== undefined ? <div className={classes.title}>{title}</div> : null}
        </Grid>
        <Grid item>{children}</Grid>
    </Grid>
);

export default withStyles(styles)(PdfHeader);

/**
 * Обертка для контента таба с определенными настройками организации
 * 
 * @author Artem Bakulin
 */

import { WithStyles, withStyles } from '@material-ui/core';
import React from 'react';

import { styles } from './styles';

interface IProps extends WithStyles<typeof styles> {
    hidden: boolean;
    children: React.ReactNode;
}

const SettingsTab = ({ hidden, children, classes }: IProps) => (
    <div className={classes.tabsContent} hidden={hidden}>{children}</div>
)

export default withStyles(styles)(SettingsTab);

/**
 * Страница "Новые авто" месячного PDF отчета.
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import moment from 'moment';
import React from 'react';
import { ColumnChart } from '../../../components/Pdf/Charts/ColumnChart';

import { calcIncrease } from '../../../components/Pdf/Charts/helpers';
import { LineChart } from '../../../components/Pdf/Charts/LineChart';
import { Typo } from '../../../components/Pdf/Typo';
import { PolyglotSingleton } from '../../../lib/services/translation';
import { IChartSummaryData, IQuarterSummaryData } from '../../../types/analytics';

import { useStyles } from './styles';

interface IProps {
    data: IQuarterSummaryData;
}

const polyglot = PolyglotSingleton.getInstance();

const getChartData = (key: keyof IChartSummaryData['avgNewVisitors'], data: IQuarterSummaryData): number[] => [
    data.comporable[0].avgNewVisitors[key],
    ...data.quarters.slice(1).map(row => row.avgNewVisitors[key]),
];

export const NewCarsPage = ({ data }: IProps) => {
    const classes = useStyles();

    const newData = data.months.newPercents;
    const monthData: number[][] = [
        [...newData.current.slice(1).map(row => row.value)],
    ];
    const months = [...newData.current.slice(1).map(row => row.date)];
    const labels = newData.current.slice(1).map(row => moment(row.date).format('MMM'));
    const weekMonthIncrease = (new Array(labels.length)).fill(null);
    for (let i = 0; i <= monthData[0].length - 1; i += 1) {
        weekMonthIncrease[i] =
            calcIncrease(monthData[0][i], newData.current[i].value);
    }

    const quarters = [
        moment(data.comporable[0].date).format(polyglot.t('common.dates.quarter')),
        ...data.quarters.slice(1).map(quarter => moment(quarter.date).format(polyglot.t('common.dates.quarter'))),
    ];
    const getData = (key: keyof IChartSummaryData['avgNewVisitors']) => getChartData(key, data);
    const getYearIncrease = (key: keyof IChartSummaryData['avgNewVisitors']) => {
        const keyData = getData(key);
        const yearIncrease = new Array(keyData.length).fill(null);

        yearIncrease[yearIncrease.length - 1] = calcIncrease(keyData[keyData.length - 1], keyData[0]);

        return yearIncrease;
    };
    const getMonthIncrease = (key: keyof IChartSummaryData['avgNewVisitors']) => {
        const keyData = getData(key);
        const rawData = data.quarters.map(row => row.avgNewVisitors[key]);

        return keyData.map((value, index) => (index > 0 ? calcIncrease(value, rawData[index - 1]) : null));
    };

    const maxValue = Math.max(
        ...getData('avgInWorkday'),
        ...getData('avgInHoliday'),
    );

    return (
        <>
            <div className={classes.fullwidthChart}>
                <LineChart
                    title={polyglot.t('pdf.charts.shareNewCars')}
                    labels={labels}
                    data={monthData}
                    monthIncrease={weekMonthIncrease}
                    valueFormat="percent"
                    size="big"
                    months={months}
                    type="quarter"
                />
            </div>
            <div style={{ paddingTop: '12mm' }}>
                <Typo className={classes.title}>{polyglot.t('pdf.charts.newInDay.title')}</Typo>
                <Typo className={classes.subtitle}>{polyglot.t('pdf.charts.newInDay.hint')}</Typo>
            </div>
            <div className={classes.halfwidthCharts}>
                <ColumnChart
                    labels={quarters}
                    data={getData('avgInWorkday')}
                    pastYearDivider={1}
                    yearIncrease={getYearIncrease('avgInWorkday')}
                    monthIncrease={getMonthIncrease('avgInWorkday')}
                    legend={polyglot.t('pdf.charts.newInDay.weekdays')}
                    showFooter
                    size="big"
                    max={maxValue}
                    type="quarter"
                />
                <ColumnChart
                    labels={quarters}
                    data={getData('avgInHoliday')}
                    pastYearDivider={1}
                    yearIncrease={getYearIncrease('avgInHoliday')}
                    monthIncrease={getMonthIncrease('avgInHoliday')}
                    legend={polyglot.t('pdf.charts.newInDay.weekends')}
                    size="big"
                    max={maxValue}
                    type="quarter"
                />
            </div>
        </>
    );
};

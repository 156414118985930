/**
 * Поля ввода для создания кастомных возрастных групп
 *
 * @author Artem Bakulin
 */

import { Button, Checkbox, FormControlLabel, FormGroup, Grid, IconButton, Typography } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { ErrorMessage, Field, FieldArray, FieldArrayRenderProps } from 'formik';
import React from 'react';

import TextField from '../../components/Formik/TextField';
import { PolyglotSingleton } from '../../lib/services/translation';
import { IOrganization, IOrganizationSettingsAgeGroup } from '../../types/organizations';
import { DEFAULT_OWNER_AGE_GROUPS } from '../../types/vehicles';

const polyglot = PolyglotSingleton.getInstance();
interface IProps {
    values: IOrganization,
}

/* tslint:disable:jsx-no-lambda */

const generateCheckboxes = (values: IOrganization, ageGroupIndex: number, arrayHelpers: FieldArrayRenderProps) =>
    (Object.keys(DEFAULT_OWNER_AGE_GROUPS) as Array<keyof typeof DEFAULT_OWNER_AGE_GROUPS>).map(key => {
        const ageGroups = values.settings.ageGroups as IOrganizationSettingsAgeGroup[];
        const currentGroups = ageGroups[ageGroupIndex].groups;
        const disabledGroups = ageGroups.map(ageGroup => ageGroup.groups).reduce((a, b) => a.concat(b), []);

        return (
            <FormControlLabel
                key={key}
                control={
                    <Checkbox
                        checked={currentGroups.includes(DEFAULT_OWNER_AGE_GROUPS[key])}
                    />
                }
                onChange={(_: React.ChangeEvent<{}>, checked: boolean) => {
                    if (checked) {
                        arrayHelpers.push(DEFAULT_OWNER_AGE_GROUPS[key])
                    } else {
                        arrayHelpers.remove(currentGroups.indexOf(DEFAULT_OWNER_AGE_GROUPS[key]))
                    }
                }}
                disabled={
                    disabledGroups.includes(DEFAULT_OWNER_AGE_GROUPS[key]) && !currentGroups.includes(DEFAULT_OWNER_AGE_GROUPS[key])
                }
                label={key}
            />
        );
    });

const generateAgeGroup = (values: IOrganization, index: number, removeFunc: (index: number) => void) => (
    <Grid item xs={12} key={index}>
        <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={1}><b>{`${index + 1}.`}</b></Grid>
            <Grid item xs={6}>
                <Field
                    name={`settings.ageGroups.${index}.name`}
                    placeholder={polyglot.t('organizations.settings.ageGroupName')}
                    component={TextField}
                    fullWidth
                />
            </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={11}>
                <FormGroup row>
                    <FieldArray name={`settings.ageGroups.${index}.groups`}>
                        {arrayHelpers => generateCheckboxes(values, index, arrayHelpers)}
                    </FieldArray>
                </FormGroup>
            </Grid>
            <Grid item xs={1}>
                <IconButton onClick={() => { removeFunc(index); }}><Delete /></IconButton>
            </Grid>
            <ErrorMessage name={`settings.ageGroups.${index}.groups`}>{msg => <Typography color="error">{msg}</Typography>}</ErrorMessage>
        </Grid>
    </Grid>
);

const AgesFields = ({ values }: IProps) => (
    <FieldArray name="settings.ageGroups">
        {
            (arrayHelpers: FieldArrayRenderProps) => (
                <>
                    {
                        Array.isArray(values.settings.ageGroups) && values.settings.ageGroups.length > 0 ?
                            values.settings.ageGroups.map((_, index) => generateAgeGroup(values, index, arrayHelpers.remove)) : null
                    }
                    <Grid item xs={12}>
                        <Button
                            color="primary"
                            variant="outlined"
                            size="small"
                            onClick={() => { arrayHelpers.push({ name: '', groups: [] }); }}
                        >{polyglot.t('organizations.settings.createAgeGroupBtn')}</Button>
                    </Grid>
                </>
            )
        }
    </FieldArray>
);

export default AgesFields;

/**
 * Селектор выбора типа данных (визиты/уники).
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import React, { useCallback } from 'react';

import { PolyglotSingleton } from '../../../lib/services/translation';

import { useStyles } from './styles';

const polyglot = PolyglotSingleton.getInstance();

interface IProps {
    isUniqs: boolean,
    onSetUniqsChange(isPercent: boolean): void,
}

export const ChartUniqsSelector = ({isUniqs, onSetUniqsChange}: IProps) => {
    const classes = useStyles();
    const changeChartUniqsType = useCallback((_, value: boolean | null) => {
        onSetUniqsChange(value === null ? isUniqs : value);
    }, [onSetUniqsChange, isUniqs]);

    return (
        <ToggleButtonGroup
            className={classes.percentBtns}
            value={isUniqs}
            exclusive
            onChange={changeChartUniqsType}
        >
            <ToggleButton value={false}>{polyglot.t('common.filters.visitType.visits')}</ToggleButton>
            <ToggleButton value={true}>{polyglot.t('common.filters.visitType.uniqs')}</ToggleButton>
        </ToggleButtonGroup>
    );
}

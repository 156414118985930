/**
 * Reducers для управления состоянием на странице рекламных когорт.
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import { Action, handleActions } from 'redux-actions';

import { cohortsActions } from '../actions/cohorts';
import { ICampaignCohort } from '../types/organizations';

export interface ICohortsState {
    inProgress: boolean;
    items?: ICampaignCohort[],
}

const initialState: ICohortsState = {
    inProgress: false,
}

// tslint:disable-next-line:no-any
export const cohortsReducer = handleActions<ICohortsState, any>(
    {
        // Список рекламных когорт
        [cohortsActions.cohort.list.send.toString()]: (state) => ({
            ...state,
            inProgress: true,
        }),
        [cohortsActions.cohort.list.success.toString()]: (state, action: Action<ICampaignCohort[]>) => ({
            ...state,
            inProgress: false,
            items: action.payload
        }),
        [cohortsActions.cohort.list.fail.toString()]: (state) => ({
            ...state,
            inProgress: false,
        }),

        // Получение актуальных данных по когорте
        [cohortsActions.cohort.get.success.toString()]: (state, action: Action<ICampaignCohort>) => {
            const items: ICampaignCohort[] = [...(state.items ?? [])];
            const itemIndex = items.findIndex((item) => item.id === action.payload.id);

            if (itemIndex >= 0) {
                items[itemIndex] = action.payload;
            } else {
                items.push(action.payload);
            }

            return {
                ...state,
                items,
            }
        },

        // Удаление рекламной когорты
        [cohortsActions.cohort.delete.success.toString()]: (state, action: Action<number>) => ({
            ...state,
            items: state.items?.filter((item) => item.id !== action.payload),
        }),
    },
    initialState,
);

/**
 * Третья страница раздела "Посещаемость" годового отчета.
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import { Typography, WithStyles, withStyles } from '@material-ui/core';
import React from 'react';

import ColumnChartStacked, { IStackedSeries } from '../../../components/Charts/ColumnChartStacked';
import Translate from '../../../components/Translate';
import { IYearSummaryData } from '../../../types/analytics';
import { styles } from '../styles';
import { getStackedChartData } from '../Summary';

interface IProps extends WithStyles<typeof styles> {
    data: IYearSummaryData[];
}

const VisitsPage3 = ({ data, classes }: IProps) => (
    <div className={classes.chartRowTwoThirds}>
        <Typography variant="h5" className={classes.title}>
            <Translate phrase="visits.byAge" />
        </Typography>
        <ColumnChartStacked
            id="visitsByAgeChart"
            data={getStackedChartData(data, 'visitsByAge', 'visits') as IStackedSeries[]}
            noElements={true}
            className={classes.fullHeight}
            classes={{
                chartWrapper: classes.chart,
            }}
            withTotals
        />
    </div>
);

export default withStyles(styles)(VisitsPage3);

/**
 * Стандартный график с заголовком для PDF отчета.
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import {
    CategoryAxis,
    CircleBullet,
    ColumnSeries,
    LabelBullet,
    Legend,
    LineSeries,
    ValueAxis,
    XYChart as AmChart,
} from '@amcharts/amcharts4/charts';
import { Typography, WithStyles, withStyles } from '@material-ui/core';
import React, { useMemo } from 'react';

import { styleComporableColumn, styleComporableSerie } from '../../components/Charts/ColumnChartStacked';
import XYChart from '../../components/Charts/XYChart';
import { NumberFormatFunction } from '../../types/global';

import { styles } from './styles';

type ChartResult = Array<{ period: string; value: number }>;

interface IProps extends WithStyles<typeof styles> {
    id: string;
    data: ChartResult | Array<{ period: string; [index: string]: string | number }>;
    title: string;
    series?: LineSeries[] | ColumnSeries[];
    seriesType?: 'column' | 'line';
    numberFormat?: string | NumberFormatFunction;
    minY?: number;
    disableAxis?: boolean;
    legend?: Legend;
    comporableCategory?: string;
    rotateLabels?: number;
}

const initAxes = (disableAxis: boolean, minY?: number, comporableCategory?: string, rotateLabels?: number) => (
    chart: AmChart,
) => {
    const yAxe = chart.yAxes.push(new ValueAxis());
    const xAxe = chart.xAxes.push(new CategoryAxis());

    xAxe.dataFields.category = 'period';

    if (disableAxis) {
        xAxe.renderer.grid.template.disabled = true;
        yAxe.renderer.labels.template.disabled = true;
        yAxe.renderer.grid.template.disabled = true;
    }

    if (minY !== undefined) {
        yAxe.min = minY;
    }

    if (comporableCategory !== undefined) {
        styleComporableColumn(xAxe, comporableCategory);
    }

    if (rotateLabels !== undefined) {
        xAxe.renderer.labels.template.rotation = rotateLabels;
        xAxe.renderer.labels.template.horizontalCenter = 'middle';
        xAxe.renderer.labels.template.verticalCenter = 'middle';
    }
};

const Chart = ({
    id,
    data,
    classes,
    numberFormat,
    seriesType,
    title,
    minY,
    series,
    disableAxis,
    legend,
    comporableCategory,
    rotateLabels,
}: IProps) => {
    const defaultDisableAxis = seriesType === 'column';
    const chartSeries = useMemo(() => {
        if (series !== undefined) {
            return series;
        }

        const serie = seriesType === 'column' ? new ColumnSeries() : new LineSeries();
        const bullet = new CircleBullet();
        const label = new LabelBullet();

        serie.dataFields.categoryX = 'period';
        serie.dataFields.valueY = 'value';
        label.label.text = '{value}';
        label.verticalCenter = 'top';
        label.label.dy = -15;
        serie.bullets.push(label);

        if (seriesType !== 'column') {
            serie.bullets.push(bullet);
        }

        if (seriesType === 'column') {
            (serie as ColumnSeries).columns.template.maxWidth = 100;
            if (comporableCategory !== undefined) {
                styleComporableSerie(serie as ColumnSeries, comporableCategory);
            }
        }

        return [serie];
    }, [seriesType, series, comporableCategory]);

    return (
        <div className={classes.chartWrapper}>
            <Typography variant="h5" className={classes.title} dangerouslySetInnerHTML={{ __html: title }} />
            <XYChart
                id={id}
                data={data}
                initAxes={initAxes(disableAxis ?? defaultDisableAxis, minY, comporableCategory, rotateLabels)}
                series={chartSeries}
                numberFormat={numberFormat}
                className={classes.chart}
                legend={legend}
            />
        </div>
    );
};

export default withStyles(styles)(Chart);

/** @author Artem Bakulin <dekkyartem@gmail.com> */

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
    cardsWrapper: {
        display: 'grid',
        gridTemplateColumns: 'repeat(6, 1fr)',
        alignItems: 'stretch',
        gap: '5mm',
        marginBottom: '6mm',
    },
    fullwidthChart: {
        width: '100%',
        marginBottom: '6mm',
    },
    halfwidthCharts: {
        display: 'grid',
        gap: '8mm 20mm',
        gridTemplateColumns: '1fr 1fr',
    },
    title: {
        fontSize: '4mm',
        fontWeight: 600,
    },
    subtitle: {
        fontSize: '2.5mm',
    },
    summaryCharts: {
        display: 'grid',
        gap: '8mm 20mm',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gridTemplateRows: '1fr 1fr',
    },
    visitsByTypeCharts: {
        width: '75%',
        display: 'grid',
        gap: '20mm 30mm',
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: '1fr 1fr',
    },
    frequency: {
        display: 'grid',
        gridTemplateColumns: '1fr 3fr',
        marginBottom: '20mm',
        gap: '20mm',
    },
    term: {
        fontSize: '4.5mm',
        marginBottom: '6mm',
    },
    bold: {
        fontWeight: 'bold',
    },
    prices: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: 'repeat(6, 1fr)',
        width: 'fit-content',
    }
}));

import { makeStyles } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';

export const styles = makeStyles(() => ({
    root: {
        color: blue[300],
        fontSize: 25,
    },
    wrapper: {
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 25,
        backgroundColor: '#fff',
        width: 70,
        height: 40,
        border: `1px solid ${blue[300]}`,
        marginLeft: 16,
    }
}));
/**
 * Reducers для аналитического календаря (выбор периодов дат для аналитических страниц)
 *
 * @author Artem Bakulin
 */

import { Action, handleActions } from 'redux-actions';

import { analyticsCalendarActions } from '../actions/analytics.calendar';
import { CURRENT_PERIOD_TYPE_MAPPING } from '../lib/services/dates.periods.service';
import {
    CurrentPeriod,
    CurrentPeriodPresetsTypes,
    CurrentPeriodTypeEnum,
    IDatesPeriod,
    LastPeriodTypeEnum,
} from '../types/date.periods';

export interface IAnalyticsCalendarState {
    currentPeriodType: CurrentPeriodTypeEnum;
    lastPeriodType: LastPeriodTypeEnum;
    currentPeriod: CurrentPeriod;
    customLastPeriod?: IDatesPeriod;
    isCompareEnabled: boolean;
}

const initialState: IAnalyticsCalendarState = {
    currentPeriodType: CurrentPeriodTypeEnum.Last7Days,
    lastPeriodType: LastPeriodTypeEnum.Prior,
    currentPeriod: CURRENT_PERIOD_TYPE_MAPPING[CurrentPeriodTypeEnum.Last7Days],
    isCompareEnabled: true
};

/**
 * Reducers для аналитического календаря (выбор периодов дат для аналитических страниц)
 */
// tslint:disable-next-line:no-any
export const analyticsCalendarReducer = handleActions<IAnalyticsCalendarState, any>(
    {
        [analyticsCalendarActions.analyticsCalendar.changeCurrentPeriodType.toString()]: (
            state: IAnalyticsCalendarState,
            action: Action<CurrentPeriodPresetsTypes>,
        ) => ({
            ...state,
            currentPeriodType: action.payload,
            currentPeriod: CURRENT_PERIOD_TYPE_MAPPING[action.payload],
            lastPeriodType:
                state.lastPeriodType === LastPeriodTypeEnum.Custom ? LastPeriodTypeEnum.Prior : state.lastPeriodType,
            customLastPeriod: undefined,
        }),
        [analyticsCalendarActions.analyticsCalendar.changeLastPeriodType.toString()]: (
            state: IAnalyticsCalendarState,
            action: Action<LastPeriodTypeEnum>,
        ) => ({
            ...state,
            lastPeriodType: action.payload,
            customLastPeriod: undefined,
        }),
        [analyticsCalendarActions.analyticsCalendar.setCustomCurrentPeriod.toString()]: (
            state: IAnalyticsCalendarState,
            action: Action<IDatesPeriod>,
        ) => ({
            ...state,
            currentPeriodType: CurrentPeriodTypeEnum.Custom,
            currentPeriod: {
                type: 'custom',
                period: action.payload,
            },
            lastPeriodType: LastPeriodTypeEnum.Prior,
            customLastPeriod: undefined,
        }),
        [analyticsCalendarActions.analyticsCalendar.setCustomLastPeriod.toString()]: (
            state: IAnalyticsCalendarState,
            action: Action<IDatesPeriod>,
        ) => ({
            ...state,
            lastPeriodType: LastPeriodTypeEnum.Custom,
            customLastPeriod: action.payload,
        }),
        [analyticsCalendarActions.analyticsCalendar.toggleComparePeriod.toString()]: (
            state: IAnalyticsCalendarState,
            action: Action<boolean>,
        ) => ({
            ...state,
            isCompareEnabled: action.payload
        })
    },
    initialState,
);

import { Button, Grid, Typography, Select, MenuItem, Checkbox, WithStyles, withStyles, Divider } from '@material-ui/core';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ruLocale from "date-fns/locale/ru";
import moment from 'moment';
import React, { useState, useEffect, useCallback } from 'react';

import {
    getDatesPeriodByType,
    getDatesPeriodByCurrentPeriod,
    getLastDatesPeriodByType,
    getCurrentPeriodPresetByType,
} from '../../lib/services/dates.periods.service';

import { PolyglotSingleton } from '../../lib/services/translation';
import { IAnalyticsCalendarState } from '../../reducers/analytics.calendar';
import { CurrentPeriodTypeEnum, IDatesPeriod, LastPeriodTypeEnum} from '../../types/date.periods';
import AnalyticsCalendarDatepicker from '../AnalyticsCalendarDatepicker';
import {IAnalyticsCalenderDatepickerPeriods} from '../AnalyticsCalendarDatepicker';

import { styles } from './styles';

export const MIN_DATE_VNUKOVO = moment('2018-08-10').toDate();

interface IProps extends IAnalyticsCalendarState, WithStyles<typeof styles> {
    changeCurrentPeriodType(type: CurrentPeriodTypeEnum): void;
    changeLastPeriodType(type: LastPeriodTypeEnum): void;
    setCustomCurrentPeriod(period: IDatesPeriod): void;
    setCustomLastPeriod(period: IDatesPeriod): void;
    toggleComparePeriod(compareToggle: boolean): void;
    toggleCalendar(): void
}

interface IAnalyticsCalendarLocalState  {
    isCompareEnabled: boolean;
    selectedCurrentPeriodDates: IDatesPeriod;
    selectedCurrentPeriodType: CurrentPeriodTypeEnum;
    selectedLastPeriodDates: IDatesPeriod;
    selectedLastPeriodType: LastPeriodTypeEnum;
    selectablePeriod: 'current' | 'last';
}

const polyglot = PolyglotSingleton.getInstance();

const changeCurrentPeriodTypeEvent = (changeCurrentPeriodTypeAction: (type: CurrentPeriodTypeEnum) => void) => (
    value: CurrentPeriodTypeEnum | null,
) => {
    if (value !== null) {
        changeCurrentPeriodTypeAction(value);
    }
};

const changeLastPeriodTypeEvent = (changeLastPeriodTypeAction: (type: LastPeriodTypeEnum) => void) => (
    value: LastPeriodTypeEnum | null,
) => {
    if (value !== null) {
        changeLastPeriodTypeAction(value);
    }
};

const isDisableComparativeKeyboardDatePicker = (isEnabled: boolean): boolean =>
    !isEnabled;

const AnalyticsCalendar = ({
    classes,
    currentPeriod,
    currentPeriodType,
    changeCurrentPeriodType,
    lastPeriodType,
    customLastPeriod,
    changeLastPeriodType,
    setCustomCurrentPeriod,
    setCustomLastPeriod,
    toggleComparePeriod,
    isCompareEnabled,
    toggleCalendar
}: IProps) => {
    const currentPeriodDates = getDatesPeriodByCurrentPeriod(currentPeriod);
    const lastPeriodDates =
        lastPeriodType !== LastPeriodTypeEnum.Custom
            ? getLastDatesPeriodByType(currentPeriod, lastPeriodType)
            : (customLastPeriod as IDatesPeriod);

    const initialLocalState: IAnalyticsCalendarLocalState = {
        isCompareEnabled,
        selectablePeriod: 'current',
        selectedCurrentPeriodDates: currentPeriodDates,
        selectedCurrentPeriodType: currentPeriodType,
        selectedLastPeriodDates: lastPeriodDates,
        selectedLastPeriodType: lastPeriodType,
    }

    const [localState, setLocalState] = useState(initialLocalState);

    const selectCurrentPeriod = (value: CurrentPeriodTypeEnum) => {
        if (value === CurrentPeriodTypeEnum.Custom) {
            setLocalState(state => ({...state, isCurrentPeriodEditable: true, selectedCurrentPeriodType: value}));
        } else {
            setLocalState(state => (
                {
                    ...state,
                    isCurrentPeriodEditable: false,
                    selectedCurrentPeriodDates: getDatesPeriodByCurrentPeriod(getCurrentPeriodPresetByType(value)),
                    selectedCurrentPeriodType: value,
                }
            ));
        }
    };

    const selectCustomCurrentPeriod = useCallback((from: Date | null | undefined, to: Date | null | undefined) => {
        if (!from || !to || isNaN(from?.valueOf()) || isNaN(to?.valueOf())) {
            return;
        }

        selectCurrentPeriod(CurrentPeriodTypeEnum.Custom);
        setLocalState(state => (
            {...state, selectedCurrentPeriodDates: {from, to}}
        ));
    }, []);

    const selectCustomLastPeriod = useCallback((from: Date | null | undefined, to: Date | null | undefined) => {
        if (!from || !to || isNaN(from?.valueOf()) || isNaN(to?.valueOf())) {
            return;
        }

        selectLastPeriod(LastPeriodTypeEnum.Custom);
        setLocalState(state => ({...state, selectedLastPeriodDates: {from, to}}));
    }, []);

    const selectLastPeriod = (value: LastPeriodTypeEnum) => {
        if (value === LastPeriodTypeEnum.Custom) {
            setLocalState(state => ({...state, isLastPeriodEditable: true, selectedLastPeriodType: value}));
        } else {
            setLocalState(state => (
                {
                    ...state,
                    isLastPeriodEditable: false,
                    selectedLastPeriodDates: getDatesPeriodByType(state.selectedCurrentPeriodDates, value),
                    selectedLastPeriodType: value,
                }
            ));
        }
    };


    const toggleCompare = (value: any) => {
        setLocalState(state => ({...state, isCompareEnabled: value}));
    };

    const resetCalendar = () => {
        setLocalState(initialLocalState);
    }

    const submitCalendar = () => {
        toggleCalendar();

        if (isCompareEnabled !== localState.isCompareEnabled) {
            toggleComparePeriod(localState.isCompareEnabled);
        }

        if (localState.selectedCurrentPeriodType === CurrentPeriodTypeEnum.Custom) {
            setCustomCurrentPeriod(localState.selectedCurrentPeriodDates);
        } else {
            changeCurrentPeriodTypeEvent(changeCurrentPeriodType)(localState.selectedCurrentPeriodType);
        }

        if (!localState.isCompareEnabled) {
            return;
        }

        if (localState.selectedLastPeriodType === LastPeriodTypeEnum.Custom) {
            setCustomLastPeriod(localState.selectedLastPeriodDates);
        } else {
            changeLastPeriodTypeEvent(changeLastPeriodType)(localState.selectedLastPeriodType);
        }
    };

    useEffect(() => {
        if (localState.isCompareEnabled) {
            selectLastPeriod(localState.selectedLastPeriodType);
        }
    }, [localState.selectedCurrentPeriodDates, localState.selectedLastPeriodType, localState.isCompareEnabled]);

    const onDatePickerChange = useCallback((event: IAnalyticsCalenderDatepickerPeriods) => {
        if(localState.selectablePeriod === 'current') {
            selectCurrentPeriod(CurrentPeriodTypeEnum.Custom);

            selectCustomCurrentPeriod(event.currentPeriod?.from, event.currentPeriod?.to);
            if (localState.isCompareEnabled) {
                setLocalState(state => ({...state, selectablePeriod: 'last'}));
            }
        } else {
            selectLastPeriod(LastPeriodTypeEnum.Custom);
            selectCustomLastPeriod(event.lastPeriod?.from, event.lastPeriod?.to);
            setLocalState(state => ({...state, selectablePeriod: 'current'}));
        }
    }, [localState, selectCustomCurrentPeriod, selectCustomLastPeriod]);

    return (
        <Grid item container alignItems="center" className={classes.root}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
                <Grid item xs={9} xl={8} container className={classes.datePicker}>
                    <AnalyticsCalendarDatepicker
                        currentPeriodDefault={localState.selectedCurrentPeriodDates}
                        lastPeriodDefault={localState.selectedLastPeriodDates}
                        selectablePeriod={localState.selectablePeriod}
                        isCompareEnabled={localState.isCompareEnabled}
                        onChange={onDatePickerChange}
                    />
                </Grid>

                <Grid item xs={3} xl={4} container spacing={1} justify="space-between" alignContent="center" direction="column" className={classes.periodControls}>
                    <Grid item container alignContent="center" direction="column">
                        <Grid item container spacing={3} wrap="nowrap" alignItems="center" justify="flex-end">
                            <Grid item xs={6} container justify="flex-end">
                                <Grid item>
                                    <Typography variant="caption" align="right">
                                        {polyglot.t('dashboard.datesInterval')}:
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid item xs={6}>
                                <Select
                                    className={classes.periodSelect}
                                    value={localState.selectedCurrentPeriodType}
                                    onChange={(event) => selectCurrentPeriod(event.target.value as CurrentPeriodTypeEnum)}
                                >
                                    <MenuItem value={CurrentPeriodTypeEnum.LastWeek}>
                                        {polyglot.t('common.periods.lastWeek')}
                                    </MenuItem>

                                    <MenuItem value={CurrentPeriodTypeEnum.Last7Days}>
                                    {polyglot.t('common.periods.lastPrefix')} {polyglot.t('common.periods.days', 7)}
                                    </MenuItem>

                                    <MenuItem value={CurrentPeriodTypeEnum.CurrentMonth}>
                                        {polyglot.t('common.periods.currentMonth')}
                                    </MenuItem>

                                    <MenuItem value={CurrentPeriodTypeEnum.LastMonth}>
                                        {polyglot.t('common.periods.lastMonth')}
                                    </MenuItem>

                                    <MenuItem value={CurrentPeriodTypeEnum.Last30Days}>
                                    {polyglot.t('common.periods.lastPrefix')} {polyglot.t('common.periods.days', 30)}
                                    </MenuItem>

                                    <MenuItem value={CurrentPeriodTypeEnum.CurrentYear}>
                                        {polyglot.t('common.periods.currentYear')}
                                    </MenuItem>

                                    <Divider />

                                    <MenuItem value={CurrentPeriodTypeEnum.Custom}>
                                        {polyglot.t('common.periods.custom')}
                                    </MenuItem>
                                </Select>
                            </Grid>
                        </Grid>

                        <Grid item container spacing={1} wrap="nowrap" alignItems="center" justify="flex-end">
                            <Grid item>
                                <KeyboardDatePicker
                                    value={localState.selectedCurrentPeriodDates.from}
                                    disableToolbar
                                    variant="inline"
                                    format="dd MM yyyy"
                                    margin="normal"
                                    onChange={(event) => {selectCustomCurrentPeriod(event, localState.selectedCurrentPeriodDates.to)}}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </Grid>
                            <Grid item>-</Grid>
                            <Grid item>
                                <KeyboardDatePicker
                                    value={localState.selectedCurrentPeriodDates.to}
                                    disableToolbar
                                    variant="inline"
                                    format="dd MM yyyy"
                                    margin="normal"
                                    minDate={localState.selectedCurrentPeriodDates.from}
                                    onChange={(event) => {selectCustomCurrentPeriod(localState.selectedCurrentPeriodDates.from, event)}}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Grid item container spacing={3} wrap="nowrap" alignItems="center" justify="flex-end">
                            <Grid item xs={6} container alignItems="center" justify="flex-end">
                                <Grid item>
                                    <Checkbox
                                        checked={localState.isCompareEnabled}
                                        onChange={(event) => toggleCompare(event.target.checked)}
                                    />
                                </Grid>
                                <Grid item>
                                    {polyglot.t('dashboard.compareWith')}:
                                </Grid>
                            </Grid>

                            <Grid item xs={6}>
                                <Select
                                    className={classes.periodSelect}
                                    disabled={!localState.isCompareEnabled}
                                    autoWidth={true}
                                    value={localState.selectedLastPeriodType}
                                    onChange={(event) => selectLastPeriod(event.target.value as LastPeriodTypeEnum)}
                                >
                                    <MenuItem value={LastPeriodTypeEnum.Prior}>
                                        {polyglot.t('dashboard.precedingPeriod')}
                                    </MenuItem>

                                    <MenuItem value={LastPeriodTypeEnum.Comparable}>
                                        {polyglot.t('dashboard.comparablePeriod')}
                                    </MenuItem>

                                    <Divider />

                                    <MenuItem value={LastPeriodTypeEnum.Custom}>
                                        {polyglot.t('common.periods.custom')}
                                    </MenuItem>
                                </Select>
                            </Grid>
                        </Grid>

                        <Grid item container spacing={1} wrap="nowrap" alignItems="center" justify="flex-end">
                            <Grid item>
                                <KeyboardDatePicker
                                    disabled={isDisableComparativeKeyboardDatePicker(localState.isCompareEnabled)}
                                    value={localState.selectedLastPeriodDates.from}
                                    disableToolbar
                                    variant="inline"
                                    format="dd MM yyyy"
                                    margin="normal"
                                    onChange={(event) => {selectCustomLastPeriod(event, localState.selectedLastPeriodDates.to)}}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </Grid>
                            <Grid item>-</Grid>
                            <Grid item>
                                <KeyboardDatePicker
                                    disabled={isDisableComparativeKeyboardDatePicker(localState.isCompareEnabled)}
                                    value={localState.selectedLastPeriodDates.to}
                                    disableToolbar
                                    variant="inline"
                                    format="dd MM yyyy"
                                    margin="normal"
                                    minDate={localState.selectedLastPeriodDates.from}
                                    onChange={(event) => {selectCustomLastPeriod(localState.selectedLastPeriodDates.from, event)}}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </Grid>
                        </Grid>

                    </Grid>


                    <Grid item container justify="flex-end">
                        <Grid item>
                            <Button className={classes.periodReset} variant="contained" onClick={resetCalendar} color="default">
                                {polyglot.t('dashboard.reset')}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" onClick={submitCalendar} color="primary">
                                {polyglot.t('dashboard.apply')}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </MuiPickersUtilsProvider>
        </Grid>
    );
};

export default withStyles(styles)(AnalyticsCalendar);

/**
 * Страница "Новые авто" месячного PDF отчета.
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import moment from 'moment';
import React from 'react';
import { ColumnChart } from '../../../components/Pdf/Charts/ColumnChart';

import { calcIncrease } from '../../../components/Pdf/Charts/helpers';
import { LineChart } from '../../../components/Pdf/Charts/LineChart';
import { Typo } from '../../../components/Pdf/Typo';
import { PolyglotSingleton } from '../../../lib/services/translation';
import { IChartSummaryData, IMonthSummaryData } from '../../../types/analytics';
import { CHART_DATE_FORMAT } from '../Summary';

import { useStyles } from './styles';

interface IProps {
    data: IMonthSummaryData;
}

const polyglot = PolyglotSingleton.getInstance();

const getChartData = (key: keyof IChartSummaryData['avgNewVisitors'], data: IMonthSummaryData): number[] => [
    data.comporable[0].avgNewVisitors[key],
    ...data.months.slice(1).map(row => row.avgNewVisitors[key]),
];

export const NewCarsPage = ({ data }: IProps) => {
    const classes = useStyles();

    const newData = data.weeks.newPercents;
    const weekData: number[][] = [
        [...newData.current.slice(1).map(row => row.value)],
    ];
    const weeks = [...newData.current.slice(1).map(row => row.date)];
    const labels = newData.current.slice(1).map(row => row.week);
    const weekMonthIncrease = (new Array(labels.length)).fill(null);
    for (let i = 0; i <= weekData[0].length - 1; i += 1) {
        weekMonthIncrease[i] =
            calcIncrease(weekData[0][i], newData.current[i].value);
    }

    const months = [
        moment(data.comporable[0].date).format(CHART_DATE_FORMAT),
        ...data.months.slice(1).map(month => moment(month.date).format(CHART_DATE_FORMAT)),
    ];
    const getData = (key: keyof IChartSummaryData['avgNewVisitors']) => getChartData(key, data);
    const getYearIncrease = (key: keyof IChartSummaryData['avgNewVisitors']) => {
        const keyData = getData(key);
        const yearIncrease = new Array(keyData.length).fill(null);

        yearIncrease[yearIncrease.length - 1] = calcIncrease(keyData[keyData.length - 1], keyData[0]);

        return yearIncrease;
    };
    const getMonthIncrease = (key: keyof IChartSummaryData['avgNewVisitors']) => {
        const keyData = getData(key);
        const rawData = data.months.map(row => row.avgNewVisitors[key]);

        return keyData.map((value, index) => (index > 0 ? calcIncrease(value, rawData[index - 1]) : null));
    };

    const maxValue = Math.max(
        ...getData('avgInWorkday'),
        ...getData('avgInHoliday'),
    );

    return (
        <>
            <div className={classes.fullwidthChart}>
                <LineChart
                    title={polyglot.t('pdf.charts.shareNewCars')}
                    labels={labels}
                    data={weekData}
                    monthIncrease={weekMonthIncrease}
                    valueFormat="percent"
                    size="big"
                    weeks={weeks}
                />
            </div>
            <div style={{ paddingTop: '12mm' }}>
                <Typo className={classes.title}>{polyglot.t('pdf.charts.newInDay.title')}</Typo>
                <Typo className={classes.subtitle}>{polyglot.t('pdf.charts.newInDay.hint')}</Typo>
            </div>
            <div className={classes.halfwidthCharts}>
                <ColumnChart
                    labels={months}
                    data={getData('avgInWorkday')}
                    pastYearDivider={1}
                    yearIncrease={getYearIncrease('avgInWorkday')}
                    monthIncrease={getMonthIncrease('avgInWorkday')}
                    legend={polyglot.t('pdf.charts.newInDay.weekdays')}
                    showFooter
                    size="big"
                    max={maxValue}
                />
                <ColumnChart
                    labels={months}
                    data={getData('avgInHoliday')}
                    pastYearDivider={1}
                    yearIncrease={getYearIncrease('avgInHoliday')}
                    monthIncrease={getMonthIncrease('avgInHoliday')}
                    legend={polyglot.t('pdf.charts.newInDay.weekends')}
                    size="big"
                    max={maxValue}
                />
            </div>
        </>
    );
};

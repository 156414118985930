/**
 * Утилиты для работы с датами
 *
 * @authro Artem Bakulin
 */

import moment from 'moment';

import {
    AnalyticsDataGranularity,
    CurrentPeriodTypeEnum,
    IDatesPeriod,
    LastPeriodTypeEnum,
    SeasonsEnum,
    SeasonsNames,
} from '../../types/date.periods';
import { PeriodFormatFunction } from '../../types/global';
import { PolyglotSingleton } from '../services/translation';

const polyglot = PolyglotSingleton.getInstance();

/**
 * Возвращает Date из строки / числа / Moment или другой даты.
 *
 * @param date Переменная, которая может быть преобразованна в дату
 */
export const toDate = (date: Date | moment.Moment | string | number) => moment(date).toDate();

/**
 * Вычисляет количество дней между двумя датами с учетом того, что между двумя одинаковыми датами один день.
 *
 * @param date1 Дата
 * @param date2 Дата
 */
export const daysBetweenDates: (date1: Date, date2: Date, include?: boolean) => number = (
    date1: Date,
    date2: Date,
    include: boolean = true,
): number => {
    if (date1.valueOf() < date2.valueOf()) {
        return daysBetweenDates(date2, date1);
    }

    return (
        Math.abs(
            Math.ceil(
                (moment(date1)
                    .endOf('day')
                    .valueOf() -
                    moment(date2)
                        .startOf('day')
                        .valueOf()) /
                    86400000,
            ),
        ) - (include ? 1 : 0)
    );
};

/**
 * Возвращает время года / сезон для указанной даты
 *
 * @param date Дата, для которой необходимо определить время года / сезон
 */
export const getSeasonByDate = (date: Date): SeasonsEnum => {
    const month = date.getMonth();
    const monthIndex = month === 11 ? 0 : month + 1;

    return Math.floor(monthIndex / 3);
};

/**
 * Возвращает период дат для указанного времени года / сезона в году
 *
 * @param season Время года / сезон
 * @param year Год, для которого необходимо найти период дат в сезоне
 */
export const getSeasonDatePeriodForYear = (season: SeasonsEnum, year: number): IDatesPeriod => {
    if (season === SeasonsEnum.Winter) {
        return {
            from: moment()
                .year(year - 1)
                .month(11)
                .startOf('month')
                .toDate(),
            to: moment()
                .year(year)
                .month(1)
                .endOf('month')
                .toDate(),
        };
    }

    const from = moment()
        .year(year)
        .month(season * 2 + (season - 1))
        .startOf('month')
        .toDate();

    return {
        from,
        to: moment(from)
            .add(2, 'months')
            .endOf('month')
            .toDate(),
    };
};

/**
 * Возвращает формат даты для графиков, в зависимости от типа выбранных дат на календаре
 */
export const getPeriodFormat = (
    currentPeriod: CurrentPeriodTypeEnum,
    lastPeriod: LastPeriodTypeEnum
): string | PeriodFormatFunction => {
    if (
        currentPeriod === CurrentPeriodTypeEnum.Custom ||
        [LastPeriodTypeEnum.Custom, LastPeriodTypeEnum.Comparable].includes(lastPeriod)
    ) {
        return polyglot.t(`common.dates.dateShort`);
    }

    if (currentPeriod === CurrentPeriodTypeEnum.LastMonth) {
        return polyglot.t('common.dates.monthYearString');
    }

    if (currentPeriod === CurrentPeriodTypeEnum.LastSeason) {
        return (dateFrom: Date) =>
            `${polyglot.t(SeasonsNames[getSeasonByDate(dateFrom)])} ${moment(dateFrom).format('YY')}`;
    }

    return polyglot.t(`common.dates.dateShortPdf`);
};

/**
 * Проверяет корректный передан период или нет
 *
 * @param period Период, который необходимо проверить на корректность данных
 */
export const isValidPeriod = (period?: Partial<IDatesPeriod>): boolean =>
    period !== undefined
    && period.from !== undefined
    && period.to !== undefined
    && period.from.valueOf() <= period.to.valueOf();

/**
 * Возвращает строку с датой для графиков с гранулярностью
 *
 * @param period Период данных
 * @param indexOfKey Текущий индекс значения с серии занчений
 * @param granularity Граннулярность данных
 */
export const getPeriodKey = (period: IDatesPeriod, indexOfKey: number, granularity: AnalyticsDataGranularity): string => {
    const curDate = granularity !== 'all' ? moment(period.from).add(indexOfKey, granularity) : moment(period.from);

    switch (granularity) {
        case 'quarter':
        case 'month':
            return curDate.format('MMM');
        default:
            return curDate.format('D.MM');
    }
};

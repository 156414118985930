import { createStyles, Theme } from '@material-ui/core';
// tslint:disable-next-line:no-submodule-imports
import grey from '@material-ui/core/colors/grey';

export const styles = (theme: Theme) =>
    createStyles({
        root: {
            color: theme.palette.text.primary,
            textTransform: 'none',
            height: 40,
            borderRight: `1px solid ${grey[200]}`,
            padding: '8px 16px',
            whiteSpace: 'nowrap',

            '&:last-child': {
                borderRight: 'none',
            },

            '&$selected': {
                color: `${theme.palette.common.white} !important`,
                backgroundColor: `${theme.palette.primary.main} !important`,
            },
        },
    });

/**
 * Раздел "Лояльность" годового отчета.
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import { CategoryAxis, ColumnSeries, LabelBullet, ValueAxis } from '@amcharts/amcharts4/charts';
import { Grid, Typography, WithStyles, withStyles } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect } from 'react';

import PieChart from '../../components/Charts/PieChart';
import XYChart from '../../components/Charts/XYChart';
import Translate from '../../components/Translate';
import { IYearSummaryData } from '../../types/analytics';

import { styles } from './styles';

interface IProps extends WithStyles<typeof styles> {
    lastVisits: IYearSummaryData['lastVisits'];
    loyaltyVisits: IYearSummaryData['loyaltyVisits'];
}

const getYAxes = () => {
    const yAxe = new ValueAxis();
    yAxe.min = 0;

    return [yAxe];
};

const getXAxes = () => {
    const xAxe = new CategoryAxis();
    xAxe.dataFields.category = 'label';

    return [xAxe];
};

const getSeries = () => {
    const label = new LabelBullet();
    label.label.text = '{value}';
    label.label.dy = -15;
    label.label.truncate = false;
    label.numberFormatter.numberFormat = '#.a';

    const serie = new ColumnSeries();
    serie.dataFields.categoryX = 'label';
    serie.dataFields.valueY = 'value';
    serie.columns.template.tooltipText = '[bold]{name}[/]\n[font-size:14px]{valueY}';
    serie.bullets.push(label);

    return [serie];
};

const getFreqData = (data: IYearSummaryData['loyaltyVisits']) =>
    Object.keys(data).map(key => ({
        label: key,
        value: data[key],
    }));

const getFreqPercentsData = (data: IYearSummaryData['loyaltyVisits']) => {
    const freqResult = getFreqData(data);
    const total = freqResult.reduce((a, b) => a + b.value, 0);
    const freqPercents: Array<{ label: string; value: number }> = [];
    /* tslint:disable:no-magic-numbers */
    freqPercents.push(
        {
            label: '1',
            value: freqResult[0].value > 0 ? freqResult[0].value / total : 0,
        },
        {
            label: '2',
            value: freqResult[1].value > 0 ? freqResult[1].value / total : 0,
        },
        {
            label: '3-6',
            value:
                freqResult.slice(2, 6).reduce((a: number, b) => a + b.value, 0) > 0
                    ? freqResult.slice(2, 6).reduce((a: number, b) => a + b.value, 0) / total
                    : 0,
        },
        {
            label: '7-12',
            value:
                freqResult.slice(6, 12).reduce((a, b) => a + b.value, 0) > 0
                    ? freqResult.slice(6, 12).reduce((a, b) => a + b.value, 0) / total
                    : 0,
        },
        {
            label: '>12',
            value:
                freqResult.slice(12).reduce((a, b) => a + b.value, 0) > 0
                    ? freqResult.slice(12).reduce((a, b) => a + b.value, 0) / total
                    : 0,
        },
    );
    /* tslint:enable:no-magic-numbers */

    return freqPercents;
};

const getLastVisitsData = (data: IYearSummaryData['lastVisits']) =>
    data.map(row => ({
        label: moment(row.date, 'YYYY-M-DD').format('MMM YY'),
        value: row.uniqs,
    }));

const LoyaltyPage = ({ loyaltyVisits, lastVisits, classes }: IProps) => {
    const loyaltyXAxes = getXAxes();
    const loyaltyYAxes = getYAxes();
    const lastXAxes = getXAxes();
    const lastYAxes = getYAxes();

    useEffect(() => {
        loyaltyXAxes[0].renderer.grid.template.disabled = true;
        loyaltyYAxes[0].renderer.grid.template.disabled = true;
        loyaltyYAxes[0].renderer.inside = true;
        loyaltyYAxes[0].renderer.labels.template.disabled = true;

        lastXAxes[0].renderer.grid.template.disabled = true;
        lastYAxes[0].renderer.grid.template.disabled = true;
        lastYAxes[0].renderer.inside = true;
        lastYAxes[0].renderer.labels.template.disabled = true;
    }, [loyaltyXAxes, loyaltyYAxes, lastXAxes, lastYAxes]);

    return (
        <Grid
            container
            spacing={3}
            alignItems="stretch"
            direction="column"
            wrap="nowrap"
            className={classes.fullHeight}
        >
            <Grid item xs={12} container spacing={3} alignItems="stretch" className={classes.chartRow}>
                <Grid item xs={6}>
                    <Typography variant="h5" className={classes.title}>
                        <Translate phrase="loyalty.charts.byFrequency" />
                    </Typography>
                    <XYChart
                        id="loyaltyFrequencyChart"
                        data={getFreqData(loyaltyVisits)}
                        numberFormat="#.a"
                        xAxes={loyaltyXAxes}
                        yAxes={loyaltyYAxes}
                        series={getSeries()}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h5" className={classes.title}>
                        <Translate phrase="loyalty.charts.byFrequencyPercents" />
                    </Typography>
                    <PieChart
                        id="loyaltyFrequencyPercentsChart"
                        data={getFreqPercentsData(loyaltyVisits)}
                        numberFormat="#.%"
                        legend="top"
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} className={classes.chartRow}>
                <Typography variant="h5" className={classes.title}>
                    <Translate phrase="loyalty.charts.byUniqs" />
                </Typography>
                <XYChart
                    id="loyaltyUniqsChart"
                    data={getLastVisitsData(lastVisits)}
                    numberFormat="#.a"
                    xAxes={lastXAxes}
                    yAxes={lastYAxes}
                    series={getSeries()}
                />
            </Grid>
        </Grid>
    );
};

export default withStyles(styles)(LoyaltyPage);

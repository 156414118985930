/**
 * Общие типы всего приложения
 *
 * @author Artem Bakulin
 */

/* tslint:disable:member-ordering */

export interface INotification {
    message: string;
    type: 'error' | 'info' | 'success';
}

/**
 * Ответ с сервера с учетом пагинации
 */
export interface IPaginationResult<T> {
    /** Массив запрашиваемых значений */
    items: T[];
    /** Общее кол-во всех записей в БД */
    total: number;
}

export type NumberFormatFunction = (value: number) => string;
export type NumberLocaleFormat = 'decimal' | 'percent' | NumberFormatFunction | Intl.NumberFormatOptions;
export type PeriodFormatFunction = (dateFrom: Date, dateTo: Date) => string;

export type IAnalyticsPageResult<T> = { [period: string]: T } | undefined;
export interface IAnalyticsPageState<T> {
    results: { [hash: string]: IAnalyticsPageResult<T> | undefined };
    inProgress: boolean;
    error?: Error;
}

export interface IAppConfig {
    smartLookKey?: string;
}

export type ChartDataType = 'visits' | 'uniqs';

export enum Language {
    RU = 'ru',
    EN = 'en',
    ES = 'es',
    FR = 'fr',
}

import { ITranslation } from '../lib/services/translation';

import { BaseApi } from './abstract.api';

/**
 * API для работы с переводами
 */
class TranslationApi extends BaseApi {
    public constructor() {
        super('/areas/translation');
    }

    /**
     * Получение блока переводов
     */
    public async get(namespace: string, lang?: string): Promise<ITranslation> {
        return (await this.axios.get(`/ns/${namespace}${lang !== undefined ? `/${lang}` : ''}`)).data as ITranslation;
    }

    /**
     * Получение блока переводов
     */
    public async all(lang?: string): Promise<ITranslation> {
        return (await this.axios.get(lang !== undefined ? `/${lang}` : '')).data as ITranslation;
    }
}

export default new TranslationApi();

/**
 * Едииная кнопка для генерации отчета.
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import { Button } from '@material-ui/core';
import React from 'react';

import Translate from '../../components/Translate';

interface IProps {
    onClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
    className?: string | undefined;
}

const ReportButton = ({ onClick, className }: IProps) => (
    <Button className={className} color="primary" variant="outlined" onClick={onClick}>
        <Translate phrase="reports.generateReport" />
    </Button>
);

export default ReportButton;

import {
    AppBar,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Toolbar,
    Typography,
    WithStyles,
    withStyles,
} from '@material-ui/core';
import { AccountCircle, ExitToApp, Language as LanguageIcon, Settings } from '@material-ui/icons';
import { IconFlagRU, IconFlagUS, IconFlagES, IconFlagFR } from 'material-ui-flags';
import React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import { PolyglotSingleton } from '../../lib/services/translation';
import { IAppState } from '../../reducers';
import { Language } from '../../types/global';
import Logo from '../Logo';

import { styles } from './styles';

interface IProps extends WithStyles<typeof styles>, RouteComponentProps {
    user: IAppState['global']['user'];
    logout(): void;
    changeLanguage(lng: Language): void;
}

interface IState {
    profileEl?: HTMLElement;
    lngEl?: HTMLElement;
}

const polyglot = PolyglotSingleton.getInstance();

class Header extends React.PureComponent<IProps, IState> {
    public constructor(props: IProps) {
        super(props);

        this.state = {};
    }

    public render() {
        const { classes, user, logout } = this.props;
        const isOpenProfile = this.state.profileEl !== undefined;
        const isOpenLng = this.state.lngEl !== undefined;

        return (
            <AppBar color="default" className={classes.root}>
                <Toolbar className={classes.toolbar}>
                    <Link to="/">
                        <Logo />
                    </Link>

                    <div>
                        <IconButton
                            aria-owns={isOpenProfile ? 'profileMenu' : undefined}
                            aria-haspopup
                            onClick={this.handleProfileClick}
                        >
                            <AccountCircle />
                        </IconButton>
                        <Menu
                            id="profileMenu"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            anchorEl={this.state.profileEl}
                            getContentAnchorEl={null}
                            open={isOpenProfile}
                            onClose={this.handleProfileClose}
                            disableAutoFocusItem
                        >
                            <MenuItem disabled>
                                <Typography>{user !== undefined ? user.fullName : ''}</Typography>
                            </MenuItem>
                            <MenuItem onClick={this.redirectTo('/profile')}>
                                <ListItemIcon>
                                    <Settings />
                                </ListItemIcon>
                                <ListItemText>{polyglot.t('common.user.profile')}</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={logout}>
                                <ListItemIcon>
                                    <ExitToApp />
                                </ListItemIcon>
                                <ListItemText>{polyglot.t('common.user.logout')}</ListItemText>
                            </MenuItem>
                        </Menu>

                        <IconButton
                            aria-owns={isOpenLng ? 'languageMenu' : undefined}
                            aria-haspopup
                            onClick={this.handleLngClick}
                        >
                            <LanguageIcon />
                        </IconButton>
                        <Menu
                            id="languageMenu"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            anchorEl={this.state.lngEl}
                            getContentAnchorEl={null}
                            open={isOpenLng}
                            onClose={this.handleLngClose}
                            disableAutoFocusItem
                        >
                            <MenuItem onClick={this.handleChangeLanguage(Language.RU)}>
                                <ListItemIcon>
                                    <IconFlagRU />
                                </ListItemIcon>
                                <ListItemText>Русский</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={this.handleChangeLanguage(Language.EN)}>
                                <ListItemIcon>
                                    <IconFlagUS />
                                </ListItemIcon>
                                <ListItemText>English</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={this.handleChangeLanguage(Language.ES)}>
                                <ListItemIcon>
                                    <IconFlagES />
                                </ListItemIcon>
                                <ListItemText>Español</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={this.handleChangeLanguage(Language.FR)}>
                                <ListItemIcon>
                                    <IconFlagFR />
                                </ListItemIcon>
                                <ListItemText>Français</ListItemText>
                            </MenuItem>
                        </Menu>
                    </div>
                </Toolbar>
            </AppBar>
        );
    }

    private readonly handleProfileClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({
            profileEl: event.currentTarget,
            lngEl: undefined
        });
        this.setState({ profileEl: event.currentTarget });
    };

    private readonly handleProfileClose = () => {
        this.setState({ profileEl: undefined });
    };

    private readonly handleLngClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({
            profileEl: undefined,
            lngEl: event.currentTarget
        });
    };

    private readonly handleLngClose = () => {
        this.setState({ lngEl: undefined });
    };

    private readonly redirectTo = (path: string) => () => {
        this.handleProfileClose();
        this.props.history.push(path);
    };

    private readonly handleChangeLanguage = (lng: Language) => () => {
        this.props.changeLanguage(lng);
        this.setState({ lngEl: undefined });
    }
}

export default withStyles(styles)(withRouter(Header));

import { createStyles, Theme } from '@material-ui/core';

export const styles = (theme: Theme) =>
    createStyles({
        thumb: {
            border: `2px solid ${theme.palette.common.white}`,
            borderRadius: theme.shape.borderRadius,
            width: theme.spacing(6),
            height: theme.spacing(6),
            transformOrigin: '0 0',
        },
        isZoom: {
            '&:hover': {
                boxShadow: theme.shadows[10],
                transform: 'scale(5)',
            },
        },
    });

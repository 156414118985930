/**
 * Кастомный класс для форматирования чисел в графиках, который в конструкторе принимает функцию офрматирования чисел.
 *
 * @author Artem Bakulin
 */

// tslint:disable-next-line:no-submodule-imports
import { NumberFormatter } from '@amcharts/amcharts4/core';

import { NumberFormatFunction } from '../../../types/global';

/**
 * Кастомный класс для форматирования чисел в графиках, который в конструкторе принимает функцию форматирования чисел.
 */
export class CustomNumberFormatter extends NumberFormatter {
    private readonly formatFunc: NumberFormatFunction;

    public constructor(formatFunction: NumberFormatFunction) {
        super();

        this.formatFunc = formatFunction;
    }

    public format(value: number): string {
        return this.formatFunc(value);
    }
}

/**
 * API для работы с пользователями системы
 *
 * @author Artem Bakulin
 */

import { AxiosPromise } from 'axios';
import { Language } from '../types/global';

import { IUser } from '../types/users';

import { BaseApi } from './abstract.api';

/**
 * API для работы с пользователями системы
 */
class UsersApi extends BaseApi {
    public constructor() {
        super('/users');
    }

    /**
     * Авторизация пользователя в системе
     *
     * @param email Email пользователя
     * @param password Пароль пользователя
     */
    public async authenticate(email: string, password: string): Promise<IUser> {
        return (await this.axios.post<IUser>('/authenticate', { email, password, siteCode: 'analytics' })).data;
    }

    /**
     * Деавторизация пользователя
     */
    public logout(): AxiosPromise<void> {
        return this.axios.delete('/logout');
    }

    /**
     * Получение профиля текущего авторизованного пользователя
     */
    public async profile(): Promise<IUser> {
        return (await this.axios.get<IUser>('/profile')).data;
    }

    /** Изменение языка пользователя */
    public async changeLanguage(lng: Language): Promise<IUser> {
        return (await this.axios.post<IUser>(`/profile/lang/${lng}`)).data;
    }
}

export default new UsersApi();

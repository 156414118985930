import { CircularProgress, Grid, WithStyles, withStyles } from '@material-ui/core';
import React from 'react';

import { styles } from './styles';

interface IProps extends WithStyles<typeof styles> {}

// tslint:disable-next-line:variable-name
const GlobalProgress = ({ classes }: IProps) => (
    <Grid container justify="center" alignItems="center" className={classes.wrapper}>
        <CircularProgress color="secondary" className={classes.progress} />
    </Grid>
);

export default withStyles(styles)(GlobalProgress);

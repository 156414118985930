import { Dialog, DialogContent, DialogContentText, DialogTitle, Typography } from '@material-ui/core';
import React from 'react';

import { PolyglotSingleton } from '../../lib/services/translation';
import { IUserOrganization } from '../../types/organizations';

interface IProps {
    open: boolean;
    onClose(): void;
    organization: IUserOrganization;
    /* tslint:disable:no-null-undefined-union */
    form: React.ReactNode;
    helpText?: string;
    error?: string;
}

const polyglot = PolyglotSingleton.getInstance();

const ImportStoplistModal = ({ open, onClose, organization, form, helpText, error }: IProps) => (
    <Dialog open={open} onClose={onClose}>
        <DialogTitle>{polyglot.t('stoplist.fromCsv.header', { name: organization.name })}</DialogTitle>
        <DialogContent>
            {helpText !== undefined ? <DialogContentText>{helpText}</DialogContentText> : null}
            {form}
            {error !== undefined
                ? <Typography color="error">{error}</Typography>
                : null
            }
        </DialogContent>
    </Dialog>
);

export default ImportStoplistModal;

/**
 * Раздел "Частота" месячного отчета.
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import { Grid, Typography, WithStyles, withStyles } from '@material-ui/core';
import React from 'react';

import ColumnChartStacked, { IStackedSeries } from '../../../components/Charts/ColumnChartStacked';
import Translate from '../../../components/Translate';
import { PolyglotSingleton } from '../../../lib/services/translation';
import { IChartSummaryData, ICustomSummaryData, IMonthSummaryData } from '../../../types/analytics';
import { PeriodFormatFunction } from '../../../types/global';
import { styles } from '../styles';
import { CHART_DATE_FORMAT, formatPeriodDate } from '../Summary';

interface IProps extends WithStyles<typeof styles> {
    data: ICustomSummaryData | IMonthSummaryData;
    periodFormat?: string | PeriodFormatFunction;
}

const polyglot = PolyglotSingleton.getInstance();

const getRegionsData = (data: IProps['data'], periodFormat: string | PeriodFormatFunction): IStackedSeries[] => [
    ...('comporable' in data
        ? [
              {
                  period: formatPeriodDate(data.comporable[0].date, periodFormat),
                  [polyglot.t('frequency.labels.msk')]: data.comporable[0].visitsByRegion.msk,
                  [polyglot.t('frequency.labels.others')]: data.comporable[0].visitsByRegion.others,
              },
          ]
        : []),
    ...data.months.map((row: IChartSummaryData) => ({
        period: formatPeriodDate(row.date, periodFormat),
        [polyglot.t('frequency.labels.msk')]: row.visitsByRegion.msk,
        [polyglot.t('frequency.labels.others')]: row.visitsByRegion.others,
    })),
];

const getFreqData = (data: IProps['data'], periodFormat: string | PeriodFormatFunction): IStackedSeries[] => [
    ...('comporable' in data
        ? [
              {
                  period: formatPeriodDate(data.comporable[0].date, periodFormat),
                  1: data.comporable[0].visitsByFreq.visitsFreq1,
                  2: data.comporable[0].visitsByFreq.visitsFreq2,
                  3: data.comporable[0].visitsByFreq.visitsFreq3,
                  '>3': data.comporable[0].visitsByFreq.visitsFreqMore,
              },
          ]
        : []),
    ...data.months.map((row: IChartSummaryData) => ({
        period: formatPeriodDate(row.date, periodFormat),
        1: row.visitsByFreq.visitsFreq1,
        2: row.visitsByFreq.visitsFreq2,
        3: row.visitsByFreq.visitsFreq3,
        '>3': row.visitsByFreq.visitsFreqMore,
    })),
];

const FrequencyPage = ({ data, classes, periodFormat = CHART_DATE_FORMAT }: IProps) => {
    const regionsData = getRegionsData(data, periodFormat);
    const freqData = getFreqData(data, periodFormat);
    const comporable = 'comporable' in data ? regionsData[0].period : undefined;

    return (
        <Grid container spacing={2} alignItems="stretch" className={classes.chartRowTwoThirds}>
            <Grid item xs={6}>
                <div className={classes.chartWrapper}>
                    <Typography variant="h5" className={classes.title}>
                        <Translate phrase="frequency.charts.byFrequency" />
                    </Typography>
                    <ColumnChartStacked
                        id="byFrequency"
                        data={freqData}
                        noElements={true}
                        className={classes.fullHeight}
                        classes={{ chartWrapper: classes.chart }}
                        comporableCategory={comporable}
                        withTotals
                    />
                </div>
            </Grid>
            <Grid item xs={6}>
                <div className={classes.chartWrapper}>
                    <Typography variant="h5" className={classes.title}>
                        <Translate phrase="frequency.charts.byRegions" />
                    </Typography>
                    <ColumnChartStacked
                        id="byRegions"
                        data={regionsData}
                        noElements={true}
                        className={classes.fullHeight}
                        classes={{ chartWrapper: classes.chart }}
                        comporableCategory={comporable}
                        withTotals
                    />
                </div>
            </Grid>
        </Grid>
    );
};

export default withStyles(styles)(FrequencyPage);

/**
 * Reducers для страницы "Посещаемость" организации
 *
 * @author Artem Bakulin
 */

import { Action, handleActions } from 'redux-actions';

import { analyticsPagesActions } from '../actions/analytics.pages';
import { IDateAnalyticsPeriod } from '../types/analytics';
import { IAnalyticsPageResult, IAnalyticsPageState } from '../types/global';

export interface IVisitsResultPeriod {
    /** Кол-во посетителей с разбивкой по ценовым сегментам */
    byPrice: { [index: string]: IDateAnalyticsPeriod[] };
    /** Кол-во уникальных посетителей с разбивкой по ценовым сегментам */
    byPriceUniqs: { [index: string]: IDateAnalyticsPeriod[] };
    /** Кол-во посетителей по типу  */
    byType: {
        /** Кол-во визитов легковых автомобилей */
        carsVisits: IDateAnalyticsPeriod[];
        /** Кол-во визитов автобусов */
        busVisits: IDateAnalyticsPeriod[];
        /** Кол-во визитов такси */
        taxiVisits: IDateAnalyticsPeriod[];
        /** Общее кол-во визитов */
        all: IDateAnalyticsPeriod[];
        /** Кол-во уникальных легковых автомобилей */
        carsUniqs: IDateAnalyticsPeriod[];
        /** Кол-во уникальных автобусов */
        busUniqs: IDateAnalyticsPeriod[];
        /** Кол-во уникальных такси */
        taxiUniqs: IDateAnalyticsPeriod[];
        /** Общее кол-во уникальных посетителей */
        allUniqs: IDateAnalyticsPeriod[];
    };
    /** Кол-во посетителей по возрасту */
    byAges: { [index: string]: IDateAnalyticsPeriod[] };
    /** Кол-во уникальных посетителей по возрасту */
    byAgesUniqs: { [index: string]: IDateAnalyticsPeriod[] };
    /** Кол-во посещений по возрастным группам и ценовым сегментам авто */
    byAgesAndPrices: { [index: string]: { [index: string]: IDateAnalyticsPeriod[] } };
    /** Кол-во посещений по ценовым сегментам авто и возрастным группам */
    byPricesAndAges: { [priceIndex: string]: { [index: string]: IDateAnalyticsPeriod[] } };
    /** Кол-во посещений по возрастным группам и ценовым сегментам авто */
    byAgesAndPricesUniqs: { [index: string]: { [index: string]: IDateAnalyticsPeriod[] } };
    /** Кол-во посещений по ценовым сегментам авто и возрастным группам */
    byPricesAndAgesUniqs: { [priceIndex: string]: { [index: string]: IDateAnalyticsPeriod[] } };
}

const initialState: IAnalyticsPageState<IVisitsResultPeriod> = {
    inProgress: false,
    results: {},
};

/**
 * Reducers для страницы Dashboard организации
 */
// tslint:disable-next-line:no-any
export const visitsPageReducer = handleActions<IAnalyticsPageState<IVisitsResultPeriod>, any>(
    {
        [analyticsPagesActions.visits.send.toString()]: (state: IAnalyticsPageState<IVisitsResultPeriod>) => ({
            ...state,
            inProgress: true,
        }),
        [analyticsPagesActions.visits.success.toString()]: (
            state: IAnalyticsPageState<IVisitsResultPeriod>,
            action: Action<{ hashString: string; data: IAnalyticsPageResult<IVisitsResultPeriod> }>,
        ) => ({
            ...state,
            results:
                state.results[action.payload.hashString] !== undefined
                    ? state.results
                    : {
                        ...state.results,
                        [action.payload.hashString]: action.payload.data,
                    },
            inProgress: false,
            error: undefined,
        }),
        [analyticsPagesActions.visits.fail.toString()]: (
            state: IAnalyticsPageState<IVisitsResultPeriod>,
            action: Action<Error>,
        ) => ({
            ...state,
            inProgress: false,
            error: action.payload,
        }),
    },
    initialState,
);

/** @author Artem Bakulin <dekkyartem@gmail.com> */

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
    title: {
        fontSize: '4mm',
        fontWeight: 600,
        marginBottom: '6mm',
    },
    table: {
        width: '100%',
        fontFamily: '"HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif',
        color: '#121212',
        textAlign: 'center',
        borderSpacing: 0,
        borderCollapse: 'collapse',
    },
    column: {
        fontWeight: 500,
        border: '1px solid #f1f1f1',
    },
    row: {
        fontWeight: 300,
        border: '1px solid #f1f1f1',
    },
    td: {
        border: '1px solid #f1f1f1',
    },
    standard: {
        padding: '2mm',
        fontSize: '5mm',
    },
    additional: {
        padding: '2mm',
        fontSize: '3.5mm',
        textAlign: 'right',
        color: 'rgba(18, 18, 18, 0.5)',
        border: '1px solid #f1f1f1',
    },
    small: {
        padding: '1mm !important',
        fontSize: '3.5mm !important',
    },
    divider: {
        borderLeftColor: 'transparent',
        borderRightColor: 'transparent',
        height: '5mm',
    },
    footCell: {
        padding: '2mm',
        fontWeight: 'bold',
        fontSize: '4mm',
        border: '1px solid #f1f1f1',
    }
}));

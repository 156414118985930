import { createStyles, Theme } from '@material-ui/core';

export const styles = (theme: Theme) =>
    createStyles({
        title: {
            ...theme.typography.h5,
            padding: theme.spacing(4),
        },
        actions: {
            justifyContent: 'center',
            padding: theme.spacing(4),
        },
    });

import { Grid, WithStyles, withStyles } from '@material-ui/core';
import React from 'react';

import PdfFooter from '../PdfFooter';
import PdfHeader from '../PdfHeader';

import { styles } from './styles';

interface IProps extends WithStyles<typeof styles> {
    page?: number;
    title?: string;
    children?: React.ReactNode;
    period?: string;
    headerContent?: React.ReactNode;
}

const PdfSection = ({ classes, children, page, title, headerContent, period }: IProps) => (
    <Grid
        container
        direction="column"
        alignItems="stretch"
        justify="space-around"
        className={classes.unbreakable}
        wrap="nowrap"
    >
        <PdfHeader page={page} title={title}>
            {headerContent}
        </PdfHeader>
        <Grid
            container
            wrap="nowrap"
            alignItems="stretch"
            direction="column"
            justify="center"
            className={classes.content}
        >
            {children}
        </Grid>
        <PdfFooter period={period} />
    </Grid>
);

export default withStyles(styles)(PdfSection);

/**
 * Сервис для переводов
 */

import dot from 'dot-object';
import moment from 'moment';
import Polyglot from 'node-polyglot';

import translationApi from '../../api/translation.api';

export class PolyglotSingleton {
    protected static instance = new Polyglot({
        allowMissing: true,
    });

    public check = null;

    public static getInstance(): Polyglot & { phrases: ITranslation } {
        return PolyglotSingleton.instance as Polyglot & { phrases: ITranslation };
    }
}

export interface ITranslation {
    [key: string]: string | ITranslation;
}

export type ITranslationNamespaces = string | string[];

// tslint:disable-next-line:no-any
export const loadTranslations = async (namespaces: ITranslationNamespaces): Promise<void> => {
    const polyglot = PolyglotSingleton.getInstance();

    const ns: string[] = typeof namespaces === 'string' ? [namespaces] : namespaces;

    for (const namespace of ns) {
        if (!polyglot.has(`${namespace}.loaded`)) {
            const phrases: ITranslation = {};
            const result = await translationApi.get(namespace, polyglot.locale());
            phrases[namespace] = {
                requestStatus: 'ok',
                ...result,
            };
            polyglot.extend(phrases);
        }
    }
};

// tslint:disable-next-line:no-any
export const loadAllTranslations = async (): Promise<void> => {
    const polyglot = PolyglotSingleton.getInstance();
    polyglot.extend(await translationApi.all(polyglot.locale()));
};

export const localize = async (): Promise<void> => {
    const polyglot = PolyglotSingleton.getInstance();

    let lang = localStorage.getItem('lng');

    if (lang === null) {
        let locale = navigator.language;
        if (Array.isArray(navigator.languages) && navigator.languages.length > 0) {
            // tslint:disable-next-line:no-unsafe-any
            locale = navigator.languages[0];
        }

        lang = locale.toLowerCase().length > 2 ? locale.toLowerCase().slice(0, 2) : 'en';
    }

    moment.locale(lang);
    polyglot.clear();
    polyglot.locale(lang);

    await loadAllTranslations();
};

export const objectify = (key?: string): {} => {
    const polyglot = PolyglotSingleton.getInstance();
    const subKey = key === undefined ? '' : key;

    const result: ITranslation = {};

    Object.keys(polyglot.phrases).forEach(phrase => {
        if (phrase.indexOf(subKey) === 0) {
            const substr = phrase.substring(subKey.length + 1);
            if (substr !== 'requestStatus') {
                result[substr] = polyglot.t(phrase);
            }
        }
    });

    dot.object(result);

    return result;
};

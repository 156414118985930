/**
 * Отдельная страница PDF-отчета
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import { Grid } from '@material-ui/core';
import cx from 'classnames';
import React from 'react';

import { useStyles } from './styles';

interface IProps {
    className?: string;
}

export const Page: React.FC<IProps> = ({ children, className }) => {
    const classes = useStyles();

    return (
        <Grid container direction="column" className={cx(classes.page, className)}>
            <Grid item className={classes.content} container alignItems="stretch">
                {children}
            </Grid>
        </Grid>
    );
};

/**
 * Роли и правда доступа пользователей.
 *
 * @author Artem Bakulin
 */

import { AccessControl } from 'accesscontrol';

import { IUser } from './types/users';

/**
 * Роли, используемые на сайте
 */
export enum UserRolesEnum {
    ROLE_SYNAPS = 'ROLE_SYNAPS',
    ROLE_ORGANIZATION_ADMIN = 'ROLE_ORGANIZATION_ADMIN',
    ROLE_ORGANIZATION_ANALYST = 'ROLE_ORGANIZATION_ANALYST',
}

/**
 * Список всех возможных ролей польователя
 */
export const UserRoles = [
    UserRolesEnum.ROLE_SYNAPS,
    UserRolesEnum.ROLE_ORGANIZATION_ADMIN,
    UserRolesEnum.ROLE_ORGANIZATION_ANALYST,
];

/**
 * Список ролей суперпользователя
 */
export const UserAdminRoles = [UserRolesEnum.ROLE_SYNAPS];

/**
 * Ресурсы сайта
 */
export enum SiteResourcesEnum {
    Organizations = 'organizations',
    OrganizationStats = 'organization_stats',
    OrganizationsStopLits = 'organizations_stop_list',
    Users = 'users',
    HolidayCalendar = 'holiday_calendar',
}

/**
 * Права доступа
 */
const acl = new AccessControl();
acl
    /* ROLE_ORGANIZATION_MANAGER */
    .grant(UserRolesEnum.ROLE_ORGANIZATION_ANALYST)
    .readOwn('organization_stats')

    /* ROLE_ORGANIZATION_ADMIN */
    .grant(UserRolesEnum.ROLE_ORGANIZATION_ADMIN)
    .extend(UserRolesEnum.ROLE_ORGANIZATION_ANALYST)
    // Users
    .createOwn(SiteResourcesEnum.Users)
    .readOwn(SiteResourcesEnum.Users)
    .updateOwn(SiteResourcesEnum.Users)
    .deleteOwn(SiteResourcesEnum.Users)
    // Organizations
    .readOwn(SiteResourcesEnum.Organizations)
    .updateOwn(SiteResourcesEnum.Organizations)
    // Organizations Stop List
    .readOwn(SiteResourcesEnum.OrganizationsStopLits)
    .createOwn(SiteResourcesEnum.OrganizationsStopLits)
    .updateOwn(SiteResourcesEnum.OrganizationsStopLits)
    .deleteOwn(SiteResourcesEnum.OrganizationsStopLits)

    /* ROLE_SYNAPS */
    .grant(UserRolesEnum.ROLE_SYNAPS)
    // Users
    .createAny(SiteResourcesEnum.Users)
    .readAny(SiteResourcesEnum.Users)
    .updateAny(SiteResourcesEnum.Users)
    .deleteAny(SiteResourcesEnum.Users)
    // Organizations
    .createAny(SiteResourcesEnum.Organizations)
    .readAny(SiteResourcesEnum.Organizations)
    .updateAny(SiteResourcesEnum.Organizations)
    .deleteAny(SiteResourcesEnum.Organizations)
    // Holiday Calendar
    .createAny(SiteResourcesEnum.HolidayCalendar)
    .readAny(SiteResourcesEnum.HolidayCalendar)
    .updateAny(SiteResourcesEnum.HolidayCalendar)
    .deleteAny(SiteResourcesEnum.HolidayCalendar);

/**
 * Возвращает домашнюю страницу пользователя в зависимости от его ролей.
 *
 * @param user Авторизованный пользователь
 */
const getHomeUrl = (userOrRoles: IUser | UserRolesEnum[]): string => {
    const roles = Array.isArray(userOrRoles) ? userOrRoles : userOrRoles.roles;

    if (roles.includes(UserRolesEnum.ROLE_SYNAPS)) {
        return '/organizations';
    }

    return '/';
};

export { acl, getHomeUrl };

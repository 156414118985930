import { createStyles } from '@material-ui/core';

export const styles = () =>
    createStyles({
        root: {
            padding: '10px 30px',
            minHeight: 40,
            flex: 'none',
        },
        period: {
            whiteSpace: 'nowrap',
            fontWeight: 'bold',
        },
    });

/**
 * Общая для всех графиков подсказка, в футере.
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import cx from 'classnames';
import React from 'react';

import { PolyglotSingleton } from '../../../../lib/services/translation';
import { Typo } from '../../Typo';

import { useStyles } from './styles';

interface IProps {
    wrap?: 'wrap' | 'nowrap',
    type?: 'month' | 'quarter'
}

const polyglot = PolyglotSingleton.getInstance();

export const FooterHint = ({ wrap, type = 'month' }: IProps) => {
    const classes = useStyles();
    const getCircles = (isBg: boolean) => (
        <div className={classes.circles}>
            <span className={`${classes.circle} ${isBg ? classes.positiveBg : classes.positive}`} />
            <span className={`${classes.circle} ${isBg ? classes.negativeBg : classes.negative}`} />
        </div>
    );

    return (
        <div className={cx(classes.footer, { [classes.wrap]: wrap === 'wrap' })}>
            <Typo className={classes.hint} component="div">
                {getCircles(true)}
                {polyglot.t(type === 'quarter' ? 'common.charts.hints.fromQuarter' : 'common.charts.hints.fromMonth')}
            </Typo>
            <Typo className={classes.hint} component="div">
                {getCircles(false)}
                {polyglot.t('common.charts.hints.fromYear')}
            </Typo>
        </div>
    );
};

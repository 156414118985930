import { createStyles, Theme } from '@material-ui/core';

export const styles = (theme: Theme) =>
    createStyles({
        card: {
            height: '100%',
        },
        chartWrapper: {
            marginTop: theme.spacing(12),
            width: '100%',
            height: 280,
        },
        resultData: {
            maxWidth: theme.breakpoints.values.xl,
        },
        helpTitle: {
            boxShadow: theme.shadows[1],
        },
        helpContent: {
            paddingTop: theme.spacing(3),
        },
        helpActions: {
            justifyContent: 'center',
        },
    });

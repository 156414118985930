import { connect } from 'react-redux';

import { globalActions } from '../actions/global';
import App from '../App';
import { IAppState } from '../reducers';

const mapStateToProps = (state: IAppState) => ({
    app: state.global,
});

const mapDispatchToProps = {
    initialize: globalActions.global.initialize.begin,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(App);

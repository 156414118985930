/**
 * Интерфейсы для описания автомобилей
 *
 * @author Artem Bakulin
 */

export interface IVehicle {
    /** Уникальное id автомобиля. Primary key in DB. */
    id: number;
    /** id генерации / микросегмента конкретного автобиля */
    generationId: number;
    /** Генерация / микросегмента конкретного автомобиля */
    generation?: IVehicleGeneration;
    /** Гос. номер автомобиля */
    plate: string;
}

export interface IVehicleGeneration {
    /** Уникальное id генерации автомобиля. Primary key in DB. */
    id: number;
    /** id модели автомобиля */
    modelId: number;
    /** Модель автомобиля */
    model?: IVehicleModel;
    /** Название микросегмента */
    name: string;
}

export interface IVehicleModel {
    /** Уникальное id модели автомобиля. Primary key in DB. */
    id: number;
    /** id бренда автомобиля */
    brandId: number;
    /** Бренд автомобиля */
    brand?: IVehicleBrand;
    /** Название модели автомобиля */
    name: string;
}

export interface IVehicleBrand {
    /** Уникальное id бренда автомобиля. Primary key in DB. */
    id: number;
    /** id страны автомобильного бренда */
    countryId: number | null;
    /** Название автомобильного бренда */
    name: string;
}

/** Наши возрастные группы водителей */
export const DEFAULT_OWNER_AGE_GROUPS = {
    '18-24': 1,
    '25-29': 2,
    '30-34': 3,
    '35-39': 4,
    '40-44': 5,
    '45-49': 6,
    '50-54': 7,
    '55-59': 8,
    '60-64': 9,
    '65+': 10,
}

/** Наши ценовые категории автомобилей */
export const DEFAULT_VEHICLE_PRICE_KEYS = ['low', 'middle', 'middlePlus', 'premium', 'premiumPlus', 'luxury'];

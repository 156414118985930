import { IAppConfig } from '../types/global';

import { BaseApi } from './abstract.api';

/**
 * API всего приложения
 */
class AppApi extends BaseApi {
    public constructor() {
        super('/');
    }

    /**
     * Получение конфигурации приложения
     */
    public async config(): Promise<IAppConfig> {
        return (await this.axios.get<IAppConfig>('/config')).data;
    }
}

export default new AppApi();

/**
 * Стили для модального окна настроек организации
 * 
 * @author Artem Bakulin
 */

import { createStyles, Theme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

export const styles = (theme: Theme) => createStyles({
    root: {
        width: theme.breakpoints.values.md,
        height: 600,
    },
    header: {
        backgroundColor: grey[50],
    },
    footer: {
        backgroundColor: grey[50],
    },
    content: {
        display: 'flex',
        flexGrow: 1,
        height: '100%',
        borderTop: `1px solid ${theme.palette.divider}`,
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        minWidth: 200,
    },
    tabsContent: {
        width: '100%',
        padding: theme.spacing(2),
    },
    form: {
        width: '100%',
    }
})

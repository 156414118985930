/**
 * Столбчатый график для PDF-отчета.
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import { ChartData } from 'chart.js';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { draw } from 'patternomaly';
import React from 'react';
import { Bar } from 'react-chartjs-2';

import { Typo } from '../../Typo';
import { FooterHint } from '../FooterHint';
import { getChartOptions, IChartProps } from '../helpers';
import { useStyles } from '../styles';

interface IProps extends IChartProps {
    data: number[];
}

const dataToSeries = (
    data: number[],
    labels: string[],
    pastYearDivider: number,
    label?: string
): ChartData => ({
    labels,
    datasets: [{
        label,
        backgroundColor: [
            ...(new Array(pastYearDivider)).fill(draw('diagonal', 'rgb(0, 70, 222, 0.05)', 'rgb(0, 70, 222, 0.25)', 10)),
            ...(new Array(data.length - pastYearDivider)).fill('#c6d8ee'),
        ],
        data
    }],
});

export const ColumnChart = ({
    title,
    legend,
    labels,
    data,
    monthIncrease,
    yearIncrease,
    valueFormat = 'absolute',
    pastYearDivider,
    showFooter = false,
    size = 'standard',
    weeks,
    months,
    max,
    type
}: IProps) => {
    const classes = useStyles();
    const chartData = dataToSeries(data, labels, pastYearDivider ?? 0, legend);

    return (
        <div className={classes.colummChart}>
            {title !== undefined ? <Typo className={classes.title}>{title}</Typo> : null}
            <div className={`${classes.chart} ${classes[size]}`}>
                <Bar
                    data={chartData}
                    options={getChartOptions(
                        valueFormat,
                        monthIncrease,
                        yearIncrease,
                        legend !== undefined,
                        pastYearDivider,
                        weeks,
                        months,
                        max,
                    )}
                    plugins={[DataLabelsPlugin]}
                />
            </div>
            {showFooter ? <FooterHint type={type} /> : null}
        </div>
    );
}

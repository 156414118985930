/**
 * @author Artem Bakulin <dekkyartem@gmail.com>
 *
 * @TODO: Перенести методы styleComporableColumn и styleComporableSerie в отдельное более логичное место.
 */
import { CategoryAxis, ColumnSeries, LabelBullet, Legend, ValueAxis, XYChart } from '@amcharts/amcharts4/charts';
import { color, Color, Container, create, LinePattern, percent } from '@amcharts/amcharts4/core';
import am4lang_ru_RU from '@amcharts/amcharts4/lang/ru_RU';
import { Button, Grid } from '@material-ui/core';
import { Save } from '@material-ui/icons';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

export interface IStackedSeries {
    period: string;
    config?: { [index: string]: unknown };
    [valueKey: string]: unknown;
}
type ChartValueType = 'absolute' | 'percents';
type LegendPosition = 'top' | 'bottom' | 'right' | 'left';

interface IProps {
    id: string;
    data: IStackedSeries[];
    className?: string;
    classes?: {
        chart?: string;
        chartWrapper?: string;
        chartWrapperExtended?: string;
        export?: string;
    };
    // tslint:disable-next-line:no-null-undefined-union
    children?: React.ReactNode;
    legendContainer?: string;
    legendPosition?: LegendPosition;
    disableLegend?: boolean;
    noElements?: boolean;
    withTotals?: boolean;
    comporableCategory?: string;
    chartExportLink?: string;
}

export const styleComporableColumn = (xAxe: CategoryAxis, column: string): void => {
    const range = xAxe.axisRanges.create();
    range.category = column;
    range.endCategory = column;
    range.locations.category = 1;
    range.locations.endCategory = 1;
    range.axisFill.fill = color('#cccccc');
    range.axisFill.fillOpacity = 1;
    range.label.disabled = true;
};

export const styleComporableSerie = (serie: ColumnSeries, column: string): void => {
    serie.columns.template.adapter.add('fill', (fill, target) => {
        // tslint:disable-next-line:no-any
        if (target.dataItem !== undefined && (target.dataItem as any).categoryX === column) {
            const pattern = new LinePattern();
            pattern.width = 10;
            pattern.height = 10;
            pattern.strokeWidth = 1;
            pattern.rotation = 45;
            pattern.stroke = fill as Color;

            return pattern;
        }

        return fill;
    });

    serie.columns.template.background.adapter.add('fill', (fill, target) => {
        // tslint:disable-next-line: no-any
        if (target.dataItem !== undefined && (target.dataItem as any).categoryX === column) {
            return color({
                r: 255,
                g: 255,
                b: 255,
                // tslint:disable-next-line:no-any no-unsafe-any
                ...((target.dataItem as any).column.stroke as Color).rgb,
                a: 0.5,
            });
        }

        return fill;
    });
};

const addLegend = (chart: XYChart, legendPosition: LegendPosition, legendContainer?: string): void => {
    chart.legend = new Legend();
    chart.legend.position = legendPosition;
    chart.legend.markers.template.width = 20;
    chart.legend.markers.template.height = 20;
    chart.legend.labels.template.fontSize = 12;
    chart.legend.itemContainers.template.paddingTop = 0;
    chart.legend.itemContainers.template.paddingBottom = 5;
    chart.legend.itemContainers.template.paddingRight = 0;
    chart.legend.itemContainers.template.paddingLeft = 0;

    if (legendContainer !== undefined) {
        const container = create(legendContainer, Container);
        container.width = percent(100);
        container.height = percent(100);
        chart.legend.parent = container;
    }
};

const addLabelBullet = (serie: ColumnSeries, withTotals: boolean) => {
    const labelBullet = serie.bullets.push(new LabelBullet());
    labelBullet.label.fill = color('white');
    labelBullet.label.text = '{valueY}';
    labelBullet.label.truncate = false;
    labelBullet.label.wrap = true;
    labelBullet.label.maxWidth = 96;
    labelBullet.locationY = 0.5;
    labelBullet.label.adapter.add('fill', (labelColor, target) => {
        // tslint:disable-next-line:no-unsafe-any no-any
        if (target.dataItem !== undefined && (target.dataItem as any).itemHeight < 10) {
            return color('#000');
        }

        return labelColor;
    });

    if (withTotals) {
        labelBullet.label.adapter.add('text', (textLabel, target) => {
            // tslint:disable-next-line:strict-boolean-expressions
            if (target.dataItem) {
                const percents = Math.round(target.dataItem.values.valueY.totalPercent);

                return `${textLabel} / ${percents}%`;
            }

            return textLabel;
        });
    }
};

const XYChartStacked = ({
    id,
    data,
    noElements,
    classes,
    className,
    children,
    legendContainer,
    comporableCategory,
    legendPosition = 'top',
    withTotals = false,
    disableLegend = false,
    chartExportLink,
}: IProps) => {
    const [dataType, setDataType] = useState<ChartValueType>('absolute');
    const handleSelect = useCallback((_, value: ChartValueType) => {
        setDataType(value);
    }, []);

    const dataPercent = useMemo(() => {
        const dataKeys = Object.keys(data[0]).filter(key => key !== 'period' && key !== 'config');

        return data.map(serie => {
            const total = dataKeys.reduce((prev: number, key: string) => prev + (serie[key] as number), 0);

            return Object.assign(
                {
                    period: serie.period,
                },
                ...dataKeys.map(key => ({ [key]: (serie[key] as number) / total })),
            ) as IStackedSeries;
        });
    }, [data]);

    useEffect(() => {
        const chart = create(id, XYChart);
        chart.language.locale = am4lang_ru_RU;
        chart.language.locale._thousandSeparator = ' ';

        const xAxeAdded = chart.xAxes.push(new CategoryAxis());
        const yAxeAdded = chart.yAxes.push(new ValueAxis());

        xAxeAdded.dataFields.category = 'period';
        xAxeAdded.renderer.grid.template.location = 0;
        xAxeAdded.renderer.grid.template.disabled = true;
        xAxeAdded.renderer.minGridDistance = 5;

        yAxeAdded.renderer.inside = true;
        yAxeAdded.renderer.labels.template.disabled = true;
        yAxeAdded.renderer.grid.template.disabled = true;
        yAxeAdded.min = 0;
        yAxeAdded.calculateTotals = withTotals;

        chart.data = dataType === 'absolute' ? data : dataPercent;
        chart.maskBullets = false;
        chart.margin(0, 0, 0, 0);
        chart.padding(0, 0, 0, 0);

        xAxeAdded.events.on('sizechanged', (ev: { type: 'sizechanged'; target: CategoryAxis }) => {
            const axis = ev.target;
            const cellWidth = axis.pixelWidth / (axis.endIndex - axis.startIndex);
            if (cellWidth < axis.renderer.labels.template.maxWidth) {
                axis.renderer.labels.template.rotation = -45;
                axis.renderer.labels.template.horizontalCenter = 'right';
                axis.renderer.labels.template.verticalCenter = 'middle';
            } else {
                axis.renderer.labels.template.rotation = 0;
                axis.renderer.labels.template.horizontalCenter = 'middle';
                axis.renderer.labels.template.verticalCenter = 'top';
            }
        });

        Object.keys(data[0])
            .filter(key => key !== 'period' && key !== 'config')
            .forEach(key => {
                const serie = chart.series.push(new ColumnSeries());

                serie.name = key;
                serie.stacked = true;
                serie.dataFields.categoryX = 'period';
                serie.dataFields.valueY = key;
                serie.sequencedInterpolation = true;
                serie.columns.template.tooltipText = '[bold]{name}[/]\n[font-size:14px]{valueY}';
                serie.columns.template.maxWidth = 100;
                serie.columns.template.configField = 'config';
                serie.columns.template.background.fill = color();

                if (comporableCategory !== undefined) {
                    styleComporableSerie(serie, comporableCategory);
                }

                addLabelBullet(serie, withTotals);
            });

        if (comporableCategory !== undefined) {
            styleComporableColumn(xAxeAdded, comporableCategory);
        }

        if (!disableLegend) {
            addLegend(chart, legendPosition, legendContainer);
        }

        chart.numberFormatter.numberFormat = dataType === 'absolute' ? chart.numberFormatter.numberFormat : '#.%';

        return () => {
            chart.dispose();
        };
    });

    let chartClass;
    if (classes !== undefined) {
        chartClass = classes.chartWrapperExtended !== undefined ? classes.chartWrapperExtended : classes.chartWrapper;
    }

    return (
        <Grid container direction="column" alignItems="stretch" className={className}>
            {(noElements === undefined || !noElements) && (
                // tslint:disable-next-line:strict-boolean-expressions
                <Grid item container justify="flex-end">
                    {children}
                    <ToggleButtonGroup value={dataType} exclusive onChange={handleSelect}>
                        <ToggleButton value="absolute">123</ToggleButton>
                        <ToggleButton value="percents">%</ToggleButton>
                    </ToggleButtonGroup>
                    {
                        chartExportLink &&
                        <Button 
                            variant="outlined" 
                            color="primary" 
                            className={classes !== undefined ? classes.export : undefined} 
                            onClick={() => window.open(chartExportLink)} >
                            <Save />
                        </Button>
                    }
                </Grid>
            )}
            <Grid item className={chartClass}>
                <div id={id} style={{ width: '100%', height: '100%' }} />
            </Grid>
        </Grid>
    );
};

export default XYChartStacked;

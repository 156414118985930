import {
    Button,
    FormControlLabel,
    Grid,
    LinearProgress,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import api from '../../api/holiday.calendar.api';
import PageHeader from '../../components/PageHeader';
import Layout from '../../containers/Layout';
import { PolyglotSingleton } from '../../lib/services/translation';
import { IHolidayCalendarEntity } from '../../types/holiday.calendar';

const polyglot = PolyglotSingleton.getInstance();

const HolidayCalendarPage = () => {
    const [inProgress, setProgress] = useState(true);
    const [isShowLast, setFilterLast] = useState(false);
    const [data, setData] = useState<IHolidayCalendarEntity[]>([]);
    const toggleFilterLast = () => {
        setFilterLast(!isShowLast);
    };
    const updateData = async () => {
        setProgress(true);
        const holidays = await api.update();
        setProgress(false);
        setData(holidays);
    };

    useEffect(() => {
        setProgress(true);
        (async () => {
            const holidays = await api.list();
            setProgress(false);
            setData(holidays);
        })().catch((error: Error) => {
            // tslint:disable-next-line:no-console
            console.log('Fetch holidays error', error);
        });
    }, []);

    const currentYear = new Date().getFullYear();

    return (
        <Layout>
            <PageHeader>
                <Grid container justify="space-between" alignItems="center">
                    <Typography variant="h1">{polyglot.t('calendar.header')}</Typography>
                    <Button variant="outlined" color="primary" disabled={inProgress} onClick={updateData}>
                        {polyglot.t('calendar.buttons.refresh')}
                    </Button>
                </Grid>
            </PageHeader>
            {inProgress ? <LinearProgress /> : null}
            {data.length > 0 ? (
                <>
                    <Grid container justify="flex-end">
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={isShowLast}
                                    onChange={toggleFilterLast}
                                    value={isShowLast}
                                    color="primary"
                                />
                            }
                            label={polyglot.t('calendar.labels.prev')}
                        />
                    </Grid>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{polyglot.t('calendar.columns.year')}</TableCell>
                                <TableCell>{polyglot.t('calendar.columns.month')}</TableCell>
                                <TableCell>{polyglot.t('calendar.columns.holidays')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data
                                .filter(item => isShowLast || item.year >= currentYear)
                                .map(item => (
                                    <TableRow key={`${item.year}.${item.month}`} hover>
                                        <TableCell>{item.year}</TableCell>
                                        <TableCell>
                                            {moment(`2019-${item.month + 1}-01`, 'YYYY-M-DD').format('MMMM')}
                                        </TableCell>
                                        <TableCell>{item.days.join(', ')}</TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </>
            ) : null}
        </Layout>
    );
};

export default HolidayCalendarPage;

import { Omit, WithStyles, withStyles } from '@material-ui/core';
import {
    AddCircle,
    Check,
    ChevronLeft,
    ChevronRight,
    Clear,
    Close,
    Delete,
    Edit,
    FilterList,
    FirstPage,
    LastPage,
    SaveAlt,
    Search,
} from '@material-ui/icons';
import MaterialTable, { Components, Icons, MaterialTableProps } from 'material-table';
import React from 'react';

import { objectify } from '../../lib/services/translation';
import { IStopListRecord } from '../../types/organizations';

import { styles } from './styles';

export interface IRemoteDataTableResult<T> {
    data: T[];
    page: number;
    totalCount: number;
}

interface IProps extends Omit<MaterialTableProps<IStopListRecord>, 'icons'>, WithStyles<typeof styles> {
    className?: string;
}

const defaultComponents = (classes: IProps['classes']): Components => ({
    /* tslint:disable-next-line:no-unsafe-any */
    Container: props => <div className={classes.root} {...props} />,
});

/* tslint:disable:no-any */
const defaultIcons: Icons = {
    Search: React.forwardRef((props, ref) => <Search innerRef={ref} {...props} />) as any,
    ResetSearch: React.forwardRef((props, ref) => <Close innerRef={ref} {...props} />) as any,
    Export: React.forwardRef((props, ref) => <SaveAlt innerRef={ref} {...props} />) as any,
    Add: React.forwardRef((props, ref) => <AddCircle innerRef={ref} {...props} />) as any,
    Check: React.forwardRef((props, ref) => <Check innerRef={ref} {...props} />) as any,
    Delete: React.forwardRef((props, ref) => <Delete innerRef={ref} {...props} />) as any,
    Edit: React.forwardRef((props, ref) => <Edit innerRef={ref} {...props} />) as any,

    FirstPage: React.forwardRef((props, ref) => <FirstPage innerRef={ref} {...props} />) as any,
    LastPage: React.forwardRef((props, ref) => <LastPage innerRef={ref} {...props} />) as any,
    NextPage: React.forwardRef((props, ref) => <ChevronRight innerRef={ref} {...props} />) as any,
    PreviousPage: React.forwardRef((props, ref) => <ChevronLeft innerRef={ref} {...props} />) as any,
    Filter: React.forwardRef((props, ref) => <FilterList innerRef={ref} {...props} />) as any,
    Clear: React.forwardRef((props, ref) => <Clear innerRef={ref} {...props} />) as any,
    DetailPanel: React.forwardRef((props, ref) => <ChevronRight innerRef={ref} {...props} />) as any,

    // SortArrow?: () => React.ReactElement<any>;
    // ThirdStateCheck?: () => React.ReactElement<any>;
    // ViewColumn?: () => React.ReactElement<any>;
};
/* tslint:enable:no-any */

const DataTable = ({ localization, classes, components, ...rest }: IProps) => {
    /* tslint:disable-next-line:no-inferred-empty-object-type */
    const customLocales = { ...objectify('datatable'), ...(localization !== undefined ? localization : {}) };
    const customComponents = { ...defaultComponents(classes), ...(components !== undefined ? components : {}) };

    return <MaterialTable components={customComponents} icons={defaultIcons} localization={customLocales} {...rest} />;
};

export default withStyles(styles)(DataTable);

import { Grid, LinearProgress, Typography, WithStyles, withStyles } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import hash from 'object-hash';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { analyticsPagesActions } from '../../actions/analytics.pages';
import PageHeader from '../../components/PageHeader';
import Layout from '../../containers/Layout';
import { PolyglotSingleton } from '../../lib/services/translation';
import { IAppState } from '../../reducers';
import { ILoyaltyPageState } from '../../reducers/loyalty.page';
import { selectedOrganizationSelector } from '../../selectors/common.selectors';

import ResultData from './ResultData';
import { styles } from './styles';

interface IProps extends WithStyles<typeof styles> { }

const polyglot = PolyglotSingleton.getInstance();

const LoyaltyPage = ({ classes }: IProps) => {
    const inProgress = useSelector((state: IAppState) => state.loyalty.inProgress);
    const error = useSelector((state: IAppState) => state.loyalty.error);
    const selectedMonths = useSelector((state: IAppState) => state.loyalty.selectedMonths);
    const organization = useSelector(selectedOrganizationSelector);
    const hashString: string = `${hash(selectedMonths)}.${organization !== undefined ? organization.id : ''}`;
    const result = useSelector((state: IAppState) => state.loyalty.results[hashString]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (organization !== undefined && result === undefined && !inProgress && error === undefined) {
            dispatch(analyticsPagesActions.loyalty.send(organization.id, selectedMonths));
        }
    });

    const changeMonths = useCallback(
        (_: React.MouseEvent, value: ILoyaltyPageState['selectedMonths']) => {
            if (value !== selectedMonths) {
                dispatch(analyticsPagesActions.loyalty.selectMonths(value));
            }
        },
        [selectedMonths, dispatch],
    );

    return (
        <Layout>
            <PageHeader>
                <Grid container spacing={3} justify="space-between" wrap="nowrap" alignItems="stretch">
                    <Grid item>
                        <Typography variant="h1" className={classes.title}>
                            {polyglot.t('loyalty.header')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <ToggleButtonGroup value={selectedMonths} exclusive onChange={changeMonths}>
                            <ToggleButton value={1}>{polyglot.t('loyalty.periods.1')}</ToggleButton>
                            <ToggleButton value={6}>{polyglot.t('loyalty.periods.6')}</ToggleButton>
                            <ToggleButton value={12}>{polyglot.t('loyalty.periods.12')}</ToggleButton>
                            <ToggleButton value={24}>{polyglot.t('loyalty.periods.24')}</ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                </Grid>
            </PageHeader>
            {inProgress ? <LinearProgress /> : null}
            {result !== undefined ? <ResultData result={result} /> : null}
        </Layout>
    );
};

export default withStyles(styles)(LoyaltyPage);

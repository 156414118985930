/**
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import { Grid, WithStyles, withStyles } from '@material-ui/core';
import React from 'react';

import { PolyglotSingleton } from '../../lib/services/translation';

import { Chart } from './Chart';
import { styles } from './styles';

interface IProps extends WithStyles<typeof styles> {}

const polyglot = PolyglotSingleton.getInstance();

const ResultData = ({ classes }: IProps) => (
    <Grid container spacing={4} className={classes.chartsWrapper} direction="column" alignItems="stretch">
        {/* <Grid item>
            <Chart
                filter="price"
                type="long-visits"
                title={polyglot.t('involvement.charts.longVisitsByPrice')}
            />
        </Grid>
        <Grid item>
            <Chart
                filter="age"
                type="long-visits"
                title={polyglot.t('involvement.charts.longVisitsByAge')}
            />
        </Grid> */}
        <Grid item>
            <Chart
                filter="price"
                type="duration"
                title={polyglot.t('involvement.charts.averageByPrice')}
            />
        </Grid>
        <Grid item>
            <Chart
                filter="age"
                type="duration"
                title={polyglot.t('involvement.charts.averageByAge')}
            />
        </Grid>
    </Grid>
);

export default withStyles(styles)(ResultData);

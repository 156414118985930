import { Legend, LegendPosition, PieChart as PieChartOriginal, PieSeries } from '@amcharts/amcharts4/charts';
import { color, create, percent } from '@amcharts/amcharts4/core';
import am4lang_ru_RU from '@amcharts/amcharts4/lang/ru_RU';
import React, { useEffect } from 'react';

import { CustomNumberFormatter } from '../../../lib/charts/formatters/custom.number.formatter';
import { NumberFormatFunction } from '../../../types/global';

// tslint:disable-next-line: no-any
interface IProps<T = any> {
    id: string;
    data: T[];
    valueKey?: string;
    labelKey?: string;
    numberFormat?: string | NumberFormatFunction;
    className?: string;
    legend?: LegendPosition;
}

const PieChart = ({ id, data, valueKey = 'value', labelKey = 'label', numberFormat, className, legend }: IProps) => {
    useEffect(() => {
        const chart = create(id, PieChartOriginal);
        chart.language.locale = am4lang_ru_RU;
        chart.language.locale._thousandSeparator = ' ';

        const serie = chart.series.push(new PieSeries());
        chart.data = data;

        serie.dataFields.value = valueKey;
        serie.dataFields.category = labelKey;
        serie.ticks.template.disabled = true;
        serie.alignLabels = false;
        serie.labels.template.fill = color('#fff');
        serie.labels.template.radius = percent(-30);
        serie.labels.template.text = `{value.percent.formatNumber('0.')}%`;
        serie.slices.template.tooltipText = `{category}: ${serie.labels.template.text}`;

        serie.labels.template.adapter.add('radius', (radius, target) => {
            // tslint:disable-next-line:no-unsafe-any
            if (target.dataItem && target.dataItem.values.value.percent < 10) {
                return 0;
            }

            return radius;
        });

        serie.labels.template.adapter.add('fill', (labelColor, target) => {
            // tslint:disable-next-line:no-unsafe-any
            if (target.dataItem && target.dataItem.values.value.percent < 10) {
                return color('#000');
            }

            return labelColor;
        });

        if (numberFormat !== undefined) {
            if (typeof numberFormat === 'function') {
                chart.numberFormatter = new CustomNumberFormatter(numberFormat);
            } else {
                chart.numberFormatter.numberFormat = numberFormat;
            }
        }

        if (legend !== undefined) {
            chart.legend = new Legend();
            chart.legend.position = legend;
            chart.legend.valueLabels.template.text = '';
        }

        return () => {
            chart.dispose();
        };
    });

    return <div id={id} style={{ width: '100%', height: '100%' }} className={className} />;
};

export default PieChart;

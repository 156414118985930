/**
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import React, { useCallback, useState } from 'react';

import { FormControl, Grid, InputLabel, MenuItem, Select, WithStyles, withStyles } from '@material-ui/core';
import moment from 'moment';

import Translate from '../../components/Translate';
import { MIN_DATE_VNUKOVO } from '../../components/AnalyticsCalendar';
import { PolyglotSingleton } from '../../lib/services/translation';
import { IUserOrganization } from '../../types/organizations';

import { ReportFileType } from '.';
import ReportButton from './ReportButton';
import { styles } from './styles';


interface IProps extends WithStyles<typeof styles> {
    id: string;
    organization: IUserOrganization;
    setProgress?(state: boolean): void;
}

const polyglot = PolyglotSingleton.getInstance();

const quarterElements = () => {
    const firstQuarter: moment.Moment = moment(MIN_DATE_VNUKOVO).startOf('quarter').add(5, 'quarter');
    const quartersCount: number = moment().diff(firstQuarter, 'quarters');
    const months: React.ReactElement[] = [];

    for (let i = 0; i < quartersCount; i += 1) {
        const curMonth: moment.Moment = moment(firstQuarter).add(i, 'quarters');

        months.push(
            <MenuItem value={curMonth.format('YYYY-MM-01')} key={curMonth.format('YYYY-MM-01')}>
                {curMonth.format(polyglot.t('common.dates.quarter'))}
            </MenuItem>,
        );
    }

    return months.reverse();
};

const ReportQuarter = ({ id, classes, organization }: IProps) => {
    const [quarter, setQuarter] = useState(
        moment()
            .subtract(1, 'quarter')
            .format('YYYY-MM-01'),
    );
    const [fileType, setFileType] = useState<ReportFileType>('pdf');

    const changeQuarter = useCallback((event: React.ChangeEvent<{ value: unknown }>) => {
        setQuarter(event.target.value as string);
    }, []);

    const changeFile = useCallback((event: React.ChangeEvent<{ value: unknown }>) => {
        setFileType(event.target.value as ReportFileType);
    }, []);

    const generateReport = useCallback(() => {
        window.open(`/api/areas/summary/${organization.id}?type=${fileType}&periodType=quarter&quarter=${quarter}`);
    }, [quarter, fileType, organization]);

    return (
        <Grid container spacing={3}>
            <Grid item>
                <FormControl variant="outlined" className={classes.monthSelect}>
                    <InputLabel id={`${id}MonthLabel`}>
                        <Translate phrase="reports.labels.selectQuarter" />
                    </InputLabel>
                    <Select value={quarter} id={`${id}Quarter`} onChange={changeQuarter}>
                        {quarterElements()}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item>
                <FormControl variant="outlined" className={classes.fileSelect}>
                    <InputLabel id={`${id}FileLabel`}>
                        <Translate phrase="reports.labels.selectFileType" />
                    </InputLabel>
                    <Select value={fileType} id={`${id}File`} onChange={changeFile}>
                        <MenuItem value="pdf">PDF</MenuItem>
                        <MenuItem value="xlsx">Excel</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item>
                <ReportButton onClick={generateReport} />
            </Grid>
        </Grid>
    );
}

export default withStyles(styles)(ReportQuarter);


import { connect } from 'react-redux';

import { globalActions } from '../actions/global';
import { usersActions } from '../actions/users';
import Layout from '../components/Layout';
import { IAppState } from '../reducers';

const mapStateToProps = (state: IAppState) => ({
    user: state.global.user,
    organization: state.global.selectedOrganization,
});

const mapDispatchToProps = {
    logout: usersActions.user.logout.send,
    changeOrganization: globalActions.global.changeOrganization,
    changeLanguage: usersActions.user.changeLanguage.change,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Layout);

/**
 * Компонент для интеграции Material UI TextField с Formik Field
 *
 * @author Artem Bakulin
 */

import { TextField as MuiTextField } from '@material-ui/core';
// tslint:disable-next-line:no-submodule-imports
import { TextFieldProps as MuiTextFieldProps } from '@material-ui/core/TextField';
import { FieldProps, getIn } from 'formik';
import * as React from 'react';

interface ITextFieldProps
    extends FieldProps,
        Pick<MuiTextFieldProps, Exclude<keyof MuiTextFieldProps, 'onChange' | 'name' | 'value'>> {}

const fieldPropsToTextFieldProps = ({ field, form, variant, ...props }: ITextFieldProps): MuiTextFieldProps => {
    const { name } = field;
    const { touched, errors } = form;

    const fieldError = getIn(errors, name) !== undefined ? getIn(errors, name) : props.error;
    const showError = getIn(touched, name) && fieldError !== undefined;

    return {
        ...field,
        ...props,
        // tslint:disable-next-line:no-any
        variant: variant as any,
        error: showError,
        // tslint:disable-next-line:no-unsafe-any
        helperText: showError ? fieldError : props.helperText,
    };
};

export default (props: ITextFieldProps) => <MuiTextField {...fieldPropsToTextFieldProps(props)} />;

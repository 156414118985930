import hash from 'object-hash';
import { Action } from 'redux-actions';
// tslint:disable-next-line:no-submodule-imports
import { all, call, put, select, takeEvery } from 'redux-saga/effects';

import { analyticsPagesActions } from '../actions/analytics.pages';
import { globalActions } from '../actions/global';
import api from '../api/analytics.api';
import { IAppState } from '../reducers';
import { IAnalyticsCalendarState } from '../reducers/analytics.calendar';
import { IFrequencyPageResult, IFrequencyPageState } from '../reducers/frequency.page';

function* sendDataRequest(action: Action<{ organizationId: number; data: IAnalyticsCalendarState }>) {
    try {
        const results = (yield select((state: IAppState) => state.frequency.results)) as IFrequencyPageState['results'];
        const hashString = `${hash(action.payload.data)}.${action.payload.organizationId}`;
        const resultData = results[hashString];

        const result: IFrequencyPageResult =
            resultData !== undefined
                ? resultData
                : ((yield call(
                      [api, 'frequencyData'],
                      action.payload.organizationId,
                      action.payload.data,
                  )) as IFrequencyPageResult);

        yield put(analyticsPagesActions.frequency.success(hashString, result));
    } catch (error) {
        yield put(
            globalActions.global.notifications.add({
                message: 'Ошибка на сервере. Невозможно получить данные за период.',
                type: 'error',
            }),
        );
        yield put(analyticsPagesActions.frequency.fail(error as Error));
    }
}

function* getData() {
    yield takeEvery(analyticsPagesActions.frequency.send.toString(), sendDataRequest);
}

export default function* rootSaga() {
    yield all([getData()]);
}

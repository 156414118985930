
/**
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import React from 'react';

import AnalyticsPageHeader from '../../components/AnalyticsPageHeader';
import Layout from '../../containers/Layout';
import { PolyglotSingleton } from '../../lib/services/translation';

import ResultData from './ResultData';

const polyglot = PolyglotSingleton.getInstance();

const VisitsPage = () => (
    <Layout>
        <AnalyticsPageHeader title={polyglot.t('visits.header')} />
        <ResultData />
    </Layout>
);

export default VisitsPage;

import { CategoryAxis, ColumnSeries, LabelBullet, ValueAxis } from '@amcharts/amcharts4/charts';
import React, { useEffect, useMemo } from 'react';

import XYChart from '../../components/Charts/XYChart';
import { NumberFormatFunction } from '../../types/global';

interface IProps {
    data: Array<{ label: string; value: number }>;
    id: string;
    numberFormat?: string | NumberFormatFunction;
    className?: string;
}

const Chart = ({ id, data, numberFormat, className }: IProps) => {
    const yAxes = useMemo(() => [new ValueAxis()], []);
    const xAxes = useMemo(() => [new CategoryAxis()], []);
    const series = useMemo(() => [new ColumnSeries()], []);
    const label = useMemo(() => {
        const newLabel = new LabelBullet();
        newLabel.label.text = '{value}';
        newLabel.label.dy = -15;
        newLabel.numberFormatter.numberFormat = '#.a';

        return newLabel;
    }, []);

    xAxes[0].dataFields.category = 'label';
    yAxes[0].min = 0;
    series[0].dataFields.categoryX = 'label';
    series[0].dataFields.valueY = 'value';
    series[0].columns.template.tooltipText = '[bold]{name}[/]\n[font-size:14px]{valueY}';
    series[0].bullets.push(label);

    useEffect(() => {
        xAxes[0].renderer.grid.template.disabled = true;
        yAxes[0].renderer.grid.template.disabled = true;
        yAxes[0].renderer.inside = true;
        yAxes[0].renderer.labels.template.disabled = true;
    }, [xAxes, yAxes]);

    return (
        <div className={className}>
            <XYChart id={id} data={data} yAxes={yAxes} xAxes={xAxes} series={series} numberFormat={numberFormat} />
        </div>
    );
};

export default Chart;

/**
 * Страница "Визиты по возрасту автовладельца" для месячного отчета.
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import moment from 'moment';
import React from 'react';

import { Table } from '../../../components/Pdf/Charts/Table';
import { PolyglotSingleton } from '../../../lib/services/translation';
import { IMonthSummaryData } from '../../../types/analytics';
import { IUserOrganization } from '../../../types/organizations';
import { DEFAULT_OWNER_AGE_GROUPS } from '../../../types/vehicles';
import { CHART_DATE_FORMAT } from '../Summary';

interface IProps {
    data: IMonthSummaryData;
    organization?: IUserOrganization;
}

const polyglot = PolyglotSingleton.getInstance();

export const VisitsByAgePage = ({ data, organization }: IProps) => {
    const orgAgeGroups = organization?.settings.ageGroups;
    const groups = Array.isArray(orgAgeGroups) && orgAgeGroups.length > 0
        ? orgAgeGroups.map(group => group.name)
        : Object.keys(DEFAULT_OWNER_AGE_GROUPS);
    const rows = [
        [...groups.map(group => data.comporable[0].visitsByAge[`visits_percent_${group}`])],
        ...data.months
            .slice(1)
            .map(monthData => [...groups.map(group => monthData.visitsByAge[`visits_percent_${group}`])]),
    ];
    const additionalRows = [
        [...groups.map(group => data.comporable[0].visitsByAge[`visits_${group}`])],
        ...data.months
            .slice(1)
            .map(monthData => [...groups.map(group => monthData.visitsByAge[`visits_${group}`])]),
    ];
    const months = [
        moment(data.comporable[0].date).format(CHART_DATE_FORMAT),
        ...data.months.slice(1).map(month => moment(month.date).format(CHART_DATE_FORMAT)),
    ];

    return (
        <Table
            title={polyglot.t('pdf.charts.visitsByAge')}
            columnsHeader={groups}
            rowsHeader={months}
            rows={rows}
            additionalRows={additionalRows}
            valueFormat="percent"
            additionalValueFormat="absolute"
        />
    );
};

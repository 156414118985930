/**
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import moment, { Moment } from 'moment';

import { AnalyticsDataGranularity, IDatesPeriod } from '../../../types/date.periods';

export const getWeekCategory = (date: Moment, curPeriod: IDatesPeriod, isFirst: boolean, isLast: boolean): string => {
    const start = isFirst ? moment(curPeriod.from) : moment(date).startOf('week');
    const end = isLast ? moment(curPeriod.to) : moment(date).endOf('week');

    return `${start.format('D.MM.YYYY')} - ${end.format('D.MM.YYYY')}`;
};

export const getDateCategories = (
    data: Array<Array<{ date?: string }>>,
    granularity: AnalyticsDataGranularity,
    curPeriod: IDatesPeriod,
) => {
    const maxLength = data.reduce((a, b) => (b.length > a ? b.length : a), 0);
    const categories = [];
    const dateFormat = granularity === 'month' ? 'MMM YY' : 'D.MM.YYYY';

    for (let i = 0; i < maxLength; i += 1) {
        const date =
            // tslint:disable-next-line: strict-type-predicates
            data[0][i] !== undefined
                ? moment(data[0][i].date)
                : moment(data[0][data[0].length - 1].date).add(i - data[0].length + 1, 'days');

        categories.push(
            granularity === 'week'
                ? getWeekCategory(date, curPeriod, i === 0, i + 1 === maxLength)
                : date.format(dateFormat),
        );
    }

    return categories;
};

export const isEmptyChartData = (data: Array<Array<{ date?: string }>> | undefined): boolean =>
    !Array.isArray(data) || !Array.isArray(data[0]) || data[0].length === 0;

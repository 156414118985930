/**
 * Утилиты для работы со строками
 *
 * @author Artem Bakulin
 */

import moment from 'moment';

import { ApiChartValuesType } from '../../components/Charts/ApiChart/types';
import { IDatesPeriod } from '../../types/date.periods';
import { PeriodFormatFunction } from '../../types/global';
import { PolyglotSingleton } from '../services/translation';

/**
 * Возвращает строку, представляющую период между двумя датами
 *
 * @param dateFrom Дата начала периода.
 * @param dateTo Дата окончания периода.
 * @param format Формат даты. Можно указатьт отдельно формат для каждой даты.
 * @param delimiter Разделитель между датами.
 * @param compressIdentical Сжимать две одинаковые даты в одну или нет
 */
export const datePeriodString = (
    dateFrom: Date,
    dateTo: Date,
    format: string | [string, string] | PeriodFormatFunction = 'DD.MM.YYYY',
    delimiter: string = ' — ',
    compressIdentical: boolean = true,
): string => {
    if (typeof format === 'function') {
        return format(dateFrom, dateTo);
    }

    const fromString = moment(dateFrom).format(Array.isArray(format) ? format[0] : format);
    const toString = moment(dateTo).format(Array.isArray(format) ? format[1] : format);

    if (compressIdentical && fromString === toString) {
        return fromString;
    }

    return `${fromString}${delimiter}${toString}`;
};

/**
 * Возвращает период времени из строки
 *
 * @param periodString Строка с периодом времени
 * @param format Формат даты в строке
 * @param delimiter Рзаделитель дат в строке
 */
export const datesPeriodFromString = (
    periodString: string,
    format: string = 'DD.MM.YYYY',
    delimiter: string = ' — ',
): IDatesPeriod => {
    const dates = periodString.split(delimiter).map(dateString => moment(dateString, format, true).toDate());

    return {
        from: dates[0],
        to: dates[1],
    };
};

/**
 * Переводит первый символ строки в верхний регистр
 *
 * @param text Строка, которую необходимо преобразовать
 */
export const capitalize = (text: string) => `${text.charAt(0).toUpperCase()}${text.slice(1)}`;

/**
 * Возвращает строку, представляющую собой продолжительность, из числа
 *
 * @param value Продолжительность в миллисекундах
 */
export const durationString = (value: number) => {
    const valueInMinutes = Math.round(value / 60000);
    const hours = Math.floor(valueInMinutes / 60);
    const minutes = valueInMinutes % 60;

    return PolyglotSingleton.getInstance().t('common.dates.timeTemplate', { hours, minutes });
};

/**
 * Возвращает функцию форматирования числа, согласно переданному типу.
 *
 * @param type Тип числовых данных графика, в который надо форматировать значение
 */
export const formatValue = (
    type: ApiChartValuesType = 'absolute',
    options: Intl.NumberFormatOptions = { maximumFractionDigits: 1 },
) => (value?: number | null) => {
    if (value === undefined || value === null) {
        return '';
    }

    switch (type) {
        case 'duration':
            return durationString(value);
        case 'percent':
            return value.toLocaleString('ru-RU', { ...options, style: 'percent' });
        case 'float':
            return value.toLocaleString('ru-RU', { ...options, style: 'decimal' });
        case 'absolute':
        default:
            return value.toLocaleString('ru-RU', options);
    }
};

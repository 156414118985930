import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
    page: {
        fontFamily: '"HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif',
        fontWeight: 300,
        pageBreakInside: 'avoid',
        pageBreakAfter: 'always',
        height: '209mm',
        width: '297mm',
        overflow: 'hidden',
        padding: '0 0 0 3%',
        color: '#121212',
    },
    content: {
        height: '100%',
    }
}));

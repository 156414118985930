/**
 * Полная схема валидации настроек организации
 *
 * @author Artem Bakulin
 */

import { array, number, object, string } from 'yup';

export const schema = object().shape({
    name: string()
        .trim()
        .required('Обязательное поле'),
    contactPerson: string().nullable(),
    email: string()
        .email('Неверный формат email адреса')
        .nullable(),
    phone: string().nullable(),
    site: string()
        .url('Неверный формал url адреса организации')
        .nullable(),
    description: string().nullable(),
    settings: object().shape({
        workTime: object().shape({
            start: string()
                .trim()
                .transform((value: string) => !Boolean(value) ? null : value)
                .nullable()
                .notRequired()
                .matches(/^([01]\d|2[0-3]):?([0-5]\d)$/, 'Необходимо указать время в формате чч:мм'),
            end: string()
                .trim()
                .transform((value: string) => !Boolean(value) ? null : value)
                .nullable()
                .notRequired()
                .matches(/^([01]\d|2[0-3]):?([0-5]\d)$/, 'Необходимо указать время в формате чч:мм'),
        }),
        minVisitTime: number().required(),
        minLongVisit: number().required(),
        ageGroups: array()
            .notRequired()
            .of(object().shape({
                name: string().required('Необходимо указать название группы'),
                groups: array().min(1, 'Вы должны выбрать хотя бы одну возрастную группу').of(number())
            })),
    }),
});

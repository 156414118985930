/**
 * PDF отчет за полный год.
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import moment from 'moment';
import React, { useEffect, useState } from 'react';

import api from '../../api/analytics.api';
import PdfSection from '../../components/PdfSection';
import { PolyglotSingleton } from '../../lib/services/translation';
import { durationString } from '../../lib/utils/strings';
import { IYearSummaryData } from '../../types/analytics';

import LoyaltyPage from './LoyaltyPage';
import { getMultyChartData } from './Summary';
import DurationPage from './YearSummary/DurationPage';
import FrequencyPage from './YearSummary/FrequencyPage';
import VisitsPage1 from './YearSummary/VisitsPage1';
import VisitsPage2 from './YearSummary/VisitsPage2';
import VisitsPage3 from './YearSummary/VisitsPage3';
import VisitsPagesMore from './YearSummary/VisitsPagesMore';

export const getChartData = <T extends Exclude<keyof IYearSummaryData, 'date'>>(
    data: IYearSummaryData[],
    key: T,
    field: keyof IYearSummaryData[T],
): Array<{ date: string; value: number }> =>
    data.map(row => ({
        date: moment(row.date).format('MMM YY'),
        value: (row[key][field] as unknown) as number,
    }));

interface IProps {
    organizationId: number;
    year: number;
}

const polyglot = PolyglotSingleton.getInstance();

const PdfYearSummary = ({ organizationId, year }: IProps) => {
    const [inProgress, setProgress] = useState(false);
    const [data, setData] = useState<IYearSummaryData[]>();

    useEffect(() => {
        // tslint:disable-next-line: no-floating-promises
        (async () => {
            setProgress(true);
            try {
                const summaryData = (await api.summaryData(organizationId, {
                    periodType: 'year',
                    year,
                })) as IYearSummaryData[];
                setData(summaryData);
            } catch (error) {
                // tslint:disable-next-line:no-console
                console.log(error);
            }
            setProgress(false);
        })();
    }, [organizationId, year]);

    if (inProgress || data === undefined) {
        return null;
    }

    const visitsByPriceAndAgesPagesCount: number = Object.keys(data[0].visitsByPriceAndAge).length;

    return (
        <>
            <PdfSection page={1} title={polyglot.t('visits.header')} period={year.toString()}>
                <VisitsPage1 data={data} />
            </PdfSection>
            <PdfSection page={2} title={polyglot.t('visits.header')} period={year.toString()}>
                <VisitsPage2 data={data} />
            </PdfSection>
            <PdfSection page={3} title={polyglot.t('visits.header')} period={year.toString()}>
                <VisitsPage3 data={data} />
            </PdfSection>
            <VisitsPagesMore startPage={4} data={data} year={year} />
            <PdfSection
                page={visitsByPriceAndAgesPagesCount + 4}
                title={polyglot.t('involvement.header')}
                period={year.toString()}
            >
                <DurationPage
                    data={getMultyChartData(data, 'longVisitsByPrice')}
                    id="longVisitsByPrice"
                    title={polyglot.t('involvement.charts.longVisitsByPrice')}
                    numberFormat="#.%"
                />
            </PdfSection>
            <PdfSection
                page={visitsByPriceAndAgesPagesCount + 5}
                title={polyglot.t('involvement.header')}
                period={year.toString()}
            >
                <DurationPage
                    data={getMultyChartData(data, 'longVisitsByAge')}
                    id="longVisitsByAge"
                    title={polyglot.t('involvement.charts.longVisitsByAge')}
                    numberFormat="#.%"
                />
            </PdfSection>
            <PdfSection
                page={visitsByPriceAndAgesPagesCount + 6}
                title={polyglot.t('involvement.header')}
                period={year.toString()}
            >
                <DurationPage
                    data={getMultyChartData(data, 'durationByPrice')}
                    id="durationByPrice"
                    title={polyglot.t('involvement.charts.averageByPrice')}
                    numberFormat={durationString}
                />
            </PdfSection>
            <PdfSection
                page={visitsByPriceAndAgesPagesCount + 7}
                title={polyglot.t('involvement.header')}
                period={year.toString()}
            >
                <DurationPage
                    data={getMultyChartData(data, 'durationByAge')}
                    id="durationByAge"
                    title={polyglot.t('involvement.charts.averageByAge')}
                    numberFormat={durationString}
                />
            </PdfSection>
            <PdfSection
                page={visitsByPriceAndAgesPagesCount + 8}
                title={polyglot.t('frequency.header')}
                period={year.toString()}
            >
                <FrequencyPage data={data} />
            </PdfSection>
            <PdfSection
                page={visitsByPriceAndAgesPagesCount + 9}
                title={polyglot.t('loyalty.header')}
                period={year.toString()}
            >
                <LoyaltyPage lastVisits={data[0].lastVisits} loyaltyVisits={data[0].loyaltyVisits} />
            </PdfSection>
        </>
    );
};

export default PdfYearSummary;

import { createStyles, Theme } from '@material-ui/core';

export const styles = (theme: Theme) =>
    createStyles({
        select: {
            width: 300,
        },
        actions: {
            '& > *': {
                margin: `0 ${theme.spacing(2)}px`,
            },
        },
    });

import { Fab, WithStyles, withStyles } from '@material-ui/core';
// tslint:disable-next-line:no-submodule-imports
import { FabProps } from '@material-ui/core/Fab';
import React from 'react';

import { styles } from './styles';

interface IProps extends WithStyles<typeof styles>, Pick<FabProps, Exclude<keyof FabProps, 'classes'>> {}

const PageFab = ({ classes, children, ...rest }: IProps) => (
    <Fab {...rest} className={classes.addBtn}>
        {children}
    </Fab>
);

export default withStyles(styles)(PageFab);

import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, Grid } from '@material-ui/core';
import React from 'react';

import OrganizationForm from '../../containers/OrganizationForm';
import { PolyglotSingleton } from '../../lib/services/translation';
import { IOrganization } from '../../types/organizations';

interface IProps {
    isOpen: boolean;
    handleClose(): void;
    organization?: IOrganization;
}

const polyglot = PolyglotSingleton.getInstance();

const OrganizationModal = ({ isOpen, handleClose, organization }: IProps) => (
    <Dialog open={isOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
            {organization !== undefined ? organization.name : 'Новая организация'}
        </DialogTitle>
        <DialogContent>
            {organization === undefined ? (
                <DialogContentText>{polyglot.t('organizations.require')}</DialogContentText>
            ) : null}
            <OrganizationForm
                onSuccess={handleClose}
                onError={handleClose}
                organization={organization}
                actionsBtns={
                    <Grid item container spacing={3} justify="flex-end">
                        <Grid item>
                            <Button onClick={handleClose} variant="outlined">
                                {polyglot.t('common.buttons.cancel')}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button type="submit" color="primary" variant="contained">
                                {organization !== undefined
                                    ? polyglot.t('common.buttons.save')
                                    : polyglot.t('common.buttons.create')}
                            </Button>
                        </Grid>
                    </Grid>
                }
            />
        </DialogContent>
    </Dialog>
);

export default OrganizationModal;

/**
 * Стили для годового отчета в PDF.
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import { createStyles, Theme } from '@material-ui/core';

export const styles = (theme: Theme) =>
    createStyles({
        title: {
            flex: 'none',
            fontSize: '1rem',
            marginBottom: '10px',
        },
        legend: {
            width: '100%',
            height: 40,
            flex: 'none',
        },
        fullHeight: {
            height: '100%',
        },
        fullWidth: {
            width: '100%',
        },
        chartRow: {
            flexShrink: 1,
            flexGrow: 1,
        },
        chartRowTwoThirds: {
            flexShrink: 1,
            height: '75%',
        },
        chart: {
            height: '100%',
            flexGrow: 1,
            flexShrink: 1,
        },
        chartWrapper: {
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap',
        },
        indicatorsRow: {
            flex: 'none',
            flexWrap: 'nowrap',
            marginBottom: theme.spacing(3),
            maxHeight: 215,
        },
    });

/**
 * Математические функции.
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import { formatValue } from './strings';

/**
 * Возвращает разницу между двумя значениями.
 */
export const difference = (a: number, b: number, isRaw: boolean = false) => {
    const diff = (a / b) - 1;

    return isRaw ? diff : `${diff > 0 ? '+' : ''}${formatValue('percent')(diff)}`;
}

/**
 * Абстрактный класс API, который должны наследовать все API классы.
 *
 * @author Artem Bakulin
 */

import axios, { AxiosInstance } from 'axios';
import moment from 'moment';

import { apiHostPrefix } from '../lib/utils/urls';


export const MIN_DATE = moment('2018-08-10').toDate();

/**
 * Сериализует JSON в строку, заменяя пустые строки на null.
 *
 * @param keys Массив ключей, к которым надо применить замену.
 */
// tslint:disable-next-line:no-any
export const stringifyWithoutEmpty = (keys?: string[]) => (data: any) =>
    JSON.stringify(data, (key, value) => {
        if (value === '') {
            if ((keys !== undefined && keys.includes(key)) || keys === undefined) {
                return null;
            }
        }

        return value;
    });

/**
 * Парсит JSON строку заменяя null значения на пустые строки.
 *
 * @param keys  Массив ключей, к которым надо применить замену.
 */
export const parseNullToEmpty = <T>(keys?: Array<keyof T>) => (data: string): T =>
    JSON.parse(data, (key, value) => {
        if (value === null) {
            if ((keys !== undefined && keys.includes(key as keyof T)) || keys === undefined) {
                return '';
            }
        }

        return value;
    }) as T;

/**
 * Абстрактный класс API, который должны наследовать все API классы.
 *
 * *В базовом классе происходит создание и инициализация экземпляра axios*
 */
export abstract class BaseApi {
    /** URL префикс для всех запросов в api */
    protected readonly apiPrefix: string;
    /** Экземпляр axios для взаимодйствия с backend */
    protected readonly axios: AxiosInstance;

    /**
     * Конструктор класса
     *
     * @param urlPrefix URL префикс, актуальный для конкретного класса API
     */
    protected constructor(urlPrefix: string = '') {
        this.apiPrefix = `${apiHostPrefix()}/api${urlPrefix}`;
        this.axios = axios.create({ baseURL: this.apiPrefix });
    }

    /**
     * Возвращает строку, которую можно использовать в url для сортировки
     *
     * @param field Название поля для сортировки
     * @param direction Направление сортировки
     */
    // tslint:disable-next-line:prefer-function-over-method
    protected getGroupBy(field: string, direction: 'ASC' | 'DESC' | 'asc' | 'desc' = 'ASC'): string {
        return `${direction.toUpperCase() === 'DESC' ? '-' : '+'}${field}`;
    }
}

/**
 * Reducers для страницы Dashboard организации
 *
 * @author Artem Bakulin
 */

import { Action, handleActions } from 'redux-actions';

import { analyticsPagesActions } from '../actions/analytics.pages';
import { IDateAnalyticsPeriod } from '../types/analytics';
import { IAnalyticsPageResult, IAnalyticsPageState } from '../types/global';

export interface IDashboardResultPeriod {
    /** Кол-во посетителей */
    visitors: Array<IDateAnalyticsPeriod<string>>;
    /** Кол-во состоятельных посетителей */
    richVisitors: Array<IDateAnalyticsPeriod<string>>;
    /** Процент состоятельных посетителей */
    richVisitorsPercent: Array<IDateAnalyticsPeriod<string>>;
    /** Кол-во премиальных авто */
    richVisitorsUniqs: Array<IDateAnalyticsPeriod<string>>;
    /** Процент премиальных авто */
    richVisitorsUniqsPercent: Array<IDateAnalyticsPeriod<string>>;
    /** Среднее кол-во посетителей в будний день */
    avgInWeekday?: Array<IDateAnalyticsPeriod<string>>;
    /** Среднее кол-во посетителей в выходной день */
    avgInDayOff?: Array<IDateAnalyticsPeriod<string>>;
    /** Соотношение между кол-вом посетителей в выходной и будний дни */
    dayRatio?: Array<IDateAnalyticsPeriod<string>>;
    /** Кол-во уникальных посетителей за период */
    uniqVisitors: Array<IDateAnalyticsPeriod<string>>;
    /** Кол-во лояльных посетителей за период */
    loyaltyVisitors: Array<IDateAnalyticsPeriod<string>>;
    /** Процент лояльных посетителей от уникальных посетителей */
    loyaltyVisitorsPercent: Array<IDateAnalyticsPeriod<string>>;
    /** Кол-во новых посетителей */
    newVisitors: Array<IDateAnalyticsPeriod<string>>;
    /** Процент новых посетителей */
    newVisitorsPercent: Array<IDateAnalyticsPeriod<string>>;
    /** Средняя продолжительность визита */
    avgVisitDuration: Array<IDateAnalyticsPeriod<string>>;
    /** Процент длительных визитов */
    longVisitsPercent: Array<IDateAnalyticsPeriod<string>>;
    /** Кол-во постоянных посетителей */
    regularVisitors: Array<IDateAnalyticsPeriod<string>>;
    /** Процент постоянных посетителей */
    regularVisitorsPercent: Array<IDateAnalyticsPeriod<string>>;
}

const initialState: IAnalyticsPageState<IDashboardResultPeriod> = {
    inProgress: false,
    results: {},
};

/**
 * Reducers для страницы Dashboard организации
 */
// tslint:disable-next-line:no-any
export const dashboardPageReducer = handleActions<IAnalyticsPageState<IDashboardResultPeriod>, any>(
    {
        [analyticsPagesActions.dashboard.send.toString()]: (state: IAnalyticsPageState<IDashboardResultPeriod>) => ({
            ...state,
            inProgress: true,
        }),
        [analyticsPagesActions.dashboard.success.toString()]: (
            state: IAnalyticsPageState<IDashboardResultPeriod>,
            action: Action<{ hashString: string; data: IAnalyticsPageResult<IDashboardResultPeriod> }>,
        ) => ({
            ...state,
            results:
                state.results[action.payload.hashString] !== undefined
                    ? state.results
                    : {
                          ...state.results,
                          [action.payload.hashString]: action.payload.data,
                      },
            inProgress: false,
            error: undefined,
        }),
        [analyticsPagesActions.dashboard.fail.toString()]: (
            state: IAnalyticsPageState<IDashboardResultPeriod>,
            action: Action<Error>,
        ) => ({
            ...state,
            inProgress: false,
            error: action.payload,
        }),
    },
    initialState,
);

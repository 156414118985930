/**
 * Период дат
 */
export interface IDatesPeriod {
    /** Дата начала периода */
    from: Date;
    /** Дата окончания периода */
    to: Date;
}

export enum CurrentPeriodTypeEnum {
    Custom = 'custom',
    Today = 'today',
    CurrentMonth = 'currentMonth',
    Yesterday = 'yesterday',
    Last7Days = 'last7days',
    LastWeek = 'lastWeek',
    Last30Days = 'last30days',
    LastMonth = 'lastMonth',
    LastQuarter = 'lastQuarter',
    LastSeason = 'lastSeason',
    CurrentYear = 'currentYear'
}

export enum LastPeriodTypeEnum {
    Custom = 'custom',
    Prior = 'prior',
    Comparable = 'comparable',
}

export enum SeasonsEnum {
    Winter = 0,
    Spring = 1,
    Summer = 2,
    Autumn = 3,
}

export type AnalyticsDataGranularity = 'all' | 'year' | 'quarter' | 'month' | 'week' | 'day';

/**
 * Названия сезонов
 */
export const SeasonsNames: { [season in SeasonsEnum]: string } = {
    [SeasonsEnum.Winter]: 'common.dates.seasons.winter',
    [SeasonsEnum.Spring]: 'common.dates.seasons.spring',
    [SeasonsEnum.Summer]: 'common.dates.seasons.summer',
    [SeasonsEnum.Autumn]: 'common.dates.seasons.fall',
};

export interface ICurrentPeriodAccurate {
    type: 'accurate';
    value: number;
    unit: 'months' | 'days';
}

export interface ICurrentPeriodRounded {
    type: 'rounded';
    value: number;
    unit: 'days' | 'weeks' | 'quarters' | 'months' | 'seasons' | 'years';
}

export interface ICurrentPeriodCustom {
    type: 'custom';
    period: IDatesPeriod;
}

export type CurrentPeriod = ICurrentPeriodAccurate | ICurrentPeriodRounded | ICurrentPeriodCustom;
export type CurrentPeriodPresetsTypes = Exclude<CurrentPeriodTypeEnum, CurrentPeriodTypeEnum.Custom>;
export type CurrentPresetPeriod = Exclude<CurrentPeriod, ICurrentPeriodCustom>;

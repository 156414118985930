/**
 * Первая страница для раздела "Продолжительность" месячного отчета.
 *
 * @author Artem Bakulin <dekkyartem@gmail.com>
 */

import { CircleBullet, ColumnSeries, LabelBullet, Legend, LineSeries } from '@amcharts/amcharts4/charts';
import { color } from '@amcharts/amcharts4/core';
import { Grid, WithStyles, withStyles } from '@material-ui/core';
import React from 'react';

import { styleComporableSerie } from '../../../components/Charts/ColumnChartStacked';
import { PolyglotSingleton } from '../../../lib/services/translation';
import { durationString } from '../../../lib/utils/strings';
import { ICustomSummaryData, IMonthSummaryData } from '../../../types/analytics';
import { PeriodFormatFunction } from '../../../types/global';
import Chart from '../Chart';
import { styles } from '../styles';
import { CHART_DATE_FORMAT, getMultyChartData } from '../Summary';

interface IProps extends WithStyles<typeof styles> {
    data: IMonthSummaryData | ICustomSummaryData;
    chartType: 'column' | 'line';
    periodFormat?: string | PeriodFormatFunction;
}

const polyglot = PolyglotSingleton.getInstance();

const getSeries = (keys: string[], chartType: 'column' | 'line', comporableCategory?: string) =>
    keys
        .filter((key: string) => key !== 'period')
        .map((key: string): ColumnSeries | LineSeries => {
            if (chartType === 'column') {
                const columnSerie = new ColumnSeries();
                columnSerie.name = key;
                columnSerie.dataFields.categoryX = 'period';
                columnSerie.dataFields.valueY = key;
                columnSerie.columns.template.maxWidth = 100;
                columnSerie.columns.template.tooltipText = '{valueY}';
                columnSerie.stacked = true;
                columnSerie.sequencedInterpolation = true;
                columnSerie.columns.template.tooltipText = '[bold]{name}[/]\n[font-size:14px]{valueY}';
                columnSerie.columns.template.maxWidth = 100;

                if (comporableCategory !== undefined) {
                    styleComporableSerie(columnSerie, comporableCategory);
                }

                const labelBullet = columnSerie.bullets.push(new LabelBullet());
                labelBullet.label.text = '{valueY}';
                labelBullet.label.fill = color('white');
                labelBullet.locationY = 0.5;

                return columnSerie;
            }

            const serie = new LineSeries();
            const bullet = new CircleBullet();
            serie.name = key;
            serie.dataFields.categoryX = 'period';
            serie.dataFields.valueY = key;
            serie.bullets.push(bullet);

            return serie;
        }) as ColumnSeries[] | LineSeries[];

const getLegend = () => {
    const legend = new Legend();
    legend.position = 'top';
    legend.markers.template.width = 20;
    legend.markers.template.height = 20;
    legend.labels.template.fontSize = 12;
    legend.itemContainers.template.paddingTop = 0;
    legend.itemContainers.template.paddingBottom = 5;
    legend.itemContainers.template.paddingRight = 0;
    legend.itemContainers.template.paddingLeft = 0;

    return legend;
};

const DurationPage = ({ classes, data, chartType, periodFormat = CHART_DATE_FORMAT }: IProps) => {
    const byPriceData = [
        ...('comporable' in data ? getMultyChartData([data.comporable[0]], 'longVisitsByPrice', periodFormat) : []),
        ...getMultyChartData(data.months, 'longVisitsByPrice', periodFormat),
    ];
    const byAgeData = [
        ...('comporable' in data ? getMultyChartData([data.comporable[0]], 'longVisitsByAge', periodFormat) : []),
        ...getMultyChartData(data.months, 'longVisitsByAge', periodFormat),
    ];
    const avgByPriceData = [
        ...('comporable' in data ? getMultyChartData([data.comporable[0]], 'durationByPrice', periodFormat) : []),
        ...getMultyChartData(data.months, 'durationByPrice', periodFormat),
    ];
    const avgByAgeData = [
        ...('comporable' in data ? getMultyChartData([data.comporable[0]], 'durationByAge', periodFormat) : []),
        ...getMultyChartData(data.months, 'durationByAge', periodFormat),
    ];
    const comporable = 'comporable' in data ? byPriceData[0].period : undefined;

    return (
        <Grid container spacing={2} alignItems="stretch" className={classes.chartRow}>
            <Grid item xs={6}>
                <Chart
                    id={`longVisitsByPriceChart`}
                    title={polyglot.t('involvement.charts.longVisitsByPrice')}
                    data={byPriceData}
                    series={getSeries(Object.keys(data.months[0].longVisitsByPrice), chartType, comporable)}
                    comporableCategory={comporable}
                    legend={getLegend()}
                    disableAxis={chartType === 'column'}
                    numberFormat="#.%"
                />
            </Grid>
            <Grid item xs={6}>
                <Chart
                    id={`longVisitsByAgeChart`}
                    title={polyglot.t('involvement.charts.longVisitsByAge')}
                    data={byAgeData}
                    series={getSeries(Object.keys(data.months[0].longVisitsByAge), chartType, comporable)}
                    comporableCategory={comporable}
                    legend={getLegend()}
                    disableAxis={chartType === 'column'}
                    numberFormat="#.%"
                />
            </Grid>

            <Grid item xs={6}>
                <Chart
                    id={`averageByPriceChart`}
                    title={polyglot.t('involvement.charts.averageByPrice')}
                    data={avgByPriceData}
                    series={getSeries(Object.keys(data.months[0].durationByPrice), chartType, comporable)}
                    numberFormat={durationString}
                    legend={getLegend()}
                    disableAxis={chartType === 'column'}
                    comporableCategory={comporable}
                    minY={3000000}
                />
            </Grid>
            <Grid item xs={6}>
                <Chart
                    id={`averageByAgeChart`}
                    title={polyglot.t('involvement.charts.averageByAge')}
                    data={avgByAgeData}
                    series={getSeries(Object.keys(data.months[0].durationByAge), chartType, comporable)}
                    numberFormat={durationString}
                    legend={getLegend()}
                    disableAxis={chartType === 'column'}
                    comporableCategory={comporable}
                    minY={3000000}
                />
            </Grid>
        </Grid>
    );
};

export default withStyles(styles)(DurationPage);

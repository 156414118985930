/**
 * Поля ввода для настроек визитов организации
 *
 * @author Artem Bakulin
 */

import { Grid, Typography } from '@material-ui/core';
import { Field } from 'formik';
import React from 'react';

import TextField from '../../components/Formik/TextField';
import { PolyglotSingleton } from '../../lib/services/translation';

const polyglot = PolyglotSingleton.getInstance();

interface IProps {
    prefix?: string
}

const SettingsFields = ({ prefix }: IProps) => {
    const name = prefix !== undefined ? `${prefix}.` : '';

    return (
        <>
            <Grid item xs={12}>
                <Typography>{polyglot.t('organizations.settings.workTime')}</Typography>
            </Grid>
            <Grid item xs={12} container spacing={2}>
                <Grid item>
                    <Field
                        name={`${name}workTime.start`}
                        label={polyglot.t('organizations.settings.workTimeStart')}
                        component={TextField}
                    />
                </Grid>
                <Grid item>
                    <Field
                        name={`${name}workTime.end`}
                        label={polyglot.t('organizations.settings.workTimeEnd')}
                        component={TextField}
                    />
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Typography>{polyglot.t('organizations.settings.visitsTimes')}</Typography>
            </Grid>
            <Grid item xs={12} container spacing={2}>
                <Grid item>
                    <Field
                        name={`${name}minVisitTime`}
                        type="number"
                        label={polyglot.t('organizations.settings.minVisitTime')}
                        component={TextField}
                    />
                </Grid>
                <Grid item>
                    <Field
                        name={`${name}minLongVisit`}
                        type="number"
                        label={polyglot.t('organizations.settings.longVisitTime')}
                        component={TextField}
                    />
                </Grid>
            </Grid>
        </>
    );
}

export default SettingsFields;

import { mixed, object, string } from 'yup';

export const schema = object().shape({
    type: string()
        .required('Обязательное поле')
        .oneOf(['all', 'employee', 'commercial']),
    file: mixed()
        .required('Необходимо указать файл с данными')
        .test('file', 'Вы должны выбрать CSV файл', (value?: File) => value !== undefined && value.type === 'text/csv'),
});
